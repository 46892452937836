import { A } from '@solidjs/router'
import Logo from '../logo.png'
import { Show } from 'solid-js';
interface IProps {
	showQuote: boolean;
}

const SuccessScreen = (props: IProps) => {
	return (
		<div class="single-import_tab">
			<div class="single-import_tab--container">
				<div class="single-import_tab--head">
					<p class="st-logo">
						<img src={Logo} alt="" />
					</p>
				</div>
				<div class="single-import_tab--body">
					<span class="status-icon">
						<ScsIcon />
					</span>
					<span class="status-text scs quote">
						הלקוחות צורפו בהצלחה
					</span>
					<Show when={props.showQuote}>
						<p class="st-quote">
							<span class="icon">
								<InfoIcon/>
							</span>
							<b>שימו לב!</b>
							<br/>לקוחות עם העדפות חיפוש מהשנה האחרונה נכנסו למאגר לקוחות פעילים 
							<br/>את שאר הלקוחות תמצאו תחת ‘לקוחות פוטנציאליים’
							בהצלחה!<br/>
						</p>
					</Show>
				</div>
				<div class="single-import_tab--actions">
					<A
						href="/customers?type=potential"
						class="btn--next"
					>
						קדימה, התחילו לעבוד!
					</A>
				</div>
			</div>
		</div>
	)
}

export default SuccessScreen;


const ScsIcon = () => (
<svg width="189" height="189" viewBox="0 0 189 189" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M94.5 162.422C132.012 162.422 162.422 132.012 162.422 94.5C162.422 56.9878 132.012 26.5781 94.5 26.5781C56.9878 26.5781 26.5781 56.9878 26.5781 94.5C26.5781 132.012 56.9878 162.422 94.5 162.422Z" fill="#234F68"/>
	<path opacity="0.3" d="M94.5 41.3437C102.11 41.3437 108.303 35.5645 109.106 28.1699C104.399 27.1392 99.5174 26.5781 94.5 26.5781C56.9865 26.5781 26.5781 56.9865 26.5781 94.5C26.5781 99.5174 27.1392 104.399 28.1699 109.106C35.5674 108.303 41.3437 102.11 41.3437 94.5C41.3437 65.1902 65.1902 41.3437 94.5 41.3437Z" fill="white"/>
	<path opacity="0.15" d="M160.831 79.8938C153.433 80.697 147.657 86.8897 147.657 94.5C147.657 123.81 123.81 147.656 94.5007 147.656C86.8905 147.656 80.6978 153.435 79.8945 160.83C84.6018 161.861 89.4833 162.422 94.5007 162.422C132.014 162.422 162.423 132.013 162.423 94.5C162.423 89.4826 161.861 84.6011 160.831 79.8938Z" fill="black"/>
	<path opacity="0.04" d="M94.5 189C125.488 189 150.609 185.034 150.609 180.141C150.609 175.248 125.488 171.281 94.5 171.281C63.5116 171.281 38.3906 175.248 38.3906 180.141C38.3906 185.034 63.5116 189 94.5 189Z" fill="black"/>
	<path d="M44.4375 69.1829C49.9835 58.2593 59.0023 49.3822 70.0293 44.0164" stroke="white" stroke-width="4.6875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M78.9265 124.338L54.0316 99.3637C52.8592 98.1884 52.8592 96.2807 54.0316 95.1053L62.5219 86.5885C63.6943 85.4132 65.5961 85.4132 66.7685 86.5885L84.8859 104.762L121.587 63.0344C122.683 61.7882 124.582 61.6671 125.825 62.7686L134.829 70.7391C136.072 71.8406 136.191 73.7424 135.095 74.9886L92.0472 123.928C88.6127 127.835 82.6002 128.024 78.9265 124.338Z" fill="#8BC83F"/>
</svg>
)
const InfoIcon = () => (
<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M8.125 0C3.64502 0 0 3.64502 0 8.125C0 12.605 3.64502 16.25 8.125 16.25C12.605 16.25 16.25 12.605 16.25 8.125C16.25 3.64502 12.605 0 8.125 0ZM8.125 1.25C11.9287 1.25 15 4.32129 15 8.125C15 11.9287 11.9287 15 8.125 15C4.32129 15 1.25 11.9287 1.25 8.125C1.25 4.32129 4.32129 1.25 8.125 1.25Z" fill="#234F68"/>
	<path d="M6 6.11133C6.052 4.93777 6.71294 4 8.02131 4C9.19403 4 10 4.84285 10 5.96206C10 6.81463 9.6276 7.41923 9.03196 7.83788C8.44178 8.24506 8.26634 8.55839 8.26634 9.12989V9.47735H7.4581V8.98062C7.45264 8.26306 7.74643 7.77452 8.38352 7.3287C8.92352 6.9419 9.13849 6.59393 9.13849 6.01182C9.13849 5.338 8.68353 4.8442 7.98935 4.8442C7.28716 4.8442 6.84813 5.33518 6.79759 6.11133H6Z" fill="#234F68"/>
	<path d="M7.27628 11.242C7.27628 10.7962 7.54906 10.4841 7.92543 10.4841C8.30179 10.4841 8.56428 10.7962 8.56392 11.242C8.56392 11.6815 8.30324 12 7.92543 12C7.54761 12 7.27628 11.6815 7.27628 11.242Z" fill="#234F68"/>
</svg>
)