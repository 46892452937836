import { For, createEffect, createSignal } from "solid-js";
import Picker from "../../Components/Picker";
import { Dots } from "../MyCustomers/Dots";
import { pricify } from "../AddAsset/AssetInfo";
import { logEvent } from "../../analytics";
import Spacer from "../../Components/Spacer";
import { Nav } from "../Home/Nav";
import { Link } from "@solidjs/router";
import { getDeals } from "./getDeals";

export default function Deals() {
  const sectionOptions = {
    closedDeals: "עסקאות שנסגרו",
    unclosedDeals: "עסקאות שטרם נסגרו",
  };
  const [section] = createSignal<"closedDeals" | "unclosedDeals">(
    "closedDeals"
  );

  const query = getDeals();

  return (
    <div class="w-full md:pb-8 md:h-screen overflow-y-auto">
      <Nav />

      <div class="flex justify-center flex-col items-center p-2 md:py-0 md:px-6">
        <div class="relative text-right w-full">
          <div class="text-5xl font-bold text-right tracking-wide w-full">
            {sectionOptions[section()]} <span class="text-green">.</span>
          </div>
          <Spacer height="6px" />
          <div class="text-right w-full text-darkGrey">
            הצטרף למשפחת גשר ותהיה חלק ממהפכה בעולם הנדל”ן
          </div>
          <Spacer />
          <div class="h-[1px] bg-lightGrey w-full" />
        </div>
      </div>
      <h3 class="text-left px-8 py-4">
        סה״כ: <strong>{query.data?.length}</strong> עסקאות
      </h3>
      <div class="flex gap-4 flex-wrap pt-2 justify-center">
        <For
          each={query.data.toSorted((a, b) => {
            return new Date(a.deal_date) > new Date(b.deal_date) ? 1 : -1;
          })}
        >
          {(deal) => {
            const isBuying = () => deal.realtor_relation_to_deal === "רכישה";
            const isSelling = () => deal.realtor_relation_to_deal === "מכירה";
            return (
              <Link
                href={
                  "/deal/" + deal.id + "/" + (isBuying() ? "buyer" : "seller")
                }
                class="shadow-md flex flex-col p-4 px-6 w-3/12 relative"
              >
                <div
                  class="absolute right-1 top-2 bottom-2 w-1 rounded-xl"
                  classList={{
                    "bg-green": isBuying(),
                    "bg-error": isSelling(),
                  }}
                ></div>
                <div class="flex justify-between">
                  <p class="text-lg text-blue font-bold">
                    {isBuying() && deal.purchasing_customer?.name}
                    {isSelling() && deal.selling_customer?.name}
                  </p>
                  <p>
                    <Dots />
                  </p>
                </div>
                <div
                  class="text-base font-bold"
                  classList={{
                    "text-green": deal.realtor_relation_to_deal === "רכישה",
                    "text-error": deal.realtor_relation_to_deal === "מכירה",
                  }}
                >
                  {deal.realtor_relation_to_deal}
                </div>
                <div class="flex justify-between">
                  <p>{deal.asset.address}</p>
                  <p> {pricify(deal.payed_price)}</p>
                </div>
              </Link>
            );
          }}
        </For>
      </div>
    </div>
  );
}
