import { SetStoreFunction } from "solid-js/store";
import { Setter, Show, createEffect } from "solid-js";
interface IProps {
    setTab: Setter<string>;
    tab: string;
    activeTotal: number;
    dormantTotal: number;
    activeCount: number;
    dormantCount: number;
}

const CustomersTabsNav = (props: IProps) => {
    return (
        <div class="asset--customers__nav">
            <button
                class={props.tab === 'active' ? 'active btn--nav' : 'btn--nav'}
                onClick={() => props.setTab('active')}
            >
                לקוחות פעילים 
                (
                    <Show when={props.activeCount > 0}>
                        {props.activeCount}/
                    </Show>
                    {props.activeTotal}
                )
            </button>
            <button
                class={props.tab === 'dormant' ? 'active btn--nav' : 'btn--nav'}
                onClick={() => props.setTab('dormant')}
            >
                לקוחות רדומים 
                (
                    <Show when={props.dormantCount > 0}>
                        {props.dormantCount}/
                    </Show>
                    {props.dormantTotal}
                )
            </button>
        </div>
    )
}

export default CustomersTabsNav