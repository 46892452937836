import {
  Component,
  createEffect,
  createSignal,
  DEV,
  lazy,
  onMount,
  Show,
} from "solid-js";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

import logo from "./Icons/logo.svg";
import Spacer from "./Components/Spacer";
import { BottomNavbar } from "./Components/BottomNavbar";
import { initializeApp } from "firebase/app";
import { setLoggedIn, user } from "./globalState";

import { useLocation, useNavigate } from "@solidjs/router";
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth";
import { getUserDetails } from "./api/getUserDetails";
import axios from "axios";
import { Capacitor } from "@capacitor/core";
import { SafeArea } from "capacitor-plugin-safe-area";
import { createMutable } from "solid-js/store";
import { SideBarDesktop } from "./SideBarDesktop";
import { initializeNotifications } from "./Hooks/initializeNotifications";
import { isSmall } from "./Pages/Home/isSmall";
import { openWeeklyReport } from "./openWeeklyReport";
import AppRoutes from "./AppRoutes";
import { firebaseConfig } from "./firebaseConfig";
import ToastrComponent from "./Components/Toastr/Toastr";
import { getAllUserData, getUserActiveAssets, getUserBuyers, getUserLeads, getUserSellers } from "./Store/User";
import './styles/sass/main.sass'
import posthog from "posthog-js";
import ConnectTeamOnBoarding from "./Pages/ConnectTeam/OnBoarding";
import { checkWhatsupStatus } from "./Store/SocialNetworks";
import AllModalsContainer from "./Components/Modals/AllModals";
declare global {
  const appVersion: string;
}
// App.js

var link = document.querySelector("link[rel~='icon']");
if (!link) {
  link = document.createElement("link");
  link.rel = "icon";
  document.head.appendChild(link);
}
link.href = logo;

export const [callModal, setCallModal] = createSignal();

const App: Component = () => {
  const navigate = useNavigate();
  const location = useLocation();

  onMount(async () => {
    initPostHog()
    enableZoomOnWeb();
    // console.log('mount app web')
    await initFirebase();
    createSafeAreas();
  });



  const initPostHog = async () => {
    if (import.meta.env.MODE !== 'development') {
      posthog.init(import.meta.env.VITE_POSTHOG_KEY)
    }
  }

  return (
    <div
      class="font-Noto-Sans h-full"
      style={{ direction: "rtl" }}
      id="mainview"
    >
      <div class="hidden lg:block fixed inset-0 h-2 bg-blue z-30 rounded-b-2xl"></div>
      <SafeAreaDiv position={"top"} />
      <main class="lg:flex lg:w-screen">
        <SideBarDesktop />
        <AppRoutes />
      </main>

      <Show when={(user.name && !location.pathname.includes("/public")) && !location.pathname.includes("/public-agent") && !location.pathname.includes("/import")}>
        <Spacer height="120px" class="md:hidden" />
        <BottomNavbar />
      </Show>
      <SafeAreaDiv position={"bottom"} />
      <ToastrComponent />
      {/* <ConnectTeamOnBoarding />
      <AllModalsContainer /> */}
    </div>
  );

  async function initFirebase() {
    if (Capacitor.getPlatform() === "web") {
      console.log('start init Firebase Analytics on web')
      await FirebaseAnalytics.initializeFirebase(firebaseConfig);
      console.log('finished init Firebase Analytics on web')
    }

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    let auth;
    if (Capacitor.isNativePlatform()) {
      auth = initializeAuth(app, {
        persistence: indexedDBLocalPersistence,
      });
      // console.log('init Firebase Analytics on Native')
    } else {
      auth = getAuth();
    }
    auth.onAuthStateChanged(async (user) => {
      if (user && !location.pathname.includes('registration')) {
        const token = await user.getIdToken();
        axios.defaults.headers.common.Authorization = token;
        await getUserDetails();
        // await checkWhatsupStatus()
        await getAllUserData()
        initializeNotifications(navigate);
      } else {
        setLoggedIn(false);
        if (!location.pathname.includes("public") && !location.pathname.includes("registration") && !location.pathname.includes("/a/") && !location.pathname.includes("/terms") ) {
          navigate("/login");
        }
      }
    });
  }
};

export default App;
const createSafeAreas = () =>
  SafeArea.getSafeAreaInsets().then(({ insets }) => {
    if (Capacitor.getPlatform() === "ios") {
      screenState.bottom = Math.round(insets.bottom / 2);
      screenState.top = Math.round(insets.top * 0.9);
    } else {
      screenState.bottom = 0;
      screenState.top = 0;
    }
  });

export const screenState = createMutable({ bottom: 0, top: 0 });

function SafeAreaDiv({ position }: { position: "top" | "bottom" }) {
  const height = () => screenState[position];

  return (
    <div
      style={{ width: "100%", height: `${height()}px`, "z-index": 100 }}
      class="fixed bg-white bg-opacity-0 md:hidden"
      classList={{
        "bottom-0": position === "bottom",
        "top-0": position === "top",
      }}
    >
      <Spacer height={height() + "px"} />
    </div>
  );
}

const enableZoomOnWeb = () => {
  if (Capacitor.getPlatform() === "web") {
    document
      .querySelector('meta[name="viewport"]')
      ?.setAttribute(
        "content",
        "viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=2.5"
      );
  }
};

export const desktopWidth = "lg:w-[calc(100vw-192px)]";
