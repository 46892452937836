import { createQuery } from "@tanstack/solid-query";
import axios from "axios";

export const useGetAgent = (id) =>
  createQuery({
    enabled: Boolean(id()),
    queryKey: () => ["agent", id()],
    queryFn: async () => {
      const res = await axios.get(`/access-control/users/${id()}/`);
      return res.data;
    },
  });

export const useGetAgentAssets = (id) =>
  createQuery({
    enabled: Boolean(id),
    queryKey: () => ["agent", id],
    queryFn: async () => {
      const res = await axios.get(`/access-control/users/${id}/public_assets/`);
      return res.data;
    },
  });
