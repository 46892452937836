import Logo from '../logo.png'
import { createEventDispatcher } from '@solid-primitives/event-dispatcher';
interface IProps {
	onTryAgain: (evt:CustomEvent<any>) => void;
}


const ErrorScreen = (props: IProps) => {
	const dispatch = createEventDispatcher(props);
	return (
		<div class="single-import_tab">
			<div class="single-import_tab--container">
				<div class="single-import_tab--head">
					<p class="st-logo">
						<img src={Logo} alt="" />
					</p>
				</div>
				<div class="single-import_tab--body">
					<span class="status-icon">
						<ErrorIcon />
					</span>
					<span class="status-text error">
						קובץ לא תקין!
					</span>
				</div>
				<div class="single-import_tab--actions">
					<button
						class="btn--next"
						onClick={() => dispatch('tryAgain', 'first')}
					>
						נסה שוב
					</button>
				</div>
			</div>
		</div>
	)
}

export default ErrorScreen
const ErrorIcon = () => (
<svg width="189" height="189" viewBox="0 0 189 189" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M94.5 162.422C132.012 162.422 162.422 132.012 162.422 94.5C162.422 56.9878 132.012 26.5781 94.5 26.5781C56.9878 26.5781 26.5781 56.9878 26.5781 94.5C26.5781 132.012 56.9878 162.422 94.5 162.422Z" fill="#234F68"/>
<path opacity="0.3" d="M94.5 41.3437C102.11 41.3437 108.303 35.5645 109.106 28.1699C104.399 27.1392 99.5174 26.5781 94.5 26.5781C56.9865 26.5781 26.5781 56.9865 26.5781 94.5C26.5781 99.5174 27.1392 104.399 28.1699 109.106C35.5674 108.303 41.3437 102.11 41.3437 94.5C41.3437 65.1902 65.1902 41.3437 94.5 41.3437Z" fill="white"/>
<path opacity="0.15" d="M160.831 79.8938C153.433 80.697 147.657 86.8897 147.657 94.5C147.657 123.81 123.81 147.656 94.5007 147.656C86.8905 147.656 80.6978 153.435 79.8945 160.83C84.6018 161.861 89.4833 162.422 94.5007 162.422C132.014 162.422 162.423 132.013 162.423 94.5C162.423 89.4826 161.861 84.6011 160.831 79.8938Z" fill="black"/>
<path opacity="0.04" d="M94.5 189C125.488 189 150.609 185.034 150.609 180.141C150.609 175.248 125.488 171.281 94.5 171.281C63.5116 171.281 38.3906 175.248 38.3906 180.141C38.3906 185.034 63.5116 189 94.5 189Z" fill="black"/>
<path d="M44.4375 69.1829C49.9835 58.2593 59.0023 49.3822 70.0293 44.0164" stroke="white" stroke-width="4.6875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path opacity="0.3" d="M94 149C112.985 149 128.375 146.691 128.375 143.844C128.375 140.996 112.985 138.688 94 138.688C75.0152 138.688 59.625 140.996 59.625 143.844C59.625 146.691 75.0152 149 94 149Z" fill="black"/>
<path d="M126.309 68.1018L119.899 61.6926C117.141 58.934 112.67 58.934 109.912 61.6926L61.6939 109.91C58.9354 112.669 58.9354 117.139 61.6939 119.898L68.1032 126.307C70.8618 129.066 75.3322 129.066 78.0908 126.307L126.309 78.0895C129.066 75.3309 129.066 70.8604 126.309 68.1018Z" fill="#C83F3F"/>
<path d="M119.899 126.307L126.309 119.898C129.067 117.139 129.067 112.669 126.309 109.91L78.0908 61.6926C75.3322 58.934 70.8618 58.934 68.1032 61.6926L61.6939 68.1018C58.9354 70.8604 58.9354 75.3309 61.6939 78.0895L109.912 126.307C112.67 129.064 117.141 129.064 119.899 126.307Z" fill="#C83F3F"/>
<path opacity="0.3" d="M74.1833 67.7719L67.7724 74.1828C66.088 75.85 63.888 76.6922 61.688 76.6922C61.3099 76.6922 60.9489 76.675 60.5708 76.6234C59.0067 73.925 59.3849 70.4187 61.688 68.0984L68.0989 61.6875C70.4192 59.3844 73.9255 59.0062 76.6239 60.5703C76.9505 63.1312 76.1427 65.8125 74.1833 67.7719Z" fill="white"/>
<path opacity="0.15" d="M126.313 119.902L119.902 126.312C117.582 128.616 114.093 128.994 111.394 127.43H111.377C111.05 124.869 111.858 122.187 113.818 120.228L119.146 114.9L111.119 106.891C107.768 103.522 107.768 98.0906 111.119 94.739H111.136L126.313 109.916C129.063 112.666 129.063 117.134 126.313 119.902Z" fill="black"/>
<path d="M67.3602 73.375C66.7002 73.375 66.0402 73.1241 65.5366 72.6205C64.5295 71.6133 64.5295 69.9822 65.5366 68.975L70.6929 63.8187C71.7001 62.8116 73.3312 62.8116 74.3384 63.8187C75.3455 64.8259 75.3455 66.457 74.3384 67.4642L69.1821 72.6205C68.6802 73.1241 68.0202 73.375 67.3602 73.375Z" fill="white"/>
<path d="M114.539 106.085C120.206 100.34 129.456 100.279 135.2 105.944C140.944 111.61 141.006 120.861 135.341 126.605C129.674 132.349 120.424 132.411 114.68 126.746" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M117.499 111.139L110.416 112.738C108.928 113.073 107.599 111.76 107.919 110.266L109.437 103.178C109.784 101.557 111.783 100.952 112.958 102.111L118.524 107.6C119.701 108.762 119.118 110.773 117.499 111.139Z" fill="white"/>
</svg>
)