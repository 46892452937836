import { createQuery } from "@tanstack/solid-query";
import axios from "axios";

const useGetAgency = () =>
  createQuery({
    queryKey: () => {
      return ["get agency details"];
    },
    queryFn: getAgency,
  });

export default useGetAgency;

const getAgency = async () => {
  const userReq = await axios.get("/access-control/agency/details/");
  return userReq.data;
};
