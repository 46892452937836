import { useLocation, useParams } from "@solidjs/router";
import { createQuery } from "@tanstack/solid-query";
import axios from "axios";
import {
  createEffect,
  createSignal,
  For,
  Index,
  onCleanup,
  onMount,
  Show,
} from "solid-js";
import Button from "../../Components/Button";
import { FlexCol, FlexRow } from "../../Components/Flex";
import Heading, { Colors } from "../../Components/Heading";
import Spacer from "../../Components/Spacer";
import {
  RealtorHomeAsset,
  AssetTypeEnum,
  AirConditioningStateEnum,
  AssetFacingEnum,
  AssetConditionEnum,
} from "../../types";
import shareWhatsapp from "../../utilities/shareWhatsApp";
import { WhatsAppIcon } from "../../Icons/WhatsAppIcon";
import { pricify } from "../AddAsset/AssetInfo";
import call from "../../utilities/call";
import { PicturesModal } from "../Assets/PicturesModal";
import { getPublicAsset } from "../Assets/getAsset";
import { Capacitor } from "@capacitor/core";
import { Modal } from "../../Components/Modal";
import TextInput from "../../Components/TextInput";
import { createStore } from "solid-js/store";
import { logEvent } from "../../analytics";
import { isSmall } from "../Home/isSmall";
import { AssetPropertyList, ImagesRoll, MainImage } from "../Assets/ViewAsset";

const PublicAsset = () => {
  const decodedParams = atob(window.location.href.split("?")[1]);
  const queryParams = new URLSearchParams(decodedParams);
  const agentPhone = queryParams.get("agent_phone");
  const clientStatus = queryParams.get("customer_status");
  const hideButtons = queryParams.get('hide_button')

  const params = useParams();
  const asset = getPublicAsset(() => atob(params.id), clientStatus);

  createEffect(() => {
    if (asset.data?.banner.image_url) {
      logEvent("banners-watched", {
        event_category: "banner",
        event_label: asset.data.banner.client,
        banner_id: asset.data.banner.id,
      });
    }
  });
  const [contactUsModal, setContactUsModal] = createSignal(false);
  const [form, setForm] = createStore({ name: "", phone: "", email: "" });
  const [picturesModal, setPicturesModal] = createSignal();
  return (
    <Show when={asset.data}>
      <ContactFormModal
        contactUsModal={contactUsModal}
        setContactUsModal={setContactUsModal}
        setForm={setForm}
        asset={asset}
        form={form}
      />
      <Show when={picturesModal()}>
        <PicturesModal
          pictureModal={picturesModal}
          setPictureModal={setPicturesModal}
          asset={asset}
        />
      </Show>

      <Show when={!isSmall()}>
        <div class="w-full">
          <div class="w-full flex gap-4 justify-end p-8">
            <Show when={!hideButtons}>
              <Button
                class="w-fit md:px-4 md:m-0"
                short
                color="green"
                onClick={() => {
                  shareWhatsapp({ phone: agentPhone.split("_")[0] });
                }}
              >
                <FlexRow class="gap-4">
                  <span class="text-white">הודעת וואטספ למתווך</span>
                  <WhatsAppIcon white />
                </FlexRow>
              </Button>
              <Button
                name="call agent"
                class="w-fit md:px-4 md:m-0"
                short
                color="blue"
                onClick={async () => {
                  await call({
                    phone: agentPhone.split("_")[0],
                  });
                }}
              >
                <FlexRow class="gap-4">
                  <span class="text-white">התקשר למתווך</span>
                </FlexRow>
              </Button>
            </Show>
          </div>
          <div class="flex pr-8 gap-8">
            <section class="w-3/12">
              <MainImage asset={asset} />
            </section>
            <Show when={asset.data?.image_urls.length}>
              <section class="flex flex-col gap-4 w-48">
                <ImagesRoll asset={asset} setPictureModal={setPicturesModal} />
              </section>
            </Show>
            <div class="flex flex-col w-4/12 gap-4 h-[70vh]">
              <AssetPropertyList asset={asset} />
            </div>
            <section class="w-3/12">
              <Show when={asset.data.notes}>
                <Heading
                  side={isSmall() ? "right" : "middle"}
                  color={Colors.secondary}
                >
                  תיאור הנכס{" "}
                </Heading>
                <Spacer />
                <Heading class="w-10/12 m-auto">{asset.data.notes} </Heading>
              </Show>
              <button
                class="w-full p-8 py-20"
                onclick={() => {
                  logEvent("banner_clicked", {
                    event_category: "banner",
                    event_label: asset.data?.banner?.client,
                    banner_id: asset.data.banner.id,
                  });
                  setContactUsModal(true);
                }}
              >
                <img
                  class="shadow-md m-0 w-full"
                  src={asset.data.banner.image_url}
                />
              </button>
            </section>
          </div>
        </div>
      </Show>
      <Show when={isSmall()}>
        <div class=" bg-lightGrey pb-6">
          <div class="w-full flex flex-col p-8 bg-white shadow-blackShadow">
            <div class="text-darkBlue text-lg font-medium ">
              <strong>
                {" "}
                {asset.data.street.name +
                  " " +
                  (asset.data?.address_number || "")}{" "}
                ,{asset.data.neighborhood?.name || ""}
              </strong>
            </div>
            <div>
              {AssetTypeEnum[asset.data.asset_type]}, {asset.data.city.name}
            </div>
            <div class="text-darkGrey text-xs">
              {/* {asset.data. + " " + asset.data.district} */}
            </div>
            <Spacer height="4px" />
            <Spacer height="8px" />
            <div class="flex gap-3">
              <div class="p-1 w-10 shadow-md rounded-md bg-white text-center text-xs font-bold">
                {asset.data.number_of_rooms} <br />
                <div class="font-thin">חדרים</div>
              </div>
              <div class="p-1 w-10 shadow-md rounded-md bg-white text-center text-xs font-bold">
                {asset.data.floor} <br />
                <div class="font-thin">קומה</div>
              </div>
              <div class="p-1 w-10 shadow-md rounded-md bg-white text-center text-xs font-bold">
                {Number(asset.data.asset_built_area)} <br />{" "}
                <div class="font-thin">מ״ר</div>
              </div>
            </div>
            <Spacer height="22px" />
            <div class="w-full h-0.5 bg-lightGrey "></div>
            <Spacer />
            <FlexRow justify="between" class="w-full">
              <span class="text-darkGrey">מחיר הנכס</span>
              <span class="text-lg"> {pricify(asset.data.price)}</span>
            </FlexRow>
            <Spacer />
            <button
              classList={{ hidden: !asset.data.image_urls?.[0] }}
              onclick={() => {
                setPicturesModal("0");
              }}
            >
              <img
                class="w-full m-auto rounded-md"
                src={asset.data.image_urls?.[0]}
                alt="main image of asset"
              />
            </button>
            <Show when={asset.data?.image_urls.length}>
              <Spacer />
            </Show>
            <FlexRow justify="start" class="gap-3">
              <Index each={asset.data.image_urls}>
                {(item, i) => (
                  <button
                    onclick={() => {
                      setPicturesModal(i.toString());
                    }}
                  >
                    <img
                      class="w-fit rounded-md h-24"
                      src={asset.data.image_urls?.[i]}
                      alt="image of the asset"
                    />
                  </button>
                )}
              </Index>
              <Show when={asset.data?.image_urls.length}>
                <Spacer />
              </Show>
            </FlexRow>
            <Spacer />
            <button
              onclick={() => {
                logEvent("banner_clicked", {
                  event_category: "banner",
                  event_label: asset.data?.banner?.client,
                  banner_id: asset.data.banner.id,
                });
                setContactUsModal(true);
              }}
            >
              <img class="w-full shadow-md" src={asset.data.banner.image_url} />
            </button>
          </div>
          <Spacer />
          <FlexCol class="gap-2 w-11/12 m-auto">
            {" "}
            <For each={assetProperties(asset)}>
              {(property) => (
                <Show when={property.value}>
                  <FlexRow
                    class="p-4 border-b-2 border-lightGrey w-11/12 m-auto"
                    justify="between"
                  >
                    <Heading side="right" color={Colors.secondary}>
                      {property.name}
                    </Heading>
                    {property.value} {property.area ? "מ״ר" : null}{" "}
                  </FlexRow>
                </Show>
              )}
            </For>
            <Show when={asset.data.notes}>
              <div class="p-1 py-4 border-t  border-t-darkGrey  border-opacity-70">
                <Heading side="right" color={Colors.secondary}>
                  תיאור הנכס{" "}
                </Heading>
                <Spacer />
                <Heading>{asset.data.notes} </Heading>
              </div>
            </Show>
            <Show when={!hideButtons}>
              <Spacer height="8px" />
              <Button
                class="w-8/12"
                short
                color="green"
                onClick={() => {
                  shareWhatsapp({ phone: agentPhone.split("_")[0] });
                }}
              >
                <FlexRow class="gap-4">
                  <span class="text-white">הודעת וואטספ למתווך</span>
                  <WhatsAppIcon white />
                </FlexRow>
              </Button>
              <Button
                name="call agent"
                class="w-8/12"
                short
                color="blue"
                onClick={async () => {
                  await call({
                    phone: agentPhone.split("_")[0],
                  });
                }}
              >
                <FlexRow class="gap-4">
                  <span class="text-white">התקשר למתווך</span>
                </FlexRow>
              </Button>
            </Show>
          </FlexCol>
        </div>
      </Show>
    </Show>
  );
};

const CheckIcon = (props) => (
  <svg
    width="19"
    class={props.visible ? "opacity-100" : "opacity-0"}
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.293 0.292969L6 11.5859L1.70703 7.29297L0.292969 8.70703L6 14.4141L18.707 1.70703L17.293 0.292969Z"
      fill="#234F68"
    />
  </svg>
);

function ContactFormModal(props) {
  const { contactUsModal, setContactUsModal, setForm, asset, form } = props;
  return (
    <Modal
      classList={{ "max-w-lg": true }}
      open={contactUsModal()}
      onClose={() => {
        setContactUsModal(false);
      }}
    >
      <h2 class="font-semibold text-center">יצירת קשר</h2>
      <TextInput
        label="שם מלא"
        name="name"
        onChange={(e) =>
          setForm({ [e.currentTarget.name]: e.currentTarget.value })
        }
      />
      <TextInput
        label="דואר אלקטרוני"
        name="email"
        onChange={(e) =>
          setForm({ [e.currentTarget.name]: e.currentTarget.value })
        }
      />
      <TextInput
        label="מספר טלפון לחזרה"
        name="phone"
        onChange={(e) =>
          setForm({ [e.currentTarget.name]: e.currentTarget.value })
        }
      />
      <Spacer />
      <Button
        class="w-20"
        short
        name="submit"
        color="green"
        onClick={async () => {
          logEvent("send_email_to_advertiser", {
            event_category: asset.data?.banner?.client,
            event_label: asset.data.banner.client,
            banner_id: asset.data.banner.id,
          });

          await sendMailToBannerOwner(asset.data?.banner?.id, form);

          setContactUsModal(false);
        }}
      >
        שלח
      </Button>
    </Modal>
  );
}

async function sendMailToBannerOwner(
  id: string,
  form: { name: string; phone: string; email: string }
) {
  await axios.post("/advertisement/" + id + "/send_email_to_advertiser/", {
    message: `יש לך ליד חדש מ
            \n
            ${form.name}
            \n
            ${form.phone}
            \n
            ${form.email}
            \n
            בברכה-צוות גשר`,
  });
}

export function useEnableZoom() {
  onMount(() => {
    if (Capacitor.getPlatform() === "web") {
      return;
    }
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute(
        "content",
        "viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=2.5"
      );
  });
  onCleanup(() => {
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute(
        "content",
        "viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
      );
  });
}

export function getAsset(id: () => string) {
  return createQuery({
    queryFn: () => {
      return fetchAsset(id());
    },
    queryKey: () => ["assets", id()],
  });
}
export const fetchAsset = async (id: string) => {
  if (!id) {
    return null;
  }

  const { data }: { data: RealtorHomeAsset } = await axios.get(
    "/real-estate/asset/" + id + "/"
  );
  return data;
};

const generateAirawysString = (north, south, west, east) => {
  const airways = [];
  if (north) {
    airways.push("צפון");
  }
  if (east) {
    airways.push("מזרח");
  }
  if (west) {
    airways.push("מערב");
  }
  if (south) {
    airways.push("דרום");
  }
  return airways.join(", ");
};

export const assetProperties = (asset) => [
  { name: "עיר", value: asset.data.city?.name },
  { name: "שכונה", value: asset.data.neighborhood?.name },
  { name: "מחיר הנכס", value: pricify(asset.data.price) },
  {
    name: "סוג נכס",
    value: AssetTypeEnum[asset.data.asset_type],
  },
  { name: "שנת בניית", value: asset.data.construction_year },

  { name: "שירותים", value: asset.data.toilets },
  { name: "מקלחות", value: asset.data.bathrooms },

  {
    name: "מרפסת שמש",
    value:
      Number(asset.data.sun_terrace_size) ||
      (asset.data.has_sun_terrace ? "כן" : null),
    area: asset.data.sun_terrace_size,
  },

  {
    name: "חנייה",
    value:
      asset.data.number_of_parking_spots ||
      (asset.data.has_parking_in_registry ? "כן" : null),
  },
  {
    name: "ממ״ד",
    value:
      Number(asset.data.in_house_shelter_size) ||
      (asset.data.has_in_house_shelter ? "כן" : null),
    area: asset.data.in_house_shelter_size,
  },
  {
    name: "מעלית",
    value:
      asset.data.number_of_elevators || (asset.data.has_elevator ? "כן" : null),
  },
  {
    name: "מספר חדרים",
    value: asset.data.number_of_rooms,
  },
  {
    name: "שטח",
    value: Number(asset.data.asset_built_area),
    area: asset.data.asset_built_area,
  },
  {
    name: "קומה",
    value: () => {
      const floor = asset.data.floor;
      const highest_floor = asset.data.highest_floor;
      let floorText = asset.data.floor;
      if (floor === null) {
        return "";
      }

      if (floor === 0) {
        floorText = "קרקע";
      }
      if (highest_floor) {
        floorText = floorText + " / " + highest_floor;
      }
      return floorText;
    },
  },
  {
    name: "מפלסים",
    value:
      asset.data.asset_type === "AT" ? null : asset.data.asset_levels.length,
  },
  {
    name: "מיזוג אוויר",
    value: asset.data.has_air_conditioning && "כן",
  },
  { name: "לאן פונה הנכס", value: AssetFacingEnum[asset.data.asset_facing] },
  { name: "מצב הנכס", value: AssetConditionEnum[asset.data.asset_condition] },
  {
    name: "כיווני אוויר",
    value: generateAirawysString(
      asset.data.north_airway,
      asset.data.south_airway,
      asset.data.west_airway,
      asset.data.east_airway
    ),
  },
  {
    name: "נגישות לנכים",
    value: asset.data.is_accessible_for_disabled_people && "כן",
  },
];

export default PublicAsset;
