import { createSignal, Show, For, createEffect, onMount, JSX } from "solid-js";
import { debounce } from "@solid-primitives/scheduled";
import { handleRequired } from "../Pages/Login/validate";
import { SetStoreFunction } from "solid-js/store";
import Button from "./Button";
import { AddIcon } from "../Pages/AddAsset/Icons";
import { Closer } from "./MultiSelect";
import { MissingNeighborhoodReportModal } from "../Pages/AddClient/EditForm";
import { CloseOnEscape } from "./Modal";

const AutoComplete = (props: Props) => {
  const [open, setOpen] = createSignal(false);
  const [reportModal, setReportModal] = createSignal();

  const trigger = debounce((value: string) => {
    props.onSearch(value);
  }, 300);
  const [position, setPosition] = createSignal<"top" | "bottom">("top");
  return (
    <button
      onclick={(e) => {
        const location = e.target.getBoundingClientRect();
        if (location.top > window.innerHeight * 0.7) {
          setPosition("bottom");
        } else {
          setPosition("top");
        }
        setOpen(!open());
        props.errorSetter &&
          props.required &&
          handleRequired(props.name, props.value, props.errorSetter);
      }}
      class={"m-auto relative md:flex md:flex-col " + props.class}
    >
      <CloseOnEscape close={() => setOpen(false)} />
      <Show when={reportModal()}>
        <MissingNeighborhoodReportModal
          city={props.city}
          onClose={() => setReportModal(false)}
        />
      </Show>
      <div class="text-right text-sm text-darkGrey">
        {props.label} {props.required && "*"}
      </div>
      <input
        autocomplete="off"
        autocorrect="off"
        placeholder={props.placeholder}
        name={props.name}
        value={props.value || ""}
        oninput={(e) => {
          setOpen(true);
          trigger.clear();
          trigger(e.currentTarget.value);
        }}
        disabled={props.disabled}
        type="text"
        class={`w-full h-[72px] ${
          props.error && props.error(props.name) ? "border border-error" : ""
        }  text-blue p-4 rounded-xl text-sm disabled:text-darkGrey bg-lightGrey `}
      />
      <Show when={props.icon}>
        <img src={props.icon} class="absolute left-2 top-[50%]" alt="חיפוש" />
      </Show>
      <div class="text-right text-error">{props.errorLabel}</div>
      <Show when={open() && props.options && !props.disabled}>
        <Closer handler={() => setOpen(false)} />

        <div
          class="bg-white rounded shadow-blackShadow absolute z-50  w-full max-h-[320px] md:min-w-[280px] overflow-y-scroll"
          style={{ direction: "ltr" }}
          classList={{
            "md:top-12": position() === "top" && props.name === "client/asset",
            "md:top-14": position() === "top" && props.short,
            "md:top-24": position() === "top" && !props.short,
            "md:bottom-12":
              position() === "bottom" && props.name === "client/asset",
            "md:bottom-14": position() === "bottom" && props.short,
            "md:bottom-24": position() === "bottom" && !props.short,
          }}
        >
          <Show when={props.missingNeighborhood}>
            <Button
              name="דיווח"
              onClick={() => {
                setReportModal(true);
              }}
              short
              border
              class="gap-2 mt-2"
            >
              לא מצאת את השכונה? דווח לנו{" "}
              <AddIcon class="fill-white stroke-blue" pathColor="darkBlue" />
            </Button>
          </Show>
          <For
            each={
              props.options
                ? Object.keys(props.options).filter((key) => {
                    return props.options[key]?.includes(props.value);
                  })
                : []
            }
          >
            {(key) => (
              <>
                <Show when={!props.element}>
                  <div class="text-blue text-sm p-3 hover:font-bold">
                    <button
                      id="add"
                      onClick={() => {
                        props.onChange(key, props.options[key]);
                      }}
                      class="w-full text-right"
                    >
                      <Show when={props.options[key].img}>
                        <img width="25px" src={props.options[key]} />
                      </Show>
                      {props.options[key]}
                    </button>
                  </div>
                </Show>
                <Show when={props.element}>
                  {props.element &&
                    props.options &&
                    props.element(props.options[key], key)}
                </Show>
              </>
            )}
          </For>
        </div>
      </Show>
    </button>
  );
};

export default AutoComplete;

type Props = {
  short?: boolean;
  city?: string;
  missingNeighborhood?: boolean;
  errorSetter?: SetStoreFunction<any>;
  element?: (item: string, id: string) => void;
  required?: boolean;
  prepopulated?: boolean;
  /**
   * PlaceHolder for the text's input
   */
  placeholder?: string;

  label?: string;
  disabled?: boolean;
  value?: string | number;

  /**
   * the function that will run when the user Clicks an option.
   * mostly, should trigger the "picked" prop.
   * @param value
   * @returns
   */
  onChange?: (value: string, name: string) => void;
  error?: boolean;
  errorLabel?: string;
  icon?: string;
  name: string;

  /**
   * Classes for the elemnt
   */
  class?: string;

  /**
   * the options the user can select from.
   */
  options: { [id: number]: string } | undefined;

  /**
   * The function that would run when a user writes.
   * this mostly will be a debounced query..
   * @param value
   */
  onSearch: (value: string) => void;
};
