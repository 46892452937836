const PlusIcon = (props: { color?: string }) => (
  <div class="w-6 flex justify-center">
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50021 0.720001C3.93156 0.720001 0.220215 4.43135 0.220215 9C0.220215 13.5687 3.93156 17.28 8.50021 17.28C13.0689 17.28 16.7802 13.5687 16.7802 9C16.7802 4.43135 13.0689 0.720001 8.50021 0.720001ZM8.50021 1.44C12.6798 1.44 16.0602 4.82046 16.0602 9C16.0602 13.1795 12.6798 16.56 8.50021 16.56C4.32068 16.56 0.940215 13.1795 0.940215 9C0.940215 4.82046 4.32068 1.44 8.50021 1.44ZM8.14021 4.68V8.64H4.18021V9.36H8.14021V13.32H8.86021V9.36H12.8202V8.64H8.86021V4.68H8.14021Z"
        fill={props.color ? props.color : "white"}
      />
    </svg>
  </div>
);

export default PlusIcon;
