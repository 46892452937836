export const Dots = (props: { class?: string }) => (
  <svg
    width="28"
    height="28"
    class={"pointer-events-none " + props.class}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 3.7334C13.2573 3.7334 12.5452 4.0284 12.0201 4.5535C11.4949 5.0786 11.2 5.79079 11.2 6.5334C11.2 7.276 11.4949 7.9882 12.0201 8.5133C12.5452 9.0384 13.2573 9.3334 14 9.3334C14.7426 9.3334 15.4547 9.0384 15.9799 8.5133C16.505 7.9882 16.8 7.276 16.8 6.5334C16.8 5.79079 16.505 5.0786 15.9799 4.5535C15.4547 4.0284 14.7426 3.7334 14 3.7334ZM14 11.2001C13.2573 11.2001 12.5452 11.4951 12.0201 12.0202C11.4949 12.5453 11.2 13.2575 11.2 14.0001C11.2 14.7427 11.4949 15.4549 12.0201 15.98C12.5452 16.5051 13.2573 16.8001 14 16.8001C14.7426 16.8001 15.4547 16.5051 15.9799 15.98C16.505 15.4549 16.8 14.7427 16.8 14.0001C16.8 13.2575 16.505 12.5453 15.9799 12.0202C15.4547 11.4951 14.7426 11.2001 14 11.2001ZM14 18.6667C13.2573 18.6667 12.5452 18.9617 12.0201 19.4868C11.4949 20.0119 11.2 20.7241 11.2 21.4667C11.2 22.2093 11.4949 22.9215 12.0201 23.4466C12.5452 23.9717 13.2573 24.2667 14 24.2667C14.7426 24.2667 15.4547 23.9717 15.9799 23.4466C16.505 22.9215 16.8 22.2093 16.8 21.4667C16.8 20.7241 16.505 20.0119 15.9799 19.4868C15.4547 18.9617 14.7426 18.6667 14 18.6667Z"
      fill="#234F68"
    />
  </svg>
);
