import { For, Show, Setter, createEffect, createSignal } from "solid-js";
import CustomersListItem from "./ListItem";


interface IProps {
    list: Array<any>;
    checked: Array<string>;
    asset: any;
    setChecked: Setter<string>;
    showCheckbox: boolean;
    customerSend: Function;
    customerType: string;
}

const CustomersList = (props: IProps) => {
    const [list, setList]:Array<any> = createSignal([])
    createEffect(() => {
        let sorted_list:any = [...props.list].sort((a, b):any => {
            return Number(b.is_vip) - Number(a.is_vip)
        })
        setList(sorted_list)
    })

    const [visibleMobile, setVisibleMobile] = createSignal(4)

    return (
        <>
            <div class="asset--customers__list">
                <For
                each={list()}
                >
                {(customer, index) => {
                    if (customer.phone) {
                        return (
                            <CustomersListItem
                                asset={props.asset}
                                customer={customer}
                                checked={props.checked}
                                index={index()}
                                setChecked={props.setChecked}
                                visible={visibleMobile()}
                                showCheckbox={props.showCheckbox}
                                customerSend={props.customerSend}
                                customerType={props.customerType}
                            />
                        )
                    }
                }}
                </For>
            </div>
            <Show when={visibleMobile() < list().length}>
                <div class="asset--customers__list--load">
                    <button
                        class="btn--load-more"
                        onClick={() => {
                            setVisibleMobile(visibleMobile() + 5)
                        }}
                    >
                        הצג עוד
                    </button>
                </div>
            </Show>
        </>
    )
}
export default CustomersList

