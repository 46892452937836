import axios from "axios"
import { createStore } from "solid-js/store"
import { RealtorHomeAsset } from "../types"
import { createSignal } from "solid-js"
import { pricify } from "../Pages/AddAsset/AssetInfo"
import { setToastr, setUser } from "../globalState"

export const getAllUserData = async () => {
    await Promise.all([
        getUserActiveAssets(),
        getUserBuyers(),
        getUserSellers(),
        getUserLeads()
    ]).catch(err => {
        setToastr({
            status: 'error',
            show: true,
            text: 'Something went wrong'
        })
        console.log(err);
    });
};

export const [userActiveAssets, setUserActiveAssets] = createStore<RealtorHomeAsset[]>([])

export const getUserActiveAssets = async () => {
    const request = await axios.get('/access-control/users/active_assets/')
        .then(resp => {
            if (resp && resp.status == 200 && resp.data.active_assets) {
                let data = resp.data.active_assets
                data.forEach((item:any, index:any) => {
                    data[index].price = pricify(item.price)
                }) 
                setUserActiveAssets(data)
                setUser({active_assets: data})
            }
        })
        .catch(err => {
            console.log(err)
        })
}



export const [userBuyers, setUserBuyers] = createStore([])

export const getUserBuyers = async () => {
    const request = await axios.get('/access-control/users/buyers/')
        .then(resp => {
            if (resp && resp.status == 200) {
                setUserBuyers(resp.data)
                setUser({buyers: resp.data})
            }
        })
        .catch(err => {
            console.log(err)
        })
}



export const [userSellers, setUserSellers] = createStore([])
const [userSellersFetched, setUserSellersFetched] = createSignal(false)

export const getUserSellers = async () => {

    const request = await axios.get('/access-control/users/sellers/')
        .then(resp => {
            if (resp && resp.status == 200) {
                setUserSellers(resp.data)
                setUser({sellers: resp.data})
            }
        })
        .catch(err => {
            console.log(err)
        })
}



export const [userLeads, setUserLeads] = createStore([])

export const getUserLeads = async () => {
    const request = await axios.get('/access-control/users/leads/')
        .then(resp => {
            if (resp && resp.status == 200) {
                setUserLeads(resp.data)
                setUser({leads: resp.data})
            }
        })
        .catch(err => {
            console.log(err)
        })
}