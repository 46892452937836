import { Presence } from "@motionone/solid";
import axios from "axios";
import { createSignal, Match, Show, Switch } from "solid-js";
import { createStore } from "solid-js/store";
import Button from "../../Components/Button";
import { Modal } from "../../Components/Modal";
import { user } from "../../globalState";
import { AssetPicker } from "../PhoneCall/AssetPicker";
import Confetti from "./Confetti.png";
import { RealtorHomeAsset } from "../../types";
import { getAsset } from "../PublicAsset/PublicAsset";
import { useNavigate } from "@solidjs/router";
import { getUserDetails } from "../../api/getUserDetails";
import { createQuery } from "@tanstack/solid-query";
import MultiSelect from "../../Components/MultiSelect";
import TextInput from "../../Components/TextInput";
import Spacer from "../../Components/Spacer";
import { FastAddAsset } from "../AddAsset/FastAddAsset";
import { checkPhoneNumber } from "../AddClient/BuyerForm";

interface Props {
  customer?: string;
  asset?: RealtorHomeAsset;
  hide: () => void;
  success?: () => void;
}
export function DealModal(props: Props) {
  const agents = getAgents();

  const [form, setForm] = createStore({
    street: "",
    asset: props.asset || "",
    customer: props.customer || "",
    purchasing_customer_realtor: "",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = createSignal(false);
  const buyerOptions = () =>
    [...user.buyers, ...user.leads].reduce((acc, item) => {
      return { ...acc, [item.id]: item.name };
    }, {});
  const [customerModal, setCustomerModal] = createSignal(false);
  const assetQuery = getAsset(() => form.asset?.id);
  const [customer, setCustomer] = createStore({ phone: "", name: "" });
  const [assetModal, setAssetModal] = createSignal(false);
  checkPhoneNumber(customer, setCustomer);
  return (
    <Presence>
      <Switch
        fallback={
          <Modal
            open={true}
            onClose={props.hide}
            classList={{ "max-w-lg": true }}
          >
            <div class="p-4 flex flex-col items-center lg:w-8/12 lg:m-auto gap-8 overflow-y-scroll">
              <img
                class="text-center w-fit"
                src={Confetti}
                width="100px"
                alt="confetti"
              />
              <Show when={props.asset}>
                <div class="text-2xl text-center font-semibold">
                  ברכותינו, הזן את פרטי הלקוח שרכש את הדירה
                </div>

                <MultiSelect
                  value={{ [form.purchasing_customer_realtor]: true }}
                  singular
                  placeholder="בחר סוכן"
                  class="w-full"
                  name="agent"
                  onclick={(id) => {
                    if (form.customer) {
                      setForm({ customer: null });
                    }
                    setForm({ purchasing_customer_realtor: id });
                  }}
                  label="בחר סוכן אליו משוייך הלקוח"
                  options={agents?.data}
                />

                <MultiSelect
                  placeholder="מי הלקוח שתרצה לשייך?"
                  value={{ [form.customer]: true }}
                  name="customer"
                  singular
                  label="או בחר לקוח"
                  class="w-full"
                  options={buyerOptions()}
                  onclick={(id) => {
                    if (form.purchasing_customer_realtor) {
                      setForm({ purchasing_customer_realtor: null });
                    }
                    setForm({ customer: id });
                  }}
                />
                <Button
                  short
                  disabled={form.customer}
                  onClick={() => {
                    setCustomerModal(true);
                  }}
                  color="green"
                >
                  הוסף לקוח
                </Button>
              </Show>
              <Show when={props.customer}>
                <div class="text-2xl text-center font-semibold">
                  ברכותינו, הזן את פרטי הנכס שסגרת
                </div>
                <AssetPicker
                  hideSendButton
                  allAssets
                  form={form}
                  setForm={setForm}
                />
                <Button
                  border
                  fullWidth
                  onClick={() => {
                    setAssetModal(true);
                  }}
                >
                  הוסף נכס שלא קיים במערכת
                </Button>
              </Show>
              <Button
                onClick={async () => {
                  const assetId = form.asset.id || form.newAsset;
                  console.log(form)
                  setLoading(true);
                  await axios.post(`/real-estate/asset/${assetId}/deal_made/`, {
                    deal_date: new Date(),
                    deal_type: "PE",
                    purchasing_customer: form.customer && Number(form.customer),
                    asset: assetId,
                    purchasing_customer_realtor:
                      form.purchasing_customer_realtor || user.id,
                    selling_customer_realtor:
                      assetQuery.data?.realtor || user.id,
                    selling_customer: assetQuery.data?.seller || null,
                  });
                  await getUserDetails();
                  if (props.success) {
                    setLoading(true);
                    props.success();
                  }
                }}
                fullWidth
                color="green"
                disabled={loading()}
              >
                {loading() ? "טוען..." : "סיום"}
              </Button>
              <button onclick={props.hide}>
                <p class="text-center underline">ביטול</p>
              </button>
            </div>
          </Modal>
        }
      >
        <Match when={assetModal()}>
          <Modal
            open={true}
            classList={{ "lg:max-w-lg": true }}
            onClose={() => setAssetModal(false)}
          >
            <div class="p-4 bg-white overflow-y-scroll">
              <FastAddAsset
                setForm={setForm}
                close={() => setAssetModal(false)}
              />
            </div>
          </Modal>
        </Match>
        <Match when={customerModal()}>
          <Modal
            onClose={() => {
              setCustomerModal(false);
            }}
            open={true}
          >
            <div class="p-4 bg-white">
              <TextInput
                onChange={(e) => {
                  setCustomer({ phone: e.target.value });
                }}
                required
                type="tel"
                label="טלפון לקוח"
                name="phone"
              />
              <TextInput
                onChange={(e) => {
                  setCustomer({ name: e.target.value });
                }}
                label="שם לקוח"
                name="name"
                required
                value={customer.name}
              />
              <Spacer />
              <Button
                onClick={async () => {
                  if (customer.current_user_id) {
                    setForm({ customer: customer.current_user_id });
                    setCustomerModal(false);
                    setCustomer({});
                    return;
                  }
                  setLoading(true);

                  const { data } = await axios.post(
                    "/real-estate/customer/",
                    customer
                  );
                  await getUserDetails();
                  setLoading(false);
                  setCustomer({});
                  setForm({ customer: data.id });
                  setCustomerModal(false);
                }}
                color="green"
                short
                disabled={!customer.phone || !customer.name || loading()}
              >
                {loading() ? "טוען..." : "שלח"}
              </Button>
            </div>
          </Modal>
        </Match>
      </Switch>
    </Presence>
  );
}
function getAgents() {
  return createQuery({
    queryKey: () => ["get agents"],
    queryFn: async () => {
      const { data } = await axios.get(
        "/access-control/users/with_same_activity_areas/"
      );
      const res= {}
      data.forEach((agent) => {res[agent.id]=agent.name})
      return res
    },
  });
}
