import { useParams } from "@solidjs/router";
import Spacer from "../../Components/Spacer";
import { Component, Show } from "solid-js";
import { SetStoreFunction } from "solid-js/store";
import { Header } from "./Header";
import { BuyerForm } from "./BuyerForm";
import { SellerForm } from "./SellerForm";
import { screenState } from "../../App";
import { user } from "../../globalState";
import { isSmall } from "../Home/isSmall";

const AddClient: Component = () => {
  const params = useParams();
  const type = params.type;

  return (
    <>
      <div class="relative md:w-full" style={{ top: screenState.top + "px" }}>
        <Header
          title={isSmall() ? "קונה נכס" : "הוספת לקוח חדש"}
          nav={Boolean(user.id && !isSmall())}
        >
          <p class="text-sm my-2">הוסף את פרטי הלקוח למערכת למציאת התאמות</p>
          <Spacer class="md:hidden" height={"37px"} />
        </Header>
        <Spacer height="12px" />
        <Show when={type === "buyer"}>
          <BuyerForm />
        </Show>
        <Show when={type === "seller"}>
          <SellerForm />
        </Show>
      </div>
    </>
  );
};

export default AddClient;

export function handleForm(e: Event, setForm: SetStoreFunction<any>) {
  const target = e.target as HTMLInputElement;
  setForm({ [target.name]: target.value });
}
