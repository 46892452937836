import { createQuery } from "@tanstack/solid-query";
import axios from "axios";

export const getRelevantAssets = (id: string) => {
  return createQuery(
    () => ["algorithm", id],
    async () => {
      const res = await axios.get(
        `/real-estate/purchase_listing/${id}/get_matches/`
      );
      return res.data;
    }
  );
};
