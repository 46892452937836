import { createEffect, createSignal, For, Show } from "solid-js";
import { handleRequired } from "../Pages/Login/validate";
import { Closer } from "./MultiSelect";
import ChevronDown from "../Icons/ChevronDown";
import { CloseOnEscape } from "./Modal";
type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  nullable?: boolean;
  value?: string | number | null;
  onChange?: (name: string, value: string) => void;
  error?: (name: string) => boolean;
  errorLabel?: string;
  icon?: boolean;
  options: { [key: string]: string };
  class?: string;
  onBlur?: (name: string) => void;
  placeholder?: string;
  required?: boolean;
  halfs?: boolen;
  floors?: boolean;
  errorSetter?: () => {};
  showGroundFloor?: boolean;
};

const Select = (props: Props) => {
  const [open, setOpen] = createSignal(false);
  return (
    <>
      <CloseOnEscape close={() => setOpen(false)} />
      <div class={"m-auto md:m-0 relative " + props.class}>
        <div class="text-right text-sm text-darkGrey">
          {props.label} {props.required && "*"}
        </div>
        <button
          id={props.name}
          onClick={() => {
            setOpen(!open());
            if (props.errorSetter && props.required) {
              handleRequired(props.name, props.value, props.errorSetter);
            }
          }}
          disabled={props.disabled}
          class={`w-full ${
            props.error && props.error(props.name) ? "border border-error" : ""
          } h-[72px] text-blue p-6 text-right rounded-xl text-sm disabled:text-darkGrey bg-lightGrey `}
        >
          <p class="text-blue">
            {() => {
              if (
                (!props.value || props.value === "0" || props.value === 0) &&
                props.nullable
              ) {
                return "";
              }
              if (
                props.value === "0" ||
                (props.value === 0 && props.showGroundFloor)
              ) {
                return "קרקע";
              }
              if (props.halfs) {
                return Number(props.value);
              }
              if (props.options?.[props.value]) {
                return props.options?.[props.value];
              }
              return props.placeholder;
            }}
          </p>
        </button>
        <Show when={open() && !props.disabled}>
          <Closer
            handler={(e) => {
              if (e.relatedTarget?.id !== "add") {
                setOpen(false);
                if (props.errorSetter && props.required) {
                  handleRequired(props.name, props.value, props.errorSetter);
                }
              }
            }}
          />
          <div class="bg-white rounded shadow-blackShadow absolute z-[100] w-full h-fit  overflow-y-scroll">
            <Show when={props.nullable}>
              <div class="text-blue text-sm p-3 hover:font-bold">
                <button
                  id="add"
                  name={props.name}
                  onClick={() => {
                    props.onChange(props.name, null);
                    if (open() && props.errorSetter && props.required) {
                      handleRequired(
                        props.name,
                        props.value,
                        props.errorSetter
                      );
                    }
                    setOpen(false);
                  }}
                  class="w-full text-right text-white"
                >
                  ריק
                </button>
              </div>
            </Show>
            <For each={Object.keys(props.options)}>
              {(key) => {
                return (
                  <>
                    <div class="text-blue text-sm p-3 hover:font-bold">
                      <button
                        id="add"
                        name={props.name}
                        onClick={(e) => {
                          props.onChange(props.name, key);
                          if (open() && props.errorSetter && props.required) {
                            handleRequired(
                              props.name,
                              props.value,
                              props.errorSetter
                            );
                          }
                          setOpen(false);
                        }}
                        class="w-full text-right"
                      >
                        {props.options[key] === 0 ? "קרקע" : props.options[key]}
                        {props.floors && props.options[key] === 40 ? "+" : ""}
                      </button>
                    </div>
                    <Show when={props.halfs}>
                      <div class="text-blue text-sm p-3 hover:font-bold">
                        <button
                          id="add"
                          onClick={(e) => {
                            props.onChange(props.name, Number(key) + 0.5);
                            if (open() && props.errorSetter && props.required) {
                              handleRequired(
                                props.name,
                                props.value,
                                props.errorSetter
                              );
                            }
                            setOpen(false);
                          }}
                          class="w-full text-right"
                        >
                          {Number(props.options[key]) + 0.5}
                        </button>
                      </div>
                    </Show>
                  </>
                );
              }}
            </For>
          </div>
        </Show>{" "}
        <div class="absolute top-1/3 left-6 cursor-pointer pointer-events-none">
          {" "}
          <Show when={!props.disabled}>
            <ChevronDown />
          </Show>
        </div>
        <div class="text-right text-error">{props.errorLabel}</div>
      </div>
    </>
  );
};

export default Select;
