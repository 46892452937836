import { Show, createEffect, createSignal, onCleanup, onMount } from 'solid-js'
import axios from 'axios';
import './Customers.sass'

import CustomersTabsNav from './Navigation'
import CustomersList from './List';

import WhatsUsModal from './WhatsUpModal';
import WhatsupSendModal from './SendModal';
import WhatsupSendSuccessModal from './SuccessModal'
import WhatsupSendErrorModal from './ErrorModal';
import WhatsappGuidModal from './WhatsappGuidModal';
import { isSmall } from '../../Home/isSmall';
import { setMobileNavbarVisible, user } from '../../../globalState';
import WhatsupLoadingModal from './LoadingModal';
import LoaderIcon from '../../../Components/LoaderIcon';

interface IProps {
    asset: any;
    refParent?: HTMLElement;
}


const CustomersTab = (props: IProps) => {
    if (!props.asset) {
        return (<></>);
    }
    const [error, setError] = createSignal('אופס... הייתה בעיה בשליחה <br/>נסה לשלוח שוב')
    
    const [qrLoading, setQrLoading] = createSignal(false)
    const [connectionLoading, setConnectionLoading] = createSignal(false)

    const [userAvailableToSend, setUserAvailableToSend] = createSignal(true)

    const [activeTab, setActiveTab] = createSignal('active')
    const [dormantCustomers, setDormantCustomers] = createSignal([]);
    const [dormantToSend, setDormantToSend]:Array<any> = createSignal([]);
    // const [dormantToSend, setDormantToSend]:Array<any> = createSignal([488,489]);
    const [activeCustomers, setActiveCustomers]:Array<any> = createSignal([]);
    const [activeToSend, setActiveToSend]:Array<any> = createSignal([]);

    const [customersToSend, setCustomersToSend]:Array<any> = createSignal([]);
    
    createEffect(() => {
        if (props.asset && props.asset.data && props.asset.data.potential_buyers && props.asset.data.potential_dormant_buyers) {
            setActiveCustomers(props.asset.data.potential_buyers)
            setDormantCustomers(props.asset.data.potential_dormant_buyers)
        }
    })

    const [sentMessages, setSentMessages] = createSignal(0)
    const [limitMessages, setLimitMessages] = createSignal(0)


    createEffect(() => {
        setCustomersToSend(activeToSend().concat(dormantToSend()))
    })


    const setAllCustomers = () => {
        if (activeTab() === 'active') {
            setActiveToSend(activeCustomers().map((c:any) => c.id))
        } else if (activeTab() === 'dormant') {
            setDormantToSend(dormantCustomers().map((c:any) => c.id))
        }
    }
    const removeAllCustomers = () => {
        if (activeTab() === 'active') {
            setActiveToSend([])
        } else if (activeTab() === 'dormant') {
            setDormantToSend([])
        }
    }



    const [connectionModalStatus, setConnectionModalStatus] = createSignal(false)

    const [sendModalStatus, setSendModalStatus] = createSignal(false)

    const [sendSuccessModalStatus, setSendSuccessModalStatus] = createSignal(false)

    const [sendErrorModalStatus, setSendErrorModalStatus] = createSignal(false)

    const [videoGuideModalStatus, setVideoGuideModalStatus] = createSignal(false)

    const [loadingModalStatus, setLoadingModalStatus] = createSignal(false)

    const [qrCode, setQrCode]:any = createSignal(null)

    let qrCodeTimeout:any = null;
    let connectionStatusTimeout: any = null;

    const getQrCode = async (startTimeout: boolean) => {
        setQrLoading(true)
        await axios.get('whatsapp/client/qr_code/')
            .then((resp:any) => {
                if (resp.data.qr_code) {
                    setQrCode(resp.data.qr_code)
                    if (!startTimeout) {
                        setUserWhatsupConnection(resp.data.state)
                        setVideoGuideModalStatus(true)
                    }
                }
            })
            .catch((err:any) => {
                // setSendErrorModalStatus(true)
            })
        setQrLoading(false)
        if (startTimeout && userWhatsupConnection() !== 'authorized') {
            clearInterval(connectionStatusTimeout)
            connectionStatusTimeout = setInterval(async () => {
                await getWhatsupStatus()
                if (userWhatsupConnection() === 'authorized') {
                    clearInterval(connectionStatusTimeout)
                    clearTimeout(qrCodeTimeout)
                    setConnectionModalStatus(false)
                    setSendModalStatus(true)

                }
            }, 1000)
            qrCodeTimeout = setTimeout(() => {
                getQrCode(true)
            }, 10000)
        } else {
            if (qrCodeTimeout) {
                clearTimeout(qrCodeTimeout)
            }
            if (connectionStatusTimeout) {
                clearInterval(connectionStatusTimeout)
            }
        }
    }

    const [userWhatsupConnection, setUserWhatsupConnection]:any = createSignal(false)
    const getWhatsupStatus = async (startInterval:boolean = false) => {
        setConnectionLoading(true)
        await axios.get('whatsapp/client/')
            .then(resp => {
                setUserWhatsupConnection(resp.data.state)
                if (resp.data.state !== 'notExisted') {
                    setSentMessages(resp.data.count_dispatches_in_this_month)
                    setLimitMessages(resp.data.limit_count_dispatches_per_month)
                }
            })
            .catch(err => {
                setSendErrorModalStatus(true)
            })
        setConnectionLoading(false)
        if (userWhatsupConnection() === 'starting' && startInterval) {
            connectionStatusTimeout = setTimeout(async () => {
                await getWhatsupStatus(true)
                if (userWhatsupConnection() === 'notAuthorized') {
                    clearTimeout(connectionStatusTimeout)
                    setLoadingModalStatus(false)
                    setVideoGuideModalStatus(true)
                }
            }, 2000)
        }
    }

    onMount(() => {
        getWhatsupStatus(true)
    })

    let notExistedconnectionStatusTimeout:any = null;
    const submitCustomers = async () => {
        if (isSmall() && userWhatsupConnection() !== 'authorized') {
            setSendErrorModalStatus(true)
            setError('יש להתחבר למערכת דרך המחשב שלך על מנת לבצע את פעולת ההתחברות לוואטסאפ לראשונה.')
            setTimeout(() => {
                setSendErrorModalStatus(false)
                setError('אופס... הייתה בעיה בשליחה <br/>נסה לשלוח שוב')
            }, 5000)
            return;
        }
        if (userWhatsupConnection() === 'authorized') {
            setSendModalStatus(true)
        } else if (userWhatsupConnection() === 'starting') {
            setLoadingModalStatus(true)
        } else if (userWhatsupConnection() === 'notAuthorized') {
            setVideoGuideModalStatus(true)
        } else if (userWhatsupConnection() === 'notExisted') {
            getQrCode(false)
            setLoadingModalStatus(true)
            notExistedconnectionStatusTimeout = setInterval(async () => {
                await getWhatsupStatus(false)
                if (userWhatsupConnection() === 'notAuthorized') {
                    clearInterval(notExistedconnectionStatusTimeout)
                    setLoadingModalStatus(false)
                    setVideoGuideModalStatus(true)
                }
            }, 2000)
        }
    }
    onCleanup(() => {
        if (connectionStatusTimeout) {
            clearInterval(connectionStatusTimeout)
            clearTimeout(connectionStatusTimeout)
        }
        if (qrCodeTimeout) {
            clearTimeout(qrCodeTimeout)
        }
    })
    createEffect(() => {
        if (
            connectionModalStatus() ||
            sendModalStatus() || 
            sendSuccessModalStatus() || 
            sendErrorModalStatus() || 
            videoGuideModalStatus()
        ) {
            setMobileNavbarVisible(false)
        } else {
            setMobileNavbarVisible(true)
        }
    })

    createEffect(() => {
        if (sentMessages() > 0 && limitMessages() > 0) {
            if (sentMessages() >= limitMessages()) {
                setUserAvailableToSend(false)
            } else {
                setUserAvailableToSend(true)
            }
        }
    })

    const sendToSingleCustomer = (data:any) => {
        removeAllCustomers()
        if (data.type === 'dormant') {
            setDormantToSend([data.id])
        } else if (data.type === 'active') {
            setActiveToSend([data.id])
        }
        submitCustomers()
    }

    return (
        <div class="asset--customers">
            <div class="asset--customers__title">
                <h5 class="st-title">
                    התאמות ({activeCustomers().length + dormantCustomers().length})
                </h5>
                <button
                    class="btn--open"
                    onClick={(e) => {
                        if (props.refParent) {
                            props.refParent.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            })
                        }
                    }}
                >
                    <ChevronTop />
                </button>
            </div>
            <CustomersTabsNav
                tab={activeTab()}
                setTab={setActiveTab}
                activeCount={activeToSend().length}
                dormantCount={dormantToSend().length}
                activeTotal={activeCustomers().length}
                dormantTotal={dormantCustomers().length}
            />
            <Show when={activeTab() === 'active'}>
                <CustomersList
                    list={activeCustomers()}
                    checked={activeToSend()}
                    setChecked={setActiveToSend}
                    asset={props.asset}
                    showCheckbox={props.asset.data.role === 'owner' || props.asset.data.role === 'contact'}
                    customerSend={sendToSingleCustomer}
                    customerType="active"
                />
            </Show>
            <Show when={activeTab() === 'dormant'}>
                <CustomersList
                    list={dormantCustomers()}
                    checked={dormantToSend()}
                    setChecked={setDormantToSend}
                    asset={props.asset}
                    showCheckbox={props.asset.data.role === 'owner' || props.asset.data.role === 'contact'}
                    customerSend={sendToSingleCustomer}
                    customerType="dormant"
                />
            </Show>
            <Show when={props.asset.data.role === 'owner' || props.asset.data.role === 'contact'}>
                <div class="asset--customers__actions">
                    <span class="checkbox--container">
                        <Show when={activeTab() === 'active'}>
                            <input
                                type="checkbox"
                                class="input--checkbox"
                                checked={activeToSend().length === activeCustomers().length}
                                onChange={(e) => {
                                    if (e.currentTarget.checked) {
                                        setAllCustomers()
                                    } else {
                                        removeAllCustomers()
                                    }
                                }}
                            />
                        </Show>
                        <Show when={activeTab() === 'dormant'}>
                            <input
                                type="checkbox"
                                class="input--checkbox"
                                checked={dormantToSend().length === dormantCustomers().length}
                                onChange={(e) => {
                                    if (e.currentTarget.checked) {
                                        setAllCustomers()
                                    } else {
                                        removeAllCustomers()
                                    }
                                }}
                            />
                        </Show>
                        <label class="checkbox-label">
                            סמן את כולם
                        </label>
                    </span>
                    <button
                        class="btn--submit"
                        disabled={
                            customersToSend().length < 1 ||
                            !userAvailableToSend() ||
                            !props.asset.data.image_urls.length ||
                            userWhatsupConnection() === 'starting' ||
                            (props.asset.data.role !== 'owner' && props.asset.data.role !== 'contact')
                        }
                        onClick={(e) => {
                            submitCustomers()
                        }}
                    >
                        שלח ללקוח
                        <Show
                            when={userWhatsupConnection() !== 'starting'}
                            fallback={<LoaderIcon maxWidth='30' maxHeight='30' color='#fff'/>}
                        >
                            <SubmitIcon />
                        </Show>
                    </button>
                </div>
                <Show when={!props.asset.data.image_urls.length}>
                    <div class="asset--customers__info">
                        <p class="st-info">
                            <span class="icon">
                                <InfoIcon />
                            </span>
                            שים לב! נכס רלוונטי לשליחה בתפוצה רק אם קיימות תמונות של הנכס במודעה.
                        </p>
                    </div>
                </Show>
                <Show when={props.asset.data.image_urls.length && userWhatsupConnection() === 'starting'}>
                    <div class="asset--customers__info">
                        <p class="st-info">
                            <span class="icon">
                                <InfoIcon/>
                            </span>
                            אנא המתן, אנחנו יוצרים את החיבור לווצאפ שלך...
                        </p>
                    </div>
                </Show>
            </Show>
            <WhatsupLoadingModal
                show={loadingModalStatus()}
                toggle={setLoadingModalStatus}
            />
            <WhatsappGuidModal
                show={videoGuideModalStatus()}
                toggle={setVideoGuideModalStatus}
                setQrCodeStatus={setConnectionModalStatus}
                getCode={getQrCode}
                loading={qrLoading()}
            />
            <WhatsUsModal
                show={connectionModalStatus()}
                toggle={setConnectionModalStatus}
                qrCode={qrCode()}
            />
            <WhatsupSendModal
                show={sendModalStatus()}
                toggle={setSendModalStatus}
                customers={customersToSend()}
                setSuccessModal={setSendSuccessModalStatus}
                setErrorModal={setSendErrorModalStatus}
                description={`${props.asset.data.street.name}
                    ${props.asset.data.address_number ? " " + props.asset.data.address_number : " "},
                    ${props.asset.data.city.name}
                `}
                setSentMessage={setSentMessages}
                sentMessages={sentMessages()}
                limitMessages={limitMessages()}
                assetId={props.asset.data.id}
                agentPhone={user.phone}
                asset={props.asset.data}
            />
            <WhatsupSendSuccessModal
                show={sendSuccessModalStatus()}
                toggle={setSendSuccessModalStatus}
                sentMessages={sentMessages()}
                limitMessages={limitMessages()}
            />
            <WhatsupSendErrorModal
                show={sendErrorModalStatus()}
                toggle={setSendErrorModalStatus}
                error={error()}
            />
        </div>
    )
}

export default CustomersTab

const SubmitIcon = () => (
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.5C6.477 2.5 2 6.977 2 12.5C2 14.39 2.525 16.16 3.438 17.668L2.546 20.7C2.49478 20.8741 2.49141 21.0587 2.53624 21.2346C2.58107 21.4104 2.67245 21.5709 2.80076 21.6992C2.92907 21.8276 3.08958 21.9189 3.26542 21.9638C3.44125 22.0086 3.62592 22.0052 3.8 21.954L6.832 21.062C8.39068 22.0051 10.1782 22.5025 12 22.5C17.523 22.5 22 18.023 22 12.5C22 6.977 17.523 2.5 12 2.5ZM9.738 14.763C11.761 16.785 13.692 17.052 14.374 17.077C15.411 17.115 16.421 16.323 16.814 15.404C16.8636 15.2897 16.8816 15.1641 16.8661 15.0405C16.8507 14.9168 16.8023 14.7996 16.726 14.701C16.178 14.001 15.437 13.498 14.713 12.998C14.5618 12.8935 14.3761 12.8516 14.1947 12.881C14.0133 12.9105 13.8503 13.009 13.74 13.156L13.14 14.071C13.1085 14.1202 13.0593 14.1555 13.0026 14.1696C12.9459 14.1837 12.8859 14.1756 12.835 14.147C12.428 13.914 11.835 13.518 11.409 13.092C10.983 12.666 10.611 12.1 10.402 11.719C10.3761 11.6706 10.3686 11.6144 10.3809 11.5609C10.3932 11.5074 10.4245 11.4602 10.469 11.428L11.393 10.742C11.5249 10.6273 11.61 10.4682 11.6321 10.2949C11.6542 10.1215 11.6118 9.94611 11.513 9.802C11.065 9.146 10.543 8.312 9.786 7.759C9.68831 7.6882 9.57386 7.64406 9.45393 7.63091C9.334 7.61776 9.21271 7.63606 9.102 7.684C8.182 8.078 7.386 9.088 7.424 10.127C7.449 10.809 7.716 12.74 9.738 14.763Z" fill="white"/>
</svg>
)

const InfoIcon = () => (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 15C10.2833 15 10.521 14.904 10.713 14.712C10.9043 14.5207 11 14.2833 11 14V9.975C11 9.69167 10.9043 9.45833 10.713 9.275C10.521 9.09167 10.2833 9 10 9C9.71667 9 9.47933 9.09567 9.288 9.287C9.096 9.479 9 9.71667 9 10V14.025C9 14.3083 9.096 14.5417 9.288 14.725C9.47933 14.9083 9.71667 15 10 15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.9043 6.52067 11 6.28333 11 6C11 5.71667 10.9043 5.479 10.713 5.287C10.521 5.09567 10.2833 5 10 5C9.71667 5 9.47933 5.09567 9.288 5.287C9.096 5.479 9 5.71667 9 6C9 6.28333 9.096 6.52067 9.288 6.712C9.47933 6.904 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20Z" fill="#234F68"/>
</svg>
)

const ChevronTop = () => (
<svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.998047 8.5L7.49805 1.5L13.998 8.5" stroke="#234F68" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)