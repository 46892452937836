export const GarbageIcon = () => (
  <svg
    width="132"
    height="132"
    viewBox="0 0 132 132"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M94.875 112.938H37.125C32.8451 112.938 29.375 109.467 29.375 105.188V35.5625H102.625V105.188C102.625 109.467 99.1549 112.938 94.875 112.938Z"
      fill="#234F68"
      stroke="#234F68"
    />
    <path
      d="M103.125 44.875H28.875C25.7336 44.875 23.1875 42.3289 23.1875 39.1875V35.0625C23.1875 31.9211 25.7336 29.375 28.875 29.375H103.125C106.266 29.375 108.812 31.9211 108.812 35.0625V39.1875C108.812 42.3289 106.266 44.875 103.125 44.875Z"
      fill="#234F68"
      stroke="#234F68"
    />
    <path
      d="M87.6562 34.0312H44.3438V23.7188C44.3438 20.3012 47.1137 17.5312 50.5312 17.5312H81.4688C84.8863 17.5312 87.6562 20.3012 87.6562 23.7188V34.0312Z"
      fill="#234F68"
      stroke="#234F68"
      stroke-width="4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M66 96.9375C63.7209 96.9375 61.875 95.0916 61.875 92.8125V57.75C61.875 55.4709 63.7209 53.625 66 53.625C68.2791 53.625 70.125 55.4709 70.125 57.75V92.8125C70.125 95.0916 68.2791 96.9375 66 96.9375Z"
      fill="#8BC83F"
    />
    <path
      d="M86.625 96.9375C84.3459 96.9375 82.5 95.0916 82.5 92.8125V57.75C82.5 55.4709 84.3459 53.625 86.625 53.625C88.9041 53.625 90.75 55.4709 90.75 57.75V92.8125C90.75 95.0916 88.9041 96.9375 86.625 96.9375Z"
      fill="#8BC83F"
    />
    <path
      d="M45.375 96.9375C43.0959 96.9375 41.25 95.0916 41.25 92.8125V57.75C41.25 55.4709 43.0959 53.625 45.375 53.625C47.6541 53.625 49.5 55.4709 49.5 57.75V92.8125C49.5 95.0916 47.6541 96.9375 45.375 96.9375Z"
      fill="#8BC83F"
    />
    <path
      opacity="0.15"
      d="M103.125 105.188V68.0625C97.4284 68.0625 92.8125 72.6804 92.8125 78.375V103.125H74.25C68.5534 103.125 63.9375 107.743 63.9375 113.438H94.875C99.4311 113.438 103.125 109.744 103.125 105.188Z"
      fill="#234F68"
    />
    <path
      opacity="0.3"
      d="M68.0625 28.875H28.875C25.4574 28.875 22.6875 31.6449 22.6875 35.0625V39.1875C22.6875 42.6051 25.4574 45.375 28.875 45.375V72.1875C34.5716 72.1875 39.1875 67.5696 39.1875 61.875V45.375C39.1875 43.0547 38.4202 40.9097 37.125 39.1875H57.75C63.4466 39.1875 68.0625 34.5696 68.0625 28.875Z"
      fill="white"
    />
    <path
      d="M36.0938 36.0938H48.4688"
      stroke="white"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
