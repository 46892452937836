import { FlexCol, FlexRow } from "../../Components/Flex";
import TextInput from "../../Components/TextInput";
import Select from "../../Components/Select";
import Picker from "../../Components/Picker";
import { AssetTypeEnum, EndCustomerCreateAsset } from "../../types";
import { createStore, SetStoreFunction } from "solid-js/store";
import Spacer from "../../Components/Spacer";
import getStreets from "../../api/getStreetsByName";
import AutoComplete from "../../Components/AutoComplete";
import { Accessor, Show, createEffect, mergeProps, onMount } from "solid-js";
import { user } from "../../globalState";
import getNeighborhoodsByName from "../../api/getNeighborhoodsByName";
import { handleRequired } from "../Login/validate";
import { handleForm } from "../AddClient/AddClient";
import ImportPhoneContact from "../../Components/Contacts/ImportContact";

export function AssetInfo(props: {
  readOnlyNameAndPhone?: boolean;
  isEdit?: boolean;
  asset: EndCustomerCreateAsset;
  setAsset: SetStoreFunction<EndCustomerCreateAsset>;
  lockName: Accessor<boolean>;
  fieldErrorHandler: any;
}) {
  const { asset, setAsset, lockName, isEdit } = mergeProps(props);
  const cities = () =>
    user.activity_areas.reduce((acc, city) => {
      return { ...acc, [city.id]: city.name };
    }, {});

  const [streets, setStreets] = createStore({});
  createEffect(() => {
    const data = getStreets(
      () => asset.streetName,
      () => asset.city
    );
    setStreets(data.data);
  });

  const [neighborhoods, setNeighborhoods] = createStore({});
  createEffect(() => {
    const data = getNeighborhoodsByName(
      () => asset.neighborhoodName,
      () => asset.city
    );

    setNeighborhoods(data.data);
  });

  return (
    <FlexCol>
      <Show when={!props.isEdit}>
        <ImportPhoneContact
          phoneKey="phone"
          nameKey="name"
          setter={setAsset}
        />
        <TextInput
          class="w-10/12 mt-2"
          onChange={(e) => {
            setAsset({ [e.currentTarget.name]: e.currentTarget.value });
          }}
          name="phone"
          type="tel"
          disabled={props.readOnlyNameAndPhone}
          value={asset.phone}
          label="טלפון הלקוח"
          error={props.fieldErrorHandler}
        />
        <TextInput
          class="w-10/12"
          onChange={(e) => {
            setAsset({ [e.currentTarget.name]: e.currentTarget.value });
          }}
          disabled={(lockName && lockName()) || props.readOnlyNameAndPhone}
          name="name"
          error={props.fieldErrorHandler}
          value={asset.name}
          label="(שיוך לקוח) שם הלקוח"
        />
      </Show>
      <Select
        required
        class="w-10/12"
        label="יישוב"
        options={cities()}
        name="city"
        error={props.fieldErrorHandler}
        value={asset.city}
        onChange={(name, value) => {
          setAsset({ street: null });
          setAsset({ neighborhood: null });
          setAsset({ streetName: "" });
          setAsset({ neighborhoodName: "" });
          setAsset({ [name]: Number(value) });
        }}
      />
      <AutoComplete
        required
        prepopulated={isEdit || asset.neighborhood}
        class="w-10/12"
        city={cities()[asset.city]}
        label="שכונה"
        missingNeighborhood
        name="neighborhood"
        error={props.fieldErrorHandler}
        options={neighborhoods}
        value={asset.neighborhoodName}
        onSearch={(value) => {
          setAsset({ neighborhood: null });
          setAsset({ neighborhoodName: value });
        }}
        onChange={(id) => {
          setAsset({ street: null });
          setAsset({ neighborhood: Number(id) });
          setAsset({
            neighborhoodName: neighborhoods[id],
          });
        }}
      />

      <AutoComplete
        required
        prepopulated={isEdit || asset.street}
        class="w-10/12"
        label="רחוב"
        name="street"
        error={props.fieldErrorHandler}
        errorSetter={setAsset}
        options={streets}
        value={asset.streetName}
        onSearch={(value) => {
          setAsset({ street: null });
          setAsset({ streetName: value });
        }}
        onChange={(streetId) => {
          setAsset({ street: Number(streetId) });
          setAsset({ streetName: streets[streetId] });
        }}
      />

      <TextInput
        class="w-10/12"
        label="מספר בניין"
        name="address_number"
        error={props.fieldErrorHandler}
        onChange={(e) => {
          setAsset({ [e.currentTarget.name]: e.currentTarget.value });
        }}
        value={asset.address_number}
      />
      <div class="w-10/12 m-auto">
        <Spacer />
        <FlexRow justify="around" class="gap-4">
          <span>האם להציג מספר בניין</span>
          <Picker
            //no type yet on backend
            name="should_show_address_number"
            options={{ true: "כן", false: "לא" }}
            error={props.fieldErrorHandler}
            handleChange={(key) => {
              setAsset({
                should_show_address_number: key === "true" ? true : false,
              });
            }}
            active={asset.should_show_address_number ? "true" : "false"}
          />
        </FlexRow>
      </div>
      <TextInput
        class="w-10/12 mt-2"
        type="tel"
        placeholder="0"
        required
        error={props.fieldErrorHandler}
        errorSetter={setAsset}
        label={"מחיר הנכס"}
        name="price"
        onChange={(e) => {
          e.target.value = unpricify(e.currentTarget.value);
          handleForm(e, setAsset);
        }}
        value={pricify(asset.price)}
      />
      <Select
        class="w-10/12"
        name="asset_type"
        label="סוג נכס"
        error={props.fieldErrorHandler}
        options={AssetTypeEnum}
        onChange={(name, value) => {
          setAsset({ [name]: value });
        }}
        value={asset.asset_type}
      />
    </FlexCol>
  );
}

export const pricify = (num) => {
  if (!num) {
    return "";
  }
  if (typeof num === "string" && num.includes("₪")) {
    return num;
  }
  return "₪" + Intl.NumberFormat("he").format(num);
};
export function unpricify(value: string) {
  if (!value) {
    return "0₪";
  }
  const cleaned = value.replaceAll(",", "").replaceAll("₪", "");
  if (!Number(cleaned)) {
    return cleaned;
  } else {
    return cleaned.replace(/\D/g, "");
  }
}
