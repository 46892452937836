import { lazy } from "solid-js";
export const ActiveGreenIcon = lazy(() => import("./ActiveGreenIcon"));
export const ArchiveFolder = lazy(() => import("./ArchiveFolder"));
export const Call = lazy(() => import("./Call"));
export { CheckIcon } from "./CheckIcon";
export const CheckedIcon = lazy(() => import("./CheckedIcon"));
export const CloseIcon = lazy(() => import("./CloseIcon"));
export const DateIcon = lazy(() => import("./DateIcon"));
export const Edit = lazy(() => import("./Edit"));
export const EditIcon = lazy(() => import("./EditIcon"));
export const EmptyIcon = lazy(() => import("./EmptyIcon"));
export const GreenAddIcon = lazy(() => import("./GreenAddIcon"));
export { HomeIcon } from "./HomeIcon";
export const LikeIcon = lazy(() => import("./LikeIcon"));
export const LocationIcon = lazy(() => import("./LocationIcon"));
export const Notification = lazy(() => import("./Notification"));
export const NotificationBell = lazy(() => import("./NotificationBell"));
export const PencilIcon = lazy(() => import("./PencilIcon"));
export const Pencil = lazy(() => import("./Pencil"));

export const PlusIcon = lazy(() => import("./PlusIcon"));
export const Preferences = lazy(() => import("./Preferences"));
export const RemoveIcon = lazy(() => import("./RemoveIcon"));
export const Share = lazy(() => import("./Share"));
export const ShareIcon = lazy(() => import("./ShareIcon"));
export const Sign = lazy(() => import("./Sign"));
export const Task = lazy(() => import("./Task"));
export const Trash = lazy(() => import("./Trash"));
export const Upload = lazy(() => import("./Upload"));
export const WhatsAppIcon = lazy(() => import("./WhatsAppIcon"));
