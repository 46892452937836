import { A } from "@solidjs/router";
import { logEvent } from "../../../analytics";

interface Props {
	userId: any;
}


const SuccessScreen = (props:Props) => {
	return (
		<div class="registration__success">
			<p class="st-icon">
				<ScsIcon />
			</p>
			<p class="st-title">
				פרטיך עודכנו בהצלחה במערכת
				<span class="green">!</span>
			</p>
			<p class="st-text">
				הודעת ווצאפ עם פרטי התחברות <br/>תשלח בשעות הקרובות.
			</p>
			<A
				onclick={() => {
					logEvent('button_click', {
						click_type: 'הבנתי, תודה!',
						button_type: 'agent_update',
						user_id: props.userId
					})
				}}
				href="/login"
				class="st-link"
			>
				הבנתי, תודה!
			</A>
		</div>
	)
}

export default SuccessScreen;


const ScsIcon = () => {
	return (
		<svg width="189" height="189" viewBox="0 0 189 189" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M94.5 162.422C132.012 162.422 162.422 132.012 162.422 94.5C162.422 56.9878 132.012 26.5781 94.5 26.5781C56.9878 26.5781 26.5781 56.9878 26.5781 94.5C26.5781 132.012 56.9878 162.422 94.5 162.422Z" fill="#234F68"/>
			<path opacity="0.3" d="M94.5 41.3437C102.11 41.3437 108.303 35.5645 109.106 28.1699C104.399 27.1392 99.5174 26.5781 94.5 26.5781C56.9865 26.5781 26.5781 56.9865 26.5781 94.5C26.5781 99.5174 27.1392 104.399 28.1699 109.106C35.5674 108.303 41.3437 102.11 41.3437 94.5C41.3437 65.1902 65.1902 41.3437 94.5 41.3437Z" fill="white"/>
			<path opacity="0.15" d="M160.831 79.8938C153.433 80.697 147.657 86.8897 147.657 94.5C147.657 123.81 123.81 147.656 94.5007 147.656C86.8905 147.656 80.6978 153.435 79.8945 160.83C84.6018 161.861 89.4833 162.422 94.5007 162.422C132.014 162.422 162.423 132.013 162.423 94.5C162.423 89.4826 161.861 84.6011 160.831 79.8938Z" fill="black"/>
			<path opacity="0.04" d="M94.5 189C125.488 189 150.609 185.034 150.609 180.141C150.609 175.248 125.488 171.281 94.5 171.281C63.5116 171.281 38.3906 175.248 38.3906 180.141C38.3906 185.034 63.5116 189 94.5 189Z" fill="black"/>
			<path d="M44.4355 69.1829C49.9815 58.2593 59.0004 49.3822 70.0273 44.0164" stroke="white" stroke-width="4.6875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M78.9284 124.338L54.0336 99.3637C52.8612 98.1884 52.8612 96.2807 54.0336 95.1053L62.5238 86.5885C63.6962 85.4132 65.598 85.4132 66.7704 86.5885L84.8878 104.762L121.589 63.0344C122.685 61.7882 124.584 61.6671 125.827 62.7686L134.831 70.7391C136.074 71.8406 136.192 73.7424 135.097 74.9886L92.0492 123.928C88.6147 127.835 82.6021 128.024 78.9284 124.338Z" fill="#8BC83F"/>
		</svg>
	)
}