import { createQuery } from "@tanstack/solid-query";
import axios from "axios";

export function getDeals(id?: string) {
  return createQuery({
    placeholderData: [],
    queryKey: () => ["set deals", id || "all"],
    queryFn: async () => {
      const res = await axios.get("/access-control/users/deals/");

      if (id) {
        const deal = res.data.find((deal) => String(deal.id) === String(id));
        return deal;
      }
      //בשביל עסקאות גם וגם
      const newDeals = [];
      res.data.forEach((deal) => {
        if (deal.realtor_relation_to_deal === "גם וגם") {
          newDeals.push({
            ...deal,
            realtor_relation_to_deal: "רכישה",
          });
          newDeals.push({
            ...deal,
            realtor_relation_to_deal: "מכירה",
          });
          return deal;
        } else {
          newDeals.push(deal);
        }
      });

      return newDeals;
    },
  });
}
