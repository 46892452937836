import { createEffect, createSignal } from 'solid-js';
import { toastr, setToastr } from '../../globalState'
import './styles.sass'


const ToastrComponent = () => {
    let [mainColor, setMainColor]:any = createSignal('#8BC83F');

    createEffect(() => {
        if (toastr.show === true) {
            setTimeout(() => {
                setToastr({show: false})
            }, 5000)
        }
    })
    createEffect(() => {
        if (toastr.status === 'success') {
            setMainColor('#8BC83F')
        } else if (toastr.status === 'warning') {
            setMainColor('#F18910')
        } else if (toastr.status === 'error') {
            setMainColor('#C83F3F')
        }
    })
    
    return (
        <div
            class="single-toastr"
            classList={{
                "show": toastr.show,
                "warning": toastr.status === 'warning',
                "error": toastr.status === 'error'
            }}
        >
            <span class="st-text">
                {toastr.text}
            </span>
            <button
                class="btn--close"
                onClick={(e) => setToastr({show: false})}
            >
                <CloseIcon
                    fill={mainColor()}
                />
            </button>
        </div>
    )
}

export default ToastrComponent


const CloseIcon = (props:any) => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_805_1590)">
<path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill={props.fill}/>
</g>
<defs>
<clipPath id="clip0_805_1590">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
)