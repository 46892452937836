import 'intl-tel-input/build/css/intlTelInput.css'
import intlTelInput from 'intl-tel-input';
import '../../node_modules/intl-tel-input/build/js/utils.js';
import './css/PhoneInput.sass'
import { createEffect, createSignal, onCleanup, onMount } from 'solid-js';


interface IProps {
	placeholder?: string;
	label?: string;
	disabled?: boolean;
	value?: string | number | null;
	countryCode?: string | null; 
	onChange: (value:any) => void;
	name: string;
	class?: string;
	required?: boolean;
	error?: (name: string) => boolean;
	updatePhone?: boolean;
}

const PhoneInput = (props:IProps) => {
	const [phoneCreated, setPhoneCreated] = createSignal(false)

	const getPhoneNumber = () => {
		let number:any = iti.getNumber().replace(/\s/g, '').replace(/-/g, '')
		props.onChange(number)
	}
	
	let iti:any;
	onMount(() => {
		const input = document.querySelector(`#${props.name}`)
		iti = intlTelInput(input, {
			initialCountry: 'IL',
			nationalMode: false,
			showSelectedDialCode: false,
		})
	})
	onCleanup(() => {
		if (iti)
			iti.destroy()
	})
	createEffect(() => {
		if (props.updatePhone) {
			setPhoneCreated(false)
		}
	})

	createEffect(() => {
		if (!phoneCreated() && props && props.value) {
			let phone:string = '';
			if (props.countryCode) {
				phone = props.countryCode
			}
			phone = phone + '' + props.value
			iti.setNumber(phone)
			getPhoneNumber()
			setPhoneCreated(true)
		}
	})

	createEffect(() => {
		if (phoneCreated() && props.value !== iti.getNumber()) {
			iti.setNumber(props.value || '');
			getPhoneNumber();
		}
	});
	


	return (
		<div class={"m-auto relative " + props.class}>
			<div class={"text-right text-sm text-darkGrey"}>
				{props.label} {props.required && "*"}
			</div>
			<div class="w-full relative int-tel-container">
				<input
					pattern={"[0-9]*"}
					id={props.name}
					type="tel"
					autocomplete="off"
					autocorrect="off"
					name={props.name}
					// oninput={props.onChange}
					oninput={getPhoneNumber}
					disabled={props.disabled}
					class={`w-full ${
						props.error && props.error(props.name) ? "border border-error" : ""
					} text-blue p-6 text-right rounded-xl disabled:text-darkGrey bg-lightGrey`}
				/>
			</div>
		</div>
	)
}

export default PhoneInput