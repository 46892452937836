import StepLogo from './logo.png'
import ClockIcon from './Clock-icon.svg'
import VerificationIcon from './vertification-icon.svg';
import CodeInput from '../../../Components/CodeInput';
import { For, Show, createSignal, onMount } from 'solid-js';
import { submitPhone, submitSMSNative, submitSMSRegistration } from '../../../api/login';
import { Capacitor } from '@capacitor/core';
import { useNavigate } from '@solidjs/router';
import { createEventDispatcher } from '@solid-primitives/event-dispatcher';
import { RecaptchaVerifier, getAuth } from 'firebase/auth';
import phoneValidation from '../../../helpers/PhoneValidation';

interface Props {
	onSetStep: (evt:CustomEvent<string>) => void;
	onSetToken: (evt:CustomEvent<string>) => void;
	smsCode?: Array<string>;
	phone: string;
}

const VerificationStep = (props: Props) => {
	const dispatch = createEventDispatcher(props);
	const codeRefs: Array<string> = ['', '', '', '', '', ''];
	const userPhone: string = props.phone;
	const [error, setError] = createSignal("");
	const [loading, setLoading] = createSignal(false);
	const navigate = useNavigate();

	const [timer, setTimer] = createSignal(60)
	const [resendStatus, setResendStatus] = createSignal(false)
	const checkInterval = () => {
		setTimer(timer() - 1)
		if (timer() <= 0) {
			clearInterval(timerInterval)
		}
	}
	const timerInterval = setInterval(checkInterval, 1000)
	onMount(() => {
		timerInterval;
	})
	
	const submitCode = async () => {
		if (loading()) {
			return;
		}
		const code = codeRefs.join('')
		if (Capacitor.getPlatform() === 'web') {
			const submit:any = await submitSMSRegistration({
				smsCode: code,
				navigate,
				phone: props.phone
			})
			if (!submit.error) {
				if (!submit.user_exist) {
					const accessToken:string = submit.accessToken;
					dispatch('setToken', accessToken, {cancelable: true})

				}
			} else if (submit.error) {
				if (submit.errorCode == 418) {
					setError('אתה לא רשאי להתחבר לגשר- לפרטים נוספים, אנא פנה לתמיכה')
				} else {
					setError("שגיאה בהזנת קוד");
				}
				
				return;
			} else {
				setError('Unexpected error!')
			}
		} else {
			submitSMSNative({
				smsCode: code,
				navigate
			})
		}
	}

	const resendCode = async () => {
		const auth:any = getAuth();
		window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
			'size': 'invisible',
			'callback': (response:any) => {
				// console.log(response)
			}
		}, auth)
		const submit = await submitPhone({ phone: phoneValidation(props.phone) })
		if (submit && submit.error) {
			setError("שגיאה בהזנת טלפון");
		} else {
			setTimer(60)
			timerInterval
		}
	}




	
	return (
		<div class="registration__step">
			<div class="registration__step--title">
				{codeRefs}
				<p class="st-logo">
					<img src={StepLogo} alt="" />
				</p>
				<h1 class="st-title">
					פתח חשבון מתווך חדש<span class="symbol">.</span>
				</h1>
				<p class="verification-img">
					<img src={VerificationIcon} alt="" />
				</p>
				<p class="st-description blue">
					קוד האימות נשלח אליך ברגעים אלה דרך
					<br/><b> הודעת SMS </b> למספר - 
					<span class="verification-phone">
						{userPhone.slice(7, 11)}
						****-
						{userPhone.slice(0, 3)}
					</span>
				</p>
				<p class="st-description code">
					הזן קוד בעל 6 הספרות
				</p>
			</div>
			<div class="registration__step--form">
				<div class="code--container">
					<For each={["6", "5", "4", "3", "2", "1"]}>
						{(item: any) => (
							<CodeInput
								name={"smsCode-" + item}
								value={codeRefs[item]}
								onkeydown={(e) => {
									if (e.key === "Meta" || e.key === "v") {
										return;
									}

									if (e.key === "Backspace") {
										console.log(item)
										codeRefs[item] = "";
										const NextElement = document.getElementById(
											"smsCode-" + (Number(item) - 1)
										);
										const element:any = document.getElementById('smsCode-' + item)
										if (element) {
											element.value = ""
										}
										if (NextElement) {
											NextElement.focus();
										}
									}
									if (e.key !== "0" && !Number(e.key)) {
										e.preventDefault();
									}
								}}
								onChange={(e?:any) => {
									if (e.currentTarget.value.length > 1) {
										e.target.value = e.data
										return;
									}
									codeRefs[Number(item) - 1] = e.currentTarget.value
									const element = document.getElementById(
										"smsCode-" + (Number(item) + 1)
									);
				
									if (element) {
										element.focus();
									}
									if (codeRefs.join('').length === 6) {
										submitCode()
										setLoading(true)
									} else {
										setLoading(false)
									}
								}}
							/>
						)}
					</For>
				</div>
				<div class="text-error text-center h-4">
					{error() || " "}
				</div>
				<Show when={timer() > 0}>
					<div class="resend--timer">
						<span class="icon">
							<img src={ClockIcon} alt="" />
						</span>
						<span class="timer-time">
							00:{timer().toLocaleString('en-US', {
								minimumIntegerDigits: 2,
								useGrouping: false
							})}
						</span>
					</div>
				</Show>
				<Show when={timer() < 1}>
					<div class="resend--action">
						<p class="st-text">
							לא קיבלת קוד SMS?&nbsp;
						</p>
						<button
							id="sign-in-button"
							onclick={() => {
								resendCode()
							}}
							class="resend-btn"
						>
							שליחה חוזרת
						</button>
					</div>
				</Show>
			</div>
		</div>
	)
}
export default VerificationStep;