import { createQuery } from "@tanstack/solid-query";
import axios from "axios";
import { Street } from "../types";
const getStreetsByName = (street: () => string, city: () => number) => {
  return createQuery(
    () => ["get street", street(), city()],
    () => fetchStreet(street(), city()),
    {
      staleTime: 10000,
    }
  );
};

export default getStreetsByName;

const fetchStreet = async (street: string, city: number) => {
  if (!street || !city) {
    return {};
  }
  const { data } = await axios.get(
    `/real-estate/streets/?search=${street}&city=${city}`
  );

  const streets: { [id: number]: string } = {};
  data.results.forEach((street: Street) => {
    streets[street.id] = street.name;
  });

  return streets;
};
