interface IProps {
    color?: string;
    maxWidth?: string;
    maxHeight?: string;
}

const LoaderIcon = (props: IProps) => {
    const color = props.color || '#8bc83f';
    return (
        <div
            class="loader-icon--container"
            style={{
                'max-width': props.maxWidth ? props.maxWidth + 'px' : '100%',
                'max-height': props.maxHeight ? props.maxWidth + 'px' :  '100%'
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width="auto" height="auto" style="shape-rendering: auto; margin: 0; display: block; max-width: 100%; max-height: 100%;">
                <g style="transform: scale(1.7); transform-origin: 50%">
                    <g transform="rotate(0 50 50)">
                        <rect x="47" y="29" rx="3" ry="6" width="6" height="12" fill={color}>
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.875s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(45 50 50)">
                        <rect x="47" y="29" rx="3" ry="6" width="6" height="12" fill={color}>
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(90 50 50)">
                        <rect x="47" y="29" rx="3" ry="6" width="6" height="12" fill={color}>
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.625s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(135 50 50)">
                        <rect x="47" y="29" rx="3" ry="6" width="6" height="12" fill={color}>
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(180 50 50)">
                        <rect x="47" y="29" rx="3" ry="6" width="6" height="12" fill={color}>
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.375s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(225 50 50)">
                        <rect x="47" y="29" rx="3" ry="6" width="6" height="12" fill={color}>
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(270 50 50)">
                        <rect x="47" y="29" rx="3" ry="6" width="6" height="12" fill={color}>
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.125s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(315 50 50)">
                        <rect x="47" y="29" rx="3" ry="6" width="6" height="12" fill={color}>
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g></g>
                </g>
            </svg>
        </div>
    )
}

export default LoaderIcon;