import Spacer from "../../Components/Spacer";
import TextInput from "../../Components/TextInput";
import {
  createEffect,
  createMemo,
  createSignal,
  For,
  Index,
  mergeProps,
  Show,
} from "solid-js";
import { DropDownAsset } from "./DropDownAsset";
import { Props } from "./PhoneCall";
import { createQuery } from "@tanstack/solid-query";
import { user } from "../../globalState";
import axios from "axios";

export function AssetPicker(props: Props) {
  const { setForm, form, allAssets, hideSendButton, title } = mergeProps(props);
  const assetsQuery = getAllPublicAssets(allAssets, () => form.street, props.assetsList);

  const [assetsList, setAssetsList]:Array<any> = createSignal(null)
  
  createEffect(() => {
    if (props.assetsList) {
      let assetsSearch = props.assetsList.filter((asset) => {
        return (
          asset.city.name.includes(form.street) ||
          asset.street.name.includes(form.street)
        );
      });
      setAssetsList(assetsSearch)
    } else {
      setAssetsList(assetsQuery.data)
    }
  })
  



  const shouldShowAssets = createMemo(() => {
    return form.street?.length > 0 && !form.asset?.id;
  });



  return (
    <span class="w-full m-auto md:m-0 relative z-50">
      <TextInput
        class="w-full"
        onChange={(e) => {
          if (form.asset) {
            assetsQuery.remove();
            setForm({ asset: null });
          }
          setForm({ street: e.target.value });
        }}
        name="street"
        label={title || "כתובת הנכס"}
        value={form.street}
      />

      
      <Show when={shouldShowAssets() && assetsList}>
        <div class="bg-white shadow-blackShadow p-2  md:absolute md:top-24 md:w-96 max-h-[70vh] overflow-scroll md:right-0">
          <For each={assetsList()}>
            {(asset) => {
              return (
                <>
                  <DropDownAsset
                    phone={form.phone}
                    hideSendButton={hideSendButton}
                    onClick={() => {
                      setForm({
                        asset: asset,
                      });
                      setForm({
                        street:
                          asset.street.name +
                          " " +
                          (asset.address_number
                            ? asset.address_number + ", "
                            : "") +
                          asset.city.name,
                      });
                    }}
                    asset={asset}
                  />
                  <Spacer />
                  <div class="border border-lightGrey"></div>
                </>
              );
            }}
          </For>
        </div>
      </Show>
    </span>
  );
}

export const getAllPublicAssets = (
  allAssets: boolean,
  search: () => string,
  assetsList: Array<any>
) => {
  return createQuery({
    queryKey: () => ["all assets", search()],
    placeholderData: [],
    queryFn: async () => {
      if (assetsList && assetsList.length) {
        return assetsList
      } else if (!allAssets) {
        return user.active_assets.filter((asset) => {
          return (
            asset.city.name.includes(search()) ||
            asset.street.name.includes(search())
          );
        });
      }
      const res = await axios.get(`/real-estate/asset`, {
        params: { visibility_status: "AL", search: search() },
      });

      return [...user.active_assets, ...res.data.results].filter((asset) => {
        return (
          asset.city.name.includes(search()) ||
          asset.street.name.includes(search())
        );
      });
    },
  });
};
