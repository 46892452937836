import Heading, { Colors } from "../../Components/Heading";
import Spacer from "../../Components/Spacer";
import { FlexRow } from "../../Components/Flex";
import logo from "../../Icons/logo.svg";
import { BackButton } from "./BackButton";
import { Capacitor } from "@capacitor/core";
import { JSX, Show } from "solid-js";
import { Nav } from "../Home/Nav";

export const Header = (props: {
  title?: string;
  children: JSX.Element;
  fixed?: boolean;
  goback?: () => void;
  hidelogo?: boolean;
  nav?: boolean;
  rightElement?: JSX.Element;
}) => {
  return (
    <nav
      class="shadow-blackShadow md:h-40  md:relative md:shadow-none md:rounded-br-none bg-white md:drop-shadow-md rounded-br-3xl rounded-bl-3xl  overflow-hidden p-4 z-20"
      classList={{
        "top-0 right-0 left-0 z-30 sticky": props.fixed,
        "pt-10": Capacitor.getPlatform() === "ios",
      }}
    >
      <Show when={props.nav}>
        <header class="absolute left-0 top-2">
          <Nav />
        </header>
      </Show>
      <FlexRow justify="around">
        <div class="w-4/12 md:pr-8">
          <button>
            <BackButton onClick={props.goback || null} />
          </button>
        </div>
        <div class="w-4/12 text-center">
          {" "}
          <Show when={!props.hidelogo}>
            <img class="w-9 m-auto" src={logo} />
          </Show>
        </div>
        <div class="w-4/12 flex justify-end">{props.rightElement}</div>
      </FlexRow>
      <Spacer height="4px" />
      <Heading class="md:text-4xl text-xl" side={"middle"} bold>
        {props.title}
      </Heading>
      <Heading class="text-base" color={Colors.secondary}>
        {props.children}{" "}
      </Heading>
    </nav>
  );
};
