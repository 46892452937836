export const ActiveGreenIcon = () => (
  <svg
    style="width: 100%;"
    width="53"
    height="5"
    viewBox="0 0 53 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 5C0 2.23858 2.23858 0 5 0H48C50.7614 0 53 2.23858 53 5H0Z"
      fill="#8BC83F"
    />
  </svg>
);
