import {
  createEffect,
  createMemo,
  createSignal,
  For,
  Index,
  onMount,
  Show,
} from "solid-js";
import Button from "./Button";
import Spacer from "./Spacer";
import { user } from "../globalState";
import Heading, { Colors } from "./Heading";
import UpdateAssetCard from "./UpdateAssetCard";
import { FlexCol, FlexRow } from "./Flex";
import { Modal } from "./Modal";
import LocationIcon from "../Icons/LocationIcon";
import megaPhone from "../Components/Megaphone.png";
import TextInput from "./TextInput";
import axios from "axios";
import { EnumKey, VisibilityStatusEnum } from "../types";
import { getUserDetails } from "../api/getUserDetails";
import { DealModal } from "../Pages/Customer/DealModal";
import { pricify, unpricify } from "../Pages/AddAsset/AssetInfo";
import { normalizeAddress } from "../Pages/Assets/ViewAsset";
import { createStore, unwrap } from "solid-js/store";
import { debug } from "console";
export const UpdateModal = (props) => {
  const [updateAsset, setUpdateAsset] = createSignal(null);
  const [dealModal, setDealModal] = createSignal(false);
  const [price, setPrice] = createSignal();
  const assetPicked = () =>
    user.active_assets.find((asset) => asset.id === updateAsset());

  let priceInput;

  const oldAssets = unwrap(user.active_assets)?.filter((item) => {
    const tenDays = 10 * 24 * 60 * 60 * 1000;
    const tenDaysAgo = new Date(new Date().getTime() - tenDays);
    const isOlderThanTenDays =
      tenDaysAgo.getTime() < new Date(item.last_modified).getTime();
    return !isOlderThanTenDays;
  });

  const [assetsToUpdate, setAssetstoUpdate] = createStore([]);

  onMount(() => {
    setAssetstoUpdate(oldAssets);
  });

  createEffect(() => {
    if (assetPicked()) {
      setPrice(unpricify(assetPicked().price));
    }
  });

  const [show, setShow] = createSignal(false);
  createEffect(() => {
    if (assetsToUpdate.length) {
      setShow(true);
    } else {
      setShow(false);
    }
  });

  window.mockAssetModal = () => {
    setAssetstoUpdate(unwrap(user.active_assets));
    setShow(true);
  };

  return (
    <>
      <Show when={dealModal()}>
        <DealModal
          success={async () => {
            setAssetstoUpdate(
              assetsToUpdate.filter((a) => {
                return a?.id !== assetPicked()?.id;
              })
            );
            setUpdateAsset(null);
            setDealModal(false);
          }}
          asset={assetPicked()}
          hide={() => setDealModal(false)}
        />
      </Show>
      <Show when={!dealModal()}>
        <Modal
          onClose={() => setShow(false)}
          open={show()}
          classList={{ "max-w-lg": true }}
        >
          <div class="p-4 overflow-y-scroll max-w-lg max-h-[75vh] z-100">
            <Show when={!assetPicked()}>
              <Heading>עדכון נכסים</Heading>
              <Heading color={Colors.secondary}>
                היי {user.name}, שמנו לב שהנכסים הבאים לא עודכנו כבר למעלה מ-10
                ימים, נא עדכן סטטוס
              </Heading>
              <Spacer />
              <Button
                name="הזכירו לי מאוחר יותר"
                onClick={() => {
                  props.hide();
                }}
                short
                color="blue"
              >
                הזכירו לי מאוחר יותר
              </Button>
              <Spacer />
              <Show when={assetsToUpdate}>
                <FlexCol class="gap-4">
                  <Index each={assetsToUpdate}>
                    {(asset) => (
                      <UpdateAssetCard
                        handleAssetUpdated={async () => {
                          await axios.post(
                            `/real-estate/asset/${
                              asset().id
                            }/update_last_modified/`
                          );
                          setAssetstoUpdate(() =>
                            assetsToUpdate.filter((a) => a.id !== asset().id)
                          );
                        }}
                        openUpdateAssetForm={(id) => {
                          setUpdateAsset(id);
                        }}
                        asset={asset()}
                      />
                    )}
                  </Index>
                </FlexCol>
              </Show>
            </Show>
            <Show when={assetPicked()}>
              <Heading class="text-xl" bold>
                אנא עדכן את המידע עבור
              </Heading>
              <Heading color={Colors.secondary}>
                <FlexRow justify="center" class="gap-1">
                  {" "}
                  <LocationIcon />
                  {normalizeAddress(assetPicked())}
                </FlexRow>
                <Spacer height={"24px"} />

                <img class="m-auto" src={megaPhone} />
              </Heading>
              <Spacer height={"24px"} />
              <FlexCol class="gap-2 w-10/12 m-auto">
                <Button
                  border
                  name="נמכר"
                  fullWidth
                  onClick={() => {
                    setDealModal(Boolean(assetPicked()));
                  }}
                >
                  נמכר
                </Button>
                <Button
                  border
                  name="ירד ממכירה"
                  fullWidth
                  onClick={async () => {
                    await axios.patch(
                      `/real-estate/asset/${assetPicked().id}/`,
                      {
                        visibility_status: "NO",
                      }
                    );
                    setAssetstoUpdate(
                      assetsToUpdate.filter((a) => a.id !== assetPicked().id)
                    );
                    setUpdateAsset(null);
                  }}
                >
                  ירד ממכירה
                </Button>
                <Button
                  border
                  name="במשא ומתן"
                  fullWidth
                  onClick={async () => {
                    await axios.patch(
                      `/real-estate/asset/${assetPicked().id}/`,
                      {
                        visibility_status: "NO",
                      }
                    );
                    setAssetstoUpdate(
                      assetsToUpdate.filter((a) => a.id !== assetPicked().id)
                    );
                    setUpdateAsset(null);
                  }}
                >
                  במשא ומתן
                </Button>
                <TextInput
                  ref={priceInput}
                  name="price"
                  value={pricify(price())}
                  onChange={(e) => {
                    setPrice(unpricify(e.target.value));
                  }}
                  label="עדכן מחיר"
                />
                <Spacer height="12px" />
                <Button
                  name="אישור"
                  onClick={async () => {
                    await axios.patch(
                      `/real-estate/asset/${assetPicked().id}/`,
                      {
                        price: unpricify(priceInput.value),
                      }
                    );
                    setAssetstoUpdate(
                      assetsToUpdate.filter((a) => a.id !== assetPicked().id)
                    );
                    setUpdateAsset(null);
                  }}
                  fullWidth
                  color="green"
                >
                  אישור
                </Button>
                <Spacer height="6px" />
                <button
                  name="ביטול"
                  class="underline text-center"
                  onclick={() => setUpdateAsset(null)}
                >
                  ביטול
                </button>
              </FlexCol>
            </Show>
          </div>
        </Modal>
      </Show>
    </>
  );
};
