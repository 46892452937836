import { Index, Show, createEffect } from "solid-js";
import { createStore } from "solid-js/store";
import Asset from "../../Components/AssetCard";
import Button from "../../Components/Button";
import CheckBox from "../../Components/CheckBox";
import { FlexCol, FlexRow } from "../../Components/Flex";
import Heading from "../../Components/Heading";
import { Modal } from "../../Components/Modal";
import Spacer from "../../Components/Spacer";
import { user } from "../../globalState";
import shareWhatsapp from "../../utilities/shareWhatsApp";
import { getRelevantAssets } from "./getRelevantAssets";
import ReportModal from "../../Components/Modals/ReportModal";
import { BuyerEndCustomerStatusEnum } from "../../types";

export function RelevantAssetsModals(props: {
  id: string;
  hide: () => void;
  phone: string;
  customerStatus: BuyerEndCustomerStatusEnum;
}) {
  const [checkboxes, setCheckboxes] = createStore({ street: "" });
  const [reportModal, setReportModal] = createStore({ asset: null });
  const assets = getRelevantAssets(props.id);

  return (
    <Modal open onClose={props.hide}>
      <Show when={reportModal.asset}>
        <ReportModal
          asset={reportModal.asset}
          onClose={() => {
            setReportModal({ asset: null });
          }}
        />
      </Show>
      <div class="p-4 h-full overflow-y-scroll">
        <FlexRow>
          <Heading bold class="w-6/12 text-xl">
            נכסים רלוונטיים
          </Heading>
          <Button
            border
            class="w-4/12"
            short
            onClick={() => {
              if (!checkboxes[assets.data?.[0].id]) {
                assets.data.forEach((asset) => {
                  setCheckboxes({ [asset.id]: true });
                });
              } else {
                assets.data.forEach((asset) => {
                  setCheckboxes({ [asset.id]: false });
                });
              }
            }}
          >
            {" "}
            בחר הכל
          </Button>
        </FlexRow>
        <Spacer />
        <FlexCol class="gap-4">
          <Index each={assets.data}>
            {(asset) => {
              return (
                <div class="relative">
                  <div class="z-10 flex w-full absolute justify-between p-2">
                    <CheckBox
                      value={checkboxes[asset().id]}
                      setValue={(value) =>
                        setCheckboxes({ [asset().id]: value })
                      }
                    />
                    <button
                      onclick={() => {
                        setReportModal({ asset: asset() });
                      }}
                      class="flex items-center gap-2"
                    >
                      <ReportIcon />
                      <p class="text-xs">דווח</p>
                    </button>
                  </div>
                  <Asset fullWidth asset={asset()} />
                </div>
              );
            }}
          </Index>
          <Button
            onClick={() => {
              const checkedIds = Object.keys(checkboxes).filter(
                (id) => checkboxes[id]
              );
              const checkedAssets = assets.data.filter((asset) => {
                return checkedIds.includes(String(asset.id));
              });

              shareWhatsapp({
                phone: props.phone,
                assets: checkedAssets,
                customerStatus: props.customerStatus,
              });
            }}
          >
            שלח {Object.keys(checkboxes).filter((id) => checkboxes[id]).length}{" "}
            נכסים
          </Button>
        </FlexCol>
      </div>
    </Modal>
  );
}

export const ReportIcon = (props: { white: boolean }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.585 0.981895C10.4792 0.987097 10.3723 1.00661 10.2656 1.04186L0.965039 4.11679C0.394756 4.3058 0 4.89399 0 5.5565V7.06594C0 7.72845 0.394756 8.31664 0.965039 8.50565L2.46387 9.00097C2.33119 10.2654 2.98679 11.5019 4.11973 11.8745C5.25019 12.2459 6.40205 11.6082 6.91055 10.4713L10.265 11.5806C11.1188 11.8631 12 11.1325 12 10.1415V2.48091C12 1.73783 11.5046 1.14097 10.8967 1.00796C10.7954 0.985798 10.6907 0.976693 10.585 0.981895ZM10.6324 1.97907C10.8867 1.96161 11.1 2.17641 11.1 2.48091V10.1415C11.1 10.49 10.8217 10.7205 10.5217 10.6212L1.22168 7.54563C1.02922 7.48141 0.9 7.2894 0.9 7.06594V5.5565C0.9 5.33304 1.02922 5.14103 1.22168 5.07681L10.5217 2.00123C10.5592 1.98884 10.5961 1.98156 10.6324 1.97907ZM3.3252 9.28579L6.04922 10.1865C5.7012 10.8068 5.03886 11.1327 4.37461 10.9145C3.70893 10.6956 3.31217 10.0188 3.3252 9.28579Z"
      fill={props.white ? "white" : "#234F68"}
    />
  </svg>
);
