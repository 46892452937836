import { createQuery } from "@tanstack/solid-query";
import axios from "axios";
import { Street } from "../types";
const getNeighborhoodsByName = (
  neighborhood: () => string,
  city: () => number
) => {
  return createQuery(
    () => ["fetchNeighborhood", neighborhood(), city()],
    () => fetchNeighborhood(neighborhood(), city()),
    {
      staleTime: 10000,
    }
  );
};

export default getNeighborhoodsByName;

const fetchNeighborhood = async (neighborhood: string, city: number) => {
  if (!city) {
    return {};
  }
  let query = await axios.get(`/real-estate/neighborhoods/`, {
    params: { search: neighborhood, city },
  });

  const neighborhoods: { [id: number]: string } = {};
  query.data.results.forEach((neighborhood: Street) => {
    neighborhoods[neighborhood.id] = neighborhood.name;
  });

  while (query.data.next) {
    query = await axios.get(query.data.next);

    query.data.results.forEach((neighborhood: Street) => {
      neighborhoods[neighborhood.id] = neighborhood.name;
    });
  }

  return neighborhoods;
};
