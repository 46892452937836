export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="13"
    viewBox="0 0 11 13"
    fill="none"
  >
    <path
      d="M11 5.2C11 8.0717 5.5 13 5.5 13C5.5 13 0 8.0717 0 5.2C0 3.82087 0.579463 2.49823 1.61091 1.52304C2.64236 0.547856 4.04131 0 5.5 0C6.95869 0 8.35764 0.547856 9.38909 1.52304C10.4205 2.49823 11 3.82087 11 5.2Z"
      fill="#8BC83F"
    />
  </svg>
);
