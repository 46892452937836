export const PicturesIcon = (props: { active: boolean }) => (
  <svg
    width="18"
    class="w-6 relative right-[2px]"
    height="18"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2.2002V19.8002H22V2.2002H0ZM0.88 3.0802H21.12V14.5202H16.4209L13.3409 12.3202H10.7422L8.94438 10.5224L6.80281 10.9521L4.90875 8.10926L0.88 12.138V3.0802ZM16.5 5.7202C15.412 5.7202 14.52 6.61223 14.52 7.7002C14.52 8.78816 15.412 9.6802 16.5 9.6802C17.588 9.6802 18.48 8.78816 18.48 7.7002C18.48 6.61223 17.588 5.7202 16.5 5.7202ZM16.5 6.6002C17.1119 6.6002 17.6 7.08832 17.6 7.7002C17.6 8.31207 17.1119 8.8002 16.5 8.8002C15.8881 8.8002 15.4 8.31207 15.4 7.7002C15.4 7.08832 15.8881 6.6002 16.5 6.6002ZM4.77125 9.49113L6.39719 11.9283L8.65563 11.478L10.3778 13.2002H13.0591L16.1391 15.4002H21.12V18.9202H0.88V13.3824L4.77125 9.49113Z"
      fill={"#BABABA"}
      class={props.active ? "fill-lightGrey" : "fill-blue"}
    />
  </svg>
);

export const AddIcon = (props: {
  class?: string;
  white?: boolean;
  fillColor?: string;
  pathColor?: string;
}) => {
  let { fillColor, pathColor } = props;
  if (props.white) {
    fillColor = "white";
    pathColor = "#234F68";
  }
  return (
    <svg
      class={props.class}
      width="24"
      height="24"
      viewBox="0 0 30 30"
      fill={props.fillColor ? props.fillColor : "#234F68"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" class={props.class} />
      <g clip-path="url(#clip0_519_7352)">
        <path
          d="M14.6381 7.33788C14.547 7.43345 14.4973 7.56122 14.5 7.69327L14.5 14.5001L7.69322 14.5001C7.62633 14.4991 7.55992 14.5116 7.49792 14.5367C7.43593 14.5619 7.37961 14.5992 7.3323 14.6465C7.26195 14.7169 7.21421 14.8066 7.19517 14.9043C7.17612 15.002 7.18665 15.1031 7.22541 15.1948C7.26417 15.2864 7.32938 15.3644 7.41272 15.4188C7.49606 15.4732 7.59372 15.5014 7.69322 15.5L14.5 15.5L14.5 22.3068C14.4987 22.3733 14.5106 22.4394 14.5351 22.5013C14.5597 22.5631 14.5963 22.6194 14.6428 22.667C14.6894 22.7145 14.745 22.7522 14.8063 22.778C14.8676 22.8038 14.9335 22.817 15 22.817C15.0665 22.817 15.1324 22.8038 15.1937 22.778C15.255 22.7522 15.3106 22.7145 15.3572 22.667C15.4037 22.6194 15.4403 22.5631 15.4648 22.5013C15.4894 22.4394 15.5013 22.3733 15.4999 22.3068L15.4999 15.5L22.3068 15.5C22.3733 15.5013 22.4394 15.4894 22.5012 15.4649C22.5631 15.4404 22.6194 15.4038 22.6669 15.3572C22.7144 15.3106 22.7522 15.2551 22.7779 15.1938C22.8037 15.1324 22.817 15.0666 22.817 15C22.817 14.9335 22.8037 14.8677 22.7779 14.8063C22.7522 14.745 22.7144 14.6894 22.6669 14.6429C22.6194 14.5963 22.5631 14.5597 22.5012 14.5352C22.4394 14.5107 22.3733 14.4987 22.3068 14.5001L15.4999 14.5001L15.4999 7.69327C15.502 7.59251 15.4736 7.49348 15.4184 7.40916C15.3632 7.32483 15.2838 7.25916 15.1906 7.22073C15.0975 7.18231 14.9949 7.17294 14.8963 7.19384C14.7977 7.21475 14.7077 7.26495 14.6381 7.33788Z"
          stroke={props.pathColor ? props.pathColor : "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_519_7352">
          <rect
            width="16"
            height="16"
            stroke={props.pathColor ? props.pathColor : "white"}
            transform="translate(7 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const DeleteIcon = () => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.52929 0C6.14058 0 4.98424 0.983037 4.79015 2.25H0.602206C0.523528 2.24895 0.445412 2.26272 0.3724 2.29051C0.299387 2.31831 0.232934 2.35957 0.176902 2.41191C0.12087 2.46425 0.0763769 2.52661 0.0460079 2.59538C0.0156389 2.66415 0 2.73795 0 2.8125C0 2.88705 0.0156389 2.96085 0.0460079 3.02962C0.0763769 3.09839 0.12087 3.16075 0.176902 3.21309C0.232934 3.26543 0.299387 3.30669 0.3724 3.33449C0.445412 3.36228 0.523528 3.37605 0.602206 3.375H1.44799L2.44531 13.136C2.55376 14.1984 3.48617 15 4.61311 15H10.4455C11.5724 15 12.5044 14.1984 12.6133 13.136L13.6106 3.375H14.4564C14.5351 3.37605 14.6132 3.36228 14.6862 3.33449C14.7592 3.30669 14.8256 3.26543 14.8817 3.21309C14.9377 3.16075 14.9822 3.09839 15.0126 3.02962C15.0429 2.96085 15.0586 2.88705 15.0586 2.8125C15.0586 2.73795 15.0429 2.66415 15.0126 2.59538C14.9822 2.52661 14.9377 2.46425 14.8817 2.41191C14.8256 2.35957 14.7592 2.31831 14.6862 2.29051C14.6132 2.26272 14.5351 2.24895 14.4564 2.25H10.2684C10.0743 0.983037 8.918 0 7.52929 0ZM7.52929 1.125C8.27312 1.125 8.88334 1.60056 9.05773 2.25H6.00085C6.17524 1.60056 6.78546 1.125 7.52929 1.125ZM5.74804 5.25C6.07579 5.25 6.34179 5.50163 6.34179 5.8125V11.4375C6.34179 11.7484 6.07579 12 5.74804 12C5.42029 12 5.15429 11.7484 5.15429 11.4375V5.8125C5.15429 5.50163 5.42029 5.25 5.74804 5.25ZM9.31054 5.25C9.63829 5.25 9.90429 5.50163 9.90429 5.8125V11.4375C9.90429 11.7484 9.63829 12 9.31054 12C8.98279 12 8.71679 11.7484 8.71679 11.4375V5.8125C8.71679 5.50163 8.98279 5.25 9.31054 5.25Z"
      fill="#234F68"
    />
  </svg>
);
export const AttentionIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_519_7345)">
      <path
        d="M12 0.959961C5.90842 0.959961 0.959961 5.90842 0.959961 12C0.959961 18.0915 5.90842 23.04 12 23.04C18.0915 23.04 23.04 18.0915 23.04 12C23.04 5.90842 18.0915 0.959961 12 0.959961ZM12 1.91996C17.5727 1.91996 22.08 6.42725 22.08 12C22.08 17.5727 17.5727 22.08 12 22.08C6.42725 22.08 1.91996 17.5727 1.91996 12C1.91996 6.42725 6.42725 1.91996 12 1.91996ZM12 2.87996C6.96881 2.87996 2.87996 6.96881 2.87996 12C2.87996 17.0311 6.96881 21.12 12 21.12C17.0311 21.12 21.12 17.0311 21.12 12C21.12 6.96881 17.0311 2.87996 12 2.87996ZM12 3.83996C16.5123 3.83996 20.16 7.48763 20.16 12C20.16 16.5123 16.5123 20.16 12 20.16C7.48763 20.16 3.83996 16.5123 3.83996 12C3.83996 7.48763 7.48763 3.83996 12 3.83996ZM11.52 6.23996C11.255 6.23996 11.04 6.455 11.04 6.71996V12.96C11.04 13.2249 11.255 13.44 11.52 13.44H12.48C12.7449 13.44 12.96 13.2249 12.96 12.96V6.71996C12.96 6.455 12.7449 6.23996 12.48 6.23996H11.52ZM11.52 15.36C11.255 15.36 11.04 15.575 11.04 15.84V16.8C11.04 17.0649 11.255 17.28 11.52 17.28H12.48C12.7449 17.28 12.96 17.0649 12.96 16.8V15.84C12.96 15.575 12.7449 15.36 12.48 15.36H11.52Z"
        fill="#234F68"
      />
    </g>
    <defs>
      <clipPath id="clip0_519_7345">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
