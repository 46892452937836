import { callModal } from "./App";
import { GenericModal } from "./Pages/Assets/GenericModal";
import { UserIcon } from "./Pages/AddClient/BuyerForm";

export function CallModal({ setCallModal, navigate }) {
  return (
    <GenericModal
      options={[
        {
          color: "green",
          label: "עבור לדף לקוח",
          handler: () => {
            if (callModal().notification.data.phone) {
              const data: {
                phone?: string;
                dont_call_me: string;
                name: string;
                id: string;
              } = callModal().notification.data;
              setCallModal();
              navigate("/phonecall", {
                state: data,
              });
            }
          },
        },
      ]}
      alt="new call"
      icon={UserIcon}
      header="שיחה נכנסת!"
      subHeader="לחץ כאן למילוי פרטי לקוח"
    />
  );
}
