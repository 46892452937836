const WhatsupIcon = () => {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M50 100C73.2995 100 92.1875 97.9013 92.1875 95.3125C92.1875 92.7237 73.2995 90.625 50 90.625C26.7005 90.625 7.8125 92.7237 7.8125 95.3125C7.8125 97.9013 26.7005 100 50 100Z" fill="black"/>
            <path d="M55.5734 81.25H13.1765C11.0109 81.25 9.25464 79.475 9.25464 77.2844V72.2828C9.25464 58.25 20.5 46.875 34.375 46.875C48.25 46.875 59.4953 58.25 59.4953 72.2828V77.2844C59.4953 79.475 57.739 81.25 55.5734 81.25Z" fill="#234F68"/>
            <path opacity="0.15" d="M59.4953 77.2844V72.2812C59.4953 64.9188 56.3797 58.3078 51.4328 53.6672C48.4625 56.0031 47.5297 60.1984 49.4328 63.5984C50.9047 66.2281 51.6828 69.2297 51.6828 72.2812V73.4375H39.0625C34.7469 73.4375 31.25 76.9359 31.25 81.25H55.5734C57.7391 81.25 59.4953 79.475 59.4953 77.2844Z" fill="black"/>
            <path d="M34.375 43.75C43.0044 43.75 50 36.7544 50 28.125C50 19.4956 43.0044 12.5 34.375 12.5C25.7456 12.5 18.75 19.4956 18.75 28.125C18.75 36.7544 25.7456 43.75 34.375 43.75Z" fill="#8BC83F"/>
            <path opacity="0.3" d="M34.375 12.5C25.7453 12.5 18.75 19.4953 18.75 28.125C18.75 30.8609 19.4563 33.4281 20.6922 35.6656C24.0609 34.7969 26.5625 31.7656 26.5625 28.125C26.5625 23.8172 30.0672 20.3125 34.375 20.3125C38.0156 20.3125 41.0484 17.8109 41.9156 14.4422C39.6781 13.2063 37.1109 12.5 34.375 12.5Z" fill="white"/>
            <path d="M85.9375 39.0625H67.1875L60.6219 47.5047C59.1938 49.3406 56.25 48.3313 56.25 46.0047V18.75C56.25 15.2984 59.0484 12.5 62.5 12.5H85.9375C89.3891 12.5 92.1875 15.2984 92.1875 18.75V32.8125C92.1875 36.2641 89.3891 39.0625 85.9375 39.0625Z" fill="#234F68"/>
            <path d="M24.7109 25C25.6266 22.1734 27.75 19.8875 30.4703 18.75" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}
export default WhatsupIcon