import { useParams } from "@solidjs/router";
import { getDeals } from "./Pages/Deals/getDeals";
import { Show, createEffect } from "solid-js";
import Heading from "./Components/Heading";
import Spacer from "./Components/Spacer";
import { Nav } from "./Pages/Home/Nav";
import { BackButton } from "./Pages/AddClient/BackButton";
import { getAsset } from "./Pages/Assets/getAsset";
import AssetCard from "./Components/AssetCard";
import { getCustomer } from "./Pages/Customer/getCustomer";

export const Deal = () => {
  const params = useParams();
  const id = params.id;
  const type = params.type;
  const query = getDeals(id);

  const assetQuery = getAsset(() => query.data.asset?.id);

  const customer = () => {
    if (type === "buyer") {
      return query.data?.purchasing_customer;
    }
    if (type === "seller") {
      return query.data?.selling_customer;
    }
  };

  const secondAgent = () => {
    if (type === "buyer") {
      return query.data?.selling_customer_realtor;
    }
    if (type === "seller") {
      return query.data?.purchasing_customer_realtor;
    }
  };
  return (
    <div class="w-full md:pb-8 md:h-screen overflow-y-auto">
      <Nav />

      <div class="flex justify-center flex-col items-center p-2 md:py-0 md:px-6">
        <div class="relative text-right w-full">
          <div class="text-5xl font-bold text-right tracking-wide w-full">
            עסקה שנסגרה <span class="text-green">.</span>
          </div>
          <Spacer height="6px" />
          <div class="text-right w-full text-darkGrey">
            הצטרף למשפחת גשר ותהיה חלק ממהפכה בעולם הנדל”ן
          </div>
          <Spacer />
          <div class="h-[1px] bg-lightGrey w-full" />
        </div>
      </div>
      <p class="mt-4 mr-4">
        <BackButton />
      </p>

      <Show when={query.data && assetQuery.data}>
        <section class="flex flex-col items-center">
          <h1 class="text-3xl mb-4">{customer()?.name}</h1>
          <p class="flex justify-between w-80 p-4 border-b-darkGrey border-b">
            <span class="text-darkGrey">שם מלא</span>
            <span class="text-blue">{customer()?.name}</span>
          </p>
          <p class="flex justify-between w-80 p-4 border-b-darkGrey border-b">
            <span class="text-darkGrey">טלפון</span>
            <span class="text-blue">{customer()?.phone}</span>
          </p>{" "}
          <p class="flex justify-between w-80 p-4 border-b-darkGrey border-b">
            <span class="text-darkGrey">ת.עסקה</span>
            <span class="text-blue">
              {new Date(query.data.deal_date).toLocaleDateString()}
            </span>
          </p>
          <p class="flex justify-between w-80 p-4 border-b-darkGrey border-b">
            <span class="text-darkGrey">מתווך שני</span>
            <span class="text-blue">{secondAgent()?.name}</span>
          </p>
          <Spacer></Spacer>
          <AssetCard asset={assetQuery.data} />
        </section>
      </Show>
    </div>
  );
};
