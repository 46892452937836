import WhatsupIcon from "../../Pages/Assets/Customers/ModalIcon";
import NewTextarea from "../Textarea";
import { createStore } from "solid-js/store";
import { Setter, Show, createEffect } from "solid-js";
import axios from "axios";
import { unwrap } from "solid-js/store";
import { WhatsupSendMessages } from "../../api/WhatsupMsg";
import { RealtorHomeAsset } from "../../types";


interface IProps {
    show: boolean;
    toggle: Setter<boolean> | Function;
    receivers: any;
    receiversPhone: any;
    setErrorModal: Setter<boolean>;
    description: string;
    sentMessages: number;
    setSentMessage: Setter<number>;
    limitMessages: number;
    assetId?: Array<number>;
    assets?: Array<RealtorHomeAsset>;
    agentPhone: string;
    sentSuccess: Function;
    scheduleMessage: string;
    message?: string | null;
    singleCustomerName?: string | null;
}

const WhatsupSendModal = (props: IProps) => {
    createEffect(() => {
        if (props.scheduleMessage && props.message) {
            setForm({text: props.message})
        }
    })
    const [form, setForm]:any = createStore({
        text: '',
    })

    const submit = async () => {
        let data:any = {
            receivers: props.receivers,
            text: form.text,
            agent_phone: props.agentPhone
        }
        if (props.assetId) {
            data.asset_id = props.assetId
        }
        if (props.assets) {
            data.assets = props.assets
        }
        if (props.receiversPhone) {
            data.receiversPhone = props.receiversPhone
        }
        const request = await WhatsupSendMessages(data, props.scheduleMessage)
        if (request) {
            props.toggle(false)
            props.sentSuccess()
        }
    }


    return (
        <div
            class={props.show ? 'open c-modal' : 'c-modal'}
        >
            <div
                class="c-modal--overlay"
                onClick={(e) => props.toggle(false)}
            ></div>
            <div class="c-modal--container">
                <div class="c-modal--content whatsup-send">
                    <div class="c-modal--close">
                        <button
                            class="btn--close"
                            onClick={(e) => props.toggle(false)}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    <div class="c-modal--title whatsup-send">
                        <span class="st-icon">
                            <Show when={props.scheduleMessage} fallback={<WhatsupIcon />}>
                                <ScheduleIcon />
                            </Show>
                        </span>
                        <h3 class="st-title">
                            <Show when={props.scheduleMessage} fallback={`שליחת ${props.description} ללקוח`}>
                                שמור על קשר עם  {props.singleCustomerName}
                            </Show>
                        </h3>
                        <p class="st-text">
                            <Show when={props.scheduleMessage} fallback={`בחרת לשתף מידע לגביי הנכס עם ${props.receivers.length} לקוחות`}>
                                כתוב הודעה שתישלח ללקוח באופן אוטומטי במועד שבחרת על מנת לשמר את הקשר
                            </Show>
                            
                        </p>
                    </div>
                    <div class="c-modal--form">
                        <NewTextarea
                            required={true}
                            label="הוספת הודעה אישית"
                            placeholder="רשום כאן"
                            height="100"
                            countable={true}
                            maxCount={100}
                            value={form.text}
                            onChange={(e:any) => {
                                setForm({text: e.currentTarget.value})
                            }}
                        />
                        <div class="info--container">
                            <p class="info-text">
                                לאחר שליחת הודעה זו יישארו לך {props.limitMessages - props.sentMessages - props.receivers.length} שליחות נוספות 
                            </p>
                            <div class="submit--container">
                                <button
                                    class="btn--submit"
                                    style="margin-top: 0"
                                    onClick={(e:any) => {
                                        submit()
                                    }}
                                >
                                    שלח
                                    <span class="icon">
                                        <SubmitIcon />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhatsupSendModal

const CloseIcon = () => (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 0C8.06833 0 0 8.06833 0 18C0 27.9317 8.06833 36 18 36C27.9317 36 36 27.9317 36 18C36 8.06833 27.9317 0 18 0ZM18 1.56522C27.0857 1.56522 34.4348 8.91428 34.4348 18C34.4348 27.0857 27.0857 34.4348 18 34.4348C8.91428 34.4348 1.56522 27.0857 1.56522 18C1.56522 8.91428 8.91428 1.56522 18 1.56522ZM24.2532 10.9458C24.0468 10.9507 23.8507 11.037 23.7075 11.1858L18 16.8933L12.2925 11.1858C12.2195 11.1108 12.1323 11.0512 12.036 11.0105C11.9396 10.9699 11.8361 10.9489 11.7315 10.9489C11.5758 10.9489 11.4237 10.9954 11.2945 11.0823C11.1654 11.1693 11.0651 11.2928 11.0065 11.437C10.9479 11.5813 10.9337 11.7397 10.9656 11.8921C10.9976 12.0445 11.0742 12.1839 11.1858 12.2925L16.8933 18L11.1858 23.7075C11.1107 23.7797 11.0507 23.866 11.0094 23.9616C10.9681 24.0572 10.9463 24.16 10.9452 24.2642C10.9442 24.3683 10.9639 24.4716 11.0032 24.568C11.0426 24.6644 11.1008 24.7519 11.1744 24.8256C11.248 24.8992 11.3356 24.9574 11.432 24.9968C11.5284 25.0361 11.6317 25.0558 11.7358 25.0548C11.84 25.0537 11.9428 25.0319 12.0384 24.9906C12.134 24.9493 12.2203 24.8893 12.2925 24.8142L18 19.1067L23.7075 24.8142C23.7797 24.8893 23.866 24.9493 23.9616 24.9906C24.0572 25.0319 24.16 25.0537 24.2642 25.0548C24.3683 25.0558 24.4716 25.0361 24.568 24.9968C24.6644 24.9574 24.7519 24.8992 24.8256 24.8256C24.8992 24.7519 24.9574 24.6644 24.9968 24.568C25.0361 24.4716 25.0558 24.3683 25.0548 24.2642C25.0537 24.16 25.0319 24.0572 24.9906 23.9616C24.9493 23.866 24.8893 23.7797 24.8142 23.7075L19.1067 18L24.8142 12.2925C24.9279 12.1832 25.0059 12.0421 25.038 11.8877C25.0701 11.7333 25.0548 11.5729 24.9942 11.4273C24.9336 11.2818 24.8304 11.1579 24.6982 11.072C24.5659 10.9861 24.4109 10.9421 24.2532 10.9458Z" fill="white"/>
</svg>
)

const SubmitIcon = () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_805_256)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.477 2 2 6.477 2 12C2 13.89 2.525 15.66 3.438 17.168L2.546 20.2C2.49478 20.3741 2.49141 20.5587 2.53624 20.7346C2.58107 20.9104 2.67245 21.0709 2.80076 21.1992C2.92907 21.3276 3.08958 21.4189 3.26542 21.4638C3.44125 21.5086 3.62592 21.5052 3.8 21.454L6.832 20.562C8.39068 21.5051 10.1782 22.0025 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM9.738 14.263C11.761 16.285 13.692 16.552 14.374 16.577C15.411 16.615 16.421 15.823 16.814 14.904C16.8636 14.7897 16.8816 14.6641 16.8661 14.5405C16.8507 14.4168 16.8023 14.2996 16.726 14.201C16.178 13.501 15.437 12.998 14.713 12.498C14.5618 12.3935 14.3761 12.3516 14.1947 12.381C14.0133 12.4105 13.8503 12.509 13.74 12.656L13.14 13.571C13.1085 13.6202 13.0593 13.6555 13.0026 13.6696C12.9459 13.6837 12.8859 13.6756 12.835 13.647C12.428 13.414 11.835 13.018 11.409 12.592C10.983 12.166 10.611 11.6 10.402 11.219C10.3761 11.1706 10.3686 11.1144 10.3809 11.0609C10.3932 11.0074 10.4245 10.9602 10.469 10.928L11.393 10.242C11.5249 10.1273 11.61 9.9682 11.6321 9.79486C11.6542 9.62153 11.6118 9.44611 11.513 9.302C11.065 8.646 10.543 7.812 9.786 7.259C9.68831 7.1882 9.57386 7.14406 9.45393 7.13091C9.334 7.11776 9.21271 7.13606 9.102 7.184C8.182 7.578 7.386 8.588 7.424 9.627C7.449 10.309 7.716 12.24 9.738 14.263Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_805_256">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
)


const ScheduleIcon = () => (
<svg width="111" height="110" viewBox="0 0 111 110" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.5801 88.5156C21.6963 88.5156 20.2801 88.0086 19.3399 86.9963C18.3963 85.9805 17.9959 84.6742 18.1368 83.0723C18.2777 81.4705 18.9859 79.8445 20.2577 78.1928L44.4079 47.2794H22.9201C19.7146 47.2794 18.0801 46.0453 18.0801 42.9825C18.0801 39.9644 19.7146 38.6719 22.9201 38.6719H52.0426C53.9263 38.6719 55.3546 39.1789 56.3205 40.1913C57.2848 41.207 57.699 42.5012 57.558 44.0808C57.4171 45.6603 56.6866 47.2966 55.3666 48.9947L31.287 79.9562H52.8177C56.0232 79.9562 57.6096 81.2488 57.6096 84.2652C57.6096 87.3297 56.0215 88.5156 52.8177 88.5156H23.5801Z" fill="#234F68"/>
<path d="M65.5861 67.8913C64.0272 67.8913 62.855 67.472 62.0764 66.6349C61.2961 65.7945 60.9644 64.7134 61.0812 63.3882C61.1981 62.0631 61.7842 60.7173 62.8361 59.3509L82.7959 33.537L65.0172 33.5146C62.3652 33.5146 61.0469 32.6174 61.0469 30.0823C61.0469 27.5849 62.3875 26.6431 65.0395 26.6431H89.1141C90.673 26.6431 91.8537 27.0624 92.6547 27.8995C93.4522 28.7399 93.7959 29.8107 93.6791 31.1187C93.5622 32.4249 92.9572 33.781 91.8641 35.1853L71.9644 61.0267H89.7827C92.4347 61.0267 93.7152 62.1782 93.7152 64.4453C93.7152 66.6813 92.4347 67.8913 89.7827 67.8913H65.5861Z" fill="#8BC83F"/>
<path d="M34.8676 33.5156C33.8277 33.5156 33.0474 33.2355 32.5284 32.6769C32.0076 32.1166 31.7876 31.3964 31.8649 30.5113C31.9423 29.6278 32.3324 28.7306 33.0354 27.8197L46.9177 11.165H34.5049C32.7363 11.165 31.834 10.3125 31.834 8.62125C31.8323 6.9575 32.7346 6.01562 34.5032 6.01562H51.1287C52.1685 6.01562 52.9557 6.29578 53.4885 6.85437C54.0196 7.41469 54.2482 8.12969 54.1709 9.00109C54.0935 9.8725 53.6896 10.7748 52.9609 11.7116L39.1181 28.3697H50.9963C52.7649 28.3697 53.6398 29.083 53.6398 30.7467C53.6398 32.4363 52.7632 33.5156 50.9963 33.5156H34.8676Z" fill="#234F68"/>
<path opacity="0.3" d="M55.8965 103.984C76.7798 103.984 93.709 101.676 93.709 98.8281C93.709 95.9804 76.7798 93.6719 55.8965 93.6719C35.0132 93.6719 18.084 95.9804 18.084 98.8281C18.084 101.676 35.0132 103.984 55.8965 103.984Z" fill="black"/>
<path opacity="0.15" d="M32.8428 77.9546L55.3653 48.9937C55.5286 48.784 55.654 48.5778 55.8001 48.3698C52.055 45.484 46.6804 46.1646 43.774 49.8995L31.3354 65.8959C28.4222 69.6428 29.0976 75.0431 32.8428 77.9546Z" fill="black"/>
<path opacity="0.3" d="M18.0801 42.9825C18.0801 44.8783 18.7091 46.0694 19.9449 46.7053C20.8765 47.0508 21.8699 47.2656 22.9184 47.2656H36.3332C41.0787 47.2656 44.927 43.4173 44.927 38.6719H22.9201C19.7146 38.6719 18.0801 39.9644 18.0801 42.9825Z" fill="white"/>
<path d="M25.8184 42.9688H32.6934" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)