import { createSignal, JSX, JSXElement, Ref, Show } from "solid-js";

import { handleRequired } from "../Pages/Login/validate";
import { unpricify } from "../Pages/AddAsset/AssetInfo";
import { Pencil } from "../Icons";

type Props = {
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  pencilIcon?: boolean;
  errorSetter?: () => {};
  webIcon?: boolean;
  value?: string | number;
  onBlur?: (e: Event) => void;
  onChange?: (
    e: Event & {
      currentTarget: HTMLInputElement;
      target: Element;
    }
  ) => void;
  error?: boolean;
  errorLabel?: string;
  icon?: string;
  name: string;
  class?: string;
  bypassHeight?: boolean;
  required?: boolean;
  ref?: null;
  onclick?: () => void;
  type?: string;
  customButton?: JSXElement;
  onkeydown?: (e) => void;
  iconComponent?: JSXElement;
};
const CodeInput = (props: Props) => {
  return (
    <div class={"m-auto relative " + props.class}>
      <div
        class={`text-right text-sm ${
          props.pencilIcon || props.webIcon ? "text-darkGrey" : " text-darkBlue"
        }`}
      >
        {props.label} {props.required && "*"}
      </div>
      <div class="relative">
        <input
          inputmode="numeric"
          pattern="[0-9]*"
          onkeydown={props.onkeydown}
          id={props.name}
          type="tel"
          onclick={props.onclick}
          autocomplete="off"
          autocorrect="off"
          ref={props.ref}
          placeholder={props.placeholder}
          name={props.name}
          onChange={props.onChange}
          oninput={props.onChange}
          value={props.value || ""}
          disabled={props.disabled}
          class={`text-center w-full ${
            props.error ? "border border-error" : ""
          } ${
            props.webIcon ? "py-6 px-10" : ""
          }  text-blue  rounded-xl disabled:text-darkGrey bg-lightGrey`}
          classList={{
            "h-16": !props.bypassHeight,
            "pr-14": Boolean(props.iconComponent),
          }}
        />
        <Show when={props.customButton}>{props.customButton}</Show>
      </div>

      <Show when={props.iconComponent}>
        {" "}
        <div class="absolute top-[62%] translate-y-[-50%] right-6 cursor-pointer">
          {props.iconComponent}
        </div>
      </Show>
      <Show when={props.icon && !props.webIcon}>
        {" "}
        <div class="absolute top-[50%] translate-y-[-50%] left-6 cursor-pointer">
          <img src={props.icon} alt="input image" width="20px" />
        </div>
      </Show>
      <Show when={props.webIcon}>
        {" "}
        <div class="absolute top-1 right-3 cursor-pointer">
          <img src={props.icon} width={20} alt="input image" />
        </div>
      </Show>
      <Show when={props.pencilIcon && !props.disabled}>
        {" "}
        <div class="absolute top-[38%] left-6 cursor-pointer">
          <Pencil active />
        </div>
      </Show>

      <div class="text-right text-error">
        {props.error ? props.errorLabel : ""}
      </div>
    </div>
  );
};

export default CodeInput;
