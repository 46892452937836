import { JSX } from "solid-js";

export enum Colors {
  primary = "primary",
  secondary = "secondary",
}

type Props = {
  children: JSX.Element;
  color?: Colors;
  bold?: boolean;
  side?: "right" | "middle" | "left";
  class?: string;
};

export default (props: Props) => {
  return (
    <div
      class={props.class}
      classList={{
        "font-bold": props.bold,
        "text-center": props.side === "middle" || !props.side,
        "text-right": props.side === "right",
        "text-blue": props.color === "primary",
        "text-darkGrey": props.color === "secondary",
      }}
    >
      {props.children}
    </div>
  );
};
