import { Show } from 'solid-js';
import UserDataIcon from './UserDataIcon';
import UserLicenseIcon from './UserLicenseIcon';
import UserLocationIcon from './UserLocationIcon';

const UserDataSteps = (props: any) => {
	let active_step:number = props.step;
	return (
		<div
			class="registration__step--user"
			classList={{
				'half': active_step > 1,
				'full': active_step > 2
			}}
		>
			<div
				class="single-step"
				classList={
					{
						'active': active_step >= 1,
						'ready': active_step > 1
					}
				}
			>
				<p class="st-icon">
					<UserDataIcon
						active={active_step > 0}
					/>
					<Show when={active_step > 1}>
						<span class="ready-icon">
							<ReadyIcon />
						</span>
					</Show>
				</p>
				<p class="st-title">
					פרטים אישיים
				</p>
			</div>
			<div
				class="single-step"
				classList={
					{
						'active': active_step >= 2,
						'ready': active_step > 2
					}
				}
			>
				<p class="st-icon">
					<UserLicenseIcon
						active={active_step > 1}
					/>
					<Show when={active_step > 2}>
						<span class="ready-icon">
							<ReadyIcon />
						</span>
					</Show>
				</p>
				<p class="st-title">
					בניית דף מתווך
				</p>
			</div>
			<div
				class="single-step"
				classList={
					{
						'active': active_step >= 3,
						'ready': active_step > 3
					}
				}
			>
				<p class="st-icon">
					<UserLocationIcon
						active={active_step > 2}
					/>
				</p>
				<p class="st-title">
					איזורי פעילות
				</p>
			</div>
		</div>
	)
}
export default UserDataSteps;
const ReadyIcon = () => (
<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
	<rect x="20.0944" y="19.2545" width="18.1848" height="18.1848" rx="9.09242" transform="rotate(-180 20.0944 19.2545)" fill="#8BC83F" stroke="white" stroke-width="2.13939"/>
	<path d="M13.6799 7.95066L13.0897 7.54952C12.9264 7.43895 12.7027 7.48138 12.5934 7.64338L9.70063 11.9093L8.37122 10.5799C8.23237 10.441 8.00609 10.441 7.86723 10.5799L7.36195 11.0852C7.2231 11.224 7.2231 11.4503 7.36195 11.5905L9.40621 13.6347C9.52063 13.7491 9.70063 13.8366 9.86263 13.8366C10.0246 13.8366 10.1879 13.735 10.2933 13.582L13.775 8.44565C13.8856 8.28366 13.8431 8.06123 13.6799 7.95066Z" fill="white"/>
</svg>
)

const steps = [
	{
		title: "פרטים אישיים",
		icon: UserDataIcon
	},
	{
		title: "פרטי העסק",
		icon: UserLicenseIcon
	},
	{
		title: "איזורי פעילות",
		icon: UserLocationIcon
	}
]