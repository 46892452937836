import WhatsupIcon from "./ModalIcon";
import { createStore } from "solid-js/store";
import { Setter, createEffect, createSignal } from "solid-js";

interface IProps {
    show: boolean;
    toggle: Setter<boolean>;
    sentMessages: number;
    limitMessages: number;
}

const WhatsupSendSuccessModal = (props: IProps) => {
    const [lineWidth, setLineWidth] = createSignal(0)
    

    createEffect(() => {
        if (props.show) {
            setLineWidth(props.sentMessages / props.limitMessages * 100)
        } else {
            setLineWidth(0)
        }
    })

    return (
        <div
            class={props.show ? 'open c-modal' : 'c-modal'}
        >
            <div
                class="c-modal--overlay"
                onClick={(e) => props.toggle(false)}
            ></div>
            <div class="c-modal--container">
                <div class="c-modal--content whatsup-send">
                    <div class="c-modal--close">
                        <button
                            class="btn--close"
                            onClick={(e) => props.toggle(false)}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    <div class="c-modal--title whatsup-send">
                        <span class="st-icon">
                            <WhatsupIcon />
                        </span>
                        <h3 class="st-title">
                            נכס נשלח!
                        </h3>
                    </div>
                    <div class="c-modal--whatsup-scs">
                        <p class="st-text">
                            ניצלת <b>{props.sentMessages}</b> מתוך <b>{props.limitMessages}</b> שיתופים
                        </p>
                        <span class="st-line">
                            <span class="st-fill" style={{
                                width: lineWidth() + 'px'
                            }}></span>
                        </span>
                    </div>
                    <div class="c-modal--form">
                        <div class="submit--container">
                            <button
                                class="btn--submit"
                                style="width: 100%; max-width: 345px;"
                                onClick={(e:any) => {
                                    props.toggle(false)
                                }}
                            >
                                סגור
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhatsupSendSuccessModal

const CloseIcon = () => (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 0C8.06833 0 0 8.06833 0 18C0 27.9317 8.06833 36 18 36C27.9317 36 36 27.9317 36 18C36 8.06833 27.9317 0 18 0ZM18 1.56522C27.0857 1.56522 34.4348 8.91428 34.4348 18C34.4348 27.0857 27.0857 34.4348 18 34.4348C8.91428 34.4348 1.56522 27.0857 1.56522 18C1.56522 8.91428 8.91428 1.56522 18 1.56522ZM24.2532 10.9458C24.0468 10.9507 23.8507 11.037 23.7075 11.1858L18 16.8933L12.2925 11.1858C12.2195 11.1108 12.1323 11.0512 12.036 11.0105C11.9396 10.9699 11.8361 10.9489 11.7315 10.9489C11.5758 10.9489 11.4237 10.9954 11.2945 11.0823C11.1654 11.1693 11.0651 11.2928 11.0065 11.437C10.9479 11.5813 10.9337 11.7397 10.9656 11.8921C10.9976 12.0445 11.0742 12.1839 11.1858 12.2925L16.8933 18L11.1858 23.7075C11.1107 23.7797 11.0507 23.866 11.0094 23.9616C10.9681 24.0572 10.9463 24.16 10.9452 24.2642C10.9442 24.3683 10.9639 24.4716 11.0032 24.568C11.0426 24.6644 11.1008 24.7519 11.1744 24.8256C11.248 24.8992 11.3356 24.9574 11.432 24.9968C11.5284 25.0361 11.6317 25.0558 11.7358 25.0548C11.84 25.0537 11.9428 25.0319 12.0384 24.9906C12.134 24.9493 12.2203 24.8893 12.2925 24.8142L18 19.1067L23.7075 24.8142C23.7797 24.8893 23.866 24.9493 23.9616 24.9906C24.0572 25.0319 24.16 25.0537 24.2642 25.0548C24.3683 25.0558 24.4716 25.0361 24.568 24.9968C24.6644 24.9574 24.7519 24.8992 24.8256 24.8256C24.8992 24.7519 24.9574 24.6644 24.9968 24.568C25.0361 24.4716 25.0558 24.3683 25.0548 24.2642C25.0537 24.16 25.0319 24.0572 24.9906 23.9616C24.9493 23.866 24.8893 23.7797 24.8142 23.7075L19.1067 18L24.8142 12.2925C24.9279 12.1832 25.0059 12.0421 25.038 11.8877C25.0701 11.7333 25.0548 11.5729 24.9942 11.4273C24.9336 11.2818 24.8304 11.1579 24.6982 11.072C24.5659 10.9861 24.4109 10.9421 24.2532 10.9458Z" fill="white"/>
</svg>
)
