import Button from "../../Components/Button";
import Spacer from "../../Components/Spacer";
import TextInput from "../../Components/TextInput";
import PhoneInput from "../../Components/PhoneInput";
import { Accessor, Match, Setter, Show, Switch } from "solid-js";
import Select from "../../Components/Select";

import Picker from "../../Components/Picker";
import { FlexRow, FlexCol } from "../../Components/Flex";
import { handleForm } from "./AddClient";
import {
  AssetTypeEnum,
  BuyerEndCustomerStatusEnum,
  EvictionRangeEnum,
} from "../../types";
import generateRange from "../../utilities/generateRange";
import CheckBox from "../../Components/CheckBox";

import { AddIcon } from "../AddAsset/Icons";

import MultiSelect from "../../Components/MultiSelect";

import { pricify, unpricify } from "../AddAsset/AssetInfo";

import Radio from "../../Components/Radio";
import AutoComplete from "../../Components/AutoComplete";
import { isSmall } from "../Home/isSmall";
import {
  assetSchema,
  basicEndCustomerSchema,
  endCustomerSchema,
  purchaseListingSchema,
} from "../../zodSchemas";
import { z } from "zod";
import { SetStoreFunction } from "solid-js/store";
import { BuyerDesktopFields } from "./BuyerDesktopFields";
import { logEvent } from "../../analytics";

const purchaseSchema = purchaseListingSchema.partial();
type TpurchaseSchema = z.infer<typeof purchaseSchema>;
type elevatorModeT = { mode: "must" | "not must" | "from floor" };
type customerType = z.infer<typeof endCustomerSchema>;
export type BuyerFormProps = {
  fieldErrorHandler: any;
  customer: customerType;
  setCustomer: SetStoreFunction<customerType>;
  asset: TpurchaseSchema;
  setAsset: SetStoreFunction<TpurchaseSchema>;
  cityName: Accessor<string>;
  setCityName: Setter<string>;
  cityQuery: any;
  newAsset: TpurchaseSchema;
  setNewAsset: SetStoreFunction<TpurchaseSchema>;
  neighborhoodName: Accessor<string>;
  setNeighborhoodName: Setter<string>;
  neighborhoodQuery: any;
  currentNeighborhoodQuery: any;
  streetName: Accessor<string>;
  setStreetName: Setter<string>;
  cities: any;
  setReport: Setter<boolean>;
  elevatorMode: elevatorModeT;
  additionalAttributes: Accessor<boolean>;
  setAdditionalAttributes: Setter<boolean>;
  streets: { [id: number]: string }[];
};

const BuyerFormFields = (props: BuyerFormProps) => {
  const {
    fieldErrorHandler,
    customer,
    setCustomer,
    asset,
    setAsset,
    cityName,
    setCityName,
    cityQuery,
    newAsset,
    setNewAsset,
    neighborhoodName,
    setNeighborhoodName,
    currentNeighborhoodQuery,
    streetName,
    setStreetName,
    cities,
    setReport,
    streets,

    neighborhoodQuery,
    elevatorMode,
    additionalAttributes,
    setAdditionalAttributes,
  } = props;
  return isSmall() ? (
    <BuyerMobileFields
      fieldErrorHandler={fieldErrorHandler}
      customer={customer}
      setCustomer={setCustomer}
      asset={asset}
      setAsset={setAsset}
      cityName={cityName}
      setCityName={setCityName}
      cityQuery={cityQuery}
      newAsset={newAsset}
      setNewAsset={setNewAsset}
      streets={streets}
      neighborhoodName={neighborhoodName}
      setNeighborhoodName={setNeighborhoodName}
      currentNeighborhoodQuery={currentNeighborhoodQuery}
      streetName={streetName}
      setStreetName={setStreetName}
      cities={cities}
      setReport={setReport}
      neighborhoodQuery={neighborhoodQuery}
      elevatorMode={elevatorMode}
      additionalAttributes={additionalAttributes}
      setAdditionalAttributes={setAdditionalAttributes}
    />
  ) : (
    <BuyerDesktopFields
      fieldErrorHandler={fieldErrorHandler}
      customer={customer}
      streets={streets}
      setCustomer={setCustomer}
      asset={asset}
      setAsset={setAsset}
      cityName={cityName}
      setCityName={setCityName}
      cityQuery={cityQuery}
      newAsset={newAsset}
      setNewAsset={setNewAsset}
      neighborhoodName={neighborhoodName}
      setNeighborhoodName={setNeighborhoodName}
      currentNeighborhoodQuery={currentNeighborhoodQuery}
      streetName={streetName}
      setStreetName={setStreetName}
      cities={cities}
      setReport={setReport}
      neighborhoodQuery={neighborhoodQuery}
      elevatorMode={elevatorMode}
      additionalAttributes={additionalAttributes}
      setAdditionalAttributes={setAdditionalAttributes}
    />
  );
};

const BuyerMobileFields = (props: BuyerFormProps) => {
  const {
    fieldErrorHandler,
    customer,
    setCustomer,
    asset,
    setAsset,
    cityName,
    setCityName,
    cityQuery,
    newAsset,
    setNewAsset,
    neighborhoodName,
    setNeighborhoodName,
    currentNeighborhoodQuery,
    streetName,
    setStreetName,
    cities,
    setReport,
    neighborhoodQuery,
    elevatorMode,
    additionalAttributes,
    setAdditionalAttributes,
  } = props;
  return (
    <div class="flex w-full items-center flex-wrap flex-col content-start md:gap-4 md:m-0 lg:pr-8 lg:w-9/12 m-auto mt-4 gap-4 p-4 ">
      <PhoneInput
        name="phone-int"
        label="טלפון"
        onChange={(value:any) => setCustomer({ phone: value })}
        error={fieldErrorHandler}
        value={customer.phone}
        class="w-full"
        required={true}
      />
      {/* <TextInput
        disabled={location.state?.phone}
        error={fieldErrorHandler}
        onChange={(e) => setCustomer({ phone: e.currentTarget.value })}
        required
        name="phone"
        type="tel"
        label="טלפון"
        class="w-full"
        value={customer.phone}
      /> */}
      <TextInput
        // disabled={Boolean(customer.id)}
        name="name"
        error={fieldErrorHandler}
        label="שם מלא של הקונה"
        onChange={(e) => setCustomer({ name: e.currentTarget.value })}
        required
        class="w-full"
        value={customer.name}
      />

      <Select
        onChange={(name, value) => setAsset({ [name]: value })}
        options={BuyerEndCustomerStatusEnum}
        label="סוג לקוח"
        value={asset.buyer_end_customer_status}
        name="buyer_end_customer_status"
        required
        class="w-full"
      />
      <Show when={asset.buyer_end_customer_status === "IM"}>
        <Select
          class="w-full"
          label="האם הנכס נמכר"
          name="is_current_asset_sold"
          value={asset.is_current_asset_sold}
          options={{ vip: "כן - לקוח vip", no: "לא" }}
          onChange={(name, value) => setAsset({ [name]: value })}
        />
        <Show when={asset.is_current_asset_sold === "no"}>
          {/* <Select
          options={EvictionRangeEnum}
          label="זמן פינוי מבוקש"
          name="eviction_range"
          class="w-11/12"
          value={newAsset.eviction_range}
          onChange={(name, value) => {
            setNewAsset({ [name]: value });
          }}
        /> */}
          <AutoComplete
            class="w-full"
            label="עיר נוכחית"
            name="current_city"
            value={cityName()}
            options={cityQuery.data}
            onSearch={(value) => {
              setCityName(value);
            }}
            onChange={(key, value) => {
              setCityName(value);
              setNewAsset({ current_city: key });
            }}
          />
          <AutoComplete
            city={cityName()}
            missingNeighborhood
            class="w-full"
            label="שכונה נוכחית"
            name="current_neighborhood"
            value={neighborhoodName()}
            options={currentNeighborhoodQuery.data}
            onSearch={(value) => {
              setNeighborhoodName(value);
            }}
            onChange={(key, value) => {
              setNeighborhoodName(value);
              setNewAsset({ neighborhood: key });
            }}
          />
          <AutoComplete
            class="w-full"
            name="current_asset_street"
            label="רחוב נוכחי"
            value={streetName()}
            options={props.streets}
            onSearch={(value) => {
              console.log(value);
              setStreetName(value);
            }}
            onChange={(key, value) => {
              setStreetName(value);
              setNewAsset({ current_asset_street: key });
            }}
          />
          <TextInput
            class="w-full"
            onChange={(e) => handleForm(e, setNewAsset)}
            name="current_asset_house_number"
            label="מספר "
            value={newAsset.current_asset_house_number}
            error={fieldErrorHandler}
          />
          <Select
            halfs
            name="number_of_rooms"
            onChange={(name, option) => setNewAsset({ [name]: String(option) })}
            label="חדרים"
            class="w-full"
            options={generateRange(10)}
            value={newAsset.number_of_rooms}
          />
          <TextInput
            onChange={(e) => {
              e.currentTarget.value = unpricify(e.currentTarget.value);
              handleForm(e, setNewAsset);
            }}
            name="price"
            label="מחיר משוער"
            value={pricify(newAsset.price)}
            class="w-full"
            error={fieldErrorHandler}
          />
          <div class="p-4">
            האם התחילו לפרסם את הנכס?
            <div class="flex justify-center gap-8 p-4">
              {" "}
              <Radio
                onChange={() => {
                  setNewAsset("is_asset_advertised", true);
                }}
                value={newAsset.is_asset_advertised}
                label="כן"
              />
              <Radio
                onChange={() => {
                  setNewAsset("is_asset_advertised", false);
                }}
                value={!newAsset.is_asset_advertised}
                label="לא"
              />
            </div>
          </div>
        </Show>
      </Show>
      <Select
        onChange={(name, value) => setAsset({ [name]: value })}
        options={cities}
        label="בחר עיר"
        error={fieldErrorHandler}
        required
        value={asset?.city}
        name="city"
        class="w-full"
      />
      <MultiSelect
        class="w-full"
        header="בחר שכונה"
        customButton={
          <Button
            name="report"
            onClick={() => {
              setReport(true);
            }}
            short
            class="gap-2"
          >
            לא מצאת את השכונה? דווח לנו{" "}
            <AddIcon class="fill-white stroke-blue" />
          </Button>
        }
        onChange={(key, value: boolean) =>
          setAsset("neighborhoods", { [key]: value })
        }
        options={neighborhoodQuery.data}
        label="בחר שכונה"
        store={asset}
        required
        value={asset.neighborhoods}
        name="neighborhoods"
        showReport
      />
      <Select
        onChange={(name, value) => setAsset({ [name]: value })}
        options={AssetTypeEnum}
        label="סוג נכס "
        required
        value={asset.asset_type}
        name="asset_type"
        error={fieldErrorHandler}
        class="w-full"
      />
      <div class="flex w-full m-auto md:m-0 gap-6 md:justify-between">
        <Select
          onChange={(name, value) => {
            setAsset({ [name]: value });
          }}
          options={generateRange(8, 0, true)}
          label="ממס׳ חדרים"
          halfs
          value={asset?.number_of_rooms || 0}
          name="number_of_rooms"
          class="w-6/12 md:m-0"
          error={fieldErrorHandler}
          required
        />
        <Select
          halfs
          onChange={(name, value) => setAsset({ [name]: value })}
          options={generateRange(8, 0, true)}
          label="עד מס׳ חדרים"
          value={asset?.number_of_rooms_max || 0}
          name="number_of_rooms_max"
          class="w-6/12 md:m-0"
        />
      </div>
      <div class="flex flex-row md:flex-col w-full items-center md:items-start md:w-3/12">
        <label class="w-2/12 text-sm">מעלית</label>
        <Picker
          class="md:w-full"
          name="elevator_mode"
          active={elevatorMode.mode}
          options={{
            hide: "חובה",
            "from floor": "חובה מקומה",
            show: "לא חובה",
          }}
          handleChange={(key) => {
            elevatorMode.mode = key;
          }}
        />
      </div>
      <Show when={elevatorMode.mode === "from floor"}>
        {" "}
        <Select
          onChange={(name, value) => setAsset({ [name]: Number(value) })}
          options={generateRange(4)}
          label="מעלית מקומה"
          value={asset.elevator_from_floor}
          name="elevator_from_floor"
          class="w-full"
        />
      </Show>
      <Show when={isSmall()}>
        <FlexRow class="w-full">
          <label class="w-6/12">חניה בטאבו</label>
          <Picker
            name="should_have_parking_in_registry"
            active={asset.should_have_parking_in_registry ? "show" : "hide"}
            options={{ show: "חובה", hide: "לא חובה" }}
            handleChange={(key) => {
              setAsset({
                should_have_parking_in_registry: key === "show" ? true : false,
              });
            }}
          />
        </FlexRow>
        <FlexRow class="w-full">
          <label class="w-6/12"> ממ״ד</label>
          <Picker
            name="should_have_in_house_shelter"
            active={asset.should_have_in_house_shelter ? "show" : "hide"}
            options={{ show: "חובה", hide: "לא חובה" }}
            handleChange={(key) => {
              setAsset({
                should_have_in_house_shelter: key === "show" ? true : false,
              });
            }}
          />
        </FlexRow>
        <FlexRow class="w-full">
          <label class="w-6/12">מרפסת שמש</label>
          <Picker
            name="should_have_sun_terrace"
            active={asset.should_have_sun_terrace ? "show" : "hide"}
            options={{ show: "חובה", hide: "לא חובה" }}
            handleChange={(key) => {
              setAsset({
                should_have_sun_terrace: key === "show" ? true : false,
              });
            }}
          />
        </FlexRow>
      </Show>

      <TextInput
        onChange={(e) => handleForm(e, setAsset)}
        label="שטח בנוי במ״ר"
        value={asset.asset_size}
        name="asset_size"
        class="w-full"
        error={fieldErrorHandler}
      />
      <Show when={["TF", "PH", "CE"].includes(asset.asset_type)}>
        <TextInput
          onChange={(e) => handleForm(e, setAsset)}
          label={"שטח מגרש"}
          error={fieldErrorHandler}
          value={asset.asset_outside_size}
          name="asset_outside_size"
          class="w-full"
        />
      </Show>
      <Show when={["RT"].includes(asset.asset_type)}>
        <TextInput
          onChange={(e) => handleForm(e, setAsset)}
          label={"שטח הגג "}
          value={asset.asset_outside_size}
          name="asset_outside_size"
          class="w-full"
          error={fieldErrorHandler}
        />
      </Show>
      <Show when={["GT"].includes(asset.asset_type)}>
        <TextInput
          onChange={(e) => handleForm(e, setAsset)}
          label={"שטח הגינה "}
          value={asset.asset_outside_size}
          name="asset_outside_size"
          class="w-full"
          error={fieldErrorHandler}
        />
      </Show>

      <Show when={isSmall()}>
        <Button
          border
          name="מאפיינים נוספים"
          onClick={() => {
            if (!additionalAttributes()) {
              logEvent("button_click", {
                click_type: "מאפיינים נוספים",
              });
            }
            setAdditionalAttributes(!additionalAttributes());
          }}
          class="w-8/12"
        >
          <Switch>
            <Match when={!additionalAttributes()}> מאפיינים נוספים</Match>
            <Match when={additionalAttributes()}>בטל מאפיינים נוספים</Match>
          </Switch>
        </Button>
      </Show>
      <Show when={additionalAttributes()}>
        <Show when={!["TF", "PH"].includes(asset.asset_type)}>
          <FlexRow class="w-full md:m-0 justify-center md:justify-around m-auto gap-4">
            <Select
              onChange={(name, value) => setAsset({ [name]: value })}
              options={generateRange(30, -1)}
              label="מקומה"
              value={asset.from_floor}
              name="from_floor"
              class="w-6/12 md:m-0 md:w-6/12"
            />
            <Select
              onChange={(name, value) => setAsset({ [name]: value })}
              options={generateRange(30, -1)}
              label="עד קומה"
              value={asset.to_floor}
              name="to_floor"
              class="w-6/12 md:m-0 md:w-6/12"
            />
          </FlexRow>
          <FlexRow class="w-12/12 md:w-3/12 md:m-0 md:justify-between md:p-2 flex items-center justify-center m-auto gap-4">
            <CheckBox
              value={asset.not_first_floor}
              setValue={(value) => setAsset({ not_first_floor: value })}
              label={"לא קומה ראשונה"}
            />{" "}
            <CheckBox
              value={asset.not_last_floor}
              setValue={(value) => setAsset({ not_last_floor: value })}
              label="לא קומה אחרונה"
            />{" "}
          </FlexRow>
        </Show>
        <Show when={isSmall()}>
          <FlexRow class="md:w-3/12">
            <label class="w-6/12">מבנים מסוכנים</label>
            <Picker
              name="should_show_dangerous_buildings"
              active={asset.should_show_dangerous_buildings ? "show" : "hide"}
              options={{ show: "הצג", hide: "אל תציג" }}
              handleChange={(key) => {
                setAsset({
                  should_show_dangerous_buildings:
                    key === "show" ? true : false,
                });
              }}
            />
          </FlexRow>
          <FlexRow class="md:w-3/12">
            <label class="w-6/12">מחסן</label>
            <Picker
              name="should_have_storage_space"
              active={asset.should_have_storage_space ? "show" : "hide"}
              options={{ show: "חובה", hide: "לא חובה" }}
              handleChange={(key) => {
                setAsset({
                  should_have_storage_space: key === "show" ? true : false,
                });
              }}
            />
          </FlexRow>
        </Show>
      </Show>
      <TextInput
        onChange={(e) => {
          e.target.value = unpricify(e.currentTarget.value);
          handleForm(e, setAsset);
        }}
        name="budget"
        label="תקציב"
        error={fieldErrorHandler}
        required
        value={pricify(asset.budget)}
        class="w-full"
      />
      <Spacer height="12px" class="md:hidden" />
      <Select
        options={EvictionRangeEnum}
        onChange={(name, value) => setAsset({ [name]: value })}
        label="מועד פינוי"
        value={asset.requested_eviction_range}
        name="requested_eviction_range"
        class="w-full"
      />
      <Spacer height="32px" />
    </div>
  );
};

export default BuyerFormFields;
