import { createSignal, Show } from "solid-js";
import { JSXElement } from "solid-js";
import { bool } from "yup";

type Props = {
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  value?: string | number;
  onChange?: (
    e: Event & {
      currentTarget: HTMLInputElement;
      target: Element;
    }
  ) => void;
  error?: boolean;
  errorLabel?: string;
  icon?: string;
  name: string;
  class?: string;
  registrationIcon?: JSXElement;
};
const LongTextInput = (props: Props) => {
  const [active, setActive] = createSignal(false);

  return (
    <div class={"m-auto relative " + props.class}>
      <div class="text-right text-sm text-darkBlue">{props.label}</div>
      <textarea
        placeholder={props.placeholder}
        name={props.name}
        oninput={props.onChange}
        onfocus={() => setActive(true)}
        onblur={() => setActive(false)}
        value={props.value || ""}
        disabled={props.disabled}
        type="text"
        class={`w-full resize-none h-full
          ${props.registrationIcon ? "registration--area" : ""}
          ${
          props.error ? "border border-error" : ""
        } h-16 text-blue p-6 rounded-xl text-sm disabled:text-darkGrey bg-lightGrey `}
      />
      <Show when={props.icon}>
        {" "}
        <div class="absolute top-[38%] left-6 cursor-pointer">
          <img src={props.icon} alt="input image" />
        </div>
      </Show>
      <Show when={props.registrationIcon}>
        <span class="registration--icon">
          {props.registrationIcon}
        </span>
      </Show>

      <div class="text-right text-error">{props.errorLabel}</div>
    </div>
  );
};

export default LongTextInput;
