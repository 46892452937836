const ChevronDown = (props: { hexColor?: string }) => {
  return (
    <svg
      class="relative top-5"
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.6062L6 6.6062L11 1.6062"
        stroke={props.hexColor ? props.hexColor : "#234F68"}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ChevronDown;
