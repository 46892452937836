import { Show, createEffect, createSignal } from "solid-js";
import { setUser, user } from "../../globalState";
import './Import.sass'
import UploadFile from "./Tabs/UploadFile";
import { createStore } from "solid-js/store";
import axios from "axios";
import FilesList from "./Tabs/FilesList";
import ErrorScreen from "./Tabs/ErrorScreen";
import SuccessScreen from "./Tabs/SuccessScreen";
import { useNavigate } from "@solidjs/router";


const ImportContacts = () => {
	const [step, setStep] = createSignal("first");
	const navigate = useNavigate();

	const [status, setStatus] = createSignal(true);
	const [showSuccessQuote, setShowSuccessQuote] = createSignal(false)
	createEffect(() => {
		if (user.can_import_clients === false) {
			navigate('/')
		}
	})

	const changeStatus = (evt :CustomEvent<boolean>) => {
		setStatus(evt.detail)
	}

	const [form, setForm]:any = createStore({
		file: null
	})

	const deleteFile = () => {
		setForm({file: null})
	}

	const setFile = (evt :CustomEvent<string>) => {
		setForm({
			file: evt.detail
		})
		setStep('second')
	}

	const changeStep = (evt: CustomEvent<string>) => {
		setStep(evt.detail)
	}

	const changeShowSuccessQuote = (evt:CustomEvent<any>) => {
		setShowSuccessQuote(true)
	}


	return (
		<div class="import--container">
			<div class="import--content">
				<div
					class="import--content__tabs"
					classList={{
						first: step() === 'first',
						second: step() === 'second',
						third: step() === 'third'
					}}
				>
					<UploadFile
						onUploadFile={setFile}
					/>
					<FilesList
						onChangeStatus={changeStatus}
						onChangeStep={changeStep}
						onDeleteFile={deleteFile}
						onUploadErrorFile={changeShowSuccessQuote}
						file={form.file}
					/>
					<Show when={status() === false}>
						<ErrorScreen
							onTryAgain={changeStep}
						/>
					</Show>
					<Show when={status() === true}>
						<SuccessScreen
							showQuote={showSuccessQuote()}
						/>
					</Show>
				</div>
			</div>
			<div class="import--bgi">

			</div>
		</div>
	)
}
export default ImportContacts;