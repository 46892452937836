export const WhatsAppIcon = (props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7821 3.22207C16.4892 0.929612 13.3616 -0.227074 10.0775 0.0370595C5.66247 0.391439 1.79254 3.45979 0.497428 7.69254C-0.427655 10.7169 -0.0389002 13.8744 1.46766 16.4827L0.0403926 21.2184C-0.0961672 21.6729 0.319019 22.101 0.778256 21.9789L5.73846 20.6505C7.34524 21.5265 9.15356 21.9877 10.9949 21.9888H10.9993C15.6192 21.9888 19.8878 19.1648 21.3647 14.79C22.8029 10.5243 21.7391 6.18037 18.7821 3.22207ZM16.3824 14.9166C16.1534 15.5582 15.0311 16.1767 14.5268 16.2219C14.0224 16.2681 13.5499 16.4497 11.2284 15.5351C8.43442 14.4346 6.67015 11.572 6.53359 11.3893C6.39593 11.2055 5.41138 9.90026 5.41138 8.54877C5.41138 7.19729 6.12171 6.53255 6.37391 6.25851C6.6261 5.98337 6.92345 5.91514 7.10737 5.91514C7.29018 5.91514 7.4741 5.91514 7.63378 5.92174C7.82981 5.92945 8.04676 5.93935 8.25271 6.39608C8.49719 6.93976 9.03132 8.29785 9.0996 8.43542C9.16788 8.57298 9.21413 8.73367 9.12272 8.91636C9.03132 9.09905 8.98506 9.21351 8.8485 9.37419C8.71084 9.53487 8.55997 9.73187 8.43662 9.85513C8.29896 9.9916 8.15579 10.1413 8.31548 10.4153C8.47627 10.6905 9.02691 11.5896 9.84407 12.3171C10.8947 13.2526 11.779 13.542 12.0544 13.6807C12.3297 13.8182 12.4894 13.7951 12.6502 13.6113C12.8109 13.4286 13.3374 12.8101 13.5202 12.535C13.703 12.2599 13.8869 12.3061 14.1391 12.3974C14.3913 12.4888 15.7426 13.1535 16.0168 13.2911C16.2921 13.4286 16.4749 13.4969 16.5432 13.6113C16.6115 13.7247 16.6115 14.275 16.3824 14.9166Z"
      fill={props.white ? "white" : "#8BC83F"}
    />
  </svg>
);
