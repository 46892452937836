import { Show } from "solid-js";

import { CheckedIcon } from "../Icons/CheckedIcon";
import { EmptyIcon } from "../Icons/EmptyIcon";

type Props = {
  value: boolean;
  setValue: (value: boolean) => void;
  label?: string;
  disabled?: boolean;
  textSize?: any;
  expandClickArea?: boolean;
};

export default (props: Props) => {
  return (
    <button
      onclick={() => {
        props.setValue(!props.value);
      }}
      class="flex relative gap-1 text-right"
      classList={{ "m-4 mr-0 mt-0": props.expandClickArea }}
    >
      <p>
        <Show when={props.value}>
          <CheckedIcon />
        </Show>
        <Show when={!props.value}>
          <EmptyIcon />
        </Show>
      </p>

      <label
        class="mr-2 line-clamp-1"
        classList={{
          "text-lg": !props.textSize,
          [props.textSize]: Boolean(props.textSize),
        }}
      >
        {props.label}
      </label>
    </button>
  );
};
