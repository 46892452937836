export const Call = () => (
  <svg
    width="14"
    height="19"
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.53706 0.0301056C4.23975 0.00855789 3.93512 0.0388317 3.63639 0.126777L2.56724 0.442276C1.42797 0.777949 0.537799 1.67849 0.236633 2.81511C-0.432077 5.34287 0.332901 8.33485 2.32377 11.7027C4.31185 15.0659 6.57718 17.2034 9.13565 17.9011C10.2878 18.2153 11.5304 17.9151 12.4031 17.1075L13.2164 16.3561C14.1265 15.5143 14.2622 14.1122 13.5269 13.1159L12.3519 11.5261C11.7221 10.6738 10.6118 10.3109 9.59083 10.6165L7.75245 11.1649C7.67529 11.1873 7.49061 11.0826 7.32991 10.9443L7.32903 10.9434C7.0428 10.6954 6.64622 10.1943 6.22194 9.47751V9.47663C5.767 8.70688 5.57296 8.20346 5.49506 7.90617C5.41712 7.60874 5.43604 7.57256 5.44742 7.47202C5.45335 7.42186 5.47727 7.37538 5.518 7.33844L5.51888 7.33756L6.88972 6.08875C7.6751 5.37347 7.9122 4.23041 7.46929 3.26859L6.64272 1.47226V1.47138C6.25357 0.626314 5.42874 0.0947308 4.53706 0.0301056ZM4.43914 1.37647C4.85385 1.40734 5.23573 1.65516 5.41038 2.03471L6.23782 3.83104C6.43807 4.26589 6.33765 4.76351 5.97583 5.09304L4.60498 6.34097C4.32396 6.59625 4.14424 6.94429 4.10128 7.31999V7.32087C4.09009 7.41966 4.06364 7.78707 4.1842 8.24715C4.30477 8.70723 4.55368 9.31577 5.05399 10.1621H5.05487C5.5217 10.9509 5.9629 11.5502 6.44071 11.9637H6.4416C6.59677 12.0975 7.24711 12.7185 8.13177 12.4611L8.13442 12.4603L9.98073 11.9092C10.4602 11.7657 10.9728 11.9371 11.2607 12.3267L12.4357 13.9165C12.7685 14.3681 12.7103 14.982 12.2946 15.3665L11.4813 16.1188C10.9611 16.6004 10.1887 16.7884 9.49291 16.5987C7.42462 16.0346 5.37795 14.209 3.49172 11.0181C1.60288 7.82283 1.01211 5.18005 1.54661 3.15961C1.72329 2.49279 2.26464 1.93927 2.95185 1.73679L4.02012 1.42129C4.15928 1.38032 4.3009 1.36618 4.43914 1.37647Z"
      fill="#234F68"
    />
  </svg>
);
