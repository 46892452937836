import {
  RealtorHomeAsset,
  ShareOptionsEnum,
  VisibilityStatusEnum,
} from "../../types";

import axios from "axios";
import Spacer from "../../Components/Spacer";
import { Modal } from "../../Components/Modal";
import { FlexCol } from "../../Components/Flex";
import Button from "../../Components/Button";
import { user } from "../../globalState";
import useGetAgency from "../../api/getAgency";
import Heading from "../../Components/Heading";
import Select from "../../Components/Select";
import { For, Show, createSignal, onMount } from "solid-js";
import CheckBox from "../../Components/CheckBox";
import { createStore } from "solid-js/store";
import { logEvent } from "../../analytics";
import LocationIcon from "../../Icons/LocationIcon";
import { UserIcon } from "../AddClient/BuyerForm";
import { normalizeAddress } from "./ViewAsset";
import { captureException } from "@sentry/capacitor";

type Props = {
  asset: RealtorHomeAsset;
  onClose: () => void;
  refetch: () => void;
};

export default function AssetVisibilityModal(props: Props) {
  const currentContacts = {};
  props.asset.realtor_contacts.forEach((contact) => {
    currentContacts[contact.id] = true;
  });
  const currentViewers = {};
  props.asset.realtors_can_view.forEach((viewers) => {
    currentViewers[viewers.id] = true;
  });
  const [form, setForm] = createStore({
    contacts: currentContacts,
    canView: currentViewers,
  });
  const [visibilityStatus, setVisibilityStatus] = createSignal(
    props.asset.visibility_status || "AL"
  );
  const agency = useGetAgency();
  const [loading, setLoading] = createSignal(false);
  const submit = async () => {
    logEvent("button_click", {
      click_type: 'אישור',
      button_type: 'asset_status',
      // button_type: "שנה תצוגת נכס",
      // asset_id: props.asset.id,
      // asset_status: VisibilityStatusEnum[visibilityStatus()],
    });
    try {
      setLoading(true);
      
      const contacts = Object.keys(form.contacts).filter(
        (id) => form.contacts[id]
      );
      const canView = Object.keys(form.canView).filter(
        (id) => form.canView[id]
      );

      await axios.patch(`/real-estate/asset/${props.asset.id}/`, {
        visibility_status: visibilityStatus(),
      });
      await axios.put(
        `/real-estate/asset/${props.asset.id}/set_contacts/`,
        contacts
      );
      if (ShareOptionsEnum[visibilityStatus()] === ShareOptionsEnum.AG)
        await axios.put(
          `/real-estate/asset/${props.asset.id}/set_viewers/`,
          canView
        );
      props.refetch();
      props.onClose();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      captureException(err);
    }
  };

  return (
    <Modal open={true} onClose={props.onClose} classList={{ "max-w-md": true }}>
      <div class="flex flex-col items-center">
        <Spacer />
        <div class="text-2xl font-semibold"> מצב תצוגה</div>

        <div class="flex gap-2 items-center">
          <LocationIcon />
          {normalizeAddress(props.asset)}
        </div>
        <Spacer />
        <Select
          name="visibility"
          onChange={(name, value) => setVisibilityStatus(value)}
          label="שיתוף הנכס "
          class="w-full"
          options={ShareOptionsEnum}
          value={visibilityStatus()}
        />
        <Spacer />

        <FlexCol class="gap-2 w-full">
          <Heading bold class="text-xl" side="right">
            {agency.data?.name}
          </Heading>
          <div class="flex justify-around">
            <span class="rounded-full" />
            <span class="w-6/12"> </span>
            <span>איש קשר</span>
            <Show
              when={
                ShareOptionsEnum[visibilityStatus()] === ShareOptionsEnum.AG
              }
            >
              צפייה
            </Show>
          </div>
          <section class="overflow-y-auto max-h-[30vh]">
            <div class="flex justify-around my-3 items-center">
              <Show
                when={user.image_url}
                fallback={
                  <span class="scale-[0.3] w-8 h-8 relative bottom-2 left-3">
                    <UserIcon />{" "}
                  </span>
                }
              >
                <img width="30px" class="rounded-full" src={user.image_url} />
              </Show>
              <span class="w-6/12"> {user.name}</span>
              <CheckBox
                value={Boolean(form.contacts[user.id])}
                setValue={(value) => {
                  setForm("contacts", { [user.id]: value });
                }}
              />
              <Show
                when={
                  ShareOptionsEnum[visibilityStatus()] === ShareOptionsEnum.AG
                }
              >
                {" "}
                <CheckBox
                  value={
                    Boolean(form.canView[user.id]) ||
                    Boolean(form.contacts[user.id])
                  }
                  setValue={(value) => {
                    setForm("canView", { [user.id]: value });
                  }}
                />
              </Show>
            </div>

            <For each={agency.data?.realtors.filter((realtor:any) => realtor.is_working === true)}>
              {(agent) => {
                return (
                  <div class="flex justify-around my-3 items-center">
                    <Show
                      when={agent.image_url}
                      fallback={
                        <span class="scale-[0.3] w-8 h-8 relative bottom-2 left-3">
                          <UserIcon />{" "}
                        </span>
                      }
                    >
                      <img
                        width="30px"
                        class="rounded-full"
                        src={agent.image_url}
                      />
                    </Show>
                    <span class="w-6/12"> {agent.name}</span>
                    <CheckBox
                      value={Boolean(form.contacts[agent.id])}
                      setValue={(value) => {
                        setForm("contacts", { [agent.id]: value });
                      }}
                    />
                    <Show
                      when={
                        ShareOptionsEnum[visibilityStatus()] ===
                        ShareOptionsEnum.AG
                      }
                    >
                      {" "}
                      <CheckBox
                        value={
                          Boolean(form.canView[agent.id]) ||
                          Boolean(form.contacts[agent.id])
                        }
                        setValue={(value) => {
                          setForm("canView", { [agent.id]: value });
                        }}
                      />
                    </Show>
                  </div>
                );
              }}
            </For>
          </section>
          <Spacer />
          <Button
            class="w-11/12"
            disabled={loading()}
            onClick={submit}
            color="green"
          >
            {loading() ? "טוען..." : "אישור"}
          </Button>

          <Spacer height="6px" />
          <button onclick={props.onClose} class="underline text-center">
            ביטול
          </button>
        </FlexCol>
      </div>
    </Modal>
  );
}
