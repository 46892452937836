import { createStore } from 'solid-js/store';
import { createSignal, For, Show } from 'solid-js';
import StepLogo from './logo.png';
import AddAgentModal from './AddAgentModal';
import { createEventDispatcher } from '@solid-primitives/event-dispatcher';
import axios from 'axios';
interface Props {
	onSendAgents: (evt:CustomEvent<any>) => void;
	token: string;
}

const AgentsList = (props:Props) => {
	const dispatch = createEventDispatcher(props)
	const [usersList, setUsersList]:any = createStore([])
	const [modalStatus, setModalStatus] = createSignal(false);
	const addAgent = (evt:any) => {
		const agent_data = {
			first_name: evt.detail.first_name,
			last_name: evt.detail.last_name,
			phone: evt.detail.phone
		}
		const findAgent = usersList.find((agent:any) => agent.phone === agent_data.phone)
		if (!findAgent) {
			setUsersList([...usersList, agent_data])
		}
		setModalStatus(false)
	}
	const removeAgent = (phone:string) => {
		setUsersList(usersList.filter((agent:any) => agent.phone !== phone))
	}

	const sendAgents = async () => {
		if (usersList.length) {
			const request = await axios.post('/rentals/realtors/send_whats_up_messages/', usersList, {
				headers: {
					Authorization: props.token
				}
			})
				.then(resp => {
					console.log(resp)
				})
				.catch(err => {
					console.log(err)
				})
		}
		dispatch('sendAgents', true, {cancelable: true})
	}


	return (
		<div class="registration__step">
			<div class="registration__step--title">
				<p class="st-logo">
					<img src={StepLogo} alt="" />
				</p>
				<h1 class="st-title">
					בוא נתחיל  <br/>בלעשות קצת סדר<span class="symbol">...</span>
				</h1>
				<p class="st-description code">
					רשימת הסוכנים בסוכנות שלך
				</p>
			</div>
			<div class="registration__step--agents">
				<Show when={usersList.length}>
					<div class="registration__step--agents-list">
						<For each={usersList}>
							{(agent: any) => (
								<div class="single-agent">
									<p class="st-avatar">
										<UserIcon />
									</p>
									<p class="agent-info">
										<span class="st-name">
											{agent.first_name} {agent.last_name}
										</span>
										<span class="st-phone">
											{agent.phone}
										</span>
									</p>
									<button
										class="btn-delete"
										onClick={() => {
											removeAgent(agent.phone)
										}}
									>
										<DeleteIcon />
									</button>
								</div>
							)}
						</For>
					</div>
				</Show>
				<div class="registration__step--agents-actions">
					<button
						class="btn--add"
						type="button"
						onClick={() => setModalStatus(true)}
					>
						הוסף סוכן
					</button>
					<button
						class="btn--login"
						type="button"
						onClick={sendAgents}
					>
						סיימתי, אני מוכן להתחיל!
					</button>
				</div>
			</div>
			<AddAgentModal
				onCloseModal={() => setModalStatus(false)}
				onSubmitForm={(e:any) => {
					addAgent(e)
				}}
				open={modalStatus()}
			/>
		</div>
	)
}

export default AgentsList;

const DeleteIcon = () => (
<svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M9.5 0C7.74781 0 6.28881 1.11411 6.04393 2.55H0.759828C0.660555 2.54881 0.561994 2.56441 0.469871 2.59591C0.377749 2.62741 0.293902 2.67418 0.223204 2.7335C0.152507 2.79281 0.0963677 2.8635 0.05805 2.94143C0.0197323 3.01937 0 3.10302 0 3.1875C0 3.27198 0.0197323 3.35563 0.05805 3.43357C0.0963677 3.5115 0.152507 3.58219 0.223204 3.6415C0.293902 3.70082 0.377749 3.74759 0.469871 3.77909C0.561994 3.81059 0.660555 3.82619 0.759828 3.825H1.82699L3.08534 14.8875C3.22218 16.0915 4.39864 17 5.82054 17H13.1795C14.6014 17 15.7773 16.0915 15.9147 14.8875L17.173 3.825H18.2402C18.3394 3.82619 18.438 3.81059 18.5301 3.77909C18.6223 3.74759 18.7061 3.70082 18.7768 3.6415C18.8475 3.58219 18.9036 3.5115 18.9419 3.43357C18.9803 3.35563 19 3.27198 19 3.1875C19 3.10302 18.9803 3.01937 18.9419 2.94143C18.9036 2.8635 18.8475 2.79281 18.7768 2.7335C18.7061 2.67418 18.6223 2.62741 18.5301 2.59591C18.438 2.56441 18.3394 2.54881 18.2402 2.55H12.9561C12.7112 1.11411 11.2522 0 9.5 0ZM9.5 1.275C10.4385 1.275 11.2085 1.81397 11.4285 2.55H7.57151C7.79154 1.81397 8.56148 1.275 9.5 1.275ZM7.25253 5.95C7.66606 5.95 8.00169 6.23518 8.00169 6.5875V12.9625C8.00169 13.3148 7.66606 13.6 7.25253 13.6C6.83899 13.6 6.50337 13.3148 6.50337 12.9625V6.5875C6.50337 6.23518 6.83899 5.95 7.25253 5.95ZM11.7475 5.95C12.161 5.95 12.4966 6.23518 12.4966 6.5875V12.9625C12.4966 13.3148 12.161 13.6 11.7475 13.6C11.3339 13.6 10.9983 13.3148 10.9983 12.9625V6.5875C10.9983 6.23518 11.3339 5.95 11.7475 5.95Z" fill="white"/>
</svg>
)
export const UserIcon = () => (
<svg width="36" height="39" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M16.2003 17.0867C14.8731 18.2835 13.1153 19.0121 11.1874 19.0121C9.14142 19.0121 7.28707 18.1915 5.93555 16.8615" stroke="#234F68" stroke-width="1.71152" stroke-linecap="round"/>
	<path d="M15.8698 16.703C15.7302 16.5296 15.5808 16.3631 15.4219 16.2042C14.2517 15.034 12.6646 14.3766 11.0097 14.3766C9.35474 14.3766 7.76759 15.034 6.59738 16.2042C6.38731 16.4143 6.19376 16.6378 6.01758 16.8727" stroke="#234F68" stroke-width="1.71152" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M11.1876 13.6637C12.0387 13.6637 12.855 13.3256 13.4568 12.7237C14.0586 12.1219 14.3967 11.3057 14.3967 10.4546C14.3967 9.60347 14.0586 8.78723 13.4568 8.1854C12.855 7.58358 12.0387 7.24548 11.1876 7.24548C10.3365 7.24548 9.52026 7.58358 8.91844 8.1854C8.31662 8.78723 7.97852 9.60347 7.97852 10.4546C7.97852 11.3057 8.31662 12.1219 8.91844 12.7237C9.52026 13.3256 10.3365 13.6637 11.1876 13.6637V13.6637Z" stroke="#234F68" stroke-width="1.71152" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);