export const UpdateIcon = () => (
  <svg
    class="m-auto"
    width="110"
    height="110"
    viewBox="0 0 110 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.6049 70.2416C29.1999 68.6466 29.8272 66.2747 29.0779 64.1469C25.6696 54.4772 27.8215 43.2881 35.5541 35.5555C44.1685 26.9411 57.0729 25.2533 67.3871 30.4611L76.206 21.6422C60.916 11.9038 40.3976 13.6981 27.048 27.0494C15.9621 38.1353 12.8443 54.1661 17.6826 68.0398C19.1332 72.1992 24.4888 73.3577 27.6049 70.2416Z"
      fill="#8BC83F"
    />
    <path
      opacity="0.3"
      d="M53.3363 15.5342C43.7817 15.9312 34.3424 19.7503 27.0463 27.0464C19.3239 34.7687 15.4791 44.8887 15.486 55.007C15.7953 55.0397 16.103 55.0568 16.4089 55.0568C20.3552 55.0568 23.9113 52.3206 24.7947 48.3073C26.0563 42.5598 28.9386 37.309 33.1238 33.1239C36.9944 29.255 41.8172 26.4775 47.068 25.0939C51.3425 23.9664 53.9688 19.8087 53.3363 15.5342Z"
      fill="white"
    />
    <path
      d="M82.3952 39.7583C80.8002 41.3533 80.1728 43.7252 80.9222 45.853C84.3305 55.5227 82.1786 66.7118 74.4459 74.4444C65.8316 83.0588 52.9272 84.7466 42.613 79.5388L33.7941 88.3577C49.0841 98.0961 69.6025 96.3018 82.952 82.9505C94.038 71.8646 97.1558 55.8338 92.3175 41.9601C90.8669 37.8007 85.5113 36.6422 82.3952 39.7583Z"
      fill="#234F68"
    />
    <path
      opacity="0.15"
      d="M94.1067 60.7562C89.9129 59.6717 85.4958 61.8493 83.9283 65.9898C82.378 70.0856 80.0044 73.7482 76.8762 76.8764C73.303 80.4496 69.0679 83.0192 64.2898 84.5162C60.0686 85.8379 57.6365 90.1124 58.465 94.3543C67.3973 93.5757 76.1148 89.791 82.952 82.9539C89.2048 76.701 92.919 68.8773 94.1067 60.7562Z"
      fill="black"
    />
    <path
      opacity="0.3"
      d="M55 110C73.0355 110 87.6562 107.691 87.6562 104.844C87.6562 101.996 73.0355 99.6875 55 99.6875C36.9645 99.6875 22.3438 101.996 22.3438 104.844C22.3438 107.691 36.9645 110 55 110Z"
      fill="black"
    />
    <path
      d="M29.1156 35.1108C32.1956 31.113 36.1779 27.8457 40.7567 25.6182"
      stroke="white"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M77.77 15.2829C76.8264 14.9322 75.7659 15.166 75.0526 15.8775L59.2539 31.6763C58.5165 32.4136 58.2965 33.5222 58.6953 34.4847C59.094 35.4489 60.0359 36.0763 61.0775 36.0763H78.0914C78.7754 36.0763 79.432 35.8047 79.915 35.3218C79.939 35.2977 79.9614 35.2754 79.9837 35.2513C80.4701 34.7254 80.7176 34.0172 80.6626 33.3022L79.4475 17.5035C79.3701 16.498 78.7153 15.6318 77.77 15.2829Z"
      fill="#8BC83F"
    />
    <path
      d="M31.4256 73.9024C30.7071 73.9024 30.0214 74.2032 29.5349 74.7291C29.0571 75.2585 28.801 75.9632 28.8578 76.6782L30.1674 93.6422C30.2482 94.6804 30.946 95.5707 31.9378 95.8938C32.9295 96.2169 34.0174 95.911 34.6964 95.1204L49.2318 78.1511C49.8867 77.3863 50.0379 76.3104 49.6151 75.396C49.194 74.4816 48.2796 73.8938 47.2724 73.8955L31.4256 73.9024Z"
      fill="#234F68"
    />
  </svg>
);
