import Button from "../../Components/Button";
import Spacer from "../../Components/Spacer";
import TextInput from "../../Components/TextInput";
import {
  Accessor,
  For,
  Index,
  JSX,
  Match,
  Show,
  Switch,
  createEffect,
  createSignal,
  mergeProps,
  onCleanup,
  onMount,
  splitProps,
} from "solid-js";
import Select from "../../Components/Select";
import {
  createMutable,
  createStore,
  SetStoreFunction,
  unwrap,
} from "solid-js/store";
import Picker from "../../Components/Picker";
import { FlexRow, FlexCol } from "../../Components/Flex";
import { handleForm } from "./AddClient";
import {
  AssetTypeEnum,
  BuyerEndCustomerActivityStateEnum,
  BuyerEndCustomerStatusEnum,
  EnumKey,
  EvictionRangeEnum,
  PurchaseListing,
  PurchasingEndCustomer,
  VisibilityStatusEnum,
} from "../../types";
import generateRange from "../../utilities/generateRange";
import CheckBox from "../../Components/CheckBox";
import { user } from "../../globalState";
import axios from "axios";
import { AddIcon } from "../AddAsset/Icons";
import { useLocation, useNavigate, useSearchParams } from "@solidjs/router";
import getNeighborhoodsByName from "../../api/getNeighborhoodsByName";
import MultiSelect from "../../Components/MultiSelect";

import { ErrorBar } from "../AddAsset/ErrorBar";
import { checkForErrors, handleRequiredInObject } from "../Login/validate";
import { Modal } from "../../Components/Modal";
import { pricify, unpricify } from "../AddAsset/AssetInfo";

import { getUserDetails } from "../../api/getUserDetails";
import { createQuery } from "@tanstack/solid-query";
import AssetCard from "../../Components/AssetCard";

import { ShareAssetsModal } from "./ShareAssetsModal";
import { ShareAssetsButton } from "./ShareAssetsButton";
import { isSmall } from "../Home/isSmall";
import CustomerFormDesktop from "./CustomerFormDesktop";
import shareWhatsapp from "../../utilities/shareWhatsApp";
import { custom } from "zod";
import { JSXElement } from "solid-js";
import { debounce } from "@solid-primitives/scheduled";
import { logEvent } from "../../analytics";

import { GenericModal } from "../Assets/GenericModal";
import deletedHomeIcon from "../Assets/deletedHomeIcon.svg";
import WhatsupContainer from "../../Components/Whatsup/Container";
import './sass/RelevantAssetsActions.sass'
import ImportPhoneContact from "../../Components/Contacts/ImportContact";
import { RealtorHomeAsset } from "../../internalTypes";

export function EditPurchaseListing(props: {
  purchaseList?: PurchaseListing;
  customer;
  hide;
  assetModal: Accessor<number>;
  show: boolean;
  /** is the component a part of the "search asset" page */
  searchAsset?: boolean;
  setAssetModal: () => {};
  showPurchaseDeleteButton: boolean;
  useWhatsup?: boolean;
  showAssetHideBtn?: boolean | null;
}) {
  const [shareAssetsModal, setShareAssetsModal] = createSignal(false);
  const [customerModal, setCustomerModal] = createSignal(false);
  const [customer, setCustomer] = createStore({ name: "", phone: "" });
  const [success, setSuccess] = createSignal(false);
  const [pickedAssets, setPickedAssets] = createStore({});
  const [search, setSearch] = useSearchParams();

  createEffect(() => {
    // console.log(props.purchaseList)
    if (props.purchaseList?.id == null && props.purchaseList?.new_asset) {
      setAsset(props.purchaseList);
      Object.keys(props.purchaseList).forEach((key) => {
        setAsset({ [key]: props.purchaseList[key] });
      });
      setAsset({ city: undefined });
    } else if (props.purchaseList?.id) {
      setAsset(props.purchaseList);
      Object.keys(props.purchaseList).forEach((key) => {
        setAsset({ [key]: props.purchaseList[key] });
      });
      setAsset({ city: props.purchaseList.city?.id });
    } else {
      setAsset({
        asset_type: null,
        budget: null,
        from_floor: null,
        to_floor: null,
        not_first_floor: false,
        not_last_floor: false,
        floors_in_building: null,
        number_of_rooms: null,
        number_of_rooms_max: null,
        asset_size: null,
        asset_outside_size: null,
        requested_eviction_range: null,
        should_have_elevator: null,
        elevator_from_floor: 0,
        should_show_dangerous_buildings: false,
        should_have_parking_in_registry: false,
        should_have_sun_terrace: false,
        should_have_storage_space: false,
        should_have_in_house_shelter: false,
        city: null,
        neighborhoods: {},
        errors: {},
        validations: {
          required: ["budget", "neighborhoods", "number_of_rooms"],
        },
      });
    }
  });

  createEffect(() => {
    if (asset.to_floor < asset.from_floor) {
      setAsset({ to_floor: null });
    }
  });

  const [showMatches, setShowMatches] = createSignal(true);
  const newPurchaselist = () => !props.purchaseList?.id;
  const location = useLocation();
  const [additionalAttributes, setAdditionalAttributes] = createSignal(false);
  const cities = () =>
    user.activity_areas.reduce((acc, city) => {
      return { ...acc, [city.id]: city.name };
    }, {});
  const [report, setReport] = createSignal(false);

  const defaultForm = {
    asset_type: null,
    budget: null,
    from_floor: null,
    to_floor: null,
    not_first_floor: false,
    not_last_floor: false,
    floors_in_building: null,
    number_of_rooms: null,
    number_of_rooms_max: null,
    asset_size: null,
    asset_outside_size: null,
    requested_eviction_range: null,
    should_have_elevator: null,
    elevator_from_floor: 1,
    should_show_dangerous_buildings: false,
    should_have_parking_in_registry: false,
    should_have_sun_terrace: false,
    should_have_storage_space: false,
    should_have_in_house_shelter: false,
    city: null,
    neighborhoods: {},
    errors: {},
    validations: {
      required: ["budget", "neighborhoods", "number_of_rooms"],
    },
  };

  const [asset, setAsset]: [
    asset: PurchaseListing,
    setAsset: SetStoreFunction<PurchaseListing>
  ] = createStore(defaultForm);

  populateUserDetailsIfNeeded(location, setAsset);

  const debouncedAssetKeys = createMutable({ value: "" });

  const trigger = debounce((value: string) => {
    debouncedAssetKeys.value = value;
  }, 3000);

  createEffect(() => {
    //Triggering on asset's change...
    if (asset) {
      trigger.clear(); //clear old time
      trigger(JSON.stringify(asset)); //define new value
    }
  });

  const matchQuery = createQuery({
    queryKey: () => ["fetch assets", debouncedAssetKeys.value],
    queryFn: () => queryRelevantAssets(asset, props.customer),
    staleTime: 60000
  });

  const neighborhoodQuery = getNeighborhoodsByName(
    () => null,
    () => asset.city
  );

  const submit = async () => {
    if (checkForErrors(asset, setAsset)) {
      setErrors(true);
      window.scrollTo({ behavior: "smooth", top: 0 });
      return;
    } else {
      setErrors(false);
    }
    const data = JSON.parse(JSON.stringify(asset));
    data.neighborhoods = Object.keys(data.neighborhoods).filter(
      (neighborhood) => data.neighborhoods[neighborhood]
    );
    data.should_have_elevator = Boolean(data.should_have_elevator);
    delete data.errors;
    delete data.validations;

    if (newPurchaselist()) {
      await axios.post(
        `/real-estate/customer/${props.customer.data.id}/add_purchase_listing/`,
        data
      );
      props.customer.refetch();
    } else {
      await axios.put(`/real-estate/purchase_listing/${asset.id}/`, data);
      props.customer.refetch();
    }
    

    await getUserDetails();
  };
  const [errors, setErrors] = createSignal(false);

  function elevatorMode(
    should_have_elevator,
    elevator_from_floor
  ): "must" | "needed_from_floor" | "not_needed" {
    if (!should_have_elevator) {
      return "not_needed";
    }
    if (should_have_elevator && !elevator_from_floor) {
      return "must";
    }
    if (should_have_elevator && elevator_from_floor) {
      return "needed_from_floor";
    }
  }
  const navigate = useNavigate();
  const setAssetModal = props.setAssetModal;

  const preferencesModal = () => location.query.preferencesModal === "true";

  createEffect(() => {
    const scrollPosition = sessionStorage.getItem("asset scroll position");
    if (preferencesModal() && scrollPosition && assetScroll) {
      assetScroll.scrollTo({ top: Number(scrollPosition) });
      sessionStorage.removeItem("asset scroll position");
    }
  });
  let assetScroll: HTMLDivElement;

  const inputStyle = "md:m-0 w-[30%]  min-w-[150px] max-w-[200px]";


  const [openedModal, setOpenedModal] = createSignal("");


  const setUserInterest = async (asset_id: number, is_interested:boolean,) => {
    const customer_id = props.customer.data.id
    const request = await axios.post(
        `/real-estate/customer/${customer_id}/update_asset_interest/`,
        {
          asset_id: asset_id,
          is_interested: is_interested 
        }
      )
      .then(resp => {
        console.log(resp)
      })
      .catch(err => {
        console.log(err)
        alert('Something went wrong! Please try again later')
      })
    await request
    matchQuery.refetch()
    // console.log(matchQuery)
  }

  const [RelevantOptionsList, setRelevantOptionsList]:any = createSignal({
    unknown_interest: 'התאמות פתוחות',
    interested: 'מעוניין',
    not_interested: 'לא מעוניין',
    all_assets: 'כל ההתאמות'
  })

  const [activeRelevantAssetsList, setActiveRelevantAssetsList]:any = createStore([])
  const [activeRelevantAssetsTab, setActiveRelevantAssetsTab] = createSignal('unknown_interest')
  createEffect(() => {
    if (matchQuery.data) {
      let activeTab = activeRelevantAssetsTab()
      let list:any = []
      if (props.useWhatsup) {
        if (activeTab === 'all_assets') {
          list = matchQuery.data
        } else if (activeTab === 'interested') {
          list = matchQuery.data.filter(asset => asset.is_interested === true)
        } else if (activeTab === 'not_interested') {
          list = matchQuery.data.filter(asset => asset.is_interested === false)
        } else if (activeTab === 'unknown_interest') {
          list = matchQuery.data.filter(asset => asset.is_interested === null)
        }
      } else {
        list = matchQuery.data
      }
      setActiveRelevantAssetsList(list)
    }
  })

  const [whatsupInit, setWhatsupInit] = createSignal(false)
  const [whatsupState, setWhatsupState]:any = createSignal(null)
  const [whatsupStatus, setWhatsupStatus] = createSignal(false)

  const [whatsupCustomers, setWhatsupCustomers]:any = createStore([])
  const [whatsupPhones, setWhatsupPhones]:any = createStore([])

  const [whatsupAssets, setWhatsupAsset]:any = createSignal([])
  const [whatsupAssetsData, setWhatsupAssetsData]:any = createSignal([])


  const openWhatsUpContainer = (assets_id:Array<number | string>, assets:any) => {
    setWhatsupAssetsData(assets)
    setWhatsupAsset(assets_id)
    setWhatsupCustomers([props.customer.data.id])
    // setWhatsupCustomers([489])
    setWhatsupInit(true)
    setWhatsupStatus(true)
  }





  return (
    <div>
      <Show when={props.useWhatsup && whatsupInit()}>
        <WhatsupContainer
          receivers={whatsupCustomers}
          receiversPhone={whatsupPhones}
          setWhatsupInit={setWhatsupInit}
          setStateStatus={setWhatsupState}
          assetId={whatsupAssets()}
          assets={whatsupAssetsData()}
          setWhatsupAsset={setWhatsupAsset}
          show={whatsupStatus()}
          setWhatsupCustomers={setWhatsupCustomers}
          setWhatsupStatus={setWhatsupStatus}
        />
      </Show>
      <Show when={openedModal() === 'delete_approval'}>
        <DeleteApproval asset={asset} setOpenedModal={setOpenedModal} customer={props.customer} />
      </Show>
      <ErrorBar hidden={!errors()} form={asset} />{" "}
      <Show when={report()}>
        <MissingNeighborhoodReportModal
          city={cities()[asset.city]}
          onClose={() => setReport(false)}
        />
      </Show>
      <Show when={preferencesModal()}>
        <Modal
          classList={{ "md:w-11/12": true }}
          open={preferencesModal()}
          onClose={() => {
            navigate(-1);
          }}
        >
          <div class="overflow-scroll h-[75vh] lg:pb-24" ref={assetScroll}>
            <div class="flex items-center justify-between p-2">
              <h1 class="text-3xl font-bold">נכסים רלוונטיים</h1>
              <Show when={props.useWhatsup}>
                <Picker
                  options={RelevantOptionsList()}
                  active={activeRelevantAssetsTab()}
                  handleChange={setActiveRelevantAssetsTab}
                  class={'relevant_assets--tabs'}
                />
              </Show>
              <Button
                border
                name="pick all"
                class="mx-4 w-32"
                onClick={() => {
                  if (Object.values(pickedAssets)[0]) {
                    matchQuery.data?.forEach((i) => {
                      setPickedAssets({ [i.id]: false });
                    });
                  } else {
                    matchQuery.data?.forEach((i) => {
                      setPickedAssets({ [i.id]: true });
                    });
                  }
                }}
              >
                בחר הכל
              </Button>
              <Button
                class="absolute bottom-12 left-12 z-50 w-48"
                color="green"
                disabled={Object.keys(pickedAssets).length < 1}
                onClick={() => {
                  const checkedIds = Object.keys(pickedAssets).filter(
                    (id) => pickedAssets[id]
                  );
                  const checkedAssets:any = matchQuery.data?.filter((asset) => {
                    return checkedIds.includes(String(asset.id));
                  });
                  if (props.useWhatsup) {
                    let assets:Array<number | string> = []
                    checkedAssets.forEach((asset:any) => {
                      assets.push(asset.id)
                    })
                    if (props.customer && props.customer.data && props.customer.data.id) {
                      openWhatsUpContainer(assets, checkedAssets)
                    } else {
                      setShareAssetsModal(true)
                    }
                    console.log(props.customer.data)
                  } else {
                    shareWhatsapp({
                      phone: props.customer?.data?.phone,
                      assets: checkedAssets,
                      customerStatus:
                        props.customer?.data.buyer_end_customer_status,
                    });
                  }
                }}
              >
                שלח{" "}
                <span class="text-blue mx-2 font-bold">
                  {
                    Object.keys(pickedAssets).filter((id) => pickedAssets[id])
                      .length
                  }
                </span>
                נכסים
              </Button>
            </div>
            <div class="flex justify-center gap-4 p-4 flex-wrap">
              <For each={activeRelevantAssetsList}>
                {(item, i) => {
                  return (
                    <article class="xl:w-[32%] 2xl:w-[22%] w-[48%]">
                      <AssetCard
                        fullWidth
                        setOpenedModal={() => {
                          sessionStorage.setItem(
                            "asset scroll position",
                            assetScroll.scrollTop
                          );
                          setSearch({
                            asset: item.id,
                            preferencesModal: false,
                          });
                        }}
                        pickValue={pickedAssets[item.id]}
                        handlePick={(id, value) => {
                          setPickedAssets({ [id]: value });
                        }}
                        showHideAsset={props.showAssetHideBtn}
                        asset={item}
                      />
                      <Show when={props.customer && props.customer.data.id}>
                        <div class="relevant_assets--actions">
                          <button
                            class="btn btn--red"
                            disabled={item.is_interested === false}
                            onClick={(e) => {
                              setUserInterest(item.id, false)
                            }}
                          >
                            לא מעוניין
                          </button>
                          <button
                            class="btn btn--green"
                            disabled={item.is_interested === true}
                            onClick={(e) => {
                              setUserInterest(item.id, true)
                            }}
                          >
                            מעוניין
                          </button>
                        </div>
                      </Show>
                    </article>
                  );
                }}
              </For>
            </div>
          </div>
        </Modal>
      </Show>
      <Modal
        open={shareAssetsModal()}
        onClose={() => {
          setShareAssetsModal(false);
        }}
      >
        <ShareAssetsModal
          matchQuery={matchQuery}
          pickedAssets={pickedAssets}
          openWhatsUpContainer={(phone: string, assets:Array<RealtorHomeAsset>, assets_id:Array<number>) => {
            setWhatsupPhones([phone])
            setWhatsupAssetsData(assets)
            setWhatsupAsset(assets_id)
            // setWhatsupCustomers([489])
            setWhatsupInit(true)
            setWhatsupStatus(true)
          }}
        />
      </Modal>
      <Modal
        onClose={() => {
          setCustomerModal(false);
        }}
        open={customerModal()}
      >
        <div class="p-4 bg-white">
          <ImportPhoneContact
            setter={setCustomer}
            nameKey="name"
            phoneKey="phone"
          />
          <TextInput
            onChange={(e) => {
              setCustomer({ name: e.target.value });
            }}
            label="שם לקוח"
            value={customer.name}
            name="name"
            required
          />
          <TextInput
            onChange={(e) => {
              setCustomer({ phone: e.target.value });
            }}
            required
            type="tel"
            value={customer.phone}
            label="טלפון לקוח"
            name="phone"
          />
          <Spacer />
          <Button
            onClick={async () => {
              const { data } = await axios.post("/real-estate/customer/", {
                ...customer,
                buyer_end_customer_activity_state: "AV",
              });
              const req = unwrap(asset);
              req.should_have_elevator = req.should_have_elevator || false;
              delete req.errors;
              delete req.validations;
              await axios.post(
                `/real-estate/customer/${data.id}/add_purchase_listing/`,
                req
              );
              getUserDetails();
              navigate("/customer/" + data.id);
            }}
            color="green"
            short
            disabled={!customer.phone || !customer.name}
          >
            הוסף לקוח
          </Button>
        </div>
      </Modal>
      <Show when={props.show && isSmall()}>
        <FlexCol class="gap-4 p-4">
          <Select
            onChange={(name, value) => {
              if (asset.neighborhoods) {
                setAsset({ neighborhoods: {} });
              }
              setAsset({ [name]: value });
            }}
            options={cities()}
            label="עיר"
            required
            value={asset.city}
            name="city"
            class="w-10/12"
          />
          <MultiSelect
            header="שכונה"
            customButton={
              <Button
                onClick={() => {
                  setReport(true);
                }}
                short
                class="gap-2"
              >
                <span class="md:text-xs"> לא מצאת את השכונה? דווח לנו</span>
                <AddIcon class="fill-white stroke-blue" />
              </Button>
            }
            onChange={(key, value: boolean) => {
              setAsset("neighborhoods", { [key]: value });
            }}
            options={neighborhoodQuery.data}
            label="בחר שכונה"
            store={asset}
            required
            value={asset.neighborhoods}
            name="neighborhoods"
            class="w-10/12"
            showReport
          />
          <Select
            onChange={(name, value) => setAsset({ [name]: value })}
            options={AssetTypeEnum}
            label="סוג נכס "
            required
            value={asset.asset_type}
            name="asset_type"
            class="w-10/12"
          />
          <div class="flex w-10/12 m-auto gap-6">
            <Select
              onChange={(name, value) => {
                setAsset({ [name]: value });
              }}
              options={generateRange(8, 0, true)}
              label="ממס׳ חדרים"
              halfs
              value={asset.number_of_rooms}
              name="number_of_rooms"
              class="w-6/12"
              required
            />
            <Select
              halfs
              onChange={(name, value) => setAsset({ [name]: value })}
              options={generateRange(8, 0, true)}
              nullable
              label="עד מס׳ חדרים"
              value={asset.number_of_rooms_max}
              name="number_of_rooms_max"
              class="w-6/12"
            />
          </div>
          <FlexRow>
            <label class="w-2/12">מעלית</label>
            <Picker
              name="should_have_elevator"
              active={elevatorMode(
                asset.should_have_elevator,
                asset.elevator_from_floor
              )}
              options={{
                must: "חובה",
                needed_from_floor: "חובה מקומה",
                not_needed: "לא חובה",
              }}
              handleChange={(key) => {
                if (key === "not_needed") {
                  setAsset({
                    should_have_elevator: false,
                    elevator_from_floor: null,
                  });
                }
                if (key === "must") {
                  setAsset({
                    should_have_elevator: true,
                    elevator_from_floor: null,
                  });
                }
                if (key === "needed_from_floor") {
                  setAsset({
                    should_have_elevator: true,
                    elevator_from_floor: 1,
                  });
                }
              }}
            />
          </FlexRow>
          <Show
            when={
              asset.should_have_elevator && asset.elevator_from_floor !== null
            }
          >
            {" "}
            <Select
              onChange={(name, value) => setAsset({ [name]: Number(value) })}
              options={generateRange(4)}
              label="מעלית מקומה"
              value={asset.elevator_from_floor}
              name="elevator_from_floor"
              class="w-10/12"
            />
          </Show>
          <FlexRow>
            <label class="w-6/12">חניה בטאבו</label>
            <Picker
              name="should_have_parking_in_registry"
              active={asset.should_have_parking_in_registry ? "show" : "hide"}
              options={{ show: "חובה", hide: "לא חובה" }}
              handleChange={(key) => {
                setAsset({
                  should_have_parking_in_registry:
                    key === "show" ? true : false,
                });
              }}
            />
          </FlexRow>
          <FlexRow>
            <label class="w-6/12"> ממ״ד</label>
            <Picker
              name="should_have_in_house_shelter"
              active={asset.should_have_in_house_shelter ? "show" : "hide"}
              options={{ show: "חובה", hide: "לא חובה" }}
              handleChange={(key) => {
                setAsset({
                  should_have_in_house_shelter: key === "show" ? true : false,
                });
              }}
            />
          </FlexRow>
          <FlexRow>
            <label class="w-6/12">מרפסת שמש</label>
            <Picker
              name="should_have_sun_terrace"
              active={asset.should_have_sun_terrace ? "show" : "hide"}
              options={{ show: "חובה", hide: "לא חובה" }}
              handleChange={(key) => {
                setAsset({
                  should_have_sun_terrace: key === "show" ? true : false,
                });
              }}
            />
          </FlexRow>
          <TextInput
            onChange={(e) => handleForm(e, setAsset)}
            label="שטח בנוי במ״ר"
            type="tel"
            value={Number(asset.asset_size)}
            name="asset_size"
            class="w-10/12"
          />
          <Show when={["TF", "PH", "CE"].includes(asset.asset_type)}>
            <TextInput
              onChange={(e) => handleForm(e, setAsset)}
              label={"שטח מגרש"}
              value={asset.asset_outside_size}
              name="asset_outside_size"
              class="w-10/12"
            />
          </Show>
          <Show when={["RT"].includes(asset.asset_type)}>
            <TextInput
              onChange={(e) => handleForm(e, setAsset)}
              label={"שטח הגג "}
              value={asset.asset_outside_size}
              name="asset_outside_size"
              class="w-10/12"
            />
          </Show>
          <Show when={["GT"].includes(asset.asset_type)}>
            <TextInput
              onChange={(e) => handleForm(e, setAsset)}
              label={"שטח הגינה "}
              value={Number(asset.asset_outside_size)}
              name="asset_outside_size"
              class="w-10/12"
            />
          </Show>
          <Button
            onClick={() => {
              setAdditionalAttributes(!additionalAttributes());
            }}
            class="w-8/12"
          >
            <Switch>
              <Match when={!additionalAttributes()}> מאפיינים נוספים</Match>
              <Match when={additionalAttributes()}>בטל מאפיינים נוספים</Match>
            </Switch>
          </Button>
          <Show when={additionalAttributes()}>
            <Show when={!["TF", "PH"].includes(asset.asset_type)}>
              <FlexRow class="w-10/12 justify-center m-auto gap-4">
                <Select
                  onChange={(name, value) => setAsset({ [name]: value })}
                  options={generateRange(30, -1)}
                  label="מקומה"
                  value={asset.from_floor}
                  name="from_floor"
                  class="w-6/12"
                  showGroundFloor
                />
                <Select
                  onChange={(name, value) => setAsset({ [name]: value })}
                  options={generateRange(30, -1)}
                  label="עד קומה"
                  value={asset.to_floor}
                  name="to_floor"
                  class="w-6/12"
                />
              </FlexRow>
              <FlexRow class="w-12/12 flex items-center justify-center m-auto gap-4">
                <CheckBox
                  value={asset.not_first_floor}
                  setValue={(value) => setAsset({ not_first_floor: value })}
                  label={"לא קומה ראשונה"}
                />{" "}
                <CheckBox
                  value={asset.not_last_floor}
                  setValue={(value) => setAsset({ not_last_floor: value })}
                  label="לא קומה אחרונה"
                />{" "}
              </FlexRow>
            </Show>
            <FlexRow>
              <label class="w-6/12">מבנים מסוכנים</label>
              <Picker
                name="should_show_dangerous_buildings"
                active={asset.should_show_dangerous_buildings ? "show" : "hide"}
                options={{ show: "הצג", hide: "הסתר" }}
                handleChange={(key) => {
                  setAsset({
                    should_show_dangerous_buildings:
                      key === "show" ? true : false,
                  });
                }}
              />
            </FlexRow>
            <FlexRow>
              <label class="w-6/12">מחסן</label>
              <Picker
                name="should_have_storage_space"
                active={asset.should_have_storage_space ? "show" : "hide"}
                options={{ show: "חובה", hide: "לא חובה" }}
                handleChange={(key) => {
                  setAsset({
                    should_have_storage_space: key === "show" ? true : false,
                  });
                }}
              />
            </FlexRow>
          </Show>
        </FlexCol>
        <TextInput
          onChange={(e) => {
            e.currentTarget.value = unpricify(e.currentTarget.value);
            handleForm(e, setAsset);
          }}
          name="budget"
          label="תקציב"
          required
          value={pricify(asset.budget)}
          class="w-10/12"
        />
        <Spacer height="12px" />
        <Select
          options={EvictionRangeEnum}
          onChange={(name, value) => setAsset({ [name]: value })}
          label="מועד פינוי"
          value={asset.requested_eviction_range}
          name="requested_eviction_range"
          class="w-10/12"
        />
        <Spacer />
        <Spacer />

        <Show when={!props.searchAsset}>
          <div class="w-5/12 m-auto">
            <Button
              disabled={success()}
              fullWidth
              color="green"
              onClick={async () => {
                await submit();
                if (errors()) {
                  return;
                }
                logEvent('form_submit', {
                  customer_status: props.customer?.data.buyer_end_customer_status
                })
                setSuccess(true);
                setTimeout(() => {
                  setSuccess(false);
                  setAsset(defaultForm);
                  props.hide();
                }, 5000);
              }}
            >
              {success()
                ? "העדפות חיפוש עודכנו בהצלחה!"
                : newPurchaselist()
                ? "הוסף"
                : "עדכן"}
            </Button>
            <Show when={props.showPurchaseDeleteButton && !newPurchaselist()}>
              <Spacer />
              <Button
                border
                fullWidth
                onClick={() => {
                  setOpenedModal('delete_approval')
                }}
              >
                מחק את העיר
              </Button>
            </Show>
          </div>
        </Show>
        <Show when={props.searchAsset}>
          <Button
            name="add client"
            class="ml-8"
            border
            onClick={() => {
              setCustomerModal(true);
            }}
          >
            <p class="flex gap-2 justify-center">
              <span>הוסף לקוח</span>
              <AddIcon class="mr-4" white />
            </p>
          </Button>
        </Show>
      </Show>
      <Show when={!isSmall()}>
        <CustomerFormDesktop
          asset={asset}
          setAsset={setAsset}
          cities={cities}
          setReport={setReport}
          neighborhoodQuery={neighborhoodQuery}
          searchAsset={props.searchAsset}
          elevatorMode={elevatorMode}
          success={success}
          setSuccess={setSuccess}
          setPreferencesModal={() => navigate("?preferencesModal=true")}
          setCustomerModal={setCustomerModal}
          defaultForm={defaultForm}
          newPurchaselist={newPurchaselist}
          setOpenedModal={setOpenedModal}
          submit={submit}
          errors={errors}
          showDelete={props.showPurchaseDeleteButton}
        />
      </Show>
      <Spacer />
      <Show
        when={isSmall() && matchQuery.data?.length}
        fallback={
          isSmall() && <Button class="w-8/12 mb-8">אין נכסים רלוונטיים</Button>
        }
      >
        <Spacer />
        <Button
          class="w-8/12"
          onClick={() => {
            setShowMatches(!showMatches());
          }}
          color="green"
        >
          <Show when={showMatches()} fallback={"הצג נכסים רלוונטיים"}>
            {" "}
            הסתר נכסים רלוונטיים
          </Show>
        </Button>
        <Spacer />
        <Show when={showMatches()}>
          <Picker
            options={RelevantOptionsList()}
            active={activeRelevantAssetsTab()}
            handleChange={setActiveRelevantAssetsTab}
            class={'relevant_assets--tabs'}
          />
          <div class="flex flex-col gap-2 items-center p-4">
            <For each={activeRelevantAssetsList}>
              {(item, i) => {
                return (
                  <>
                    <button class="w-full">
                      <AssetCard
                        pickValue={pickedAssets[item.id]}
                        handlePick={(id, value) => {
                          setPickedAssets({ [id]: value });
                        }}
                        asset={item}
                      />
                      
                    </button>
                    <Show when={props.customer && props.customer.data.id}>
                      <div class="relevant_assets--actions">
                        <button
                          class="btn btn--red"
                          disabled={item.is_interested === false}
                          onClick={(e) => {
                            setUserInterest(item.id, false)
                          }}
                        >
                          לא מעוניין
                        </button>
                        <button
                          class="btn btn--green"
                          disabled={item.is_interested === true}
                          onClick={(e) => {
                            setUserInterest(item.id, true)
                          }}
                        >
                          מעוניין
                        </button>
                      </div>
                    </Show>
                  </>
                );
              }}
            </For>
            <Spacer height="24px" />
          </div>
        </Show>
        <ShareAssetsButton
          searchPage={props.searchAsset}
          openShareAssetModal={() => {
            setShareAssetsModal(true);
          }}
          pickedAssets={pickedAssets}
          matchQuery={matchQuery}
          phone={props.customer?.data.phone}
          customer={props.customer?.data}
          openWhatsUpContainer={openWhatsUpContainer}
        />
      </Show>
    </div>
  );
}

export const MissingNeighborhoodReportModal = (props: {
  city: string;
  onClose: () => void;
}) => {
  const [report, setReport] = createSignal("");
  return (
    <Modal
      open
      onClose={() => props.onClose()}
      classList={{ "max-w-sm": true }}
    >
      <div class="bg-white p-4 rounded-xl shadow-blackShadow">
        <p>
          לא מצאת את השכונה שחיפשת? הזן את שם השכונה ונציגנו יבחנו את הנושא
          בהקדם
        </p>
        <Spacer />
        <TextInput
          onChange={(e) => {
            setReport(e.currentTarget.value);
          }}
          name="name"
          label="שם השכונה"
        />
        <Spacer />
        <Button
          onClick={async () => {
            await axios.post("/gesher-tasks/", {
              content:
                `דיווח על שכונה חסרה - ${report()} בעיר ${
                  props.city
                }. דווח על ידי ` +
                user.name +
                ", " +
                user && user.agency && user.agency.name ? user.agency.name : '',
            });
            props.onClose();
          }}
          color="green"
          class="w-8/12"
        >
          {" "}
          דווח
        </Button>
      </div>
    </Modal>
  );
};

export async function queryRelevantAssets(data:any, customer?:any) {
  const unproxied = unwrap(data);
  const req = { ...unproxied };
  if (!unproxied.city) {
    return [];
  }

  if (req.neighborhoods) {
    const neighborhoodsIds = Object.keys(req.neighborhoods)
      .filter((key) => {
        return req.neighborhoods[key];
      })
      .map((i) => Number(i));
    req.neighborhoods = neighborhoodsIds;
  } else {
    req.neighborhoods = [req.neighborhood.id];
  }
  if (req.city?.name) {
    req.city = req.city.id;
  }

  delete req.errors;
  delete req.validations;
  let params:any = {
    ...req,
      id: null,
      city: Number(req.city) || null,
      budget: Number(req.budget) || req.price || 999999999,
      neighborhoods: req.neighborhoods,
      should_have_elevator: Boolean(req.should_have_elevator),
      number_of_rooms: Number(req.number_of_rooms) || 0,
      asset_size: Number(req.asset_size) || 0,
      asset_outside_size: Number(req.asset_outside_size) || req.asset_outside_size,
  }
  if (customer && customer.data.id) {
    params.buyer = customer.data.id
  }
  const res = await axios.post(
    "/real-estate/purchase_listing/search_for_matching_assets/",
    params,
    {
      validateStatus: function (status) {
        return true;
      },
    }
  );
  if (Array.isArray(res.data)) {
    return (
      res.data?.map((asset) => {
        return { ...asset, price: pricify(Number(asset.price)) };
      }) || []
    );
  } else {
    return [];
  }
}

function populateUserDetailsIfNeeded(
  location,
  setAsset: SetStoreFunction<PurchaseListing>
) {
  createEffect(() => {
    if (!location.state) {
      return;
    }
    if (location.state.name) {
      setAsset({ name: location.state.name });
    }
    if (location.state.id) {
      setAsset({ current_user_id: location.state.id });
    }
    if (location.state.phone) {
      setAsset({ phone: location.state.phone });
    }
  });
}



export function DeleteApproval({ setOpenedModal, asset, customer }) {
  return GenericModal({
    options: [
      {
        label: "מחק את העיר",
        color: "blue",
        handler: async () => {
          await axios.delete(`/real-estate/purchase_listing/${asset.id}/`);
          setOpenedModal('');
          customer.refetch();
        },
      },
    ],
    alt: "delete icon",
    header: "",
    icon: DeleteIcon,
    onCancel: setOpenedModal,
  });
}

export const DeleteIcon = () => (
  <svg
    width="48"
    height="45"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.52929 0C6.14058 0 4.98424 0.983037 4.79015 2.25H0.602206C0.523528 2.24895 0.445412 2.26272 0.3724 2.29051C0.299387 2.31831 0.232934 2.35957 0.176902 2.41191C0.12087 2.46425 0.0763769 2.52661 0.0460079 2.59538C0.0156389 2.66415 0 2.73795 0 2.8125C0 2.88705 0.0156389 2.96085 0.0460079 3.02962C0.0763769 3.09839 0.12087 3.16075 0.176902 3.21309C0.232934 3.26543 0.299387 3.30669 0.3724 3.33449C0.445412 3.36228 0.523528 3.37605 0.602206 3.375H1.44799L2.44531 13.136C2.55376 14.1984 3.48617 15 4.61311 15H10.4455C11.5724 15 12.5044 14.1984 12.6133 13.136L13.6106 3.375H14.4564C14.5351 3.37605 14.6132 3.36228 14.6862 3.33449C14.7592 3.30669 14.8256 3.26543 14.8817 3.21309C14.9377 3.16075 14.9822 3.09839 15.0126 3.02962C15.0429 2.96085 15.0586 2.88705 15.0586 2.8125C15.0586 2.73795 15.0429 2.66415 15.0126 2.59538C14.9822 2.52661 14.9377 2.46425 14.8817 2.41191C14.8256 2.35957 14.7592 2.31831 14.6862 2.29051C14.6132 2.26272 14.5351 2.24895 14.4564 2.25H10.2684C10.0743 0.983037 8.918 0 7.52929 0ZM7.52929 1.125C8.27312 1.125 8.88334 1.60056 9.05773 2.25H6.00085C6.17524 1.60056 6.78546 1.125 7.52929 1.125ZM5.74804 5.25C6.07579 5.25 6.34179 5.50163 6.34179 5.8125V11.4375C6.34179 11.7484 6.07579 12 5.74804 12C5.42029 12 5.15429 11.7484 5.15429 11.4375V5.8125C5.15429 5.50163 5.42029 5.25 5.74804 5.25ZM9.31054 5.25C9.63829 5.25 9.90429 5.50163 9.90429 5.8125V11.4375C9.90429 11.7484 9.63829 12 9.31054 12C8.98279 12 8.71679 11.7484 8.71679 11.4375V5.8125C8.71679 5.50163 8.98279 5.25 9.31054 5.25Z"
      fill="#234F68"
    />
  </svg>
);