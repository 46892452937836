import { Motion, Presence } from "@motionone/solid";

import { useEnableZoom } from "../PublicAsset/PublicAsset";
import { For, Index, Show, createEffect, mergeProps, onMount } from "solid-js";
import swipeIcon from "./swipeIcon.png";
import "solid-slider/slider.css";
import { Slider, createSlider } from "solid-slider";
import { CloseIcon } from "../../Icons/CloseIcon";
export function PicturesModal(props) {
  let touchstartX = 0;
  let currentTouchX = 0;
  const { setPictureModal, pictureModal, asset, increment, decrement } =
    mergeProps(props);
  useEnableZoom();

  return (
    <Motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      class="fixed bg-blue inset-0 bg-opacity-90 z-[150] flex items-center justify-center h-screen modal"
    >
      <div class="relative">
        <Show when={pictureModal() !== false}>
          <button
            onclick={() => {
              setPictureModal(false);
            }}
            class="absolute -top-8 right-2 "
          >
            <CloseIcon />
          </button>
        </Show>
        <div class="bg-white w-[85vw] max-w-4xl max-h-[85vh] relative overflow-y-scroll rounded-md">
          <div class="absolute z-50">
            <img
              src={swipeIcon}
              width="35px"
              classList={{ "opacity-100": asset.data.image_urls.length > 1 }}
              class="relative right-2 top-2 animate-pulse opacity-0 lg:bg-white"
            />
          </div>
          <Gallery asset={asset} pictureModal={pictureModal} />
        </div>
      </div>
    </Motion.div>
  );
}

function Gallery({ asset, pictureModal }) {
  let ref: HTMLElement;
  const options = { duration: 1000 };
  const [slider, { current, next, prev, moveTo }] = createSlider({
    initial: Number(pictureModal()),
  });
  onMount(() => {
    slider(ref);
  });

  return (
    <div
      ref={ref}
      class="bg-[black] flex align-middle items-center lg:h-[70vh]"
    >
      <Index each={asset.data.image_urls}>
        {(img) => (
          <div class="flex h-full flex-col justify-center">
            <img
              class="m-auto pointer-events-auto w-full"
              src={img()}
              alt="image of the house"
            />
          </div>
        )}
      </Index>
    </div>
  );
}
