import { Asset, RealtorHomeAsset } from "../../types";
import Button from "../Button";
import megaPhone from "../../Components/Megaphone.png";
import { FlexCol } from "../Flex";
import { Modal } from "../Modal";
import Spacer from "../Spacer";
import axios from "axios";
import { Show, createSignal } from "solid-js";
import { GenericModal } from "../../Pages/Assets/GenericModal";
import { logEvent } from "../../analytics";
import LocationIcon from "../../Icons/LocationIcon";
type Props = { asset: Asset; onClose: () => void };

export default function ReportModal(props: Props) {
  const [success, setSuccess] = createSignal(false);
  const reportAsset = async (data) => {
    await axios.post("/gesher-tasks/", data);
    setSuccess(true);
  };

  return (
    <>
      <Show when={success()}>
        {" "}
        <GenericModal
          alt="success"
          header="תודה על העדכון"
          icon={Like}
          options={[
            {
              color: "green",
              label: "סגור",
              handler: props.onClose,
            },
          ]}
        />
      </Show>
      <Show when={!success()}>
        <Modal classList={{ "max-w-md": true }} open onClose={props.onClose}>
          <div class="flex flex-col items-center p-2">
            <img src={megaPhone}></img>
            <Spacer />
            <div class="flex gap-2 items-center">
              <LocationIcon />
              {props.asset.street.name}
              {props.asset?.address_number
                ? " " + props.asset?.address_number
                : ""}
              {", " + props.asset.city?.name || ""}
            </div>
            <div class="text-2xl font-bold"> מה תרצה לדווח</div>
            <Spacer />
            <FlexCol class="gap-2 w-full">
              {" "}
              <Button
                class="font-bold"
                onClick={() => {
                  logEvent("button_click", {
                    // button_type: "report asset",
                    // asset_id: props.asset.id,
                    click_type: 'הנכס לא רלוונטי',
                    button_type: 'report'
                  });
                  reportAsset({
                    asset: props.asset.id,
                    content: "הנכס לא רלוונטי",
                    reported_realtor: props.asset.realtor,
                  });
                }}
                fullWidth
                border
              >
                הנכס לא רלוונטי
              </Button>
              <Button
                class="font-bold"
                onClick={() => {
                  logEvent("button_click", {
                    // asset_id: props.asset.id,
                    // button_type: "לא בבלעדיות",
                    click_type: 'לא בבלעדיות',
                    button_type: 'report'
                  });
                  reportAsset({
                    asset: props.asset.id,
                    content: "לא בבלעדיות",
                    reported_realtor: props.asset.realtor,
                  });
                }}
                fullWidth
                border
              >
                לא בבלעדיות
              </Button>
              <Button
                class="font-bold"
                onClick={() => {
                  logEvent("button_click", {
                    // button_type: "לא לשתפ",
                    // asset_id: props.asset.id,
                    click_type: 'לא לשת״פ',
                    button_type: 'report'
                  });
                  reportAsset({
                    asset: props.asset.id,
                    content: "לא לשתפ",
                    reported_realtor: props.asset.realtor,
                  });
                }}
                fullWidth
                border
              >
                לא לשת״פ
              </Button>
              <Button
                class="font-bold"
                onClick={() => {
                  logEvent("button_click", {
                    // button_type: "מחיר לא עדכני",
                    // asset_id: props.asset.id,
                    click_type: 'מחיר לא עדכני',
                    button_type: 'report'
                  });
                  reportAsset({
                    asset: props.asset.id,
                    content: "מחיר לא עדכני",
                    reported_realtor: props.asset.realtor,
                  });
                }}
                fullWidth
                border
              >
                מחיר לא עדכני
              </Button>
              <Spacer height="32px" />
              <Spacer height="6px" />
              <button onclick={props.onClose} class="underline text-center">
                ביטול
              </button>
            </FlexCol>
          </div>
        </Modal>
      </Show>
    </>
  );
}

const Like = () => (
  <svg
    width="97"
    height="97"
    viewBox="0 0 97 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      d="M48.5 97C64.4041 97 77.2969 94.9643 77.2969 92.4531C77.2969 89.942 64.4041 87.9062 48.5 87.9062C32.5959 87.9062 19.7031 89.942 19.7031 92.4531C19.7031 94.9643 32.5959 97 48.5 97Z"
      fill="black"
    />
    <path
      d="M75.7812 68.2031H56.0781V80.3281H75.7812C79.1293 80.3281 81.8438 77.6136 81.8438 74.2656C81.8438 70.9176 79.1293 68.2031 75.7812 68.2031Z"
      fill="#8BC83F"
    />
    <path
      d="M75.7812 56.0781H56.0781V68.2031H75.7812C79.1293 68.2031 81.8438 65.4886 81.8438 62.1406C81.8438 58.7926 79.1293 56.0781 75.7812 56.0781Z"
      fill="#8BC83F"
    />
    <path
      d="M75.7812 43.9531H56.0781V56.0781H75.7812C79.1293 56.0781 81.8438 53.3636 81.8438 50.0156C81.8438 46.6676 79.1293 43.9531 75.7812 43.9531Z"
      fill="#8BC83F"
    />
    <path
      d="M75.7812 31.8281H56.0781V43.9531H75.7812C79.1293 43.9531 81.8438 41.2386 81.8438 37.8906C81.8438 34.5426 79.1293 31.8281 75.7812 31.8281Z"
      fill="#8BC83F"
    />
    <path
      d="M51.5312 31.8283L55.5598 27.7998C59.4549 23.9046 60.2961 17.8982 57.621 13.083C56.0887 10.3246 52.3421 9.8002 50.1096 12.0312L34.1849 27.9559C32.6754 29.4654 31.8281 31.5115 31.8281 33.6455V69.9735C31.8281 75.692 36.4644 80.3283 42.1814 80.3283H65.1719V31.8283H51.5312Z"
      fill="#8BC83F"
    />
    <path
      d="M25.7656 80.3281H21.2188C17.8707 80.3281 15.1562 77.6136 15.1562 74.2656V34.8594C15.1562 31.5114 17.8707 28.7969 21.2188 28.7969H25.7656C29.1136 28.7969 31.8281 31.5114 31.8281 34.8594V74.2656C31.8281 77.6136 29.1136 80.3281 25.7656 80.3281Z"
      fill="#234F68"
    />
    <path
      opacity="0.3"
      d="M28.7484 29.6108C27.8648 29.1076 26.8554 28.7969 25.7656 28.7969H21.2187C17.8707 28.7969 15.1562 31.5114 15.1562 34.8594V59.1048C19.3409 59.1048 22.7344 55.7159 22.7344 51.5312V38.3787C22.7344 37.245 23.3452 36.1613 24.3758 35.6884C26.7493 34.5987 28.4589 32.3177 28.7484 29.6108Z"
      fill="white"
    />
    <path
      d="M21.9766 37.1328V44.7109"
      stroke="white"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.15"
      d="M65.1719 80.3283H75.7812C79.1293 80.3283 81.8438 77.6138 81.8438 74.2658C81.8438 71.3755 79.8174 68.9656 77.1089 68.3594C75.754 69.4461 74.7764 70.9829 74.4172 72.7501H56.0781C51.8935 72.7501 48.5 76.1436 48.5 80.3283H56.0781H65.1719Z"
      fill="black"
    />
  </svg>
);
