import {
  Accessor,
  createEffect,
  createSignal,
  Index,
  lazy,
  onMount,
  Setter,
  Show,
} from "solid-js";
import {
  A,
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "@solidjs/router";

import { loggedIn, user } from "./globalState";
import { getUserDetails } from "./api/getUserDetails";
const AddAsset = lazy(() => import("./Pages/AddAsset/AddAsset"));
const LoadingScreen = lazy(
  () => import("./Components/LoadingScreen/LoadingScreen")
);
const Documents = lazy(() => import("./Pages/Documents/Documents"));
const Form = lazy(() => import("./Pages/Forms/Forms"));
const Task = lazy(() => import("./Pages/Tasks/Task"));
import { NotificationsPage } from "./NotificationsPage";
const EditTask = lazy(() => import("./Pages/AddTasks/EditTask"));
const Profile = lazy(() => import("./Pages/Profile/Profile"));
const EditCard = lazy(() => import("./Pages/Profile/EditCard"));
const MyAssets = lazy(() => import("./Pages/Profile/MyAssets"));
const BlockedColleagues = lazy(
  () => import("./Pages/Profile/BlockedColleagues")
);
const EditAsset = lazy(() => import("./Pages/AddAsset/EditAsset"));
// const Onboarding = lazy(() => import("./Pages/Onboarding/Onboarding"));
const Agent = lazy(() => import("./Pages/Agent/Agent"));
import { App as Native } from "@capacitor/app";
import { callModal, setCallModal } from "./App";
import { Header } from "./Pages/AddClient/Header";
import { useGetAgentAssets } from "./Pages/Assets/useGetAgent";
import AssetCard from "./Components/AssetCard";
import { changeTitle } from "./analytics";
import Deals from "./Pages/Deals/Deals";
import ViewAsset from "./Pages/Assets/ViewAsset";
import { WeeklyReport } from "./WeeklyReport";
import { CallModal } from "./CallModal";
import { UpdateModal } from "./Components/UpdateModal";
import Registration from "./Pages/Registration/Registration";
import ImportContacts from "./Pages/ImportContacts/Import";
import { Deal } from "./Deal";
import TestConnectionPage from "./Pages/TestConnectionPage";
const Terms = lazy(() => import("./Pages/Terms"));
const CallMeConsent = lazy(() => import("./Pages/Forms/CallMeConsentForm"));
const EditCustomer = lazy(() => import("./Pages/Customer/EditCustomer"));
const SearchAsset = lazy(() => import("./Pages/SearchAsset/SearchAsset"));
function AppRoutes() {
  const navigate = useNavigate();
  onMount(() => {
    Native.addListener("backButton", (data) => {
      if (window.location.pathname === "/") {
        Native.minimizeApp();
      } else {
        navigate(-1);
      }
    });
    Native.addListener("appStateChange", async (state) => {
      if (!state.isActive) {
        return;
      }
      getUserDetails();
    });
  });
  const Assets = lazy(() => import("./Pages/Assets/Assets"));
  const PhoneCall = lazy(() => import("./Pages/PhoneCall/PhoneCall"));
  const AddClient = lazy(() => import("./Pages/AddClient/AddClient"));
  const Settings = lazy(() => import("./Pages/Settings/Settings"));
  const Tasks = lazy(() => import("./Pages/Tasks/Tasks"));
  const AddTask = lazy(() => import("./Pages/AddTasks/AddTask"));
  const Customer = lazy(() => import("./Pages/Customer/Customer"));
  const Login = lazy(() => import("./Pages/Login/Login"));
  const MyClients = lazy(() => import("./Pages/MyCustomers/MyClients"));
  const NewCustomersPage = lazy(() => import("./Pages/NewCustomers/Page"));
  const Home = lazy(() => import("././Pages/Home/Home"));
  const PublicAsset = lazy(() => import("./Pages/PublicAsset/PublicAsset"));

  const ConnectContacts = lazy(() => import('./Pages/ConnectTeam/Contacts'))

  const NewAssetsSearch = lazy(() => import("./Pages/SearchAsset/NewAssetsSearch"));


  const PublicAgent = lazy(() => import('./Pages/Agent/PublicView'));
  // const EditAgentPublicPage = lazy(() => import('./Pages/Agent/EditPublicView'))

  const [search] = useSearchParams();
  changeTitle("התחברות");

  const [runUpdateModalOnce, setOnce] = createSignal(true);

  return (
    <>
      <Show when={search.asset}>
        <ViewAsset />
      </Show>

      <Show
        when={
          user.active_assets &&
          !window.location.pathname.includes("/public-view/") &&
          runUpdateModalOnce()
        }
      >
        <UpdateModal
          hide={() => {
            setOnce(false);
          }}
        />
      </Show>

      <Show when={loggedIn() === "loading"}>
        <LoadingScreen />
      </Show>
      <Show when={callModal()}>
        <CallModal navigate={navigate} setCallModal={setCallModal} />
      </Show>
      <Routes>
        <Route path="/login" component={Login} />
        <Route path="/registration" component={Registration} />
        <Route path="/public-view/agent/:id" component={Agent} />
        <Route path="/public-view/agent/:id/assets" component={AgentAssets} />
        <Route path="/terms" component={Terms} />
        <Route path="/deals" component={Deals} />
        <Route path="/deal/:id/:type" component={Deal} />
        <Route path="/a/:uuid" component={PublicAgent} />
        <Route path="/public-agent/:id" component={PublicAgent} />

        <Route path="/public-view/asset/:id" component={PublicAsset} />
        <Route
          path="/public-view/consent/:id/:documentId"
          component={CallMeConsent}
        />

        <Show when={user.name}>
          <Route path="/test-connection" component={TestConnectionPage} />
          <Route path="/" component={Home} />
          <Route path="/tasks" component={Tasks} />
          <Route path="/agent/:id" component={Agent} />
          <Route path="/agent/:id/assets" component={AgentAssets} />
          <Route path="/task/:id" component={Task} />
          <Route path="/addTask" component={AddTask} />
          <Route path="/editTask" component={EditTask} />
          <Route path="/settings" component={Settings} />
          {/* <Route path="/customers" component={MyClients} /> */}
          <Route path="/customers" component={NewCustomersPage} />
          <Route path="/assets" component={Assets} />
          <Route path="/search-assets" component={NewAssetsSearch} />
          <Route path="/phonecall" component={PhoneCall} />
          <Route path="/addClient/:type" component={AddClient} />
          <Route path="/addClient" component={AddClient} />

          <Route path="/searchAsset" component={SearchAsset} />
          <Route path="/import" component={ImportContacts} />
          {/* <Route path="/onboarding" component={Onboarding} /> */}
          <Route path="/addAsset" component={AddAsset} />
          <Route path="/documents" component={Documents} />
          <Route path="/notifications" component={NotificationsPage} />
          <Route path="/edit-asset/:id" component={EditAsset} />
          <Route path="/landing-customer/:id" component={LandingCustomer} />

          <Route path="/customer/:id" component={Customer} />
          <Route path="/edit-customer/:id" component={EditCustomer} />
          <Route path="/forms" component={Form} />

          <Route path="/profile" component={Profile} />
          <Route path="/profile-edit" component={EditCard} />
          <Route path="/profile-assets" component={MyAssets} />
          <Route path="/profile-blocked" component={BlockedColleagues} />
          {/* <Route path="/public-profile" component={EditAgentPublicPage}/> */}
          {/* ConnectTeam */}
          {/* <Route path="/connect-team" component={ConnectContacts}/> */}
          {/* END */}
        </Show>
      </Routes>
    </>
  );
}

const AgentAssets = () => {
  const params = useParams();
  const assets = useGetAgentAssets(params.id);
  const [_, setSearch] = useSearchParams();
  return (
    <div>
      <div class="flex flex-col gap-4">
        <Header title="נכסים של הסוכן" />
        <Index each={assets.data} fallback={<div>טוען נכסים...</div>}>
          {(asset) => {
            return (
              <button onclick={() => setSearch({ asset: asset().id })}>
                <span class="pointer-events-none">
                  <AssetCard asset={asset()} />
                </span>
              </button>
            );
          }}
        </Index>
      </div>
    </div>
  );
};

const LandingCustomer = () => {
  const navigate = useNavigate();
  const params = useParams();

  onMount(() => {
    navigate("/customer/" + params.id);
  });
  return <></>;
};

export default AppRoutes;
