import { Index, Show } from "solid-js";
import dashboard from "./Components/Navbar/dashboard.svg";
import ConnectTeamIcon from './Components/Navbar/connectteam.svg'
import assets from "./Components/Navbar/assets.svg";
import tasks from "./Components/Navbar/tasks.svg";
import customers from "./Components/Navbar/customers.svg";
import logo from "./Icons/logo.svg";
import settings from "./Icons/Settings.svg";

import deals from "./Handshake.png";
import Spacer from "./Components/Spacer";
import { A, Link, useLocation, useNavigate } from "@solidjs/router";
import Button from "./Components/Button";
import TextInput from "./Components/TextInput";
import searchIcon from "./Icons/search.png";
import { isSmall } from "./Pages/Home/isSmall";
import { ActiveGreenIcon } from "./Icons";
import AutoComplete from "./Components/AutoComplete";
import { user } from "./globalState";
import { createMutable } from "solid-js/store";
import { UserIcon } from "./Pages/AddClient/BuyerForm";

export const SideBarDesktop = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const search = createMutable({ text: "" });

  if (location.pathname.includes("/public-view")) {
    return <></>;
  }
  return (
    <div
      class="hidden lg:block h-screen min-w-[192px] bg-white lg:z-50"
      classList={{ "lg:hidden": !user.id || location.pathname.includes('import')}}
    >
      <div class="fixed shadow-sideBar bottom-0 top-0 bg-white overflow-y-auto">
        <Link
          class="border-none mb-4 bg-blue rounded-b-full w-20 m-auto h-16 flex justify-center"
          href={`/a/${user.uuid}`}
        >
          <Show
            when={user.image_url}
            fallback={
              <span class="pt-2">
                <UserIcon height="80%" width="100%" />
              </span>
            }
          >
            <img
              src={user.image_url}
              class="h-12 w-12 my-1 rounded-full"
              alt="gesher logo"
            />
          </Show>
        </Link>
        <Link class="border-none flex justify-center" href="/">
          <img src={logo} width="37px" alt="gesher logo" />
        </Link>
        <div>
          <div class="p-2">
            <Link href="/addClient/buyer">
              <Button
                name="buyer"
                fullWidth
                color="green"
                class="mt-4 2xl:mt-6 py-2 h-auto flex gap-2 flex-wrap"
              >
                הוספת לקוח חדש
                <AddUser />
              </Button>
            </Link>
            <Link href="/addAsset">
              <Button
                fullWidth
                name="add asset"
                color="green"
                class="mt-2 py-2 h-auto flex gap-2 flex-wrap"
              >
                הוספת נכס חדש
                <AddAsset />
              </Button>
            </Link>
            <Link href="/searchAsset">
              <Button
                fullWidth
                name="add asset"
                color="blue"
                class="mt-2 py-2 h-auto flex gap-2 flex-wrap"
              >
                חיפוש נכסים מהיר <AddAsset />
              </Button>
            </Link>
          </div>{" "}
          {/* <Link href="/addClient/buyer">
              <Button icon fullWidth short color="green" class="mt-2">
                הוספת מסמך חדש
              </Button>
            </Link> */}
          <Spacer height="12px" />
          {/* <Spacer height="20px" /> */}
          <div class="flex flex-col gap-2">
            <Index
              each={[
                {
                  icon: dashboard,
                  text: "דף הבית",
                  active: location.pathname === "/",
                  link: "/",
                },
                // {
                //   icon: ConnectTeamIcon,
                //   text: "ConnecTeam",
                //   active: location.pathname === "/connect-team",
                //   link: "/connect-team",
                // },
                {
                  icon: customers,
                  text: "לקוחות",
                  active: location.pathname === "/customers",
                  link: "/customers",
                },
                {
                  icon: assets,
                  text: "נכסים",
                  active: location.pathname === "/assets",
                  link: "/assets",
                },
                {
                  icon: tasks,
                  text: "משימות",
                  active: location.pathname === "/tasks",
                  link: "/tasks",
                },
                {
                  icon: deals,
                  text: "העסקאות שלי",
                  active: location.pathname === "/deals",
                  link: "/deals",
                },
                {
                  icon: settings,
                  text: user.name,
                  active: location.pathname === `/a/${user.uuid}`,
                  link: `/a/${user.uuid}`,
                },
                // {
                //   icon: documents,
                //   text: "מסמכים",
                //   active: location.pathname === "/documents",
                //   link: "/documents",
                // },
              ]}
            >
              {(button) => {
                return (
                  <Link
                    activeClass="bg-white bold"
                    class="flex items-center gap-2 lg:py-2 2xl:py-4 text-right bg-white border-none"
                    href={button().link}
                  >
                    <div
                      class={
                        "bg-green rounded-l-lg w-1 h-14 z-20 transition-all absolute"
                      }
                      classList={{
                        "opacity-0": !button().active,
                        "opacity-100": button().active,
                      }}
                    ></div>
                    <div
                      class={
                        "bg-gradient-to-r pointer-events-none from-white to-lightGrey rounded-l-lg h-14 z-10 transition-all duration-700 absolute"
                      }
                      classList={{
                        "opacity-0": !button().active,
                        "opacity-100": button().active,
                      }}
                    ></div>
                    <span class="w-12 z-20">
                      {" "}
                      <img
                        width="24px"
                        class="w-fit m-auto"
                        src={button().icon}
                        alt="icon"
                      />
                    </span>
                    <span
                      class="text-right z-20"
                      classList={{ "font-semibold": button().active }}
                    >
                      {" "}
                      {button().text}
                    </span>
                  </Link>
                );
              }}
            </Index>
            <Show when={user.can_import_clients}>
              <A href="/import" class="btn--import-contacts">
                <span class="icon">
                  <AddUser />
                </span>
                ייבוא לקוחות<br/> מקובץ EXCEL
              </A>
            </Show>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddUser = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      d="M6.375 1.25C4.31059 1.25 2.625 2.9356 2.625 5C2.625 7.0644 4.31059 8.75 6.375 8.75C8.43941 8.75 10.125 7.0644 10.125 5C10.125 2.9356 8.43941 1.25 6.375 1.25ZM6.375 2.375C7.83141 2.375 9 3.54359 9 5C9 6.45641 7.83141 7.625 6.375 7.625C4.91859 7.625 3.75 6.45641 3.75 5C3.75 3.54359 4.91859 2.375 6.375 2.375ZM13.125 9.5C10.8469 9.5 9 11.3469 9 13.625C9 15.9031 10.8469 17.75 13.125 17.75C15.4031 17.75 17.25 15.9031 17.25 13.625C17.25 11.3469 15.4031 9.5 13.125 9.5ZM2.3335 10.25C1.564 10.25 0.9375 10.9192 0.9375 11.7419V12.3125C0.9375 13.5429 1.57327 14.6121 2.72827 15.3228C3.6999 15.9209 4.995 16.25 6.375 16.25C7.19625 16.25 8.03682 16.1284 8.80444 15.8787C8.63194 15.5483 8.49902 15.1948 8.40527 14.8254C7.7689 15.0257 7.06388 15.125 6.375 15.125C4.29787 15.125 2.0625 14.2449 2.0625 12.3125V11.7419C2.0625 11.5428 2.18687 11.375 2.3335 11.375H8.80298C9.0186 10.9614 9.29206 10.5834 9.6123 10.25H2.3335ZM13.125 10.625C13.332 10.625 13.5 10.793 13.5 11V13.25H15.75C15.957 13.25 16.125 13.418 16.125 13.625C16.125 13.832 15.957 14 15.75 14H13.5V16.25C13.5 16.457 13.332 16.625 13.125 16.625C12.918 16.625 12.75 16.457 12.75 16.25V14H10.5C10.293 14 10.125 13.832 10.125 13.625C10.125 13.418 10.293 13.25 10.5 13.25H12.75V11C12.75 10.793 12.918 10.625 13.125 10.625Z"
      fill="white"
    />
  </svg>
);

const AddAsset = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
  >
    <g clip-path="url(#clip0_3508_7696)">
      <path
        d="M8.81189 13.9244C8.81189 11.6893 10.6239 9.87732 12.859 9.87732C15.094 9.87732 16.906 11.6893 16.906 13.9244C16.906 16.1595 15.094 17.9714 12.859 17.9714C10.6239 17.9714 8.81189 16.1595 8.81189 13.9244ZM13.3295 11.2891C13.3295 11.0293 13.1187 10.8185 12.859 10.8185C12.5992 10.8185 12.3884 11.0293 12.3884 11.2891V13.4538H10.2237C9.96386 13.4538 9.75307 13.6646 9.75307 13.9244C9.75307 14.1842 9.96386 14.395 10.2237 14.395H12.3884V16.5597C12.3884 16.8195 12.5992 17.0303 12.859 17.0303C13.1187 17.0303 13.3295 16.8195 13.3295 16.5597V14.395H15.4942C15.754 14.395 15.9648 14.1842 15.9648 13.9244C15.9648 13.6646 15.754 13.4538 15.4942 13.4538H13.3295V11.2891Z"
        fill="white"
        stroke="white"
        stroke-width="0.188235"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.42721 1.6216C7.52174 1.5469 7.63768 1.50439 7.75809 1.50027C7.8911 1.49614 8.02131 1.5391 8.12574 1.6216L13.4765 5.83704C14.1556 6.37224 14.5529 7.19006 14.5529 8.05469V9.33039C14.1943 9.19809 13.816 9.10659 13.4235 9.06151V8.05469C13.4235 7.5354 13.1861 7.04618 12.7779 6.72454L7.77647 2.7841L2.775 6.72454C2.36687 7.04618 2.12941 7.5354 2.12941 8.05469V15.0532H5.51765V11.4767C5.51765 10.7558 6.11437 10.1591 6.83529 10.1591H8.71765C8.99438 10.1591 9.2528 10.247 9.46621 10.3961C9.18849 10.6633 8.94226 10.963 8.73362 11.2891C8.72838 11.2887 8.72305 11.2885 8.71765 11.2885H6.83529C6.72422 11.2885 6.64706 11.3657 6.64706 11.4767V15.2414C6.64706 15.7548 6.21924 16.1826 5.70588 16.1826H1.94118C1.42782 16.1826 1 15.7548 1 15.2414V8.05469C1 7.19006 1.39736 6.37224 2.07647 5.83704L7.42721 1.6216Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3508_7696">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
