type Props = {
  justify?: "around" | "between" | "start" | "center";
  children;
  relative?: boolean;
  class?: string;
  gap?: number;
};

const FlexRow = (props: Props) => (
  <div
    class={`flex items-center ` + props.class }
    classList={{
      relative: props.relative,
      "justify-start": props.justify === "start",
      "justify-center": props.justify === "center",

      "justify-around": props.justify === "around",
      "justify-between": props.justify === "between",
      
    }}
  >
    {props.children}
  </div>
);

const FlexCol = (props: { class?: string; children: any }) => (
  <div class={`justify-center flex flex-col gap-${props.gap} ` + props.class}>
    {props.children}
  </div>
);

export { FlexRow, FlexCol };
