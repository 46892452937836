export default () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7062 13.7277C15.7031 12.5 14.9949 11.875 14.9949 8.49023C14.9949 5.39063 13.4121 4.28633 12.1093 3.75C11.9363 3.67891 11.7734 3.51562 11.7207 3.33789C11.4921 2.56016 10.8515 1.875 9.99995 1.875C9.14839 1.875 8.50737 2.56055 8.2812 3.33867C8.22846 3.51836 8.06557 3.67891 7.89253 3.75C6.58823 4.28711 5.00698 5.3875 5.00698 8.49023C5.00503 11.875 4.29682 12.5 3.2937 13.7277C2.87807 14.2363 3.24214 15 3.96909 15H16.0347C16.7578 15 17.1195 14.234 16.7062 13.7277Z"
      stroke="#234F68"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 15V15.625C12.5 16.288 12.2366 16.9239 11.7678 17.3928C11.2989 17.8616 10.663 18.125 10 18.125C9.33696 18.125 8.70107 17.8616 8.23223 17.3928C7.76339 16.9239 7.5 16.288 7.5 15.625V15"
      stroke="#234F68"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
