const UserLicenseIcon = (props: any) => {
	let circle = props.active ? '#234F68': '#F5F4F8';
	let icon = props.active ? '#fff' : '#BABABA';
	return (
		<svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="54.2441" y="53.5546" width="53.4848" height="53.4848" rx="26.7424" transform="rotate(-180 54.2441 53.5546)"
				fill={circle}
			/>
			<path
				d="M27.5044 17.1848C26.3407 17.1848 25.3002 17.4906 24.5336 18.0089C23.8957 18.4389 23.4472 19.0275 23.313 19.6963H17.1509C16.377 19.6963 15.7391 20.2636 15.7391 20.952V27.9893C15.7354 28.0155 15.7354 28.0417 15.7391 28.0678V35.1836C15.7391 35.872 16.377 36.4394 17.1509 36.4394H37.8578C38.6318 36.4394 39.2697 35.872 39.2697 35.1836V28.0286C39.2697 28.0155 39.2697 28.0024 39.2697 27.9893V20.952C39.2697 20.2636 38.6318 19.6963 37.8578 19.6963H31.6958C31.5616 19.0275 31.113 18.4389 30.4751 18.0089C29.7085 17.4906 28.668 17.1848 27.5044 17.1848ZM27.5044 18.022C28.4842 18.022 29.3225 18.2852 29.9016 18.676C30.3317 18.967 30.6203 19.3104 30.7398 19.6963H24.2689C24.3884 19.3104 24.677 18.967 25.1072 18.676C25.6863 18.2852 26.5245 18.022 27.5044 18.022ZM17.1509 20.5334H37.8578C38.1244 20.5334 38.3284 20.7149 38.3284 20.952V27.9893C38.3266 28.0073 38.3266 28.0237 38.3284 28.0417V28.0678C38.3284 28.3049 38.1244 28.4864 37.8578 28.4864H29.8574C29.8574 27.798 29.2195 27.2307 28.4456 27.2307H26.5632C25.7892 27.2307 25.1513 27.798 25.1513 28.4864H17.1509C16.8844 28.4864 16.6803 28.3049 16.6803 28.0678C16.684 28.0417 16.684 28.0155 16.6803 27.9893V20.952C16.6803 20.7149 16.8844 20.5334 17.1509 20.5334ZM26.5632 28.0678H28.4456C28.7122 28.0678 28.9162 28.2493 28.9162 28.4864V28.8265C28.9162 28.8396 28.9162 28.8527 28.9162 28.8657V28.905C28.9125 28.936 28.9125 28.9655 28.9162 28.9965V29.3235C28.9162 29.5606 28.7122 29.7421 28.4456 29.7421H26.5632C26.2966 29.7421 26.0925 29.5606 26.0925 29.3235V28.9835C26.1054 28.9279 26.1054 28.869 26.0925 28.8134V28.4864C26.0925 28.2493 26.2966 28.0678 26.5632 28.0678ZM16.6803 29.2451C16.8292 29.2925 16.9855 29.3235 17.1509 29.3235H25.1513C25.1513 30.0119 25.7892 30.5793 26.5632 30.5793H28.4456C29.2195 30.5793 29.8574 30.0119 29.8574 29.3235H37.8578C38.0233 29.3235 38.1795 29.2925 38.3284 29.2451V35.1836C38.3284 35.4207 38.1244 35.6022 37.8578 35.6022H17.1509C16.8844 35.6022 16.6803 35.4207 16.6803 35.1836V29.2451Z"
				fill={icon}
			/>
		</svg>
	)
}
export default UserLicenseIcon;