const translations:{[key: string]: string} = {
	"A user with this license number already exist": "משתמש בעל מספר רישיון התיווך הזה כבר קיים במערכת",
	"Wrong license number": "מספר רישיון תיווך שגוי",
	"No cities found": "לא נמצאה עיר מתאימה",
	"An error occurred while verifying license number": "שגיאה באימות רישיון התיווך",
	"Error response for verifying license number site": "שגיאה באתר אימות רישיון התיווך",
	"Wrong first or last name": "שגיאה בהזנת שם או שם משפחה",
	"An error occurred while creating the user": "שגיאה בהקמת המשתמש",
	"A user with this phone number already exists": "משתמש בעל מספר הטלפון הזה כבר קיים במערכת",
	"An error occurred while saving changes": "שגיאה בשמירת השינויים",
	"You can't import clients": "You can't import clients.",
	"You have maximum 3 city to choose": "יש לך מקסימום 3 ערים לבחירה",
}

const TranslateError = (error:string) => {
	const last_char = error.slice(-1)
	const remove_symbols = [',', '.', '!', '?'];
	if (remove_symbols.includes(last_char)) {
		error = error.slice(0, -1)
	}
	error = error.trim()
	if (translations[error]) {
		return translations[error]
	}
	return error;
}
export default TranslateError;