export const CheckedIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_28_803)">
      <rect
        x="0.5"
        y="1.10645"
        width="23"
        height="23"
        rx="1.5"
        fill="#F5F4F8"
        stroke="#234F68"
      />
      <path
        d="M20.293 5.89941L9 17.1924L4.70703 12.8994L3.29297 14.3135L9 20.0205L21.707 7.31348L20.293 5.89941Z"
        fill="#234F68"
      />
    </g>
    <defs>
      <clipPath id="clip0_28_803">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.606445)"
        />
      </clipPath>
    </defs>
  </svg>
);
