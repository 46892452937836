import { JSX, Show } from "solid-js";
import AddIcon from "../Icons/PlusIcon";
type Props = {
  submit?: boolean;
  children: JSX.Element;
  disabled?: boolean;
  color?: "blue" | "green" | "red";
  icon?: boolean;
  short?: boolean;
  onClick?: JSX.EventHandlerUnion<HTMLButtonElement, MouseEvent>;
  border?: boolean;
  fullWidth?: boolean;
  class?: string;
  id?: string;
  name: string;
};
const Button = (props: Props) => {
  return (
    <button
      name={props.name}
      aria-label={props.name}
      id={props.id}
      type={props.submit ? "submit" : null}
      onclick={props.onClick}
      disabled={props.disabled}
      class={
        "border border-darkBlue m-auto px-3 disabled:bg-buttonDisabled  transition-all  hover:shadow-buttonHoverGreen disabled:shadow-buttonDisabled text-s  text-center flex items-center justify-center rounded-full " +
        props.class
      }
      classList={{
        border: props.border,
        "border-darkBlue": props.border,
        "border-solid": props.border,
        "h-16": !props.short,
        "h-10": props.short,
        "bg-green": props.color === "green",
        "bg-error": props.color === "red",

        "w-full": props.fullWidth,
        "text-white": Boolean(props.color),
        "bg-darkBlue": props.color === "blue",
      }}
    >
      {props.children}

      <Show when={props.icon}>
        <AddIcon />
      </Show>
    </button>
  );
};

export default Button;
