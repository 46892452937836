import axios from 'axios'
import shareDecodedUrl from '../utilities/decodeShareUrl';
import { setToastr } from '../globalState';
import { RealtorHomeAsset } from '../types';
import phoneValidation from '../helpers/PhoneValidation';
interface ISendWhatsupMessages {
    receivers: Array<string>;
    receiversPhone: Array<string>;
    text: string | null;
    asset_id?: Array<string | undefined> | undefined;
    agent_phone?: any;
    assets?: Array<RealtorHomeAsset>;
}


export const WhatsupSendMessages = async (data: ISendWhatsupMessages, scheduleMessage:string) => {
    let send_data:any = {
        // receivers: data.receivers,
        text: data.text
    }
    if (data.receivers.length) {
        send_data.receivers = data.receivers
    }
    if (data.receiversPhone && data.receiversPhone.length) {
        let arr:Array<string> = [];
        data.receiversPhone.forEach((phone:string) => {
            arr.push(phoneValidation(phone.replaceAll(/[^a-zA-Zа-яА-Я0-9\s]/g, '')))
        })
        send_data.phone_numbers = arr
    }
    let asset_urls:any = []
    if (data.assets && data.agent_phone) {
        data.assets.forEach((asset:any) => {
            const url = shareDecodedUrl(asset, data.agent_phone)
            asset_urls.push(url)
        })
    }
    send_data.asset_urls = asset_urls
    let response;
    let url = 'whatsapp/client/messages/';
    if (scheduleMessage && scheduleMessage.length) {
        url = 'whatsapp/client/scheduled_messages/'
        let start_time = new Date();
        start_time.setHours(0, 0, 0, 0)
        let time:any;
        let send_at:any;
        if (scheduleMessage === "week") {
            time = start_time.setDate(new Date().getDate() + 7);
        }
        if (scheduleMessage === "month") {
            time = start_time.setMonth(new Date().getMonth() + 1);
        }
        if (scheduleMessage === "3month") {
            time = start_time.setMonth(new Date().getMonth() + 3);
        }
        if (time) {
            send_at = new Date(time).toISOString()
        }
        send_data.send_at = send_at
    }
    const request = await axios.post(url, send_data)
        .then(resp => {
            if (resp.status == 200) {
                response = true
                setToastr({
                    status: 'success',
                    show: true,
                    text: 'ההודעה נשלחה בהצלחה'
                })
            } else {
                response = false
                setToastr({
                    status: 'error',
                    show: true,
                    text: 'Server error!'
                })
            }
        })
        .catch(err => {
            console.log(err)
            response = false
            setToastr({
                status: 'error',
                show: true,
                text: 'Server error!'
            })
        })
    await request
    return response
}



export const WhatsupCheckClient = async () => {
    let response:any;
    await axios.get('whatsapp/client/')
        .then(resp => {
            if (resp.status == 200) {
                response = resp.data
            } else {
                setToastr({
                    status: 'error',
                    text: 'Server error!'
                })
            }
        })
        .catch(err => {
            console.log(err)
            setToastr({
                status: 'error',
                text: 'Server error!'
            })
        })
    return response;
}




export const WhatsupGetQrCode = async () => {
    let response:any;
    await axios.get('whatsapp/client/qr_code/')
        .then(resp => {
            if (resp.status == 200) {
                response = resp.data
            } else {
                setToastr({
                    status: 'error',
                    text: 'Server error!'
                })
            }
        })
        .catch(err => {
            console.log(err)
            setToastr({
                status: 'error',
                text: 'Server error!'
            })
        })
    return response
}


