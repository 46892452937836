import { For, Show, createEffect, createSignal, onCleanup, onMount } from 'solid-js'
import './Search.sass'
import { A, useLocation, useMatch, useNavigate, useSearchParams } from '@solidjs/router'
import { isSmall } from '../../Home/isSmall'
import LoaderIcon from '../../../Components/LoaderIcon'
import _ from "lodash";
import axios from 'axios'
import { setMobileNavbarVisible, setToastr } from '../../../globalState'

interface IProps {
    
}
const AssetsSearch = (props: IProps) => {
    let ref: HTMLFormElement;
    const handleClick = (event: any) => {
        if(!ref.contains(event.target)) {
            setShowResults(false);
        };
    };
    onMount(() => {
        if (ref) {
            document.addEventListener('click', handleClick);
        }
        let params = new URLSearchParams(document.location.search);
        if (params && params.get('search')) {
            setSearch(params.get('search'))
        }
    })
    onCleanup(() => {
        document.removeEventListener('click', handleClick);
    })

    const [loading, setLoading]:any = createSignal(false)
    const [search, setSearch]:any = createSignal('')
    const [mobileView, setMobileView]:any = createSignal(false)
    const [showResults, setShowResults]:any = createSignal(false)

    const [results, setResults]:any = createSignal([])

    const [warning, setWarning]:any = createSignal('לא נמצאו תוצאות')


    const getSearchResults = _.debounce( async () => {
        setLoading(true)
        setShowResults(true)
		const request = await axios.get(`/real-estate/assets/autocomplete/?search=${search()}`)
        if (request.status === 200 && request.data) {
            setResults(request.data)
        } else {
            setToastr({
                status: 'error',
                show: true,
                text: 'Server error'
            })
        }
        setLoading(false)
	}, 300)

    const getResultTitle = (result: any) => {
        const street = result.street;
        const neighborhood = result.neighborhood
        const city = result.city
        let array = []
        if (street) array.push(street)
        if (neighborhood) array.push(neighborhood)
        if (city && city.id && city.name) array.push(city.name)
        let string = array.join(', ')
        let regex = new RegExp(`(${search()})`, 'gi');
        string = string.replace(regex, `<span>$1</span>`);
        return string;
    }

    const location = useLocation()
    const matchAssets = useMatch(() => 'assets')
    const matchSearch = useMatch(() => 'search-assets')
    const [showSearch, setShowSearch] = createSignal(false)
    createEffect(() => {
        if (location.search.includes('all%20assets') ) {
            setShowSearch(true)
        } else {
            setShowSearch(false)
        }
    })
    
    const navigate = useNavigate()
    return (
        <Show when={(Boolean(matchAssets()) && showSearch()) || Boolean(matchSearch())}>
            <div
                class="assets-search"
                classList={{'open': mobileView()}}
            >
                <Show when={isSmall()}>
                    <div
                        class="assets-search--form"
                    >
                        <input
                            type="search"
                            class="input--search"
                            value={search()}
                            placeholder='לחיפוש נכס הקלד שם רחוב'
                            onClick={(e:any) => {
                                setMobileView(true)
                                setMobileNavbarVisible(false)
                                const mobile_input:any = document.querySelector('.input-search-mobile')
                                if (mobile_input) {
                                    mobile_input.focus()
                                }
                            } }
                        />
                        <button
                            type="submit"
                            class="btn--submit"
                            onClick={(e:any) => {
                                setMobileView(true)
                                setMobileNavbarVisible(false)
                                const mobile_input:any = document.querySelector('.input-search-mobile')
                                if (mobile_input) {
                                    mobile_input.focus()
                                }
                            } }
                        >
                            <SearchIcon />
                        </button>
                    </div>
                </Show>
                <div class="assets-search--container">
                    <form
                        class="assets-search--form"
                        ref={ref!}
                        onSubmit={(e:any) => {
                            e.preventDefault()
                            // navigate(`/search-assets?search=${search()}`)
                            setShowResults(false)
                            let url = `/search-assets?search=${search()}&show_all=1`
                            if (results() && results().length) {
                                url = url + '&city_id=' + results()[0].city.id
                            }
                            navigate(url)
                        }}
                    >
                        <div class="form--container">
                            <button
                                type="button"
                                class="btn--close"
                                onClick={() => {
                                    setMobileView(false)
                                    setMobileNavbarVisible(true)
                                }}
                            >
                                <ChevronRight />
                            </button>
                            <input
                                type="search"
                                class="input--search input-search-mobile"
                                value={search()}
                                placeholder="לחיפוש נכס הקלד שם רחוב"
                                onFocusIn={(e:any) => {
                                    if (search().length) {
                                        if (results.length) {
                                            setShowResults(true)
                                        } else {
                                            getSearchResults()
                                        }
                                    }
                                }}
                                onkeyup={(e:any) => {
                                    if (e.key !== 'Enter') {
                                        setSearch(e.target.value)
                                        if (search().length) {
                                            setShowResults(true)
                                        } else {
                                            setShowResults(false)
                                            setResults([])
                                        }
                                        if (search().length < 2) {
                                            setWarning('אנא הקלד/י לפחות 2 תווים להתחלת החיפוש')
                                            setResults([])
                                        } else {
                                            getSearchResults()
                                            setWarning('לא נמצאו תוצאות')
                                        }
                                    }
                                }}
                            />
                            <button type="submit" class="btn--submit">
                                <SearchIcon />
                            </button>
                        </div>
                    </form>
                    <div
                        class="assets-search--results"
                        classList={{'open': showResults()}}
                    >
                        <span
                            class="st-loading"
                            classList={{'open': loading()}}
                        >
                            <LoaderIcon
                                color="#234F68"
                                maxWidth='26'
                                maxHeight='26'
                            />
                        </span>
                        <Show
                            when={results().length < 1 && !loading()}
                        >
                            <p class="st-empty">{warning()}</p>
                        </Show>
                        <Show
                            when={results().length && !loading() && search().length > 1}
                        >
                            <For
                                each={results()}
                            >
                                {(result) => (
                                    <A
                                        href={`/search-assets?search=${search()}&city_id=${result.city.id}`}
                                        class="assets-search--results-item"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setMobileView(false)
                                            setMobileNavbarVisible(true)
                                            navigate(`/search-assets?search=${search()}&city_id=${result.city.id}`)
                                        }}
                                    >
                                        <p class="st-title" innerHTML={getResultTitle(result)}>
                                        </p>
                                        <span class="st-count">
                                            {result.count}
                                        </span>
                                    </A>
                                )}
                            </For>
                            <A
                                href={`/search-assets?search=${search()}`}
                                class="show-more"
                                onClick={(e) => {
                                    e.preventDefault()
                                    setMobileView(false)
                                    setMobileNavbarVisible(true)
                                    let url = `/search-assets?search=${search()}&show_all=1`
                                    if (results() && results().length) {
                                        url = url + '&city_id=' + results()[0].city.id
                                    }
                                    navigate(url)
                                }}
                            >
                                הצג הכל 
                            </A>
                        </Show>
                    </div>
                </div>
            </div>
        </Show>
    )
}


export default AssetsSearch


const SearchIcon = () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.91992 9.59994C1.91992 5.08794 5.56792 1.43994 10.0799 1.43994C14.5919 1.43994 18.2399 5.08794 18.2399 9.59994C18.2399 11.4585 17.621 13.1704 16.5776 14.5412L22.3782 20.3417L20.3417 22.3782L14.4553 16.4918C13.1914 17.295 11.6907 17.7599 10.0799 17.7599C5.56792 17.7599 1.91992 14.1119 1.91992 9.59994ZM3.83992 9.59994C3.83992 13.0559 6.62392 15.8399 10.0799 15.8399C13.5359 15.8399 16.3199 13.0559 16.3199 9.59994C16.3199 6.14394 13.5359 3.35994 10.0799 3.35994C6.62392 3.35994 3.83992 6.14394 3.83992 9.59994Z" fill="#234F68"/>
</svg>
)
const ChevronRight = () => (
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 6.7666L8.5 8.2666L13.0703 12.8369L8.5 17.4072L10 18.9072L16.0703 12.8369L10 6.7666Z" fill="#2B2B2B"/>
</svg>
)