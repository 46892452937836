import { Show, createEffect, createSignal } from 'solid-js';
import Logo from '../logo.png'
import { createEventDispatcher } from '@solid-primitives/event-dispatcher';
import axios from 'axios';
import TranslateError from '../../../helpers/TranslateError';

interface IProps {
	file: any;
	onChangeStatus: (evt:CustomEvent<any>) => void;
	onChangeStep: (evt:CustomEvent<any>) => void;
	onUploadErrorFile: (evt:CustomEvent<any>) => void;
	onDeleteFile: (evt:CustomEvent<any>) => void;
}

const FilesList = (props: IProps) => {
	const dispatch = createEventDispatcher(props);
	const [error, setError] = createSignal('');
	const [checkStatus, setCheckStatus] = createSignal(true);
	const [stringErrors, setStringErrors] = createSignal([]);
	const [totalRows, setTotalRows] = createSignal(0);
	const [wrongRows, setWrongRows] = createSignal(0);
	const [step, setStep] = createSignal('success');

	const clearComponent = () => {
		setStep('success');
		setTotalRows(0);
		setWrongRows(0);
		setCheckStatus(true);
		setStringErrors([]);
		setError('')
	}

	createEffect(() => {
		if (!props.file) {
			dispatch('changeStep', 'first')
		}
	})
	const sentFile = async () => {
		let formData = new FormData()
		formData.set("file", props.file);
		if (checkStatus()) {
			formData.set('is_check', 'true')
		}
		const request = await axios.post('/real-estate/customer/import-excel-customers/', formData)
			.then(resp => {
				console.log(resp.data)
				if(resp.data == 'OK') {
					console.log('OK')
					dispatch('changeStatus', true)
					dispatch('changeStep', 'third')
					clearComponent()
				} else if (resp.data.wrong_rows.length) {
					setTotalRows(Number(resp.data.total_rows))
					setWrongRows(resp.data.wrong_rows.length)
					setStringErrors(resp.data.wrong_rows)
					setStep('error')
				} else if(!resp.data.wrong_rows.length) {
					setCheckStatus(false)
					sentFile()
				} else {
					dispatch('changeStatus', false)
					dispatch('changeStep', 'third')
				}
			})
			.catch(err => {
				if (err.response.status === 400) {
					setError(TranslateError(err.response.data))
				} else {
					setError(err.response)
				}
			})
	}


	return (
		<div class="single-import_tab">
			<div class="single-import_tab--container">
				<div class="single-import_tab--head">
					<p class="st-logo">
						<img src={Logo} alt="" />
					</p>
					<Show when={step() === 'success'}>
						<h2 class="st-title">
							הקובץ עלה בהצלחה!
						</h2>
					</Show>
					<Show when={step() === 'error'}>
						<h2 class="st-title">
							הצלחנו להעלות {totalRows() - wrongRows()}/{totalRows()} לקוחות בהצלחה!
						</h2>
					</Show>
					<p class="st-text">
						באישור הסנכרון יתבצע
					</p>
				</div>
				<div class="single-import_tab--body">
					<Show when={props.file}>
						<div class="files--list">
							<div class="single-file">
								<span class="icon">
									<ExcelIcon />
								</span>
								<span class="st-title">
									{props.file.name}
								</span>
								{/* <a href="" class="st-preview">
									<EyeIcon />
								</a> */}
								<button
									class="btn--delete"
									onClick={() => {
										dispatch('deleteFile', props.file)
										clearComponent()
									}}
								>
									<CloseIcon />
								</button>
							</div>
						</div>
					</Show>
					<p class="st-quote">
						<span class="icon">
							<InfoIcon/>
						</span>
						<b>שימו לב!</b>
						<br/>לקוחות עם העדפות חיפוש מהשנה האחרונה נכנסו למאגר לקוחות פעילים 
						<br/>את שאר הלקוחות תמצאו תחת ‘לקוחות פוטנציאליים’
						בהצלחה!<br/>
					</p>
					<Show when={stringErrors().length}>
						<p class="st-quote error">
							<b>
								הלקוחות שלא הצלחנו להעלות (לפי המספר הסידורי בקובץ):
							</b>
							<br/>
							<br />
							{ stringErrors().join(', ') }
						</p>
					</Show>
				</div>
				<Show when={error().length}>
					<div class="text-error text-center h-4" style="height: auto; margin-bottom: 15px;">
						{error()}
					</div>
				</Show>
				<div class="single-import_tab--actions">
					<Show when={step() === 'success'}>
						<button
							class="btn--next"
							onClick={sentFile}
						>
							אישור
						</button>
					</Show>
					<Show when={step() === 'error'}>
						<button
							class="btn--check"
							onClick={() => {
								dispatch('changeStep', 'first')
								clearComponent()
							}}
						>
							אני רוצה להעלות את הקובץ המתוקן
						</button>
						<button
							class="btn--next"
							onClick={() => {
								setCheckStatus(false)
								sentFile()
							}}
						>
							אני רוצה להמשיך!
						</button>
					</Show>
					<button
						class="btn--prev"
						onClick={() => {
							dispatch('changeStep', 'first')
							clearComponent()
						}}
					>
						ביטול
					</button>
				</div>
			</div>
		</div>
	)
}

export default FilesList


const InfoIcon = () => (
<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M8.125 0C3.64502 0 0 3.64502 0 8.125C0 12.605 3.64502 16.25 8.125 16.25C12.605 16.25 16.25 12.605 16.25 8.125C16.25 3.64502 12.605 0 8.125 0ZM8.125 1.25C11.9287 1.25 15 4.32129 15 8.125C15 11.9287 11.9287 15 8.125 15C4.32129 15 1.25 11.9287 1.25 8.125C1.25 4.32129 4.32129 1.25 8.125 1.25Z" fill="#234F68"/>
	<path d="M6 6.11133C6.052 4.93777 6.71294 4 8.02131 4C9.19403 4 10 4.84285 10 5.96206C10 6.81463 9.6276 7.41923 9.03196 7.83788C8.44178 8.24506 8.26634 8.55839 8.26634 9.12989V9.47735H7.4581V8.98062C7.45264 8.26306 7.74643 7.77452 8.38352 7.3287C8.92352 6.9419 9.13849 6.59393 9.13849 6.01182C9.13849 5.338 8.68353 4.8442 7.98935 4.8442C7.28716 4.8442 6.84813 5.33518 6.79759 6.11133H6Z" fill="#234F68"/>
	<path d="M7.27628 11.242C7.27628 10.7962 7.54906 10.4841 7.92543 10.4841C8.30179 10.4841 8.56428 10.7962 8.56392 11.242C8.56392 11.6815 8.30324 12 7.92543 12C7.54761 12 7.27628 11.6815 7.27628 11.242Z" fill="#234F68"/>
</svg>
)

const ExcelIcon = () => (
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M25.625 6.25H15.625V23.75H25.625C25.9706 23.75 26.25 23.4706 26.25 23.125V6.875C26.25 6.52938 25.9706 6.25 25.625 6.25Z" fill="#4CAF50"/>
	<path d="M20 9.375H24.375V11.25H20V9.375ZM20 15.625H24.375V17.5H20V15.625ZM20 18.75H24.375V20.625H20V18.75ZM20 12.5H24.375V14.375H20V12.5ZM15.625 9.375H18.75V11.25H15.625V9.375ZM15.625 15.625H18.75V17.5H15.625V15.625ZM15.625 18.75H18.75V20.625H15.625V18.75ZM15.625 12.5H18.75V14.375H15.625V12.5Z" fill="white"/>
	<path d="M16.875 26.25L3.75 23.75V6.25L16.875 3.75V26.25Z" fill="#2E7D32"/>
	<path d="M11.9548 19.375L10.448 16.5244C10.3905 16.4175 10.3317 16.2225 10.2705 15.9381H10.2473C10.2186 16.0725 10.1511 16.2762 10.0448 16.55L8.53172 19.375H6.18359L8.97234 14.9994L6.42047 10.625H8.81859L10.0692 13.2475C10.1667 13.4544 10.2542 13.7006 10.3317 13.9844H10.3567C10.4055 13.815 10.4967 13.5594 10.6311 13.2219L12.0223 10.625H14.2192L11.5948 14.9619L14.2923 19.3737H11.9548V19.375Z" fill="white"/>
</svg>
)
const EyeIcon = () => (
<svg width="30" height="17" viewBox="0 0 30 17" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M14.8776 0C6.61172 0 0.152578 7.65312 0.152578 7.65312C-0.0508594 7.88562 -0.0508594 8.23437 0.152578 8.46687C0.152578 8.46687 6.61172 16.12 14.8776 16.12C23.1434 16.12 29.6026 8.46687 29.6026 8.46687C29.806 8.23437 29.806 7.88562 29.6026 7.65312C29.6026 7.65312 23.1434 0 14.8776 0ZM14.8776 1.24C16.5075 1.24 18.0648 1.57664 19.5082 2.0925C20.477 3.18719 21.0776 4.61852 21.0776 6.2C21.0776 9.6318 18.3094 12.4 14.8776 12.4C11.4458 12.4 8.67758 9.6318 8.67758 6.2C8.67758 4.61852 9.25883 3.18719 10.2276 2.0925C11.6759 1.57422 13.2428 1.24 14.8776 1.24ZM8.17383 3.00312C7.70883 3.97187 7.43758 5.05687 7.43758 6.2C7.43758 10.3027 10.7749 13.64 14.8776 13.64C18.9802 13.64 22.3176 10.3027 22.3176 6.2C22.3176 5.06172 22.0584 3.97187 21.6007 3.00312C25.1027 4.7832 27.6215 7.36492 28.2657 8.06C27.2582 9.14984 21.6806 14.88 14.8776 14.88C8.07453 14.88 2.49695 9.14984 1.48945 8.06C2.13367 7.3625 4.66453 4.7832 8.17383 3.00312ZM14.8776 3.1C13.1653 3.1 11.7776 4.48773 11.7776 6.2C11.7776 7.91227 13.1653 9.3 14.8776 9.3C16.5898 9.3 17.9776 7.91227 17.9776 6.2C17.9776 4.48773 16.5898 3.1 14.8776 3.1Z" fill="#234F68"/>
</svg>
)
const CloseIcon = () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M3.70562 21.0006L3.01562 20.3106L11.3106 12.0156L3.01562 3.72061L3.70562 3.03061L12.0006 11.3256L20.2956 3.03061L20.9856 3.72061L12.6906 12.0156L20.9856 20.3106L20.2956 21.0006L12.0006 12.7056L3.70562 21.0006Z" fill="#234F68"/>
</svg>
)