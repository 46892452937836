import { Show, createSignal, from, onMount } from "solid-js";
import { createStore } from "solid-js/store";
import './Registration.sass';
import HouseImage from './house-image.svg';
import PhoneStep from "./Steps/Phone";
import VerificationStep from "./Steps/2FA";
import UserDataStep from "./Steps/UserData";
import CompanyData from "./Steps/CompanyData";
import RegionData from "./Steps/RegionData";
import SuccessScreen from "./Steps/SuccessScreen";
import { setLoggedIn, setUser, user } from "../../globalState";
import { createEffect } from "solid-js";
import { useLocation, useNavigate, useSearchParams } from "@solidjs/router";
import axios from "axios";
import TranslateError from "../../helpers/TranslateError";
import { HomeRealtor } from "../../types";
import { logEvent } from "../../analytics";
import AgentsList from "./Steps/AgentsList";



const Registration = () => {
	const navigate = useNavigate();
	const [params, setParams] = useSearchParams();
	const [knownAgency, setKnownAgency] = createSignal(false);
	const [agencyData, setAgencyData]:any = createStore({
		agency_name: null,
		agency_id: null
	})
	if (params.agency_id && params.agency_name) {
		setKnownAgency(true)
		setAgencyData({
			agency_name: params.agency_name,
			agency_id: params.agency_id
		})
	}
	const location:any = useLocation();
	createEffect(() => {
		if ( user && user.id) {
			navigate("/");
		}
		if (location.state) {
			setForm({
				phone: location.state.phone,
				accessToken: location.state.token
			})
			setStep(location.state.step)
			navigate('/registration?step=' + location.state.step)
		}

		const [stepParams, setstepParams] = useSearchParams();
		if (stepParams && stepParams.step) {
			setStep(stepParams.step)
		}
	});

	const [step, setStep] = createSignal("phone");
	setStep('phone');
	navigate('/registration?step=phone')
	const [error, setError] = createSignal("");

	const [form, setForm]:any = createStore({
		phone: "",
		accessToken: "",
		first_name: "",
		last_name: "",
		facebook: "",
		instagram: "",
		license_number: null,
		office_name: "",
		office_id: null,
		is_agency_manager: false,
		activity_areas: [],
		user_id: null,
		about: ""
	})


	let setPhone = (evt :CustomEvent<any>) => {
		setForm({phone: evt.detail.phone})
		setStep('2fa')
		navigate('/registration?step=2fa')
	}
	const setToken = (data: any) => {
		setForm({accessToken: data.detail})
		setStep('user_data')
		navigate('/registration?step=user_data')
	}
	const setUserData = (data:any) => {
		setForm({
			first_name: data.detail.first_name,
			last_name: data.detail.last_name,
			license_number: data.detail.license_number,
			user_id: data.detail.user_id
		})
		setStep('company_data')
		navigate('/registration?step=company_data')
	}
	const setCompanyData = (data:any) => {
		setForm({
			facebook: data.detail.facebook_url,
			instagram: data.detail.instagram_url,
			office_name: data.detail.company_name,
			is_agency_manager: true ? data.detail.role === 'true' : false,
		})
		setStep('region_data')
		navigate('/registration?step=region_data')
	}
	const setRegionData = (evt :CustomEvent<any>) => {
		setForm({
			activity_areas: evt.detail.cities,
			about: evt.detail.about
		})
		createUserBusinessCard()
	}
	const changeStep = (evt :CustomEvent<string>) => {
		setStep(evt.detail)
		navigate('/registration?step=' + evt.detail)
	}


	const createUserBusinessCard = async () => {
		let data = {...form}
		if (knownAgency()) {
			data.office_id = Number(agencyData.agency_id)
			delete data.office_name
		} else {
			delete data.office_id
		}

		const request = await axios.post('/rentals/realtors/completed-business-card/', data, {
			headers: {
				Authorization: form.accessToken
			}
		})
			.then((resp:any) => {
				if (resp.status === 200) {
					if (form.is_agency_manager) {
						setStep('agents_list')
						navigate('/registration?step=agents_list')
					} else {
						loginUser()
					}
					setError("")
				} else {
					setError(TranslateError(resp.response.data))
				}
			})
			.catch(err => {
				setError(TranslateError(err.response.data))
			})
		logEvent('form_submit', {
			user_id: form.user_id,
			agent_type: 'agency_manager' ? form.is_agency_manager : 'agent',
			agent_name: `${form.first_name} ${form.last_name}`,
			office_name: form.office_name,
			activity_areas: form.activity_areas
		})
	}
	const loginUser = async () => {
		try {
			axios.defaults.headers.common.Authorization = form.accessToken;
			const request = await axios.get('/access-control/users/home/?login=true')
			const userData: HomeRealtor = request.data
			setUser(userData)
			navigate("/", { replace: true });
		} catch(err) {
			console.log(err)
			setError('Unexpected error!')
		}
	}

	return (
		<div class="registration--container">
			<div class="registration--content">
				<div
					class="registration--content-container"
					classList={{'v-fa' : step() === '2fa'}}
				>
					<Show when={step() === 'phone'}>
						<PhoneStep
							onSetPhone={setPhone}
							phone={form.phone}
							ref={setPhone}
						/>
					</Show>
					<Show when={step() === '2fa'}>
						<VerificationStep
							onSetStep={changeStep}
							onSetToken={setToken}
							phone={form.phone}
						/>
					</Show>
					<Show when={step() === 'user_data'}>
						<UserDataStep
							phone={form.phone}
							token={form.accessToken}
							onSetUserData={setUserData}
						/>
					</Show>
					<Show when={step() === 'company_data'}>
						<CompanyData
							token={form.accessToken}
							knownAgency={knownAgency()}
							agencyData={agencyData}
							onSetCompanyData={setCompanyData}
						/>
					</Show>
					<Show when={step() === 'region_data'}>
						<RegionData
							token={form.accessToken}
							onSetRegionData={setRegionData}
						/>
					</Show>
					<Show when={step() === 'success_screen'}>
						<SuccessScreen
							userId={form.user_id}
						/>
					</Show>
					<Show when={step() === 'agents_list'}>
						<AgentsList
							onSendAgents={loginUser}
							token={form.accessToken}
						/>
					</Show>
					<Show when={error().length}>
						<div class="text-error text-center h-4">
							{error()}
						</div>
					</Show>
				</div>
			</div>
			<div class="registration--image">
				<img src={HouseImage} alt="registration" />
			</div>
		</div>
	);
}

export default Registration;