import { Show } from "solid-js";
import { MyAssetHeader, ColleagueAssetHeader } from "./ViewAsset";

export function Header({
  close,
  myAsset,
  asset,
  setVisibilityModal,
  shareWhatsapp,
  setStatusModal,
  statusModal,
  setDealModal,
  dealModal,
  visibilityModal,
  setOpenedModal,
}) {
  return (
    <>
      <Show when={myAsset()}>
        <MyAssetHeader
          setOpenedModal={setOpenedModal}
          close={close}
          asset={asset}
          setVisibilityModal={setVisibilityModal}
          shareWhatsapp={shareWhatsapp}
          setStatusModal={setStatusModal}
          statusModal={statusModal}
          setDealModal={setDealModal}
          dealModal={dealModal}
          visibilityModal={visibilityModal}
        />
      </Show>
      <Show when={!myAsset()}>
        <ColleagueAssetHeader
          close={close}
          asset={asset}
          setVisibilityModal={setVisibilityModal}
          shareWhatsapp={shareWhatsapp}
          setStatusModal={setStatusModal}
          statusModal={statusModal}
          setDealModal={setDealModal}
          dealModal={dealModal}
          visibilityModal={visibilityModal}
        />
      </Show>
    </>
  );
}
