import { JSX, createSignal } from "solid-js";
import fallbackHouse from "../Icons/FallbackHouse.svg";

type Props = {
  class?: string;
  alt?: string;
  img: string;
  onImageClick: JSX.EventHandlerUnion<HTMLButtonElement, MouseEvent>;
};

const ImageCard = (props: Props) => {
  const [fallbackImg, setFallbackImg] = createSignal(false);
  return (
    <button
      class={"h-24 w-24 m-auto"}
      disabled={fallbackImg()}
      onclick={props.onImageClick}
    >
      <img
        class={"h-24 rounded-md"}
        src={fallbackImg() ? fallbackHouse : props.img || fallbackHouse}
        onerror={() => setFallbackImg(true)}
        alt={props.alt ? props.alt : "a picture of the asset"}
      />
    </button>
  );
};

export const Img = (props: Props) => {
  const [fallbackImg, setFallbackImg] = createSignal(false);
  return (
    <img
      class={props.class}
      src={fallbackImg() ? fallbackHouse : props.img || fallbackHouse}
      onerror={() => setFallbackImg(true)}
      alt={props.alt ? props.alt : "a picture of the asset"}
    />
  );
};

export default ImageCard;
