import axios from "axios";
import { createSignal } from "solid-js";
import Button from "../../Components/Button";
import Heading, { Colors } from "../../Components/Heading";
import { Modal } from "../../Components/Modal";
import Spacer from "../../Components/Spacer";

export function NewNoteModal(props) {
  const [note, setNote] = createSignal("");

  return (
    <Modal
      classList={{ "md:max-w-md": true }}
      open
      onClose={() => {
        props.setNewNoteModal(false);
      }}
    >
      <div class="p-4">
        <Heading>הוסף הערה חדשה</Heading>
        <Spacer />
        <Heading side="right" color={Colors.secondary}>
          כתוב הערה
        </Heading>
        <textarea
          onchange={(e) => setNote(e.target.value)}
          class="m-auto w-full rounded-lg bg-lightGrey p-4 text-blue"
          name="newNote"
          cols="30"
          rows="10"
        />
        <Spacer />
        <Button
          color="green"
          class="w-8/12"
          onClick={async () => {
            await axios.post(`/real-estate/customer/${props.id}/add_note/`, {
              content: note(),
            });
            props.refetch();
            setNote("");
            props.setNewNoteModal(false);
          }}
        >
          אישור
        </Button>
      </div>
    </Modal>
  );
}
