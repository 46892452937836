import WhatsupIcon from "../../Pages/Assets/Customers/ModalIcon";
// import video from './whatsapp-video.gif'
import LoaderIcon from "../LoaderIcon";

import { Setter, Show } from "solid-js";
interface IProps {
    show: boolean;
    toggle: Setter<boolean> | Function;
    setQrCodeStatus: Setter<boolean>;
    getCode: Function;
    loading: boolean;
}


const WhatsupModalGuid = (props:IProps) => {
    return (
        <div
            class={props.show ? 'open c-modal' : 'c-modal'}
        >
            <div
                class="c-modal--overlay"
                onClick={(e) => props.toggle(false)}
            ></div>
            <div class="c-modal--container">
                <div class="c-modal--content whatsup-send">
                    <div class="c-modal--close">
                        <button
                            class="btn--close"
                            onClick={(e) => props.toggle(false)}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    <div class="c-modal--title whatsup-send">
                        <span class="st-icon">
                            <WhatsupIcon />
                        </span>
                        <h3 class="st-title">
                            קישור ווצאפ לגשר!
                        </h3>
                        <p class="st-text">
                            יש לשים לב- פעולה זו עלולה לקחת מספר דקות. <br/>ניתן להמשיך לעבוד עם המערכת, נציג לכם את הקוד בהקדם.
                        </p>
                        <p class="st-text">
                            יש לעקוב אחר ההוראות על מנת לקשר אותך לווצאפ האישי שלך.<br/>לאחר החיבור הראשון שלך, פעולה זו לא תידרש בשנית.
                        </p>
                    </div>
                    <div class="c-modal--whatsapp_guid">
                        {/* <img src={video} alt="" /> */}
                        <ul class="st-list">
                            <li>
                                כניסה לווצאפ דרך הנייד
                            </li>
                            <li>
                                הגדרות
                            </li>
                            <li>
                                ליד השם שלך יופיע סימן של ברקוד- יש ללחוץ עליו
                            </li>
                        </ul>
                    </div>
                    <div class="c-modal--form">
                        <div class="submit--container">
                            <button
                                class="btn--submit"
                                style="margin-top: 0"
                                onClick={async (e:any) => {
                                    await props.getCode(true)
                                    props.toggle(false)
                                    props.setQrCodeStatus(true)
                                }}
                                disabled={props.loading}
                            >
                                אני רוצה לקבל קוד QR!
                                <span class="icon">
                                    <Show when={!props.loading} fallback={<LoaderIcon maxWidth='30' maxHeight='30' color='#fff'/>}>
                                        <SubmitIcon />
                                    </Show>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhatsupModalGuid


const CloseIcon = () => (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 0C8.06833 0 0 8.06833 0 18C0 27.9317 8.06833 36 18 36C27.9317 36 36 27.9317 36 18C36 8.06833 27.9317 0 18 0ZM18 1.56522C27.0857 1.56522 34.4348 8.91428 34.4348 18C34.4348 27.0857 27.0857 34.4348 18 34.4348C8.91428 34.4348 1.56522 27.0857 1.56522 18C1.56522 8.91428 8.91428 1.56522 18 1.56522ZM24.2532 10.9458C24.0468 10.9507 23.8507 11.037 23.7075 11.1858L18 16.8933L12.2925 11.1858C12.2195 11.1108 12.1323 11.0512 12.036 11.0105C11.9396 10.9699 11.8361 10.9489 11.7315 10.9489C11.5758 10.9489 11.4237 10.9954 11.2945 11.0823C11.1654 11.1693 11.0651 11.2928 11.0065 11.437C10.9479 11.5813 10.9337 11.7397 10.9656 11.8921C10.9976 12.0445 11.0742 12.1839 11.1858 12.2925L16.8933 18L11.1858 23.7075C11.1107 23.7797 11.0507 23.866 11.0094 23.9616C10.9681 24.0572 10.9463 24.16 10.9452 24.2642C10.9442 24.3683 10.9639 24.4716 11.0032 24.568C11.0426 24.6644 11.1008 24.7519 11.1744 24.8256C11.248 24.8992 11.3356 24.9574 11.432 24.9968C11.5284 25.0361 11.6317 25.0558 11.7358 25.0548C11.84 25.0537 11.9428 25.0319 12.0384 24.9906C12.134 24.9493 12.2203 24.8893 12.2925 24.8142L18 19.1067L23.7075 24.8142C23.7797 24.8893 23.866 24.9493 23.9616 24.9906C24.0572 25.0319 24.16 25.0537 24.2642 25.0548C24.3683 25.0558 24.4716 25.0361 24.568 24.9968C24.6644 24.9574 24.7519 24.8992 24.8256 24.8256C24.8992 24.7519 24.9574 24.6644 24.9968 24.568C25.0361 24.4716 25.0558 24.3683 25.0548 24.2642C25.0537 24.16 25.0319 24.0572 24.9906 23.9616C24.9493 23.866 24.8893 23.7797 24.8142 23.7075L19.1067 18L24.8142 12.2925C24.9279 12.1832 25.0059 12.0421 25.038 11.8877C25.0701 11.7333 25.0548 11.5729 24.9942 11.4273C24.9336 11.2818 24.8304 11.1579 24.6982 11.072C24.5659 10.9861 24.4109 10.9421 24.2532 10.9458Z" fill="white"/>
</svg>
)
const SubmitIcon = () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_805_255)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.477 2 2 6.477 2 12C2 13.89 2.525 15.66 3.438 17.168L2.546 20.2C2.49478 20.3741 2.49141 20.5587 2.53624 20.7346C2.58107 20.9104 2.67245 21.0709 2.80076 21.1992C2.92907 21.3276 3.08958 21.4189 3.26542 21.4638C3.44125 21.5086 3.62592 21.5052 3.8 21.454L6.832 20.562C8.39068 21.5051 10.1782 22.0025 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM9.738 14.263C11.761 16.285 13.692 16.552 14.374 16.577C15.411 16.615 16.421 15.823 16.814 14.904C16.8636 14.7897 16.8816 14.6641 16.8661 14.5405C16.8507 14.4168 16.8023 14.2996 16.726 14.201C16.178 13.501 15.437 12.998 14.713 12.498C14.5618 12.3935 14.3761 12.3516 14.1947 12.381C14.0133 12.4105 13.8503 12.509 13.74 12.656L13.14 13.571C13.1085 13.6202 13.0593 13.6555 13.0026 13.6696C12.9459 13.6837 12.8859 13.6756 12.835 13.647C12.428 13.414 11.835 13.018 11.409 12.592C10.983 12.166 10.611 11.6 10.402 11.219C10.3761 11.1706 10.3686 11.1144 10.3809 11.0609C10.3932 11.0074 10.4245 10.9602 10.469 10.928L11.393 10.242C11.5249 10.1273 11.61 9.9682 11.6321 9.79486C11.6542 9.62153 11.6118 9.44611 11.513 9.302C11.065 8.646 10.543 7.812 9.786 7.259C9.68831 7.1882 9.57386 7.14406 9.45393 7.13091C9.334 7.11776 9.21271 7.13606 9.102 7.184C8.182 7.578 7.386 8.588 7.424 9.627C7.449 10.309 7.716 12.24 9.738 14.263Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_805_255">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
)