import { useNavigate } from "@solidjs/router";

export const BackButton = (props: { onClick?: () => void }) => {
  const navigate = useNavigate();
  return (
    <button
      onclick={() => {
        if (props.onClick) {
          props.onClick();
        } else {
          if (history.length) {
            navigate(-1);
          } else {
            navigate("/");
          }
        }
      }}
    >
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="50"
          y="50"
          width="50"
          height="50"
          rx="25"
          transform="rotate(-180 50 50)"
          fill="#F5F4F8"
        />
        <path
          d="M22.75 20.5L27.25 25L22.75 29.5"
          stroke="#234F68"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
};
