import { useSearchParams } from "@solidjs/router"
import axios from "axios"
import { createSignal, For, onMount, Show } from "solid-js"




const TestConnectionPage = () => {
    const [authState, setAuthState] = createSignal<string | null | boolean>(null)
    const [loggedIn, setLoggedIn] = createSignal<boolean>(false)
    const [accountsList, setAccountsList] = createSignal([])
    const [googleAccount, setGoogleAccount] = createSignal<any>(null)
    const [locationsList, setLocationsList] = createSignal([])
    const [googleLocation, setGoogleLocation] = createSignal<any>(null)
    const [reviews, setReviews] =  createSignal<any>([])
    const [params, setParams] = useSearchParams()
    onMount(() => {
        if (params && params.loggedIn == '1') {
            setAuthState(true)
            setLoggedIn(true)
            getAccountsList()
        }
    })

    const getAuthState = async () => {
        let redirect_url = document.location.href + '?loggedIn=1'
        await axios.post('/auth/s/google/state/', {
            next: redirect_url
        })
            .then(resp => {
                if (resp && resp.data && resp.data.state_key) {
                    setAuthState(resp.data.state_key)
                }
            })
    }
    const getAccountsList = async () => {
        await axios.get('/s/google/business/accounts/')
            .then(resp => {
                if (resp && resp.data && resp.data.length) {
                    setAccountsList(resp.data)
                }
            })
        console.log(accountsList())
    }
    const getLocationsList = async () => {
        await axios.get(`/s/google/business/accounts/${googleAccount().account_id}/locations/`)
            .then(resp => {
                if (resp && resp.data && resp.data.length) {
                    setLocationsList(resp.data)
                }
            })
        console.log(locationsList())
    }
    const getLocationsReviews = async () => {
        await axios.get(`/s/google/business/accounts/${googleAccount().account_id}/locations/${googleLocation().location_id}/reviews/`)
            .then(resp => {
                if (resp && resp.data && resp.data.reviews && resp.data.reviews.length) {
                    setReviews(resp.data.reviews)
                }
            })
        console.log(reviews())
    }
    return (
        <div
            class="connection-page"
            style="flex: 1; padding: 100px 50px;"
        >
            <Show when={authState() === null}>
                <button
                    type="button"
                    onClick={() => {
                        getAuthState()
                    }}
                >
                    Get Auth State
                </button>
            </Show>
            <Show when={!loggedIn() && authState() !== null}>
                <button
                    type="button"
                    onClick={() => {
                        // login()
                        document.location.href = import.meta.env.VITE_API_URL + 'auth/s/google/login/?state_key=' + authState()
                    }}
                >
                    Login
                </button>
            </Show>
            <Show when={loggedIn() && googleAccount() == null && googleLocation() == null && !reviews().length}>
                <Show when={accountsList() && accountsList().length}>
                    <ol>
                        <For each={accountsList()}>
                            {(account:any) => (
                                <li
                                    style="cursor: pointer;"
                                    onClick={() => {
                                        setGoogleAccount(account)
                                        getLocationsList()
                                    }}
                                >
                                    <b>{account.account_name}</b>
                                </li>
                            )}
                        </For>
                    </ol>
                </Show>
            </Show>
            <Show when={googleAccount() !== null && locationsList().length && googleLocation() == null && !reviews().length}>
                <ol>
                    <For each={locationsList()}>
                        {(location:any) => (
                            <li
                                style="cursor: pointer;"
                                onClick={() => {
                                    setGoogleLocation(location)
                                    getLocationsReviews()
                                }}
                            >
                                {location.title}
                            </li>
                        )}
                    </For>
                </ol>
            </Show>
            <Show when={googleAccount() !== null && locationsList().length && googleLocation() != null && reviews().length}>
                <Show
                    when={reviews().length}
                    fallback={<p>Your business doesn't have reviews</p>}
                >
                    <ol>
                        <For each={reviews()}>
                            {(review:any) => (
                                <li style="margin: 10px 0;">
                                    <b>Rating: {review.rating}</b>
                                    <br/>
                                    Text: <br/>{review.comment}
                                </li>
                            )}
                        </For>
                    </ol>
                </Show>
            </Show>
        </div>
    )
}

export default TestConnectionPage