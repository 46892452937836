import { createStore } from "solid-js/store";
import TextInput from "../../../Components/TextInput";
import { createEventDispatcher } from '@solid-primitives/event-dispatcher';
import { Show, createSignal } from "solid-js";
import phoneValidation from "../../../helpers/PhoneValidation";
import axios from "axios";
interface Props {
	open: boolean;
	onCloseModal: (evt:CustomEvent<any>) => void;
	onSubmitForm: (evt:CustomEvent<any>) => void;
}

const AddAgentModal = (props: Props) => {
	const dispatch = createEventDispatcher(props)
	const [agent, setAgent] = createStore({
		first_name: '',
		last_name: '',
		phone: '',
		photo: null
	})

	const [error, setError] = createSignal('מספר טלפון לא תקין')


	const clearForm = () => {
		setAgent({
			first_name: '',
			last_name: '',
			phone: '',
			photo: null
		})
	}

	const getUserAvatar = async () => {
		const request = await axios.get('/rentals/realtors/realtor-image-url/', {
			params: {
				phone: phoneValidation(agent.phone)
			}
		})
			.then(resp => {
				if (resp && resp.data.length) {
					setAgent({photo: resp.data})
				}
			})
			.catch(err => {
				console.log(err)
			})
	}

	return (
		<div
			class="c-modal add-agent--modal"
			classList={{open: props.open}}
		>
			<div
				class="c-modal--overlay"
				onClick={(e) => dispatch('closeModal', e)}
			></div>
			<div class="c-modal--container">
				<div class="c-modal--content">
					<div class="c-modal--title">
						אנא מלא את  <br/>הפרטים הבאים
					</div>
					<div class="c-modal--form">
						<TextInput
							name="first_name"
							class="mb-4"
							type="text"
							value={agent.first_name}
							label="שם פרטי"
							onChange={(e) => {
								setAgent({first_name: e.currentTarget.value})
							}}
						/>
						<TextInput
							name="last_name"
							class="mb-4"
							type="text"
							value={agent.last_name}
							label="שם משפחה"
							onChange={(e) => {
								setAgent({last_name: e.currentTarget.value})
							}}
						/>
						<TextInput
							name="phone"
							class="mb-4"
							type="number"
							value={agent.phone}
							label="טלפון נייד"
							onChange={(e) => {
								setAgent({phone: e.currentTarget.value})
							}}
						/>
						<Show
							when={agent.phone.length > 10}
						>
							<div class="text-error text-center h-4">
								{error()}
							</div>
						</Show>
						<Show when={
							agent.first_name.length > 1
							&&
							agent.last_name.length > 1
							&&
							agent.phone.length === 10
						}>
							<div class="submit--container">
								<button
									class="btn--submit"
									type="button"
									onClick={async () => {
										await getUserAvatar()
										let data = {...agent}
										data.phone = phoneValidation(data.phone)
										dispatch('submitForm', data, {cancelable: true});
										clearForm()
									}}
								>
									הזמן לגשר!
								</button>
							</div>
						</Show>
					</div>
				</div>
			</div>
		</div>
	)
}
export default AddAgentModal;