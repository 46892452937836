import Button from "../../Components/Button";
import Spacer from "../../Components/Spacer";
import TextInput from "../../Components/TextInput";
import homeIcon from "../../Icons/HomeIcon.svg";
import { createStore } from "solid-js/store";
import { checkPhoneNumber } from "./BuyerForm";
import axios from "axios";
import shareWhatsapp from "../../utilities/shareWhatsApp";
import { RealtorHomeAsset } from "../../types";
import { phoneRegex } from "../../zodSchemas";

export function ShareAssetsModal(props: { assets: [RealtorHomeAsset] }) {
  const [form, setForm] = createStore({
    name: "",
    phone: "",
    current_user_id: "",
  });

  checkPhoneNumber(form, setForm);
  return (
    <div class="bg-white p-4 flex flex-col items-center">
      <img src={homeIcon} alt="home icon" />
      <Spacer />
      <TextInput
        value={form.phone}
        name="phone"
        type="tel"
        label="טלפון"
        onChange={(e) => {
          setForm({ [e.currentTarget.name]: e.currentTarget.value });
        }}
      />
      <TextInput
        name="name"
        value={form.name}
        label="שם מלא של הקונה"
        onChange={(e) => {
          setForm({ [e.currentTarget.name]: e.currentTarget.value });
        }}
      />
      <Spacer />

      <Button
        class="w-8/12"
        disabled={Boolean(form.current_user_id) || !String(form.phone).match(phoneRegex) || !form.name}
        onClick={async () => {
          await axios.post("/real-estate/customer/", {
            name: form.name,
            phone: form.phone,
            buyer_end_customer_status: "FA",
            buyer_end_customer_activity_state: "AV",
          });
          const assets = [];
          Object.keys(props.pickedAssets)
            .filter((checked) => {
              return props.pickedAssets[checked];
            })
            .forEach((id) => {
              const asset = props.matchQuery.data?.find(
                (element) => String(element.id) === String(id)
              );
              assets.push(asset);
            });
            props.openWhatsUpContainer(form.phone)
          // shareWhatsapp({
          //   assets: assets,
          //   phone: form.phone,
          // });
        }}
        color="blue"
      >
        שלח ושמור פרטי לקוח
      </Button>
      <Spacer />
      <Button
        color="green"
        class="w-8/12"
        disabled={!String(form.phone).match(phoneRegex)}
        onClick={() => {
          const assets:Array<RealtorHomeAsset> = [];
          const assets_id:Array<number> = [];
          Object.keys(props.pickedAssets)
            .filter((checked) => {
              return props.pickedAssets[checked];
            })
            .forEach((id) => {
              const asset = props.matchQuery.data?.find(
                (element) => String(element.id) === String(id)
              );
              assets.push(asset);
              assets_id.push(asset.id)
            });
          console.log(assets)
          console.log(assets_id)
          props.openWhatsUpContainer(form.phone, assets)
          // shareWhatsapp({
          //   assets: assets,
          //   phone: form.phone,
          // });
        }}
      >
        {" "}
        שלח
      </Button>
    </div>
  );
}
