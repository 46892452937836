import StepLogo from './logo.png';
import UserDataSteps from './UserDataSteps';
import TextInput from '../../../Components/TextInput';
import SelectAsset from '../../../Components/SelectAsset';
import Select from '../../../Components/Select';
import { createStore } from 'solid-js/store';
import Button from '../../../Components/Button';
import LongTextInput from '../../../Components/LongTextInput';
import { For, Show, createMemo, createSignal } from 'solid-js';
import { onMount } from 'solid-js';
import axios from 'axios';
import _ from "lodash";
import TranslateError from '../../../helpers/TranslateError';
import { createEventDispatcher } from '@solid-primitives/event-dispatcher';

interface Props {
	onSetRegionData: (evt:CustomEvent<any>) => void;
	token?: string;
}


const RegionData = (props: Props) => {
	const dispatch = createEventDispatcher(props)
	
	const step = 3;

	const [form, setForm]:any = createStore({
		cities: [],
		about: '',
		search: ''
	});

	const [choosenCities, addChoosenCities]:any = createStore([]);
	const [cities, setCities]:any= createStore([])
	const [error, setError] = createSignal("");
	// באקה
	// ירושלים
	// אביב
	// חיפה

	const getCitiesList = _.debounce( async () => {
		if (form.search && form.search.length > 2) {
			const url = 'rentals/cities/'
			await axios.get(url, {
				params: {
					search: form.search
				}
			})
			.then(resp => {
				if (resp && resp.data && resp.data.count) {
					setCities(resp.data.results)
				} else {
					setError(TranslateError('No cities found'))
					setCities([])
				}
			})
		} else {
			setCities([])
		}
	}, 300)

	const addCity = (id:number) => {
		if (!form.cities.includes(id)) {
			setForm('cities', [...form.cities, id]);
		} else {
			setForm('cities', form.cities.filter((city_id:number) => city_id !== id));
		}
	}

	const addCityToChoosen = (id:number) => {
		const findCity = cities.find((city:any) => city.id === id)
		if (findCity) {
			if (!choosenCities.find((city:any) => city.id === id)) {
				addChoosenCities([...choosenCities, findCity]);
			} else {
				deleteChoosenCity(id)
			}
		}
	}
	const deleteChoosenCity = (id:number) => {
		addChoosenCities(choosenCities.filter((city:any) => city.id !== id));
		if (choosenCities.length < 3) {
			setError('')
		}
	}

	const checkIfCityAdded = (id:number) => {
		return choosenCities.find((city:any) => city.id === id)
	}
	
	return (
		<div class="registration__step">
			<div class="registration__step--title">
				<p class="st-logo">
					<img src={StepLogo} alt="" />
				</p>
				<h1 class="st-title">
					ברוך הבא<span class="symbol">!</span>
				</h1>
				<p class="st-description code">
					הצטרף למשפחת גשר ותהיה חלק<br/> ממהפכה בעולם הנדל”ן
				</p>
			</div>
			<UserDataSteps
				step={step}
			/>
			<div class="registration__step--form">
				<TextInput
					value={form.search}
					type="text"
					placeholder="החל לכתוב לפחות 3 תווים"
					name="phone"
					label="עיר"
					onChange={(e) => {
						if (choosenCities.length >= 3) {
							setError(TranslateError('You have maximum 3 city to choose'))
							return;
						}
						setForm({search: e.currentTarget.value});
						setError('')
						getCitiesList()
					}}
				/>
				<Show
					when={error().length}
				>
					<div class="text-error text-center h-4">
						{error() || " "}
					</div>
				</Show>
				<Show
					when={choosenCities.length}
				>
					<div class="cities--list" style="margin-bottom: 0">
						<For each={choosenCities}>
							{(city:any) => {
								return (
									<span class="single-city">
										<span class="city-value active">
											{city.name}
										</span>
										<button 
											class="btn--delete"
											onClick={(e) => {
												deleteChoosenCity(city.id)
											}}
										>
											<span></span>
											<span></span>
										</button>
									</span>
								)
							}}
						</For>
					</div>
				</Show>
				<Show
					when={cities.length}
				>
					<div class="cities--list">
						<For each={cities}>
							{(city) => {
								return (
									<span class="single-city">
										<input
											type="checkbox"
											value={Number(city.id)}
											name="city"
											class="input--checkbox"
											checked={checkIfCityAdded(city.id)}
											onChange={(e) => {
												addCity(Number(e.currentTarget.value))
												addCityToChoosen(Number(city.id))
												setCities([])
												setForm({search: ''});
											}}
										/>
										<span class="city-value">
											{city.name}
										</span>
									</span>
								)
							}}
						</For>
					</div>
				</Show>
				<LongTextInput
					name="about"
					label="אודות"
					value={form.about}
					class="h-36 mb-4 mt-4"
					registrationIcon={<AboutIcon />}
					onChange={(e: any) =>
						setForm({'about': e.target.value})
					}
				/>
				<Show
					when={form.cities.length > 0 && form.about.length}
				>
					<div class="submit--container">
						<Button
							color="green"
							name="phone-button"
							class={`w-full font-bold bottom-12 mt-6`}
							fullWidth={true}
							onClick={() => {
								dispatch('setRegionData', form, {cancelable: true})
							}}
						>
							המשך
						</Button>
					</div>
				</Show>
			</div>
		</div>
	)
}


export default RegionData;

const AboutIcon = () => (
<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M2.0625 3.4375V15.8125H5.5V19.3037L9.8667 15.8125H19.9375V3.4375H2.0625ZM3.4375 4.8125H18.5625V14.4375H9.3833L6.875 16.4436V14.4375H3.4375V4.8125Z" fill="#234F68"/>
</svg>
)