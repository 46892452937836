import Button from "../../Components/Button";
import Spacer from "../../Components/Spacer";
import TextInput from "../../Components/TextInput";
import Select from "../../Components/Select";
import { createStore } from "solid-js/store";
import Picker from "../../Components/Picker";
import { FlexRow, FlexCol } from "../../Components/Flex";
import { handleForm } from "./AddClient";

export function SellerForm() {
  const [form, setForm] = createStore({
    name: "",
    phone: "",
    customerType: "",
    street: "",
    neighborhood: "",
    budget: 100000,
    assetType: "",
    floor: "1",
    customerBudget: 110000,
    elavator: "hide",
    dangerousAssets: "hide",
    parking: "hide",
    sunRoof: "hide",
    storage: "hide",
  });

  return (
    <div>
      <TextInput
        name="name"
        label="שם מלא של המוכר"
        onChange={(e) => handleForm(e, setForm)}
        value={form.name}
      />
      <Spacer height="12px" />
      <TextInput
        onChange={(e) => handleForm(e, setForm)}
        name="phone"
        label="טלפון"
        type="tel"
        value={form.phone}
      />
      <Select
        onChange={(option) => setForm({ customerType: option })}
        options={{ tripleA: "טריפל איי", lead: "ליד" }}
        label="סוג לקוח "
        value={form.customerType}
        name="customerType"
      />
      <Select
        onChange={(option) => setForm({ street: option })}
        options={{ street: "רחוב א", street2: "רחוב ב" }}
        label="רחוב"
        value={form.street}
        name="street"
      />
      <Select
        onChange={(option) => setForm({ neighborhood: option })}
        options={{ neighborhood: "שכונה א", neighborhood1: "שכונה ב" }}
        label="שכונה"
        value={form.neighborhood}
        name="neighborhood"
      />
      <TextInput
        onChange={(e) => handleForm(e, setForm)}
        name="budger"
        label="תקציב"
        value={form.budget}
      />
      <Select
        onChange={(option) => setForm({ assetType: option })}
        options={{ villa: "וילה", penthouse: "פנטהאוז" }}
        label="סוג נכס "
        value={form.assetType}
        name="assetType"
      />
      <Select
        onChange={(option) => setForm({ floor: option })}
        options={{ "1": "1", "2": "2", "3": "3" }}
        label="בחר קומה"
        value={form.floor}
        name="floor"
      />
      <TextInput
        onChange={(e) => handleForm(e, setForm)}
        name="customerBudget"
        label="תקציב הלקוח"
        value={form.customerBudget}
      />
      <Spacer />
      <FlexCol>
        <FlexRow>
          <label class="w-6/12">מעלית</label>
          <Picker
            name="elevator"
            active={form.elavator}
            options={{ show: "הצג", hide: "הסתר" }}
            handleChange={(key) => {
              setForm("elavator", key);
            }}
          />
        </FlexRow>
        <FlexRow>
          <label class="w-6/12">מבנים מסוכנים</label>
          <Picker
            name="dangerousAssets"
            active={form.dangerousAssets}
            options={{ show: "הצג", hide: "הסתר" }}
            handleChange={(key) => {
              setForm("dangerousAssets", key);
            }}
          />
        </FlexRow>
        <FlexRow>
          <label class="w-6/12">חניה בטאבו</label>
          <Picker
            name="parking"
            active={form.parking}
            options={{ show: "הצג", hide: "הסתר" }}
            handleChange={(key) => {
              setForm("parking", key);
            }}
          />
        </FlexRow>
        <FlexRow>
          <label class="w-6/12">מרפסת שמש</label>
          <Picker
            name="sunRoof"
            active={form.sunRoof}
            options={{ show: "הצג", hide: "הסתר" }}
            handleChange={(key) => {
              setForm("sunRoof", key);
            }}
          />
        </FlexRow>
        <FlexRow>
          <label class="w-6/12">מחסן</label>
          <Picker
            name="storage"
            active={form.storage}
            options={{ show: "הצג", hide: "הסתר" }}
            handleChange={(key) => {
              setForm("storage", key);
            }}
          />
        </FlexRow>
      </FlexCol>
      <Spacer height="32px" />
      <Button color="green"> אישור</Button>
    </div>
  );
}
