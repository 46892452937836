export const EyeIcon = ({ white }: { white: string }) => (
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4943 0.5C5.77778 0.5 1.62201 3.76913 0.519513 8.0529C0.478289 8.21348 0.502543 8.38386 0.586941 8.52655C0.671338 8.66925 0.808965 8.77258 0.969546 8.8138C1.13013 8.85503 1.30051 8.83077 1.4432 8.74637C1.5859 8.66198 1.68923 8.52435 1.73045 8.36377C2.68045 4.67253 6.3608 1.75 10.4943 1.75C14.6278 1.75 18.3198 4.67356 19.2695 8.36377C19.3107 8.52435 19.4141 8.66198 19.5568 8.74637C19.6995 8.83077 19.8698 8.85503 20.0304 8.8138C20.191 8.77258 20.3286 8.66925 20.413 8.52655C20.4974 8.38386 20.5217 8.21348 20.4804 8.0529C19.3777 3.7681 15.2108 0.5 10.4943 0.5ZM10.5 3.83333C8.20415 3.83333 6.33332 5.70417 6.33332 8C6.33332 10.2958 8.20415 12.1667 10.5 12.1667C10.5 11.725 10.5669 11.3003 10.6961 10.9045C10.6294 10.9128 10.5666 10.9167 10.5 10.9167C8.89165 10.9167 7.58332 9.60833 7.58332 8C7.58332 6.39167 8.89165 5.08333 10.5 5.08333C12.1083 5.08333 13.4166 6.39167 13.4166 8C13.4166 8.06667 13.4128 8.12946 13.4044 8.19613C13.8003 8.06696 14.225 8 14.6666 8C14.6666 5.70417 12.8 3.83333 10.5 3.83333ZM14.6666 8.83333C12.8287 8.83333 11.3333 10.3287 11.3333 12.1667C11.3333 14.0046 12.8287 15.5 14.6666 15.5C15.3604 15.5 16.0042 15.2864 16.5384 14.9222L18.5998 16.9836C18.7218 17.1061 18.8816 17.1667 19.0416 17.1667C19.2016 17.1667 19.3615 17.1056 19.4835 16.9836C19.7273 16.7394 19.7273 16.3439 19.4835 16.0998L17.423 14.0384C17.7867 13.5042 18 12.8604 18 12.1667C18 10.3287 16.5046 8.83333 14.6666 8.83333ZM14.6666 10.0833C15.8154 10.0833 16.75 11.0179 16.75 12.1667C16.75 13.3154 15.8154 14.25 14.6666 14.25C13.5179 14.25 12.5833 13.3154 12.5833 12.1667C12.5833 11.0179 13.5179 10.0833 14.6666 10.0833Z"
      fill={white ? "white" : "#234F68"}
    />
  </svg>
);
