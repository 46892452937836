import { Share } from "@capacitor/share";
import { user } from "../globalState";
import { BlankEnum, BuyerEndCustomerStatusEnum, NullEnum } from "../types";
import { Capacitor } from "@capacitor/core";
type Params = {
  phone?: string;
  assets?: [{ street: { address_number: string; id: string; name: string } }];
  content?: string;
  customerStatus?: BuyerEndCustomerStatusEnum | BlankEnum | NullEnum | null;
};
const shareWhatsapp = async ({ phone, assets, customerStatus }: Params) => {
  let text = "";
  if (assets) {
    if (assets.length > 1) {
      text += `היי, מצאתי כמה נכסים שיכולים להתאים לך, במידה והלינק לא נפתח יש להוסיף אותי לאנשי הקשר.`;
      text += `%0a *` + user.name + " " + user.agency.name + "*" + "%0a %0a";
    }

    assets?.forEach((asset) => {
      const baseUrl =
        import.meta.env.VITE_PUBLIC_URL +
        "/public-view/asset/" +
        btoa(asset.id);

      const queryParams = {
        agent_phone: user.phone,
        customer_status: customerStatus || "FA",
      };

      text += `*🏠 ${asset.street.name} ${asset.address_number || ""} - ${
        asset.city.name
      }* %0a %0a


      
      ${baseUrl.toString()}?${btoa(
        new URLSearchParams(queryParams).toString()
      )}`;

      text += "%0a %0a";
    });
    text += `%0a להסרה השב: הסר`;
  } else {
    text = "שלום רב %0a";
  }

  let openUrl = "https://api.whatsapp.com/send?text=" + text;
  if (Capacitor.getPlatform() !== "web") {
    openUrl = "whatsapp://send?text=" + text;
  }
  if (phone) {
    openUrl += "&phone=972" + phone.replaceAll("-", "").slice(1);
  }

  if (Capacitor.getPlatform() === "web") {
    window.open(openUrl, "_blank");
  } else {
    window.open(openUrl, "_self");
  }
};
export default shareWhatsapp;
