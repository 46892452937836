import Button from "../../Components/Button";
import Heading from "../../Components/Heading";
import Spacer from "../../Components/Spacer";
import { Modal } from "../../Components/Modal";
import undeletedIcon from "./undeletedIcon.svg";
import TextInput from "../../Components/TextInput";
import { GenericModal } from "./GenericModal";
import { UpdateIcon } from "./UpdateIcon";
import { EnumKey, VisibilityStatusEnum } from "../../types";
import axios from "axios";
import { getUserDetails } from "../../api/getUserDetails";
import { pricify, unpricify } from "../AddAsset/AssetInfo";
import { Show, createSignal } from "solid-js";
import { ArchiveFolder } from "../../Icons/ArchiveFolder";
import { useNavigate } from "@solidjs/router";
import { LocationIcon } from "../../Icons";
import { logEvent } from "../../analytics";
import { normalizeAddress } from "./ViewAsset";

export function StatusModal({
  setStatusModal,
  setDealModal,
  asset,
  closeAssetWindow,
}) {
  const [price, setPrice] = createSignal(asset.price);
  const [successModal, setSuccessModal] = createSignal();

  return (
    <Modal
      open
      onClose={() => setStatusModal(false)}
      classList={{ "max-w-md": true }}
    >
      <Show when={!successModal()}>
        <div class="p-6 flex flex-col items-center justify-center">
          <UpdateIcon />
          <Spacer />
          <Heading class="text-xl" bold>
            אנא עדכן את המידע עבור
          </Heading>
          <Heading class="flex justify-center items-center gap-2">
            <LocationIcon />
            {normalizeAddress(asset)}
          </Heading>
          <Spacer />
          <div class="w-full m-auto flex flex-col gap-2">
            <Button
              onClick={() => {
                logEvent("button_click", {
                  click_type: 'נמכר',
                  button_type: 'asset_status'
                  // button_type: "שנה סטטוס נכס",
                  // asset_status: "נמכר",
                });
                setStatusModal(false);
                setDealModal(true);
              }}
              border
              short
              fullWidth
            >
              נמכר
            </Button>
            <Show when={asset.role === 'owner'}>
              <Button
                onClick={async () => {
                  logEvent("button_click", {
                    click_type: 'ירד ממכירה',
                    button_type: 'asset_status'
                    // button_type: "שנה סטטוס נכס",
                    // asset_status: "ירד ממכירה",
                  });
                  await axios.patch(`/real-estate/asset/${asset.id}/`, {
                    visibility_status: "NO",
                  });
                  getUserDetails();
                  setSuccessModal("out of sale");
                }}
                border
                short
                fullWidth
              >
                ירד ממכירה
              </Button>
            </Show>
            <Button
              onClick={async () => {
                logEvent("button_click", {
                  click_type: 'במשא ומתן',
                  button_type: 'asset_status'
                  // button_type: "שנה סטטוס נכס",
                  // asset_status: "במשא ומתן",
                });
                await axios.patch(`/real-estate/asset/${asset.id}/`, {
                  visibility_status: "NO",
                });
                getUserDetails();
                setSuccessModal("negotiation");
              }}
              border
              short
              fullWidth
            >
              במשא ומתן
            </Button>
          </div>
          <Spacer />
          <TextInput
            name="price"
            onChange={(e) => {
              const price = unpricify(e.currentTarget.value);
              setPrice(price);
            }}
            value={pricify(price())}
            class="w-full"
            label="עדכן מחיר"
          />
          <Spacer />
          <Button
            onClick={async () => {
              logEvent("button_click", {
                click_type: 'עדכן מחיר',
                button_type: 'asset_status'
                // button_type: "שנה סטטוס נכס",
                // asset_status: "מחיר השתנה",
              });
              await axios.patch(`/real-estate/asset/${asset.id}/`, {
                price: price(),
              });
              getUserDetails();
              setSuccessModal("price");
            }}
            fullWidth
            color="green"
          >
            עדכן מחיר
          </Button>
          <Spacer />
          <button
            onClick={() => setStatusModal(false)}
            class="underline m-auto"
          >
            ביטול
          </button>
        </div>
      </Show>
      <Show when={successModal() === "out of sale"}>
        <GenericModal
          icon={ArchiveFolder}
          header="תודה על העדכון"
          subHeader="הנכס ירד ממכירה"
          alt="archived"
          options={[
            {
              color: "green",
              label: "אישור",
              handler: () => {
                closeAssetWindow();
              },
            },
          ]}
        />
      </Show>
      <Show when={successModal() === "negotiation"}>
        <GenericModal
          icon={undeletedIcon}
          header="תודה על העדכון"
          subHeader="הנכס ירד מפרסום"
          alt="archived"
          options={[
            {
              color: "green",
              label: "אישור",
              handler: () => {
                closeAssetWindow();
              },
            },
          ]}
        />
      </Show>
      <Show when={successModal() === "price"}>
        <GenericModal
          icon={MoneyIcon}
          header="תודה על העדכון"
          alt="archived"
          options={[
            {
              color: "green",
              label: "אישור",
              handler: () => {
                setStatusModal(false);
              },
            },
          ]}
        />
      </Show>
    </Modal>
  );
}

const DeletedIcon = () => (
  <svg
    width="110"
    height="110"
    viewBox="0 0 110 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      d="M55 110C73.9848 110 89.375 107.691 89.375 104.844C89.375 101.996 73.9848 99.6875 55 99.6875C36.0152 99.6875 20.625 101.996 20.625 104.844C20.625 107.691 36.0152 110 55 110Z"
      fill="black"
    />
    <path
      d="M87.3074 29.1017L80.8982 22.6925C78.1396 19.9339 73.6691 19.9339 70.9105 22.6925L22.6927 70.9103C19.9341 73.6689 19.9341 78.1394 22.6927 80.898L29.1019 87.3072C31.8605 90.0658 36.331 90.0658 39.0896 87.3072L87.3074 39.0894C90.0643 36.3308 90.0643 31.8603 87.3074 29.1017Z"
      fill="#234F68"
    />
    <path
      d="M80.8982 87.3072L87.3074 80.898C90.066 78.1394 90.066 73.6689 87.3074 70.9103L39.0896 22.6925C36.331 19.9339 31.8605 19.9339 29.1019 22.6925L22.6927 29.1017C19.9341 31.8603 19.9341 36.3308 22.6927 39.0894L70.9105 87.3072C73.6691 90.064 78.1396 90.064 80.8982 87.3072Z"
      fill="#234F68"
    />
    <path
      opacity="0.3"
      d="M35.1828 28.7719L28.7719 35.1828C27.0875 36.85 24.8875 37.6922 22.6875 37.6922C22.3094 37.6922 21.9484 37.675 21.5703 37.6234C20.0062 34.925 20.3844 31.4187 22.6875 29.0984L29.0984 22.6875C31.4187 20.3844 34.925 20.0062 37.6234 21.5703C37.95 24.1312 37.1422 26.8125 35.1828 28.7719Z"
      fill="white"
    />
    <path
      opacity="0.15"
      d="M87.3124 80.9018L80.9015 87.3127C78.5812 89.6158 75.0921 89.9939 72.3937 88.4299H72.3765C72.0499 85.8689 72.8577 83.1877 74.8171 81.2283L80.1452 75.9002L72.1187 67.8908C68.7671 64.5221 68.7671 59.0908 72.1187 55.7393H72.1358L87.3124 70.9158C90.0624 73.6658 90.0624 78.1346 87.3124 80.9018Z"
      fill="black"
    />
    <path
      d="M28.3593 34.3751C27.6993 34.3751 27.0393 34.1242 26.5357 33.6206C25.5285 32.6134 25.5285 30.9823 26.5357 29.9751L31.6919 24.8189C32.6991 23.8117 34.3302 23.8117 35.3374 24.8189C36.3446 25.8261 36.3446 27.4572 35.3374 28.4643L30.1811 33.6206C29.6793 34.1242 29.0193 34.3751 28.3593 34.3751Z"
      fill="white"
    />
    <path
      d="M75.5374 67.0846C81.2041 61.3405 90.4544 61.2786 96.1984 66.9436C101.943 72.6103 102.004 81.8607 96.3394 87.6047C90.6727 93.3488 81.4223 93.4106 75.6783 87.7456"
      stroke="#8BC83F"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M78.4986 72.1393L71.4157 73.7378C69.9272 74.0729 68.5986 72.7598 68.9183 71.2662L70.436 64.1781C70.7832 62.5573 72.7821 61.9523 73.9577 63.1107L79.523 68.6004C80.7004 69.7623 80.1177 71.7732 78.4986 72.1393Z"
      fill="#8BC83F"
    />
  </svg>
);

const MoneyIcon = () => (
  <svg
    width="110"
    height="110"
    viewBox="0 0 110 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M89.375 92.8125H20.625C16.8283 92.8125 13.75 89.7342 13.75 85.9375V77.3438C13.75 73.547 16.8283 70.4688 20.625 70.4688H89.375C93.1717 70.4688 96.25 73.547 96.25 77.3438V85.9375C96.25 89.7342 93.1717 92.8125 89.375 92.8125Z"
      fill="#234F68"
    />
    <path
      d="M89.375 87.6562H20.625C16.8283 87.6562 13.75 84.578 13.75 80.7812V72.1875C13.75 68.3908 16.8283 65.3125 20.625 65.3125H89.375C93.1717 65.3125 96.25 68.3908 96.25 72.1875V80.7812C96.25 84.578 93.1717 87.6562 89.375 87.6562Z"
      fill="#98C900"
    />
    <path
      opacity="0.3"
      d="M55 110C73.0355 110 87.6562 107.691 87.6562 104.844C87.6562 101.996 73.0355 99.6875 55 99.6875C36.9645 99.6875 22.3438 101.996 22.3438 104.844C22.3438 107.691 36.9645 110 55 110Z"
      fill="black"
    />
    <path
      d="M89.375 82.5H20.625C16.8283 82.5 13.75 79.4217 13.75 75.625V67.0312C13.75 63.2345 16.8283 60.1562 20.625 60.1562H89.375C93.1717 60.1562 96.25 63.2345 96.25 67.0312V75.625C96.25 79.4217 93.1717 82.5 89.375 82.5Z"
      fill="#234F68"
    />
    <path
      d="M89.375 77.3438H20.625C16.8283 77.3438 13.75 74.2655 13.75 70.4688V61.875C13.75 58.0783 16.8283 55 20.625 55H89.375C93.1717 55 96.25 58.0783 96.25 61.875V70.4688C96.25 74.2655 93.1717 77.3438 89.375 77.3438Z"
      fill="#98C900"
    />
    <path
      d="M89.375 72.1875H20.625C16.8283 72.1875 13.75 69.1092 13.75 65.3125V27.5C13.75 23.7033 16.8283 20.625 20.625 20.625H89.375C93.1717 20.625 96.25 23.7033 96.25 27.5V65.3125C96.25 69.1092 93.1717 72.1875 89.375 72.1875Z"
      fill="#234F68"
    />
    <path
      d="M22.3438 61.875V30.9375C22.3438 29.9888 23.1138 29.2188 24.0625 29.2188H85.9375C86.8863 29.2188 87.6562 29.9888 87.6562 30.9375V61.875C87.6562 62.8237 86.8863 63.5938 85.9375 63.5938H24.0625C23.1138 63.5938 22.3438 62.8237 22.3438 61.875Z"
      fill="#98C900"
    />
    <path
      d="M55 60.1562C61.6447 60.1562 67.0312 54.0002 67.0312 46.4062C67.0312 38.8123 61.6447 32.6562 55 32.6562C48.3553 32.6562 42.9688 38.8123 42.9688 46.4062C42.9688 54.0002 48.3553 60.1562 55 60.1562Z"
      fill="#234F68"
    />
    <path
      d="M23.2031 36.0938C26.5255 36.0938 29.2188 33.4005 29.2188 30.0781C29.2188 26.7558 26.5255 24.0625 23.2031 24.0625C19.8808 24.0625 17.1875 26.7558 17.1875 30.0781C17.1875 33.4005 19.8808 36.0938 23.2031 36.0938Z"
      fill="#234F68"
    />
    <path
      d="M23.2031 68.75C26.5255 68.75 29.2188 66.0567 29.2188 62.7344C29.2188 59.412 26.5255 56.7188 23.2031 56.7188C19.8808 56.7188 17.1875 59.412 17.1875 62.7344C17.1875 66.0567 19.8808 68.75 23.2031 68.75Z"
      fill="#234F68"
    />
    <path
      d="M86.7969 36.0938C90.1192 36.0938 92.8125 33.4005 92.8125 30.0781C92.8125 26.7558 90.1192 24.0625 86.7969 24.0625C83.4745 24.0625 80.7812 26.7558 80.7812 30.0781C80.7812 33.4005 83.4745 36.0938 86.7969 36.0938Z"
      fill="#234F68"
    />
    <path
      d="M86.7969 68.75C90.1192 68.75 92.8125 66.0567 92.8125 62.7344C92.8125 59.412 90.1192 56.7188 86.7969 56.7188C83.4745 56.7188 80.7812 59.412 80.7812 62.7344C80.7812 66.0567 83.4745 68.75 86.7969 68.75Z"
      fill="#234F68"
    />
    <path
      d="M32.6562 49.8438C34.5547 49.8438 36.0938 48.3047 36.0938 46.4062C36.0938 44.5078 34.5547 42.9688 32.6562 42.9688C30.7578 42.9688 29.2188 44.5078 29.2188 46.4062C29.2188 48.3047 30.7578 49.8438 32.6562 49.8438Z"
      fill="#234F68"
    />
    <path
      d="M77.3438 49.8438C79.2422 49.8438 80.7812 48.3047 80.7812 46.4062C80.7812 44.5078 79.2422 42.9688 77.3438 42.9688C75.4453 42.9688 73.9062 44.5078 73.9062 46.4062C73.9062 48.3047 75.4453 49.8438 77.3438 49.8438Z"
      fill="#234F68"
    />
    <path
      opacity="0.15"
      d="M87.6562 49.8438V56.8047C87.3727 56.7652 87.0908 56.7188 86.7969 56.7188C83.4745 56.7188 80.7812 59.412 80.7812 62.7344C80.7812 63.0283 80.8277 63.3102 80.8672 63.5938H46.4062C41.6608 63.5938 37.8125 67.4403 37.8125 72.1875H89.375C93.1717 72.1875 96.25 69.1092 96.25 65.3125V41.25C91.5045 41.25 87.6562 45.0966 87.6562 49.8438Z"
      fill="black"
    />
    <path
      opacity="0.3"
      d="M20.625 20.625C16.8283 20.625 13.75 23.7033 13.75 27.5V51.5625C18.4955 51.5625 22.3438 47.7159 22.3438 42.9688V36.0078C22.6273 36.0491 22.9092 36.0938 23.2031 36.0938C26.5255 36.0938 29.2188 33.4005 29.2188 30.0781C29.2188 29.7842 29.1723 29.5023 29.1328 29.2188H37.8125C42.558 29.2188 46.4062 25.3722 46.4062 20.625H20.625Z"
      fill="white"
    />
    <path
      d="M20.625 35.2344V29.2188C20.625 28.27 21.395 27.5 22.3438 27.5H31.7969"
      stroke="white"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
