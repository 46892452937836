import { Setter, createEffect, createSignal, onMount } from "solid-js";
import WhatsupModalGuid from "./ModalGuide";
import {WhatsupGetQrCode, WhatsupCheckClient} from '../../api/WhatsupMsg'
import WhatsupErrorModal from "./ModalError";
import WhatsupQrCodeModal from "./ModalQrCode";
import WhatsupPendingModal from "./ModalPending";
import WhatsupSendModal from "./ModalMessage";

import { setToastr, user } from "../../globalState";
import { RealtorHomeAsset } from "../../types";
import { isMobile } from "../../Pages/Home/isSmall";
import WhatsupMobileModal from "./ModalForMobile";
import { checkWhatsupStatus, whatsupStatus } from "../../Store/SocialNetworks";

interface IProps {
    receivers: Array<string>;
    receiversPhone?: Array<string>;
    setWhatsupInit: Setter<boolean>;
    setStateStatus: Setter<string | null>;
    assetId?: Array<number> | undefined;
    assets?: Array<RealtorHomeAsset> | undefined;
    setWhatsupCustomers: Setter<any>;
    setWhatsupStatus: Setter<boolean>;
    setWhatsupAsset?: Setter<any>;
    successCallback?: void;
    show: boolean;
    schedule_message?: string;
    message?: string;
    singleCustomerName?: string;
    closeCallback: Function;
    onlyConnectWhatsup?: boolean | null;
}
const WhatsupContainer = (props: IProps) => {
    const [loading, setLoading] = createSignal(false)
    const [error, setError] = createSignal('')

    const [stateStatus, setStateStatus]:any = createSignal(null)

    const [guidModalStatus, setGuidModalStatus] = createSignal(false)
    const [mobileModalStatus, setMobileModalStatus] = createSignal(false)
    const [qrCodeModalStatus, setQrCodeModalStatus] = createSignal(false)
    const [sendModalStatus, setSendModalStatus] = createSignal(false)
    const [errorModalStatus, setErrorModalStatus] = createSignal(false)
    const [pendingModalStatus, setPendingModalStatus] = createSignal(false)

    const [sentMessages, setSentMessages] = createSignal(0)
    const [limitMessages, setLimitMessages] = createSignal(0)
    
    const [qrCodeData, setQrCodeData] = createSignal(null)

    let stateInterval:any = null
    let qrCodeInterval:any = null
    let qrCodeTimeout:any = null

    
    const getStateStatus = () => {
        let data:any = whatsupStatus()
        if (!data) {
            return false
        }
        setStateStatus(data.state)
        props.setStateStatus(data.state)
        if (data.count_dispatches_in_this_month) {
            setSentMessages(data.count_dispatches_in_this_month)
        }
        if (data.limit_count_dispatches_per_month) {
            setLimitMessages(data.limit_count_dispatches_per_month)
        }
    }
    createEffect(() => {
        if (whatsupStatus()) {
            getStateStatus()
        }
    })
    // onMount(() => {
    //     getStateStatus()  
    // })


    const getQrCode = async () => {
        setLoading(true)
        let data = await WhatsupGetQrCode()
        setQrCodeData(data.qr_code)
        setStateStatus(data.state)
        setLoading(false)
        if (data.state === 'notAuthorized') {
            if (stateInterval) {
                clearInterval(stateInterval)
            }
            stateInterval = setInterval( async () => {
                await checkWhatsupStatus()
                getStateStatus()
            }, 1000)
        }
        qrCodeTimeout = setTimeout(() => {
            getQrCode()
        }, 5000)
    }

    createEffect(() => {
        if (qrCodeModalStatus() !== true) {
            clearTimeouts()
        } 
    })


    const clearTimeouts = () => {
        if (stateInterval) {
            clearInterval(stateInterval)
        }
        if (qrCodeInterval) {
            clearInterval(qrCodeInterval)
        }
        if (qrCodeTimeout) {
            clearTimeout(qrCodeTimeout)
        }
    }


    createEffect(() => {
        if (props.show) {
            if (stateStatus() === 'notAuthorized') {
                if (isMobile()) {
                    setMobileModalStatus(true)
                } else {
                    setGuidModalStatus(true)
                }
                // setGuidModalStatus(true)
            } else if (stateStatus() === 'starting') {
                setLoading(true)
                setPendingModalStatus(true)
            } else if (stateStatus() === 'authorized') {
                clearTimeouts()
                if (!props.onlyConnectWhatsup) {
                    setSendModalStatus(true)
                } else {
                    setGuidModalStatus(false)
                    getStateStatus()
                    setToastr({
                        status: 'succes',
                        show: true,
                        text: 'החיבור לחשבון הווטסאפ שלך הושלם בהצלחה <br/>מברוק! התחברת עם גיל מועלם'
                    })
                }
            } else if (stateStatus() === 'notExisted') {
                getQrCode()
            }
        }
    })

    const sentSuccess = () => {
        props.setWhatsupCustomers([])
        props.setWhatsupInit(false)
        props.setWhatsupStatus(false)
        props.setStateStatus(null)
        if (props.setWhatsupAsset) {
            props.setWhatsupAsset([])
        }
        if (props.successCallback) {
            props.successCallback
        }
    }
    
    const toggleMobileStatusModal = (bool:boolean) => {
        setMobileModalStatus(bool)
        if (!bool) {
            if (props.closeCallback() !== undefined) {
                props.closeCallback()
            }
        }
    }

    const toggleGuidModal = (bool:boolean) => {
        setGuidModalStatus(bool)
        if (!bool) {
            props.closeCallback
        }
    }

    const toggleQrCodeModal = (bool:boolean) => {
        setQrCodeModalStatus(bool)
        if (!bool) {
            props.closeCallback
        }
    }
    const toggleErrorModal = (bool:boolean) => {
        setErrorModalStatus(bool)
        if (!bool) {
            props.closeCallback
        }
    }
    const togglePendingModal = (bool:boolean) => {
        setPendingModalStatus(bool)
        if (!bool) {
            props.closeCallback
        }
    }
    const toggleSendModal = (bool:boolean) => {
        setSendModalStatus(bool)
        if (!bool) {
            props.closeCallback
        }
    }

    
    return (
        <>
            <WhatsupMobileModal
                show={mobileModalStatus()}
                toggle={toggleMobileStatusModal}
            />
            <WhatsupModalGuid
                show={guidModalStatus()}
                toggle={toggleGuidModal}
                getCode={getQrCode}
                setQrCodeStatus={setQrCodeModalStatus}
                loading={loading()}
            />
            <WhatsupQrCodeModal
                show={qrCodeModalStatus()}
                toggle={toggleQrCodeModal}
                qrCode={qrCodeData()}
            />
            <WhatsupErrorModal
                show={errorModalStatus()}
                toggle={toggleErrorModal}
                error={error()}
            />
            <WhatsupPendingModal
                show={pendingModalStatus()}
                toggle={togglePendingModal}
            />
            <WhatsupSendModal
                show={sendModalStatus()}
                toggle={toggleSendModal}
                receivers={props.receivers}
                receiversPhone={props.receiversPhone}
                setErrorModal={setErrorModalStatus}
                description={'test'}
                sentMessages={sentMessages()}
                setSentMessage={setSentMessages}
                limitMessages={limitMessages()}
                assetId={props.assetId}
                assets={props.assets}
                agentPhone={user.phone}
                sentSuccess={sentSuccess}
                scheduleMessage={props.schedule_message ? props.schedule_message : ''}
                message={props.message ? props.message : null}
                singleCustomerName={props.singleCustomerName ? props.singleCustomerName : null}
            />
        </>
    )
}


export default WhatsupContainer
