import { JSX, Show, createMemo, createSignal } from "solid-js";
import Button from "../../Components/Button";
import Spacer from "../../Components/Spacer";
import { AssetTypeEnum, RealtorHomeAsset } from "../../types";
import shareWhatsapp from "../../utilities/shareWhatsApp";
import { WhatsAppIcon } from "../../Icons/WhatsAppIcon";
import fallbackHouse from "../../Icons/FallbackHouse.svg";
import { pricify } from "../AddAsset/AssetInfo";
export const DropDownAsset = (props: {
  asset: RealtorHomeAsset;
  hideSendButton?: boolean;
  horizontal?: boolean;
  onClick: (e) => void;
  showPotentials?: boolean;
  hideCustomerName: boolean;
  phone: string;
}) => {
  const [fallback, setFallback] = createSignal();
  const asset = props.asset;
  const img = createMemo(() => {
    if (fallback() || !asset.image_url) {
      return fallbackHouse;
    }
    return asset.image_url;
  });
  return (
    <button
      onclick={props.onClick}
      class="bg-white m-auto rounded-3xl justify-between items-center p-1 flex flex-col"
      classList={{ "w-80": props.horizontal, "w-11/12": !props.horizontal }}
    >
      <div class="flex">
        <div class="relative w-6/12 flex flex-col justify-center">
          {" "}
          <img
            width="100px"
            onerror={() => {
              console.log("fallbacking");
              setFallback(true);
            }}
            class="rounded-md"
            src={img()}
            alt="image of house"
          />
        </div>
        <div class="w-2/4 p-2 flex flex-col">
          <div class="text-right text-darkBlue text-s font-medium">
            {asset.street.name + " " + (asset.address_number || "")}
          </div>
          <div class="text-darkGrey text-right text-xs">
            {AssetTypeEnum[asset.asset_type] || ""}{" "}
            {asset.neighborhood?.name || ""}
          </div>
          <Spacer height="4px" />
          <div class="flex gap-2">
            <div class="w-10 rounded-md bg-white text-center text-xs font-bold">
              {asset.number_of_rooms} <br />
              <div class="font-thin">חדרים</div>
            </div>
            <Show when={asset.floor}>
              <div class="w-10 rounded-md bg-white text-center text-xs font-bold">
                {asset.floor} <br />
                <div class="font-thin">קומה</div>
              </div>
            </Show>
            <div class="w-10 rounded-md bg-white text-center text-xs font-bold">
              {Number(asset.asset_built_area)} <br />{" "}
              <div class="font-thin">מ״ר</div>
            </div>
          </div>
          <Spacer height="22px" />
          <div class="text-darkBlue text-s font-medium">
            {pricify(asset.price)}
          </div>
          <Spacer height={"8px"} />
          <Show when={!props.hideCustomerName}>
            <div class="text-darkBlue text-s font-medium">
              {asset?.realtor_contacts[0]?.name || ""}
            </div>
            <div class="text-darkGrey text-s font-medium">
              {asset?.realtor_contacts[0]?.agency.name || ""}
            </div>
          </Show>
        </div>
      </div>
      <Show when={!props.showPotentials && !props.hideSendButton}>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            shareWhatsapp({
              phone: props.phone,
              assets: [asset],
            });
          }}
          short
          fullWidth
          border
        >
          <div class="flex gap-8">
            {" "}
            שליחה ללקוח
            <WhatsAppIcon />
          </div>
        </Button>
      </Show>
      <Show when={props.showPotentials}>
        <span class="flex gap-1 mt-4">
          <PotentialsIcon />
          <span>נכס זה מתאים ל</span>
          <strong>{asset.potential_buyers.length}</strong>
          <span> מהלקוחות שלי </span>
        </span>
      </Show>
    </button>
  );
};

const PotentialsIcon = () => (
  <svg
    class="ml-2"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_709_8157)">
      <path
        d="M3.07988 0.880859C1.84754 0.880859 0.879883 1.84852 0.879883 3.08086V14.9609C0.879883 16.1932 1.84754 17.1609 3.07988 17.1609H14.9599C16.1922 17.1609 17.1599 16.1932 17.1599 14.9609V4.31836L16.2799 5.28086V14.9609C16.2799 15.7085 15.7075 16.2809 14.9599 16.2809H3.07988C2.33223 16.2809 1.75988 15.7085 1.75988 14.9609V3.08086C1.75988 2.3332 2.33223 1.76086 3.07988 1.76086H14.6986L15.4411 0.922109C15.2658 0.922109 15.1352 0.880859 14.9599 0.880859H3.07988ZM15.9499 2.35211L8.33238 10.8221L4.68863 7.59086L4.11113 8.25086L8.07113 11.7709L8.40113 12.0596L8.68988 11.7296L16.6099 2.92961L15.9499 2.35211ZM18.0399 4.84086V5.72086H18.9199C19.6675 5.72086 20.2399 6.2932 20.2399 7.04086V18.9209C20.2399 19.6685 19.6675 20.2409 18.9199 20.2409H7.03988C6.29223 20.2409 5.71988 19.6685 5.71988 18.9209V18.0409H4.83988V18.9209C4.83988 20.1532 5.80754 21.1209 7.03988 21.1209H18.9199C20.1522 21.1209 21.1199 20.1532 21.1199 18.9209V7.04086C21.1199 5.80852 20.1522 4.84086 18.9199 4.84086H18.0399Z"
        fill="#234F68"
      />
    </g>
    <defs>
      <clipPath id="clip0_709_8157">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
