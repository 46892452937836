import { Link, useNavigate, useParams, useSearchParams } from "@solidjs/router";
import axios from "axios";
import deletedHomeIcon from "./deletedHomeIcon.svg";
import {
  Accessor,
  createEffect,
  createSignal,
  For,
  Index,
  mergeProps,
  onCleanup,
  onMount,
  Setter,
  Show,
} from "solid-js";

import Button from "../../Components/Button";
import { FlexCol, FlexRow } from "../../Components/Flex";
import Heading, { Colors } from "../../Components/Heading";
import Spacer from "../../Components/Spacer";
import fallbackHouse from "../../Icons/FallbackHouse.svg";
import {
  Asset,
  AssetTypeEnum,
  CreateAsset,
  EnumKey,
  PotentialBuyer,
} from "../../types";
import { BackButton } from "../AddClient/BackButton";
import { Call } from "../../Icons/Call";
import { Trash } from "../../Icons/Trash";
import { ArchiveFolder } from "../../Icons/ArchiveFolder";
import { Edit } from "../../Icons/Edit";
import { CustomersRoll } from "../Home/CustomersRoll";
import { Presence } from "@motionone/solid";
import { DealModal } from "../Customer/DealModal";
import shareWhatsapp from "../../utilities/shareWhatsApp";
import { StatusModal } from "./StatusModal";
import AssetVisibilityModal from "./AssetVisibilityModal";
import { user } from "../../globalState";
import { ReportIcon } from "../Customer/RelevantAssetsModals";
import ReportModal from "../../Components/Modals/ReportModal";
import call from "../../utilities/call";
import { Modal } from "../../Components/Modal";
import { UserIcon } from "../AddClient/BuyerForm";
import { getUserDetails } from "../../api/getUserDetails";
import { PicturesModal } from "./PicturesModal";
import { assetProperties } from "../PublicAsset/PublicAsset";
import { LocationIcon } from "../../Icons";
import { getAsset } from "./getAsset";
import { GenericModal } from "./GenericModal";
import { Capacitor } from "@capacitor/core";
import { ShareIcon } from "./ShareIcon";
import { EyeIcon } from "./EyeIcon";
import { GarbageIcon } from "./GarbageIcon";
import { logEvent } from "../../analytics";
import { Header } from "./Header";
import { isSmall } from "../Home/isSmall";
import { pricify } from "../AddAsset/AssetInfo";
import { createMutable, createStore } from "solid-js/store";
import ImageCard from "../../Components/ImageCard";
import CheckBox from "../../Components/CheckBox";
import { desktopWidth } from "../../App";
import { Spinner } from "../../Components/LoadingScreen/Spinner";
import CustomersTab from "./Customers/CustomersTab";
import { newReportModalStatus, setNewReportModalStatus } from "../../Store/Modals";

const ViewAsset = () => {
  let mobileContainer;

  onMount(() => {
    document.body.style.overflowY = "hidden";
  });
  onCleanup(() => {
    document.body.style.overflowY = "auto";
  });

  const [search] = useSearchParams();

  const assetId = () => search.asset;
  const [statusModal, setStatusModal] = createSignal(false);
  const asset = getAsset(assetId);
  const [dealModal, setDealModal] = createSignal(false);
  const [visibilityModal, setVisibilityModal] = createSignal(false);
  const myAsset = () => asset.data?.realtor === user.id;
  const [pictureModal, setPictureModal] = createSignal(false);
  const [notes, setNotes] = createSignal(false);
  const [openedModal, setOpenedModal] = createSignal("");
  const navigate = useNavigate();
  LogAnalytics(asset);
  const [reportModal, setReportModal] = createSignal(false);
  const [contactsModal, setContactsModal] = createSignal(false);


  const countWaitingImages = () => {
    let data:Array<number> = [];
    const count = asset.data.count_images_waiting_for_remove_logo;
    for (let i = 0; i < count; i++) {
      data.push(i)
    }
    return data
  }
  const advButtonEvent = () => {
    logEvent("button_click", {
      click_type: "קישור למודעה",
      button_type: 'yad2_source',
      asset_id: asset.data?.id,
      user_id: user.id
    });
  }


  return (
    <Show when={asset.data}>
      <Show when={reportModal()}>
        <ReportModal
          asset={asset.data}
          onClose={() => {
            setReportModal(false);
          }}
        />
      </Show>
      <ContactsModal
        asset={asset}
        setContactsModal={setContactsModal}
        contactsModal={contactsModal}
      />
      <Show when={pictureModal() !== false}>
        <PicturesModal
          increment={() => {
            if (asset.data.image_urls.length - 1 === pictureModal()) {
              return;
            }
            setPictureModal(Number(pictureModal()) + 1);
          }}
          decrement={() => {
            if (Number(pictureModal()) === 0) {
              return;
            }
            setPictureModal(Number(pictureModal()) - 1);
          }}
          setPictureModal={setPictureModal}
          pictureModal={pictureModal}
          asset={asset}
        />
      </Show>
      <Show when={openedModal() === "archive success"}>
        <ArchiveModal
          onsuccess={() => navigate(-1)}
          setSuccessModal={setOpenedModal}
        />
      </Show>
      <Show when={openedModal() === "delete success"}>
        <DeleteModal
          onsuccess={() => {
            navigate(-1);
            getUserDetails();
          }}
          setSuccessModal={setOpenedModal}
        />
      </Show>
      <Show when={openedModal() === "delete approval"}>
        <DeleteApproval asset={asset.data} setOpenedModal={setOpenedModal} />
      </Show>
      <div
        class="z-[70] fixed inset-0 h-screen md:bg-white overflow-y-auto mr-auto"
        ref={mobileContainer}
        classList={{
          [desktopWidth]: true,
          "bg-white": asset.data.image_urls.length,
          "bg-lightGrey": !asset.data.image_urls.length,
        }}
      >
        <div class="hidden md:block">
          <div>
            <FlexRow class="gap-2 px-8 w-full mb-4" justify="between">
              <div class="w-4/12 relative top-2">
                <BackButton onClick={() => navigate(-1)} />
              </div>
              <div class="flex gap-6">
                <Show when={asset.data?.advertisement_url && asset.data.advertisement_url.length}>
                  <a
                    href={asset.data?.advertisement_url}
                    target="_blank"
                    class="m-auto px-3 disabled:bg-buttonDisabled transition-all hover:shadow-buttonHoverGreen disabled:shadow-buttonDisabled text-s text-center flex items-center justify-center rounded-full gap-2 w-auto h-10 bg-green text-white"
                    onClick={advButtonEvent}
                  >
                    קישור למודעה
                  </a>
                </Show>
                <Show when={asset.data?.role === 'viewer' || asset.data?.role === null}>
                  {/* <Button
                    name="hide_property"
                    onClick={() => {
                      setNewReportModalStatus(true)
                    }}
                    short
                    color="green"
                    class="gap-2"
                    fullWidth
                  >
                    Hide Asset
                  </Button> */}
                  <Button
                    name="דווח"
                    onClick={() => {
                      logEvent("button_click", {
                        click_type: "דווח",
                        button_type: 'report'
                        // asset_id: asset.data.id,
                      });
                      setReportModal(true);
                    }}
                    short
                    color="green"
                    class="gap-2 w-24"
                  >
                    דווח <ReportIcon white />
                  </Button>
                  <Button
                    name="צור קשר"
                    onClick={() => {
                      logEvent("button_click", {
                        click_type: "צור קשר",
                        // asset_id: asset.data.id,
                        button_type: 'contact'
                      });
                      setContactsModal(true);
                    }}
                    short
                    color="green"
                    class="w-24 whitespace-nowrap"
                  >
                    צור קשר{" "}
                  </Button>
                  <button
                    onclick={() => {
                      logEvent("button_click", {
                        click_type: "שיתוף",
                        asset_id: asset.data?.id,
                      });
                      shareWhatsapp({
                        assets: [asset.data],
                      });
                    }}
                    class="bg-green rounded-full p-3"
                  >
                    <ShareIcon />
                  </button>
                </Show>
                <Show when={asset.data?.role === 'owner' || asset.data?.role === 'contact'}>
                  <div class="mt-8">
                    <div class="flex gap-4 justify-end">
                      <Show when={asset.data?.role === 'owner'}>
                        <button
                          onclick={async () => {
                            logEvent("button_click", {
                              button_type: "מחיקת נכס",
                              asset_id: asset.data?.id,
                            });
                            setOpenedModal("delete approval");
                          }}
                          class="bg-blue rounded-full p-3"
                        >
                          <Trash fill="white" />
                        </button>
                      </Show>
                      <button
                        onclick={() => {
                          logEvent("button_click", {
                            button_type: "עריכת נכס",
                            asset_id: asset.data?.id,
                          });
                          navigate("/edit-asset/" + asset.data.id, {
                            state: { seller: asset.data.seller },
                          });
                        }}
                        class="bg-blue rounded-full p-3"
                      >
                        <Edit fill="white" />
                      </button>
                      <button
                        onclick={() => {
                          logEvent("button_click", {
                            click_type: "שיתוף",
                            asset_id: asset.data?.id,
                          });
                          shareWhatsapp({
                            assets: [asset.data],
                          });
                        }}
                        class="bg-green rounded-full p-3"
                      >
                        <ShareIcon />
                      </button>
                    </div>
                    <div class="flex gap-4 mt-3">
                      <Show when={asset.data?.advertisement_url && asset.data.advertisement_url.length}>
                        <a
                          href={asset.data?.advertisement_url}
                          target="_blank"
                          class="m-auto px-3 disabled:bg-buttonDisabled transition-all hover:shadow-buttonHoverGreen disabled:shadow-buttonDisabled text-s text-center flex items-center justify-center rounded-full gap-2 w-auto h-10 bg-green text-white"
                          onClick={advButtonEvent}
                        >
                          קישור למודעה
                        </a>
                      </Show>
                      <Show when={asset.data?.role === 'owner'}>
                        <Button
                          color="blue"
                          short
                          onClick={() => {
                            logEvent("button_click", {
                              button_type: "מצב תצוגה",
                              asset_id: String(asset.data?.id),
                            });
                            setVisibilityModal(true);
                          }}
                          class="flex gap-2"
                        >
                          <EyeIcon white />
                          מצב תצוגה
                        </Button>
                      </Show>
                      <Button
                        name="עדכון סטטוס"
                        onClick={() => {
                          logEvent("button_click", {
                            button_type: "עדכון סטטוס",
                            asset_id: asset.data?.id,
                          });
                          setStatusModal(true);
                        }}
                        color="green"
                        class="w-fit px-4"
                        short
                      >
                        עדכון סטטוס
                      </Button>
                      <Show when={visibilityModal()}>
                        <AssetVisibilityModal
                          asset={asset.data}
                          refetch={asset.refetch}
                          onClose={() => setVisibilityModal(false)}
                        />
                      </Show>
                      <Show when={statusModal()}>
                        <StatusModal
                          closeAssetWindow={close}
                          asset={asset.data}
                          setStatusModal={setStatusModal}
                          setDealModal={setDealModal}
                        />
                      </Show>
                      <Show when={dealModal()}>
                        <DealModal
                          hide={() => setDealModal(false)}
                          asset={asset.data}
                          success={() => {
                            navigate("/");
                            getUserDetails();
                          }}
                        />
                      </Show>
                    </div>
                  </div>
                </Show>
                {/* <button
                  onclick={() => {
                    logEvent("button_click", {
                      click_type: "שיתוף",
                      button_type: 'share'
                      // asset_id: asset.data?.id,
                    });
                    shareWhatsapp({
                      assets: [asset.data],
                    });
                  }}
                  class="bg-green rounded-full p-3"
                >
                  <ShareIcon />
                </button> */}
              </div>
            </FlexRow>
          </div>
          <div class="flex pr-8 gap-4 justify-between">
            <div
              classList={{
                "w-3/12": asset.data?.image_urls.length > 1,
                "w-4/12": !(asset.data?.image_urls.length > 1),
              }}
            >
              <MainImage asset={asset} setPictureModal={setPictureModal} />
            </div>
            <Show when={asset.data?.image_urls.length > 1 || asset.data?.count_images_waiting_for_remove_logo > 0}>
              <div class="flex flex-col gap-4 w-1/12 h-[70vh]">
                <Show when={asset.data?.image_urls.length > 1}>
                  <ImagesRoll asset={asset} setPictureModal={setPictureModal} />
                </Show>
                <Show when={asset.data?.count_images_waiting_for_remove_logo > 0}>
                  <ImagesWaiting asset={asset} />
                </Show>
              </div>
            </Show>
            <div class="flex flex-col w-4/12 gap-4 h-[70vh]">
              <AssetPropertyList asset={asset} />
            </div>
            {/* <div class="flex flex-col rounded-xl bg-lightGrey h-[70vh] w-[30%] max-w-xs pb-4">
              <Show when={asset.data}>
                <CustomersRoll showNotes asset={asset} />
              </Show>
            </div> */}
            <Show when={asset}>
              <CustomersTab
                asset={asset}
              />
            </Show>
          </div>
        </div>

        <Show when={isSmall()}>
          <div classList={{ "bg-white": asset.data.image_urls.length }}>
            <Header
              setOpenedModal={setOpenedModal}
              close={() => navigate(-1)}
              myAsset={myAsset}
              asset={asset}
              setVisibilityModal={setVisibilityModal}
              shareWhatsapp={shareWhatsapp}
              setStatusModal={setStatusModal}
              statusModal={statusModal}
              setDealModal={setDealModal}
              dealModal={dealModal}
              visibilityModal={visibilityModal}
            />

            <Show when={asset.data.image_urls.length > 1 || asset.data.count_images_waiting_for_remove_logo > 0}>
              <div class="z-10 w-screen flex flex-col p-8 overflow-y-scroll bg-white mt-1">
                <div class="flex gap-2 w-fit">
                  <Index each={asset.data.image_urls}>
                    {(img, index) => (
                      <ImageCard
                        img={img()}
                        alt="תמונה של הנכס"
                        onImageClick={() => {
                          setPictureModal(index.toString());
                        }}
                      />
                    )}
                  </Index>
                  <For each={countWaitingImages()}>
                    {(item) => (
                      <span class="h-24 w-24 m-auto flex items-center justify-center rounded-md bg-lightGrey">
                        <Spinner fill="darkBlue" />
                      </span>
                    )}
                  </For>
                </div>
              </div>
            </Show>
            <Spacer height="6px" />
            <Button
              onClick={() => {
                if (!notes()) {
                  logEvent("button_click", {
                    click_type: "פרטים על הנכס",
                    button_type: 'info'
                    // asset_id: asset.data?.id,
                  });
                }
                setNotes(!notes());
              }}
              short
              name="more details"
              disabled={!asset.data.notes}
            >
              {asset.data.notes ? "פרטים על הנכס" : "אין פרטים נוספים על הנכס"}
            </Button>
            <div
              class="text-center overflow-y-scroll transition-all duration-500 p-0"
              classList={{
                "h-0": !notes(),
                "h-44": notes(),
              }}
            >
              <p class="p-8">{asset.data.notes}</p>
            </div>
            <Spacer height="6px" />
            {/* <Show when={asset.data?.potential_buyers.length}>
              <section>
                <CustomersRoll isAssetPage showNotes asset={asset} />
              </section>
            </Show> */}
            <FlexCol
              class="gap-2 bg-white"
            >
              <Show
                when={
                  user.sellers?.find(
                    (seller) => seller.id === asset.data?.seller
                  )?.name
                }
              >
                <Spacer />
                <Link href={"/customer/" + asset.data?.seller}>
                  <FlexRow
                    class="p-4 border-b-2 border-lightGrey w-11/12 m-auto"
                    justify="between"
                  >
                    <Heading side="right" color={Colors.secondary}>
                      בעל הנכס
                    </Heading>
                    <Heading>
                      {
                        user.sellers.find(
                          (seller) => seller.id === asset.data?.seller
                        ).name
                      }
                    </Heading>
                  </FlexRow>
                </Link>
              </Show>
              <For each={assetProperties(asset).filter((i) => i.value)}>
                {(property) => {
                  let value = property.value;
                  if (typeof property.value === "function") {
                    value = property.value();
                  }
                  return (
                    <FlexRow
                      class="p-4 border-b-2 border-lightGrey w-11/12 m-auto"
                      justify="between"
                    >
                      <Heading side="right" color={Colors.secondary}>
                        {property.name}
                      </Heading>
                      <span class="text-left">
                        {value} {property.area ? "מ״ר" : null}{" "}
                      </span>
                    </FlexRow>
                  );
                }}
              </For>
            </FlexCol>
            <Show
              when={asset}
              fallback={
                <Spacer height="120px" />
              }
            >
              <CustomersTab
                asset={asset}
                refParent={mobileContainer}
              />
            </Show>
          </div>
        </Show>
      </div>
      <Spacer height="48px" />
    </Show>
  );
};

function LogAnalytics(asset) {
  createEffect(() => {
    if (asset.data) {

      logEvent("asset_view", {
        id: asset.data?.id,
        address: normalizeAddress(asset.data),
      });
    }
  });
}

export function MyAssetHeader(props) {
  const {
    asset,
    setVisibilityModal,
    shareWhatsapp,
    setStatusModal,
    statusModal,
    setDealModal,
    dealModal,
    visibilityModal,
    close,
    setOpenedModal,
  } = mergeProps(props);

  const navigate = useNavigate();
  const [fallback, setFallback] = createSignal();
  return (
    <div class={`rounded-b-3xl overflow-hidden pt-2`}>
      <button
        onclick={() => {
          logEvent("button_click", {
            click_type: "מצב תצוגה",
            button_type: 'display_status'
            // asset_id: String(asset.data?.id),
          });
          setVisibilityModal(true);
        }}
        class="flex justify-center items-center gap-2 absolute rounded-r-xl left-0 z-20 p-2 bg-white"
        classList={{
          "top-28": Capacitor.getPlatform() === "ios",
          "top-[90px]": Capacitor.getPlatform() !== "ios",
        }}
      >
        <EyeIcon />
        מצב תצוגה
      </button>
      <img
        onerror={() => setFallback(true)}
        class="w-screen relative rounded-t-3xl-md -top-2 h-72 rounded-b-3xl"
        classList={{
          "opacity-0": fallback() || !asset.data.image_urls?.[0],
        }}
        src={asset.data.image_urls?.[0]}
        alt="main image of asset"
      />
      <div
        class="absolute w-screen top-0 h-72 rounded-b-3xl"
        style={{
          background: `linear-gradient(180deg, rgba(35, 79, 104, 0.12) 0%, #234F68 100%)`,
        }}
      >
        <div class="absolute rounded-t-3xl-md top-0 w-full h-fit">
          <div
            class="flex p-4 gap-2"
            classList={{ "mt-8": Capacitor.getPlatform() === "ios" }}
          >
            <div class="w-7/12">
              <BackButton onClick={() => navigate(-1)} />
            </div>
            <div class="flex flex-col items-center w-2/12"></div>
            <button
              onclick={async () => {
                logEvent("button_click", {
                  button_type: 'delete',
                  click_type: 'delete'
                  // button_type: "מחיקת נכס",
                  // asset_id: asset.data?.id,
                });
                setOpenedModal("delete approval");
              }}
              class="bg-blue rounded-full flex justify-center items-center w-12 h-10"
            >
              <Trash fill="white" />
            </button>
            <button
              onclick={() => {
                logEvent("button_click", {
                  button_type: 'edit',
                  click_type: 'edit'
                });
                navigate("/edit-asset/" + asset.data.id, {
                  state: { seller: asset.data.seller },
                });
              }}
              class="bg-blue rounded-full flex justify-center items-center w-12 h-10"
            >
              <Edit fill="white" />
            </button>
            <button
              onclick={() => {
                logEvent("button_click", {
                  button_type: 'share',
                  click_type: 'share'
                  // button_type: "שיתוף",
                  // asset_id: asset.data?.id,
                });
                shareWhatsapp({
                  assets: [asset.data],
                });
              }}
              class="bg-green rounded-full flex justify-center items-center w-12 h-10"
            >
              <ShareIcon />
            </button>
          </div>
          <div class="text-white font-medium pr-6 text-bold w-8/12">
            <span>{normalizeAddress(asset.data)}</span>
          </div>
          <div class="pr-6 text-white">
            {asset.data.asset_type
              ? AssetTypeEnum[asset.data.asset_type] + ", "
              : ""}
            {asset.data.city.name}
          </div>
          <Spacer height="15px" />
          <div class="flex">
            <div class="flex gap-2 pr-6">
              <div class="w-12 shadow-md rounded-md bg-white text-center text-xs font-bold p-2">
                {asset.data.number_of_rooms} <br />
                <div class="font-thin">חדרים</div>
              </div>
              <div class="w-12 shadow-md rounded-md bg-white text-center text-xs font-bold p-2">
                {asset.data.floor} <br />
                <div class="font-thin">קומה</div>
              </div>
              <div class="w-12 shadow-md rounded-md bg-white text-center text-xs font-bold p-2">
                {Number(asset.data.asset_built_area)} <br />{" "}
                <div class="font-thin">מ״ר</div>
              </div>
            </div>
            <Button
              name="עדכון סטטוס"
              onClick={() => {
                logEvent("button_click", {
                  button_type: 'status_update',
                  click_type: 'עדכון סטטוס'
                  // button_type: "עדכון סטטוס",
                  // asset_id: asset.data?.id,
                });
                setStatusModal(true);
              }}
              color="green"
              class="w-fit px-4 ml-4"
              short
            >
              עדכון סטטוס
            </Button>
            <Presence>
              <Show when={statusModal()}>
                <StatusModal
                  closeAssetWindow={close}
                  asset={asset.data}
                  setStatusModal={setStatusModal}
                  setDealModal={setDealModal}
                />
              </Show>
              <Show when={dealModal()}>
                <DealModal
                  asset={asset.data}
                  success={() => {
                    navigate("/");
                    getUserDetails();
                  }}
                  hide={() => setDealModal(false)}
                />
              </Show>
              <Show when={visibilityModal()}>
                <AssetVisibilityModal
                  asset={asset.data}
                  refetch={asset.refetch}
                  onClose={() => setVisibilityModal(false)}
                />
              </Show>
            </Presence>
          </div>
        </div>
      </div>
    </div>
  );
}

const ContactsModal = (props: {
  asset: { data: Asset };
  contactsModal: Accessor<boolean>;
  setContactsModal: Setter<boolean>;
}) => {
  const assetFather:any = () => {
    if (props.asset.data.realtor_data) {
      return props.asset.data.realtor_contacts.find(realtor => realtor.id === props.asset.data.realtor_data.id)
    }
    return null;
  }

  const contactsList = () => {
    return props.asset.data.realtor_contacts
  }


  return (
  <Modal
    classList={{ "max-w-lg": true }}
    open={props.contactsModal()}
    onClose={() => props.setContactsModal(false)}
  >
    <div class="text-center p-4">
      <h1 class="flex justify-center items-center gap-2 font-bold text-2xl">
        <LocationIcon />
        {normalizeAddress(props.asset.data)}
      </h1>
      <Spacer />
      <Show when={assetFather()}>
        <h2 class="text-lg text-center">
          { assetFather().name }
        </h2>
      </Show>
      <Spacer />
      <Show when={contactsList() && contactsList().length}>
        <h2 class="text-lg">אנשי קשר</h2>
        <Spacer />
        <For each={contactsList()}>
          {(contact) => (
            <div class="flex justify-between items-center">
              <div class="flex gap-4 items-center">
                <Show when={contact?.img_url}>
                  <img src={contact.img_url} width="39px" alt="contact picture" />
                </Show>
                <Show when={!contact?.img_url}>
                  <div class="w-24 scale-75">
                    <UserIcon />
                  </div>
                </Show>
                <span class="h-auto">{contact.name}</span>
              </div>
              <div class="flex gap-8">
                <button
                  onclick={() => {
                    logEvent("button_click", {
                      click_type: "שיחה עם מתווך",
                      button_type: 'contact'
                      // asset_id: props.asset.data.id,
                    });
                    call({ phone: contact.phone });
                  }}
                >
                  <Call />
                </button>
                <button
                  onclick={() => {
                    logEvent("button_click", {
                      click_type: "וואטספ עם מתווך",
                      button_type: 'contact'
                      // asset_id: props.asset.data.id,
                    });
                    shareWhatsapp({
                      phone: contact.phone,
                      content: "שלום רב, ",
                    });
                  }}
                >
                  <WhatsappOutlined />
                </button>
              </div>
            </div>
          )}
        </For>
      </Show>
    </div>
  </Modal>
  )
};

export function ColleagueAssetHeader(props) {
  const {
    close,
    asset,
    setVisibilityModal,
    shareWhatsapp,
    setStatusModal,
    statusModal,
    setDealModal,
    dealModal,
    visibilityModal,
    advButtonEvent
  } = mergeProps(props);
  const navigate = useNavigate();
  const [reportModal, setReportModal] = createSignal(false);
  const [contactsModal, setContactsModal] = createSignal(false);
  const agent = () => asset.data.realtor_contacts?.[0];
  return (
    <div class="rounded-b-3xl overflow-hidden">
      <img
        class="w-screen rounded-t-3xl-md top-0 h-72"
        classList={{
          "opacity-0": !asset.data.image_urls?.[0],
        }}
        src={asset.data.image_urls?.[0]}
        alt="main image of asset"
      />
      <div
        class="absolute w-screen top-0 h-72 rounded-b-3xl"
        style={{
          background: `linear-gradient(180deg, rgba(35, 79, 104, 0.12) 0%, #234F68 100%)`,
        }}
      ></div>
      <div
        class="absolute rounded-t-3xl-md top-0 w-full h-72"
        classList={{ "pt-8": Capacitor.getPlatform() === "ios" }}
      >
        <FlexRow class="p-2 gap-2" justify="between">
          <div class="w-7/12">
            <BackButton onClick={() => navigate(-1)} />
          </div>
          <div class="flex flex-col items-center w-2/12"></div>
          <Show when={asset.data.role === 'contact'}>
            <button
              onclick={() => {
                logEvent("button_click", {
                  button_type: 'edit',
                  click_type: 'edit'
                });
                navigate("/edit-asset/" + asset.data.id, {
                  state: { seller: asset.data.seller },
                });
              }}
              class="bg-blue rounded-full flex justify-center items-center w-12 h-10"
            >
              <Edit fill="white" />
            </button>
          </Show>
          <button
            onclick={() => {
              logEvent("button_click", {
                click_type: "שיתוף",
                button_type: "share asset",
                // asset_id: asset.data.id,
              });
              shareWhatsapp({
                assets: [asset.data],
              });
            }}
            class="bg-green rounded-full p-3"
          >
            <ShareIcon />
          </button>
        </FlexRow>
        <div class="flex gap-4 justify-between pl-8">
          <div>
            <div class=" text-white font-medium pr-6 text-bold text-xl">
              {asset.data.street.name} {asset.data.address_number || ""}
            </div>
            <div class="pr-6 text-white">
              {asset.data.asset_type
                ? AssetTypeEnum[asset.data.asset_type] + ", "
                : ""}
              {asset.data.city.name}
            </div>
            <Spacer height="15px" />
            <div class="flex">
              <div class="flex gap-2 pr-6">
                <div class="w-12 shadow-md rounded-md bg-white text-center text-xs font-bold p-2">
                  {asset.data.number_of_rooms} <br />
                  <div class="font-thin">חדרים</div>
                </div>
                <div class="w-12 shadow-md rounded-md bg-white text-center text-xs font-bold p-2">
                  {asset.data.floor} <br />
                  <div class="font-thin">קומה</div>
                </div>
                <div class="w-12 shadow-md rounded-md bg-white text-center text-xs font-bold p-2">
                  {Number(asset.data.asset_built_area)} <br />{" "}
                  <div class="font-thin">מ״ר</div>
                </div>
              </div>
              <Presence>
                <Show when={statusModal()}>
                  <StatusModal
                    closeAssetWindow={close}
                    asset={asset.data}
                    setStatusModal={setStatusModal}
                    setDealModal={setDealModal}
                  />
                </Show>
                <Show when={dealModal()}>
                  <DealModal
                    asset={asset.data}
                    success={() => {
                      navigate("/");
                      getUserDetails();
                    }}
                    hide={() => setDealModal(false)}
                  />
                </Show>
                <Show when={visibilityModal()}>
                  <AssetVisibilityModal
                    refetch={asset.refetch}
                    asset={asset.data}
                    onClose={() => setVisibilityModal(false)}
                  />
                </Show>
                <Show when={reportModal()}>
                  <ReportModal
                    asset={asset.data}
                    onClose={() => {
                      setReportModal(false);
                    }}
                  />
                </Show>
                <ContactsModal
                  asset={asset}
                  contactsModal={contactsModal}
                  setContactsModal={setContactsModal}
                />
              </Presence>
            </div>
          </div>
          <div>
            <Show when={asset.data?.advertisement_url && asset.data.advertisement_url.length}>
              <a
                href={asset.data?.advertisement_url}
                target="_blank"
                class="m-auto mb-5 px-3 disabled:bg-buttonDisabled transition-all hover:shadow-buttonHoverGreen disabled:shadow-buttonDisabled text-s text-center flex items-center justify-center rounded-full gap-2 w-auto h-10 bg-darkBlue text-white"
                onClick={advButtonEvent}
              >
                קישור למודעה
              </a>
            </Show>
            <Show when={agent()}>
              <button
                onclick={() => {
                  navigate("/a/" + agent().uuid);
                }}
              >
                <img
                  class="rounded-full h-16 w-16 border-2 border-white"
                  src={agent().image_url}
                  width="65px"
                  height="65px"
                  classList={{ "opacity-0": !agent().image_url, 'hidden': !agent().image_url }}
                />
                <p class="text-white">{agent().name}</p>
                <p class="text-darkGrey">{agent().agency.name}</p>
              </button>
            </Show>
          </div>
        </div>
        <div class="flex p-4 justify-around gap-10">
          <Show when={asset.data.role === 'contact'}>
            <Button
              name="עדכון סטטוס"
              onClick={() => {
                logEvent("button_click", {
                  button_type: 'status_update',
                  click_type: 'עדכון סטטוס'
                  // button_type: "עדכון סטטוס",
                  // asset_id: asset.data?.id,
                });
                setStatusModal(true);
              }}
              color="green"
              class="w-fit px-4 ml-4 mr-a"
              short
            >
              עדכון סטטוס
            </Button>
          </Show>
          <Show when={asset.data.role === 'viewer' || asset.data.role === null}>
              {/* <Button
                name="hide_property"
                onClick={() => {
                  setNewReportModalStatus(true)
                }}
                short
                color="green"
                class="gap-2"
                fullWidth
              >
                Hide Asset
              </Button> */}
              <Button
                name="דווח"
                onClick={() => {
                  logEvent("button_click", {
                    click_type: "דווח",
                    // asset_id: asset.data.id,
                    button_type: 'report'
                  });
                  setReportModal(true);
                }}
                short
                color="green"
                class="gap-2"
                fullWidth
              >
                דווח <ReportIcon white />
              </Button>
              <Button
                name="צור קשר"
                onClick={() => {
                  logEvent("button_click", {
                    click_type: "צור קשר",
                    // asset_id: asset.data.id,
                    button_type: 'contact'
                  });
                  setContactsModal(true);
                }}
                short
                color="green"
                fullWidth
              >
                צור קשר{" "}
              </Button>
          </Show>
        </div>
      </div>
    </div>
  );
}

export function ArchiveModal(props) {
  return GenericModal({
    header: "תודה על העדכון",
    subHeader: "הנכס עבר לארכיון שלך",
    alt: "archive confirm",
    icon: ArchiveFolder,
    options: [
      {
        color: "green",
        label: "אישור",
        handler: () => {
          props.onsuccess();
        },
      },
    ],
  });
}

export function DeleteModal(props) {
  const navigate = useNavigate();
  return (
    <GenericModal
      icon={GarbageIcon}
      header={"תודה על העדכון"}
      subHeader={"הנכס נמחק לצמיתות"}
      alt={"delete confirmation"}
      options={[
        {
          label: "אישור",
          color: "green",
          handler: () => {
            if (props.onsuccess) {
              props.onsuccess();
            } else {
              navigate(-1);
            }
          },
        },
      ]}
    />
  );
}

export function DeleteApproval({ setOpenedModal, asset }) {
  return GenericModal({
    options: [
      {
        label: "לא, מחק לצמיתות",
        color: "blue",
        handler: async () => {
          await axios.delete(`/real-estate/asset/${asset.id}/`);
          logEvent('button_click', {
            button_type: 'delete',
            click_type: 'לא, מחק לצמיתות'
          })

          setOpenedModal("delete success");
        },
      },
      {
        label: "כן, העבר לארכיון שלי",
        color: "green",
        handler: async () => {
          await axios.patch(`/real-estate/asset/${asset.id}/`, {
            visibility_status: "NO",
          });
          logEvent('button_click', {
            button_type: 'archive',
            click_type: 'כן, העבר לארכיון שלי'
          })
          setOpenedModal("archive success");
        },
      },
    ],
    alt: "delete icon",
    header: "",
    icon: deletedHomeIcon,
    onCancel: setOpenedModal,
  });
}

const WhatsappOutlined = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.16667 0C4.1123 0 0 4.1123 0 9.16667C0 10.6679 0.395408 12.071 1.03662 13.3221L0.0429688 16.8796C-0.188468 17.7061 0.628799 18.5233 1.45557 18.2922L5.01571 17.2985C6.26575 17.9384 7.66689 18.3333 9.16667 18.3333C14.221 18.3333 18.3333 14.221 18.3333 9.16667C18.3333 4.1123 14.221 0 9.16667 0ZM9.16667 1.375C13.4779 1.375 16.9583 4.85545 16.9583 9.16667C16.9583 13.4779 13.4779 16.9583 9.16667 16.9583C7.79094 16.9583 6.50403 16.6006 5.38184 15.9754C5.22365 15.8873 5.03708 15.8651 4.86263 15.9137L1.47705 16.8581L2.42236 13.4743C2.4712 13.2996 2.449 13.1126 2.3606 12.9542C1.73417 11.8312 1.375 10.5437 1.375 9.16667C1.375 4.85545 4.85545 1.375 9.16667 1.375ZM6.06844 5.04256C5.92223 5.04256 5.68599 5.0974 5.48478 5.31649C5.28403 5.53465 4.71851 6.06379 4.71851 7.14087C4.71851 8.21795 5.50236 9.25812 5.6119 9.40479C5.72098 9.55008 7.12587 11.8317 9.35107 12.7089C11.2 13.4377 11.576 13.2925 11.9775 13.2559C12.379 13.2201 13.2731 12.7272 13.4555 12.2157C13.6379 11.7042 13.638 11.2666 13.5844 11.1764C13.5298 11.0851 13.3836 11.0299 13.1645 10.9203C12.9459 10.8108 11.8703 10.2815 11.6696 10.2087C11.4688 10.1358 11.3221 10.0988 11.1764 10.3179C11.0306 10.537 10.6114 11.0297 10.4835 11.1755C10.3556 11.3221 10.2281 11.3405 10.009 11.231C9.78949 11.1205 9.08513 10.8899 8.24821 10.1442C7.59738 9.56441 7.15953 8.84774 7.03166 8.62866C6.90424 8.41049 7.0179 8.29059 7.12744 8.18197C7.22598 8.08388 7.34643 7.9267 7.45597 7.79883C7.5646 7.67095 7.60063 7.58024 7.6735 7.43449C7.74638 7.2892 7.71014 7.16089 7.6556 7.05135C7.60106 6.94181 7.17542 5.85883 6.98063 5.4257C6.81701 5.06178 6.64457 5.05389 6.48828 5.04793C6.36086 5.04289 6.21419 5.04256 6.06844 5.04256Z"
      fill="#234F68"
    />
  </svg>
);

export default ViewAsset;

export function ImagesRoll({ asset, setPictureModal }) {
  const fallback = createMutable([]);
  return (
    <Index each={asset.data.image_urls}>
      {(img, index) => (
        <button
          class="w-full m-0"
          onclick={() => {
            setPictureModal(index.toString());
          }}
        >
          <img
            onerror={() => {
              fallback.push(index);
            }}
            class="w-full rounded-md"
            src={
              fallback.includes(index) ? fallbackHouse : img() || fallbackHouse
            }
            alt="a picture of the asset"
          />
        </button>
      )}
    </Index>
  );
}

export function ImagesWaiting(asset:any) {
  let data:Array<number> = [];
  const count = asset.data.count_images_waiting_for_remove_logo
  for (let i = 0; i < count; i++) {
    data.push(i)
  }
  return (
    <For each={data}>
      {(item) => (
        <span class="w-full flex items-center justify-center rounded-md bg-lightGrey" style="min-height:141px">
          <Spinner fill="darkBlue" />
        </span>
      )}

    </For>
  )
}

export function MainImage({ asset, setPictureModal }) {
  const [fallback, setFallback] = createSignal();
  return (
    <>
      <button onclick={() => setPictureModal(true)} class="w-full">
        <img
          onerror={() => {
            setFallback(true);
          }}
          class="aspect-square rounded-2xl mb-8 w-full"
          src={
            fallback()
              ? fallbackHouse
              : asset.data.image_urls[0] || fallbackHouse
          }
          alt="main picture oh the asset"
        />
        <div class="text-blue text-2xl font-medium pr-6 mb-2">
          {normalizeAddress(asset.data)}
        </div>
      </button>
      <div class="pr-6 text-darkGrey mb-2">
        {asset.data.asset_type
          ? AssetTypeEnum[asset.data.asset_type] + ", "
          : ""}
        {asset.data.city.name}
      </div>{" "}
      <div class="flex gap-2 pr-6">
        <div class="w-12 shadow-md rounded-md bg-white text-center text-xs md:text-base md:w-20 font-bold p-2">
          {asset.data.number_of_rooms} <br />
          <div class="font-thin">חדרים</div>
        </div>
        <div class="w-12 shadow-md rounded-md bg-white text-center text-xs md:text-base md:w-20 font-bold p-2">
          {asset.data.floor} <br />
          <div class="font-thin">קומה</div>
        </div>
        <div class="w-12 shadow-md rounded-md bg-white text-center text-xs md:text-base md:w-20 font-bold p-2">
          {Number(asset.data.asset_built_area)} <br />{" "}
          <div class="font-thin">מ״ר</div>
        </div>
      </div>{" "}
      <div class="text-darkBlue text-3xl mt-6 mr-6 font-bold">
        {pricify(asset.data.price)}
      </div>
    </>
  );
}

export function AssetPropertyList({ asset }) {
  return (
    <For each={assetProperties(asset).filter((i) => i.value)}>
      {(property) => {
        let value = property.value;

        if (typeof property.value === "function") {
          value = property.value();
        }

        return (
          <FlexRow class="md:my-0 w-11/12 m-auto" justify="between">
            <Heading side="right" color={Colors.secondary}>
              {property.name}
            </Heading>
            <span class="text-left">
              {value} {property.area ? "מ״ר" : null}{" "}
            </span>
          </FlexRow>
        );
      }}
    </For>
  );
}

export function normalizeAddress(asset, whatToHide = {}) {
  return (
    asset.street.name +
      (asset?.address_number ? " " + asset.address_number : "") +
      (!whatToHide.neighborhood
        ? ", " + (asset.neighborhood?.name || "")
        : "") || ""
  );
}

function CheckIcon({}) {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6945 0L5.26877 11.1961L1.30547 6.93997L0 8.34191L5.26877 14L17 1.40194L15.6945 0Z"
        fill="white"
      />
    </svg>
  );
}
