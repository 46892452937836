const UserLocationIcon = (props: any) => {
	let circle = props.active ? '#234F68': '#F5F4F8';
	let icon = props.active ? '#fff' : '#BABABA';
	return (
		<svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="54.2441" y="53.5546" width="53.4848" height="53.4848" rx="26.7424" transform="rotate(-180 54.2441 53.5546)"
				fill={circle}
			/>
			<path
				d="M27.4242 16.115C23.6418 16.115 20.5781 19.1787 20.5781 22.9611C20.5781 26.0882 22.2295 29.4494 23.8541 32.0535C25.4787 34.6575 27.1033 36.5061 27.1033 36.5061C27.1852 36.598 27.3022 36.6515 27.4242 36.6515C27.5462 36.6515 27.6632 36.598 27.7451 36.5061C27.7451 36.5061 29.3714 34.6191 30.9943 32C32.6172 29.3809 34.2702 26.0281 34.2702 22.9611C34.2702 19.1787 31.2066 16.115 27.4242 16.115ZM27.4242 16.9707C30.7453 16.9707 33.4145 19.64 33.4145 22.9611C33.4145 25.7139 31.8584 28.9865 30.2723 31.5454C28.9435 33.6898 27.8186 35.0704 27.4242 35.5434C27.0264 35.0737 25.9015 33.7232 24.5761 31.5989C22.9916 29.0583 21.4339 25.7807 21.4339 22.9611C21.4339 19.64 24.1031 16.9707 27.4242 16.9707ZM27.4242 20.3938C25.7745 20.3938 24.429 21.7393 24.429 23.3889C24.429 25.0386 25.7745 26.3841 27.4242 26.3841C29.0739 26.3841 30.4193 25.0386 30.4193 23.3889C30.4193 21.7393 29.0739 20.3938 27.4242 20.3938ZM27.4242 21.2495C28.6109 21.2495 29.5636 22.2022 29.5636 23.3889C29.5636 24.5756 28.6109 25.5283 27.4242 25.5283C26.2375 25.5283 25.2848 24.5756 25.2848 23.3889C25.2848 22.2022 26.2375 21.2495 27.4242 21.2495Z"
				fill={icon}
			/>
		</svg>
	)
}
export default UserLocationIcon;