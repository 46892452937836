import { For, Show } from "solid-js";
import Button from "../../Components/Button";
import Heading, { Colors } from "../../Components/Heading";
import Spacer from "../../Components/Spacer";
import { Motion } from "@motionone/solid";
import { Modal } from "../../Components/Modal";
import { GenericModalProps } from "./GenericModalProps";

export const GenericModal = (props: GenericModalProps) => {
  let Icon;
  if (typeof props.icon === "string") {
    Icon = () => <img src={props.icon} alt={props.alt} />;
  } else {
    Icon = props.icon;
  }

  return (
    <Modal open onClose={props.onCancel} classList={{ "max-w-xl": true }}>
      <div class="z-40 flex flex-col items-center justify-center m-auto gap-2 mt-8">
        <Motion.div
          initial={{ scale: 0, rotate: "40deg" }}
          animate={{ scale: 1, rotate: "0deg" }}
          transition={{ duration: 1, easing: [0.85, 0, 0.8, 1.5] }}
        >
          <Icon />
        </Motion.div>
        <Heading class="text-2xl">{props.header} </Heading>
        <Heading class="text-xl" color={Colors.secondary}>
          {props.subHeader}{" "}
        </Heading>

        <Spacer />
        <For each={props.options}>
          {(option) => {
            return (
              <Button
                class="mb-2"
                onClick={() => {
                  option.handler();
                }}
                color={option.color}
                fullWidth
              >
                {option.label}
              </Button>
            );
          }}
        </For>

        <Spacer />
        <Show when={props.onCancel}>
          <button class="underline" onclick={props.onCancel}>
            ביטול
          </button>
        </Show>
      </div>
    </Modal>
  );
};
