import StepLogo from './logo.png'
import TextInput from "../../../Components/TextInput";
import Button from '../../../Components/Button';
import { A } from '@solidjs/router';
import { createSignal, onMount } from 'solid-js';
import { createStore } from 'solid-js/store';
import { RecaptchaVerifier, getAuth } from 'firebase/auth';
import { Capacitor } from '@capacitor/core';
import { lessThan700 } from "../../Home/isSmall";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { submitPhone, submitSMS, submitSMSNative } from "../../../api/login";
import phoneValidation from '../../../helpers/PhoneValidation';
import { createEventDispatcher } from '@solid-primitives/event-dispatcher';

import HouseImage from '../house-image.svg';

declare global {
	interface Window {
		recaptchaVerifier?: any	
	}
}
interface Props {
	onSetPhone: (evt:CustomEvent<any>) => void;
	phone?: any;
	ref?: any
}


const PhoneStep = (props: Props) => {
	const dispatch = createEventDispatcher(props)
	const [error, setError] = createSignal("");
	const [phone, setPhone] = createStore({
		value: props.phone,
		error: false,
		validation: true
	})


	const authWeb = async () => {
		const auth:any = getAuth();
		const user_phone = phoneValidation(phone.value);
		window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
			'size': 'invisible',
			'callback': (response:any) => {
				// console.log(response)
			}
		}, auth)
		const submit = await submitPhone({ phone: user_phone })
		if (submit && submit.error) {
			setError("שגיאה בהזנת טלפון");
		} else {
			dispatch('setPhone', {phone: phone.value}, {cancelable: true})
		}
	}


	return (
		<div class="registration__step">
			<div class="registration__step--title">
				<p class="st-logo">
					<img src={StepLogo} alt="" />
				</p>
				<span class="xs-phone">
					<img src={HouseImage} alt="" class="st-house" />
				</span>
				<h1 class="st-title">
					התחברות<span class="symbol">.</span>
				</h1>
				<p class="st-description">
					<b>ברוך הבא לגשר - מחברים חדשנות ונדל”ן </b><br/>
					הזן את מספר הטלפון על מנת להתחבר למערכת
				</p>
			</div>
			<div class="registration__step--form">
				<TextInput
					numeric
					value={phone.value}
					class="mt-2.5"
					type="number"
					placeholder="הזן מספר טלפון"
					name="phone"
					iconComponent={<UsernameIcon />}
					label="מספר טלפון"
					// error={phone.error}
					onChange={(e) => {
					  setPhone({value: e.currentTarget.value});
					//   props.ref(e.currentTarget.value)
					}}
				/>
				<div class="text-error text-center h-4">{error() || " "}</div>
				<p class="st-info">
					בלחיצה על כפתור הבא את/ה מסכימ/ה עם&nbsp;
					<A href="/login" class="st-link">
						התקנון
					</A>
				</p>
				<div class="submit--container">
					<Button
						color="green"
						name="phone-button"
						class={`w-full font-bold bottom-12 mt-16`}
						id="sign-in-button"
						fullWidth={true}
						onClick={async () => {
							if (phone.error) {
								return;
							}
							// const auth = getAuth();
							if (Capacitor.getPlatform() === "web") {
								authWeb();
							} else {
								const res =
									await FirebaseAuthentication.signInWithPhoneNumber({
										phoneNumber: phoneValidation(phone.value),
									});
									dispatch('setPhone', {phone: phone.value}, {cancelable: true})
							}
						}}
					>
						הבא
					</Button>
				</div>
			</div>
		</div>
	)
}

export default PhoneStep


const UsernameIcon = () => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_3017_22175)">
			<path
				d="M3.4375 4.125C2.30658 4.125 1.375 5.05658 1.375 6.1875V15.8125C1.375 16.9434 2.30658 17.875 3.4375 17.875H11.2162C11.8314 20.2393 13.9849 22 16.5349 22C19.5599 22 22.0349 19.525 22.0349 16.5C22.0349 15.0973 21.4974 13.8165 20.625 12.8423V6.1875C20.625 5.05658 19.6934 4.125 18.5625 4.125H3.4375ZM3.4375 5.5H18.5625C18.951 5.5 19.25 5.79904 19.25 6.1875V11.7264C18.8239 11.4824 18.3618 11.2966 17.875 11.1732V10.3125H12.375V11.6875H13.8829C12.1885 12.6275 11.0349 14.4349 11.0349 16.5H3.4375C3.04904 16.5 2.75 16.201 2.75 15.8125V6.1875C2.75 5.79904 3.04904 5.5 3.4375 5.5ZM7.5625 6.875C6.05183 6.875 4.8125 8.11433 4.8125 9.625C4.8125 10.417 5.16365 11.121 5.70544 11.6244C4.77711 12.2416 4.125 13.2457 4.125 14.4375H5.5C5.5 13.2904 6.41542 12.375 7.5625 12.375C8.70958 12.375 9.625 13.2904 9.625 14.4375H11C11 13.2457 10.3479 12.2416 9.41956 11.6244C9.96135 11.121 10.3125 10.417 10.3125 9.625C10.3125 8.11433 9.07317 6.875 7.5625 6.875ZM12.375 7.5625V8.9375H17.875V7.5625H12.375ZM7.5625 8.25C8.33021 8.25 8.9375 8.85729 8.9375 9.625C8.9375 10.3927 8.33021 11 7.5625 11C6.79479 11 6.1875 10.3927 6.1875 9.625C6.1875 8.85729 6.79479 8.25 7.5625 8.25ZM16.5349 12.375C18.8037 12.375 20.6599 14.2312 20.6599 16.5C20.6599 18.7688 18.8037 20.625 16.5349 20.625C14.2662 20.625 12.4099 18.7688 12.4099 16.5C12.4099 14.2312 14.2662 12.375 16.5349 12.375ZM18.5974 14.6443L16.3281 16.9122L14.7463 15.3318L13.7849 16.2932L16.3281 18.8378L19.5588 15.6057L18.5974 14.6443Z"
				fill="#234F68"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3017_22175">
			<rect width="22" height="22" fill="white" />
			</clipPath>
		</defs>
	</svg>
);