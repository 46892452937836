import { setToastr } from '../../globalState'
import { ContactPayload, Contacts, PickContactResult } from "@capacitor-community/contacts";
import './sass/ImportPhoneContact.sass'
import { Capacitor } from '@capacitor/core';
import { Show } from 'solid-js';
interface IProps {
    setter: any;
    phoneKey: string;
    nameKey: string;
};
const ImportPhoneContact = (props: IProps) => {
    const importPhoneButton = async () => {
        try {
            // props.setter({[props.phoneKey]: '+380939831669'})
            // props.setter({[props.nameKey]: 'test'})
            const options = {
                projection: {
                    name: true,
                    phones: true,
                    postalAddresses: true
                }
            }
            const data:PickContactResult = await Contacts.pickContact(options)
            const contact:ContactPayload = data.contact
            let note:null|string = null;
            let phone:null|string = null;
            let name:null|string = null;
            if (contact.note && contact.note.length > 0) {
                note = contact.note
            }
            if (contact.phones && contact.phones.length > 0) {
                phone = contact.phones[0].number
            }
            if (contact.name && contact.name.display && contact.name.display.length > 0) {
                name = contact.name.display
            }
            if (phone) {
                props.setter({[props.phoneKey]: phone})
            }
            if (name) {
                props.setter({[props.nameKey]: name})
            }
            setToastr({
                show: true,
                status: 'success',
                text: 'פרטי הקשר התווספו בהצלחה'
            })
        } catch(err) {
            console.log('error - ' + err)
            setToastr({
                show: true,
                status: 'error',
                text: 'Something went wrong'
            })
        }
    }
    return (
        <Show when={Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android'}>
            <div class="import-phone-client">
                <button
                    class="btn--import"
                    type="button"
                    onClick={() => {
                        importPhoneButton()
                    }}
                >
                <span class="icon">
                    <ImportButtonIcon />
                </span>
                    ייבוא מאנשי קשר
                </button>
            </div>
        </Show>
    )
}
export default ImportPhoneContact
const ImportButtonIcon = () => (
<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.0006 4.16667H11.849L11.4765 3.42167C11.1931 2.85333 10.6223 2.5 9.98565 2.5H3.00065C2.08148 2.5 1.33398 3.2475 1.33398 4.16667V15.8333C1.33398 16.7525 2.08148 17.5 3.00065 17.5H4.66732V15.8333H6.33398V17.5H14.6673V15.8333H16.334V17.5H18.0006C18.9198 17.5 19.6673 16.7525 19.6673 15.8333V5.83333C19.6673 4.91417 18.9198 4.16667 18.0006 4.16667ZM10.5007 6.66667C11.4448 6.66667 12.1673 7.38917 12.1673 8.33333C12.1673 9.2775 11.4448 10 10.5007 10C9.55648 10 8.83398 9.2775 8.83398 8.33333C8.83398 7.38917 9.55648 6.66667 10.5007 6.66667ZM13.834 13.3333H7.16732C7.16732 13.2025 7.16732 12.8133 7.16732 12.6808C7.16732 11.5908 8.67982 10.8333 10.5007 10.8333C12.3215 10.8333 13.834 11.5908 13.834 12.6808C13.834 12.8133 13.834 13.2025 13.834 13.3333Z" fill="#234F68"/>
</svg>
)