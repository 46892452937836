import logo from "../../Icons/logo.svg";

import { user } from "../../globalState";
import { A, Link, useLocation, useNavigate, useParams } from "@solidjs/router";
import { screenState } from "../../App";
import { Match, Show, Switch, createEffect, createMemo, createSignal } from "solid-js";
import { isSmall } from "./isSmall";
import NotificationBell from "../../Icons/NotificationBell";
import { UserIcon } from "../AddClient/BuyerForm";
import { Capacitor } from "@capacitor/core";
import { NotificationsPage } from "../../NotificationsPage";
import AssetsSearch from "../Assets/Search/Main";
import { useMatch } from "@solidjs/router";
import './Sass/Nav.sass'
interface IProps {
  classList?: string;
}

export function Nav(props: IProps) {
  // const matchAssets = useMatch(() => 'assets')
  // const matchSearch = useMatch(() => 'search-assets')
  const location = useLocation()
  const [fallback, setFallback] = createSignal(false);
  const [desktopNotifications, setNotifications] = createSignal(false);
  const [showSearchOnAssetsPage, setShowSearchOnAssetsPage] = createSignal(false)
  // createEffect(() => {
  //   if (location.search.includes('all%20assets')) {
  //     setShowSearchOnAssetsPage(true)
  //   } else {
  //     setShowSearchOnAssetsPage(false)
  //   }
  // })
  return (
    <nav
      class={`flex lg:bg-white justify-between px-4 lg:px-0 gap-8
        ${Capacitor.getPlatform() === "ios" ? "pt-12" : "pt-4"}
        ${props.classList}
      `}
    >
      <Show when={desktopNotifications()}>
        <NotificationsPage
          component
          closeComponent={() => setNotifications(false)}
        />
      </Show>
      <Show when={isSmall()}>
        <Link
          href={"/notifications"}
          aria-label="go to notifications"
          class="border-none relative pt-[6px] scale-95"
        >
          <NotificationBell />
          <div
            class="rounded-full flex items-center justify-center bg-green text-center w-[18px] h-[18px] absolute -top-0 -right-1  text-xs text-white"
            classList={{ "opacity-0": !user.notifications?.length }}
          >
            <strong>{user.notifications?.length}</strong>
          </div>
        </Link>

        <Link
          aria-label="go to home"
          activeClass=""
          class="pt-1 border-none"
          href="/"
        >
          <img src={logo} width="25px" alt="gesher logo" />
        </Link>
        <Link
          activeClass=""
          aria-label="go to profile"
          href={`/a/${user.uuid}`}
          class="border-none relative"
        >
          <Show
            when={!fallback() && user.image_url}
            fallback={<UserIcon height="33" width="33" />}
          >
            <img
              onerror={() => {
                setFallback(true);
              }}
              alt="user profile picture"
              src={user.image_url}
              width="44px"
              class="rounded-full h-8 w-8"
            />
          </Show>
        </Link>
      </Show>
      <Show when={!isSmall()}>
        <div class="flex justify-end gap-4 w-full items-center pl-2 pb-6">
          <Show when={location.pathname === '/public-profile'}>
              <div class="header--profile_back">
                <A href={`/a/${user.uuid}`} class="st-link">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.75 4.5L11.25 9L6.75 13.5" stroke="#234F68" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </A>
                <span class="st-title">
                  הפרופיל שלי
                </span>
              </div>
          </Show>
          <AssetsSearch />
          <button
            aria-label="go to notifictications"
            class="border-none relative"
            onclick={() => {
              setNotifications(true);
            }}
          >
            <NotificationBell />
            <div class="rounded-full bg-green w-5 h-5 absolute -top-2 -right-2 text-xs text-white">
              <div class="relative w-5 h-5">
                <p class="absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 text-white">
                  <strong>{user.notifications?.length}</strong>
                </p>
              </div>
            </div>
          </button>
          <Link
            aria-label="go to profile page"
            activeClass=""
            href={`/a/${user.uuid}`}
            class="rounded-full relative h-8 w-8"
          >
            <img
              onerror={() => {
                setFallback(true);
              }}
              classList={{ "opacity-0": fallback() || !user.image_url }}
              src={user.image_url}
              alt="תמונת משתמש"
              class="rounded-full h-8 w-8"
            />
          </Link>
        </div>
      </Show>
    </nav>
  );
}
