import { FlexCol, FlexRow } from "../../Components/Flex";
import TextInput from "../../Components/TextInput";
import Select from "../../Components/Select";

import { AnnotatedAsset } from "../../types";
import { createStore, SetStoreFunction } from "solid-js/store";
import Spacer from "../../Components/Spacer";
import getStreets from "../../api/getStreetsByName";
import AutoComplete from "../../Components/AutoComplete";
import { createEffect, createSignal } from "solid-js";
import { user } from "../../globalState";
import getNeighborhoodsByName from "../../api/getNeighborhoodsByName";
import { handleForm } from "../AddClient/AddClient";
import { pricify, unpricify } from "./AssetInfo";
import Button from "../../Components/Button";
import axios from "axios";

export function FastAddAsset(props: {}) {
  const [asset, setAsset]: [
    asset: AnnotatedAsset,
    setAsset: SetStoreFunction<AnnotatedAsset>
  ] = createStore({
    phone: "",
    name: "",
    number_of_rooms: 4,
    address_number: null,
    city: null,
    neighborhood: 0,
    neighborhoodName: "",
    streetName: "",
    street_name: "",
    price: 0,
    errors: { city: "" },
  });
  const [loading, setLoading] = createSignal(false);
  const cities = () =>
    user.activity_areas.reduce((acc, city) => {
      return { ...acc, [city.id]: city.name };
    }, {});

  const [streets, setStreets] = createStore({});
  createEffect(() => {
    const data = getStreets(
      () => asset.streetName,
      () => asset.city
    );
    setStreets(data.data);
  });

  const [neighborhoods, setNeighborhoods] = createStore({});
  createEffect(() => {
    const data = getNeighborhoodsByName(
      () => asset.neighborhoodName,
      () => asset.city
    );

    setNeighborhoods(data.data);
  });

  return (
    <FlexCol>
      <Select
        required
        class="w-10/12 lg:m-auto"
        label="יישוב"
        options={cities()}
        name="city"
        value={asset.city}
        onChange={(name, value) => {
          setAsset({ street: null });
          setAsset({ neighborhood: null });
          setAsset({ streetName: "" });
          setAsset({ neighborhoodName: "" });
          setAsset({ [name]: value });
        }}
      />
      <AutoComplete
        required
        class="w-10/12"
        label="שכונה"
        missingNeighborhood
        name="neighborhood"
        options={neighborhoods}
        value={asset.neighborhoodName}
        onSearch={(value) => {
          setAsset({ neighborhood: null });
          setAsset({ neighborhoodName: value });
        }}
        onChange={(id) => {
          setAsset({ street: null });

          setAsset({ neighborhood: id });
          setAsset({
            neighborhoodName: neighborhoods[id],
          });
        }}
      />

      <AutoComplete
        required
        class="w-10/12"
        label="רחוב"
        name="street"
        options={streets}
        value={asset.streetName}
        onSearch={(value) => {
          setAsset({ street: null });
          setAsset({ streetName: value });
        }}
        onChange={(streetId) => {
          setAsset({ street: streetId });
          setAsset({ streetName: streets[streetId] });
        }}
      />

      <TextInput
        class="w-10/12"
        type="tel"
        placeholder="0"
        required
        errorSetter={setAsset}
        label={"מחיר הנכס"}
        name="price"
        onChange={(e) => {
          e.target.value = unpricify(e.currentTarget.value);
          handleForm(e, setAsset);
        }}
        value={pricify(asset.price)}
      />
      <Spacer />
      <Button
        disabled={
          !asset.city ||
          !asset.neighborhood ||
          !asset.street ||
          !asset.price ||
          loading()
        }
        color="green"
        onClick={async () => {
          setLoading(true);
          const newAsset = await axios.post("/real-estate/asset/", asset);
          setLoading(false);

          props.setForm({
            newAsset: newAsset.data.id,
          });
          props.setForm({
            street:
              asset.streetName +
              " " +
              (asset.address_number ? asset.address_number + ", " : "") +
              cities()[asset.city],
          });
          props.close();
        }}
      >
        {loading() ? "טוען" : "הוסף נכס"}{" "}
      </Button>
    </FlexCol>
  );
}
