import StepLogo from './logo.png';
import UserDataSteps from './UserDataSteps';
import TextInput from '../../../Components/TextInput';
import Select from '../../../Components/Select';
import { createStore } from 'solid-js/store';
import Button from '../../../Components/Button';
import { createEventDispatcher } from '@solid-primitives/event-dispatcher';
import axios from 'axios';
import { Show, createSignal } from 'solid-js';
interface IProps {
	onSetCompanyData: (evt:CustomEvent<any>) => void;
	knownAgency: boolean;
	agencyData: {agency_name: string, agency_id: number};
	token: string;
}


const CompanyData = (props: IProps) => {
	const dispatch = createEventDispatcher(props)
	const [filePlaceholder, setFilePlaceholder] = createSignal('העלאת תמונת פרופיל');
	const [fileError, setFileError] = createSignal('')
	
	const step = 2;

	const [form, setForm] = createStore({
		company_name: '',
		facebook_url: '',
		instagram_url: '',
		role: '',
	})
	if (props.agencyData.agency_name) {
		setForm({
			company_name: props.agencyData.agency_name
		})
	}
	const role_options = {
		false: 'סוכן',
		true: 'מנהל משרד'
	}

	const addUserPhoto = async (files:any) => {
		const formData = new FormData();
		let file;
		if (files[0]) {
			file = files[0]
			formData.set("file", file);
		}
		const maxSize = 5 * 1024 * 1024;
		if (file) {
			if (file.size > maxSize) {
				setFileError('File size is to big. Maximum allowed size is 5mb')
				return;
			}
			const request = await axios.put(
				"/access-control/users/set_image/",
				formData,
				{
					headers: {
						Authorization: props.token
					}
				}
			);
			setFilePlaceholder(file.name)
		} else {
			setFileError('Unknown file error')
		}
	}
	
	return (
		<div class="registration__step">
			<div class="registration__step--title">
				<p class="st-logo">
					<img src={StepLogo} alt="" />
				</p>
				<h1 class="st-title">
					ברוך הבא<span class="symbol">!</span>
				</h1>
				<p class="st-description code">
					הצטרף למשפחת גשר ותהיה חלק<br/> ממהפכה בעולם הנדל”ן
				</p>
			</div>
			<UserDataSteps
				step={step}
			/>
			<div class="registration__step--form">
				<TextInput
					name="company_name"
					type="text"
					value={form.company_name}
					placeholder='שם המשרד'
					label="&nbsp;"
					disabled={props.knownAgency}
					iconComponent={<UserFileIcon />}
					onChange={(e) => {
						setForm({company_name: e.currentTarget.value})
					}}
				/>
				<Select
					name="role"
					label="&nbsp;"
					disabled={false}
					value={form.role}
					placeholder='בחר תפקיד'
					options={role_options}
					onChange={(name, value) => {
						setForm({role: value})
					}}
				/>
				<div class="file--container">
					<label class="file--title">
						{filePlaceholder()}
					</label>
					<input
						accept="image/*"
						type="file"
						class="input--file"
						oninput={(e:any) => {
							if (e.currentTarget && e.currentTarget.files) {
								addUserPhoto(e.currentTarget.files)
							}
						}}
					/>
					<span class="input--icon">
						<FileIcon />
					</span>
				</div>
				<Show
					when={fileError().length}
				>
					<div class="text-error text-center h-4">
						{fileError()}
					</div>
				</Show>
				<TextInput
					name="facebook"
					type="text"
					class="input_social mt-4"
					value={form.facebook_url}
					placeholder='שייך פייסבוק'
					label="הוספת לינק לרשתות החברתיות:"
					iconComponent={<FacebookIcon />}
					onChange={(e) => {
						setForm({facebook_url: e.currentTarget.value})
					}}
				/>
				<TextInput
					name="instagram"
					type="text"
					class="input_social"
					value={form.instagram_url}
					placeholder='שייך אינסטגרם'
					label="&nbsp;"
					iconComponent={<InstagramIcon />}
					onChange={(e) => {
						setForm({instagram_url: e.currentTarget.value})
					}}
				/>
				<Show
					when={
						form.company_name.length &&
						form.role.length
					}
				>
					<div class="submit--container">
						<Button
							color="green"
							name="phone-button"
							class={`w-full font-bold bottom-12 mt-6`}
							fullWidth={true}
							onClick={() => {
								dispatch('setCompanyData', form, {cancelable: true})
							}}
						>
							המשך
						</Button>
					</div>
				</Show>
			</div>
		</div>
	)
}


export default CompanyData;


const FacebookIcon = () => (
	<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M21.9993 3.66663C11.8738 3.66663 3.66602 11.8745 3.66602 22C3.66602 32.1255 11.8738 40.3333 21.9993 40.3333C32.1248 40.3333 40.3327 32.1255 40.3327 22C40.3327 11.8745 32.1248 3.66663 21.9993 3.66663Z" fill="url(#paint0_linear_165_10827)"/>
		<path d="M24.4805 26.8593H29.2251L29.9704 22.0394H24.4805V19.4049C24.4805 17.4029 25.135 15.6273 27.0077 15.6273H30.0171V11.4217C29.4882 11.3502 28.3699 11.1943 26.257 11.1943C21.8441 11.1943 19.2573 13.5245 19.2573 18.8338V22.0403H14.7207V26.8602H19.2564V40.1078C20.1547 40.2417 21.065 40.3333 21.999 40.3333C22.8433 40.3333 23.6674 40.2563 24.4805 40.1463V26.8593Z" fill="white"/>
		<defs>
		<linearGradient id="paint0_linear_165_10827" x1="9.1596" y1="9.16021" x2="37.2298" y2="37.2304" gradientUnits="userSpaceOnUse">
		<stop stop-color="#2AA4F4"/>
		<stop offset="1" stop-color="#007AD9"/>
		</linearGradient>
		</defs>
	</svg>
	)
	const InstagramIcon = () => (
	<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M31.1817 38.4908L12.8483 38.5083C8.81499 38.5119 5.51224 35.2156 5.50765 31.1823L5.49024 12.8489C5.48657 8.8156 8.7829 5.51285 12.8162 5.50826L31.1496 5.49085C35.1829 5.48718 38.4856 8.78351 38.4902 12.8168L38.5076 31.1502C38.5122 35.1844 35.215 38.4872 31.1817 38.4908Z" fill="url(#paint0_radial_165_10830)"/>
		<path d="M31.1817 38.4908L12.8483 38.5083C8.81499 38.5119 5.51224 35.2156 5.50765 31.1823L5.49024 12.8489C5.48657 8.8156 8.7829 5.51285 12.8162 5.50826L31.1496 5.49085C35.1829 5.48718 38.4856 8.78351 38.4902 12.8168L38.5076 31.1502C38.5122 35.1844 35.215 38.4872 31.1817 38.4908Z" fill="url(#paint1_radial_165_10830)"/>
		<path d="M22.0007 28.4167C18.4632 28.4167 15.584 25.5384 15.584 22C15.584 18.4617 18.4632 15.5834 22.0007 15.5834C25.5381 15.5834 28.4173 18.4617 28.4173 22C28.4173 25.5384 25.5381 28.4167 22.0007 28.4167ZM22.0007 17.4167C19.4734 17.4167 17.4173 19.4728 17.4173 22C17.4173 24.5273 19.4734 26.5834 22.0007 26.5834C24.5279 26.5834 26.584 24.5273 26.584 22C26.584 19.4728 24.5279 17.4167 22.0007 17.4167Z" fill="white"/>
		<path d="M28.875 16.5C29.6344 16.5 30.25 15.8844 30.25 15.125C30.25 14.3656 29.6344 13.75 28.875 13.75C28.1156 13.75 27.5 14.3656 27.5 15.125C27.5 15.8844 28.1156 16.5 28.875 16.5Z" fill="white"/>
		<path d="M27.5007 33.9167H16.5007C12.9632 33.9167 10.084 31.0384 10.084 27.5V16.5C10.084 12.9617 12.9632 10.0834 16.5007 10.0834H27.5007C31.0381 10.0834 33.9173 12.9617 33.9173 16.5V27.5C33.9173 31.0384 31.0381 33.9167 27.5007 33.9167ZM16.5007 11.9167C13.9734 11.9167 11.9173 13.9728 11.9173 16.5V27.5C11.9173 30.0273 13.9734 32.0834 16.5007 32.0834H27.5007C30.0279 32.0834 32.084 30.0273 32.084 27.5V16.5C32.084 13.9728 30.0279 11.9167 27.5007 11.9167H16.5007Z" fill="white"/>
		<defs>
		<radialGradient id="paint0_radial_165_10830" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.7644 38.5321) scale(41.1574 41.1574)">
		<stop stop-color="#FFDD55"/>
		<stop offset="0.328" stop-color="#FF543F"/>
		<stop offset="0.348" stop-color="#FC5245"/>
		<stop offset="0.504" stop-color="#E64771"/>
		<stop offset="0.643" stop-color="#D53E91"/>
		<stop offset="0.761" stop-color="#CC39A4"/>
		<stop offset="0.841" stop-color="#C837AB"/>
		</radialGradient>
		<radialGradient id="paint1_radial_165_10830" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.8032 5.07862) scale(27.3286 18.209)">
		<stop stop-color="#4168C9"/>
		<stop offset="0.999" stop-color="#4168C9" stop-opacity="0"/>
		</radialGradient>
		</defs>
	</svg>
	)
const UserFileIcon = () => (
<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M4.1444 0.888062C3.3477 0.888062 2.65657 1.2133 2.21537 1.70966C1.77416 2.20602 1.57044 2.83896 1.57044 3.46202C1.57044 4.06006 1.78425 4.65725 2.18951 5.144C0.965229 5.84128 0.0996094 7.10724 0.0996094 8.60994V12.9592L1.57044 14.4301V20.0089H3.04128V13.8211L1.57044 12.3502V8.60994C1.57044 7.17998 2.71444 6.03598 4.1444 6.03598C5.57436 6.03598 6.71836 7.17998 6.71836 8.60994V12.3502L5.24753 13.8211V20.0089H6.71836V14.4301L8.18919 12.9592V8.60994C8.18919 7.10724 7.32357 5.84128 6.09929 5.144C6.50456 4.65725 6.71836 4.06006 6.71836 3.46202C6.71836 2.83896 6.51464 2.20602 6.07343 1.70966C5.63223 1.2133 4.9411 0.888062 4.1444 0.888062ZM7.26418 0.888062C7.61571 1.31313 7.87819 1.81322 8.03263 2.35889H10.3954V6.77139H14.8079V18.5381H8.18919V20.0089H16.2788V5.73147L11.4354 0.888062H7.26418ZM4.1444 2.35889C4.57339 2.35889 4.80154 2.49329 4.97318 2.68639C5.14482 2.87948 5.24753 3.16581 5.24753 3.46202C5.24753 3.75823 5.14482 4.04456 4.97318 4.23765C4.80154 4.43075 4.57339 4.56514 4.1444 4.56514C3.71541 4.56514 3.48726 4.43075 3.31562 4.23765C3.14398 4.04456 3.04128 3.75823 3.04128 3.46202C3.04128 3.16581 3.14398 2.87948 3.31562 2.68639C3.48726 2.49329 3.71541 2.35889 4.1444 2.35889ZM11.8663 3.39882L13.768 5.30056H11.8663V3.39882Z" fill="#234F68"/>
</svg>
)
const FileIcon = () => (
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M13.9999 0.33252L13.5974 0.717519L9.11742 5.19752C8.8943 5.42064 8.8943 5.77939 9.11742 6.00252C9.34055 6.22564 9.6993 6.22564 9.92242 6.00252L13.4399 2.48502V17.92C13.4377 18.1213 13.5427 18.3094 13.7177 18.4122C13.8927 18.5128 14.1071 18.5128 14.2821 18.4122C14.4571 18.3094 14.5621 18.1213 14.5599 17.92V2.48502L18.0774 6.00252C18.3005 6.22564 18.6593 6.22564 18.8824 6.00252C19.1055 5.77939 19.1055 5.42064 18.8824 5.19752L14.4024 0.717519L13.9999 0.33252ZM3.91992 8.96002V28H24.0799V8.96002H18.4799C18.2787 8.95783 18.0905 9.06283 17.9877 9.23783C17.8871 9.41283 17.8871 9.62721 17.9877 9.80221C18.0905 9.97721 18.2787 10.0822 18.4799 10.08H22.9599V26.88H5.03992V10.08H9.51992C9.72117 10.0822 9.9093 9.97721 10.0121 9.80221C10.1127 9.62721 10.1127 9.41283 10.0121 9.23783C9.9093 9.06283 9.72117 8.95783 9.51992 8.96002H3.91992Z" fill="white"/>
</svg>
)