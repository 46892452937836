export const ArchiveFolder = () => (
  <svg
    width="110"
    height="110"
    viewBox="0 0 110 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.5625 41.25H13.75V25.7812C13.75 21.9845 16.8283 18.9062 20.625 18.9062H39.6103C42.6284 18.9062 45.2942 20.8742 46.1811 23.76L51.5625 41.25Z"
      fill="#8BC83F"
    />
    <path
      opacity="0.3"
      d="M54.9999 109.988C76.4176 109.988 93.78 107.679 93.78 104.832C93.78 101.984 76.4176 99.6753 54.9999 99.6753C33.5822 99.6753 16.2197 101.984 16.2197 104.832C16.2197 107.679 33.5822 109.988 54.9999 109.988Z"
      fill="black"
    />
    <path
      d="M85.9375 29.2188H24.0625C18.3666 29.2188 13.75 33.8353 13.75 39.5312V79.0625C13.75 84.7584 18.3666 89.375 24.0625 89.375H85.9375C91.6334 89.375 96.25 84.7584 96.25 79.0625V39.5312C96.25 33.8353 91.6334 29.2188 85.9375 29.2188Z"
      fill="#234F68"
    />
    <path
      d="M55 51.5625H50.7031C49.28 51.5625 48.125 50.4075 48.125 48.9844C48.125 47.5612 49.28 46.4062 50.7031 46.4062H55V51.5625Z"
      fill="#8BC83F"
    />
    <path
      d="M55 51.5625H59.2969C60.72 51.5625 61.875 50.4075 61.875 48.9844C61.875 47.5612 60.72 46.4062 59.2969 46.4062H55V51.5625Z"
      fill="#8BC83F"
    />
    <path
      d="M55 42.9688H50.7031C49.28 42.9688 48.125 41.8137 48.125 40.3906C48.125 38.9675 49.28 37.8125 50.7031 37.8125H55V42.9688Z"
      fill="#8BC83F"
    />
    <path
      d="M55 42.9688H59.2969C60.72 42.9688 61.875 41.8137 61.875 40.3906C61.875 38.9675 60.72 37.8125 59.2969 37.8125H55V42.9688Z"
      fill="#8BC83F"
    />
    <path
      d="M55 34.375H50.7031C49.28 34.375 48.125 33.22 48.125 31.7969C48.125 30.3737 49.28 29.2188 50.7031 29.2188H55V34.375Z"
      fill="#8BC83F"
    />
    <path
      d="M55 34.375H59.2969C60.72 34.375 61.875 33.22 61.875 31.7969C61.875 30.3737 60.72 29.2188 59.2969 29.2188H55V34.375Z"
      fill="#8BC83F"
    />
    <path
      d="M60.1563 57.1777V56.7188C60.1563 54.8195 58.618 53.2812 56.7188 53.2812H53.2813C51.3821 53.2812 49.8438 54.8195 49.8438 56.7188V57.1708C49.8438 57.7552 49.531 58.2777 49.0549 58.6145C46.0041 60.7716 44.1719 64.5373 44.8165 68.683C45.5143 73.1637 49.2474 76.7405 53.7522 77.2698C60.0051 78.0038 65.3126 73.138 65.3126 67.0312C65.3126 63.5508 63.5835 60.4794 60.9418 58.6128C60.4674 58.2777 60.1563 57.7586 60.1563 57.1777Z"
      fill="#8BC83F"
    />
    <path
      d="M58.4375 29.2188H51.5625V60.1562H58.4375V29.2188Z"
      fill="#8BC83F"
    />
    <path
      d="M55 70.4688C56.8985 70.4688 58.4375 68.9297 58.4375 67.0312C58.4375 65.1328 56.8985 63.5938 55 63.5938C53.1015 63.5938 51.5625 65.1328 51.5625 67.0312C51.5625 68.9297 53.1015 70.4688 55 70.4688Z"
      fill="#234F68"
    />
    <path
      opacity="0.15"
      d="M96.25 79.0625V49.8438C91.5045 49.8438 87.6562 53.6903 87.6562 58.4375V79.0625C87.6562 80.0112 86.8845 80.7812 85.9375 80.7812H53.2812C48.5358 80.7812 44.6875 84.6278 44.6875 89.375H85.9375C91.6334 89.375 96.25 84.7584 96.25 79.0625Z"
      fill="black"
    />
    <path
      opacity="0.3"
      d="M49.8438 37.8125C54.5892 37.8125 58.4375 33.9659 58.4375 29.2188H24.0625C18.3666 29.2188 13.75 33.8353 13.75 39.5312V58.4375C18.4955 58.4375 22.3438 54.5909 22.3438 49.8438V39.5312C22.3438 38.5825 23.1155 37.8125 24.0625 37.8125H49.8438Z"
      fill="white"
    />
    <path
      d="M20.625 45.5469V39.5312C20.625 37.6355 22.1667 36.0938 24.0625 36.0938H35.7655"
      stroke="white"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
