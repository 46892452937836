import { createQuery } from "@tanstack/solid-query";
import { fetchAsset, fetchPublicAsset } from "./fetchAsset";

export function getAsset(id: () => string) {
  return createQuery({
    queryFn: () => {
      return fetchAsset(id());
    },
    // initialData: {
    //   asset_levels: [],
    //   potential_buyers: [],
    //   image_urls: [],
    //   street: { name: "" },
    //   city: { name: "" },
    // },
    queryKey: () => ["assets", id()],
  });
}

export function getPublicAsset(id: () => string, client_status: string) {
  return createQuery({
    refetchOnWindowFocus: false,
    staleTime: 120000,
    queryFn: () => {
      return fetchPublicAsset(id(), client_status);
    },
    queryKey: () => ["public assets", id()],
  });
}
