export function whenWasIt(start, end) {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;
  const oneHour = 1000 * 60 * 60;
  const oneMinute = 1000 * 60;

  // Calculating the time difference between two dates
  const diffInTime = (date2.getTime() - date1.getTime()) * -1;
  // Calculating the no. of days between two dates
  let diff = Math.round(diffInTime / oneDay);
  let unit = "ימים";
  const today = diff < 1;
  if (today) {
    unit = "שעות";
    diff = Math.round(diffInTime / oneHour);
  }
  const thisHour = diff < 1;
  if (thisHour) {
    unit = "דקות";
    diff = Math.round(diffInTime / oneMinute);
  }

  return { diff, unit };
}
