export const EmptyIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="1.10645"
      width="23"
      height="23"
      rx="1.5"
      fill="#F5F4F8"
      stroke="#234F68"
    />
  </svg>
);
