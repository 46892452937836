import { createEffect } from "solid-js";

export const ErrorBar = (props: {
  hidden?: boolean;
  errors: string | any[];
  form: { errors: {} };
}) => {
  function RequiredFieldText() {
    if (props.form.errors) {
      return Array.from(
        new Set(
          Object.keys(props.form.errors).map((key) => props.form.errors[key])
        )
      );
    }
  }

  return (
    <div
      class="bg-error text-white text-center flex items-center justify-center py-2 w-full"
      classList={{ hidden: props.hidden }}
    >
      {RequiredFieldText()}{" "}
      {Array.isArray(props.errors)
        ? props.errors.map((err) => <span class="mx-2">{err.msg}</span>)
        : props.errors}
    </div>
  );
};
