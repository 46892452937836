import axios from "axios"
import { createSignal } from "solid-js"
import { createStore } from "solid-js/store"
import { setToastr } from "../globalState"

export const checkWhatsupStatus = async () => {
    const request = await axios.get('whatsapp/client/')
        .then(resp => {
            if (resp.status == 200) {
                setWhatsupStatus(resp.data)
            }
        })
        .catch(err => {
            console.log(err)
        })
}
interface whatsupStatus {
    state: string;
    count_dispatches_in_this_month: number;
    limit_count_dispatches_per_month: number;
}
export const [whatsupStatus, setWhatsupStatus] = createSignal<whatsupStatus>()