import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { Capacitor } from "@capacitor/core";
import { Navigator } from "@solidjs/router";
import { NotificationTypes } from "../types";
import { onMessage, getToken, getMessaging } from "firebase/messaging";
import { captureException } from "@sentry/capacitor";
import axios from "axios";
import { GetTokenOptions } from "@capacitor-firebase/messaging";
import { isDev } from "solid-js/web";
import worker from "../../public/firebase-messaging.js?worker";
export const initializeNotifications = async (navigate: Navigator) => {
  if (!Capacitor.isNativePlatform()) {
    const options: GetTokenOptions = {
      vapidKey:
        "BCF6-7ORtmNp6FJ6sz--mhD7C1fMp8vdCHxZ_lP2DUFCpiOH41XMUeWwqzKjQXAybNxbB8muU9fxxCJUHGyRxhA",
    };
    options.serviceWorkerRegistration = await navigator.serviceWorker.register(
      isDev
        ? "../public/firebase-messaging.js"
        : "/assets/firebase-messaging.js"
    );

    const { token } = await FirebaseMessaging.getToken(options);

    //subscribe to msgs on server
    await axios.post("/access-control/users/subscribe_register_token/", {
      registration_token: token,
    });
    ("");
    console.log("subscribed to token mate no worries");
    const messaging = getMessaging();
    onMessage(messaging, (e) => {
      //this if foreground activities only - service worker is for background activities
      const notification: Notification = e.data;
      if (!notification) {
        return;
      }

      switch (notification.notification_type) {
        case NotificationTypes.NEW_CALL:
          const params = {
            id: notification.id,
            phone: notification.phone,
          };
          const url = new URLSearchParams(params);
          navigate("/phonecall?" + url.toString());

          break;

        // case NotificationTypes.RELEVANT_ASSET:
        //   navigate("?asset=" + notification.id);
        //   break;

        // case NotificationTypes.TASK_REMINDER:
        //   navigate("/task/" + notification.id);
      }
    });
  }
  if (Capacitor.isNativePlatform()) {
    console.log("not web");
    const result = await FirebaseMessaging.requestPermissions();
    await FirebaseMessaging.getToken();

    if (result.receive === "granted") {
      FirebaseMessaging.addListener("notificationReceived", (e) => {
        console.log(e);
        const notification: Notification = e.notification.data;
        if (!notification) {
          return;
        }

        if (notification.notification_type === NotificationTypes.NEW_CALL) {
          const params = {
            id: notification.id,
            phone: notification.phone,
          };
          const url = new URLSearchParams(params);
          navigate("/phonecall?" + url.toString());
        }
      });

      FirebaseMessaging.addListener("notificationActionPerformed", (e) => {
        console.log(e);
        const notification: Notification = e.notification.data;

        if (!notification) {
          return;
        }

        switch (notification.notification_type) {
          case NotificationTypes.NEW_CALL:
            if (notification.notification_type === NotificationTypes.NEW_CALL) {
              const params = {
                id: notification.id,
                phone: notification.phone,
              };
              const url = new URLSearchParams(params);
              navigate("/phonecall?" + url.toString());
            }
            break;

          case NotificationTypes.RELEVANT_ASSET:
            navigate("?asset=" + notification.id);
            break;

          case NotificationTypes.TASK_REMINDER:
            navigate("/task/" + notification.id);
        }
      });
    }

    return result.receive;
  }
};

type Notification = {
  notification_type: string;
  phone?: string;
  dont_call_me: string;
  name: string;
  id: string;
};
