export const CloseIcon = (props: { color?: string }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8 0C6.18572 0 0 6.18572 0 13.8C0 21.4143 6.18572 27.6 13.8 27.6C21.4143 27.6 27.6 21.4143 27.6 13.8C27.6 6.18572 21.4143 0 13.8 0ZM13.8 1.2C20.7657 1.2 26.4 6.83428 26.4 13.8C26.4 20.7657 20.7657 26.4 13.8 26.4C6.83428 26.4 1.2 20.7657 1.2 13.8C1.2 6.83428 6.83428 1.2 13.8 1.2ZM18.5941 8.3918C18.4359 8.39554 18.2855 8.46167 18.1758 8.57578L13.8 12.9516L9.42422 8.57578C9.3683 8.5183 9.30144 8.47262 9.22757 8.44142C9.1537 8.41022 9.07433 8.39415 8.99414 8.39414C8.87478 8.39417 8.75814 8.4298 8.65913 8.49647C8.56013 8.56313 8.48326 8.65781 8.43834 8.7684C8.39343 8.87899 8.38251 9.00046 8.407 9.11728C8.43148 9.2341 8.49024 9.34097 8.57578 9.42422L12.9516 13.8L8.57578 18.1758C8.5182 18.2311 8.47222 18.2973 8.44055 18.3706C8.40888 18.4438 8.39215 18.5227 8.39133 18.6025C8.39052 18.6824 8.40565 18.7615 8.43582 18.8354C8.46599 18.9094 8.51061 18.9765 8.56706 19.0329C8.6235 19.0894 8.69065 19.134 8.76455 19.1642C8.83846 19.1944 8.91765 19.2095 8.99747 19.2087C9.0773 19.2079 9.15616 19.1911 9.22943 19.1594C9.30271 19.1278 9.36893 19.0818 9.42422 19.0242L13.8 14.6484L18.1758 19.0242C18.2311 19.0818 18.2973 19.1278 18.3706 19.1594C18.4438 19.1911 18.5227 19.2079 18.6025 19.2087C18.6824 19.2095 18.7615 19.1944 18.8354 19.1642C18.9094 19.134 18.9765 19.0894 19.0329 19.0329C19.0894 18.9765 19.134 18.9094 19.1642 18.8354C19.1944 18.7615 19.2095 18.6824 19.2087 18.6025C19.2079 18.5227 19.1911 18.4438 19.1594 18.3706C19.1278 18.2973 19.0818 18.2311 19.0242 18.1758L14.6484 13.8L19.0242 9.42422C19.1114 9.34043 19.1712 9.23227 19.1958 9.11392C19.2204 8.99556 19.2087 8.87253 19.1622 8.76094C19.1157 8.64935 19.0366 8.5544 18.9353 8.48853C18.8339 8.42266 18.715 8.38894 18.5941 8.3918Z"
      fill={props.color ? props.color : "white"}
    />
  </svg>
);
