import { Motion, Presence } from "@motionone/solid";
import { useNavigate } from "@solidjs/router";
import { JSX, Show, onCleanup, onMount } from "solid-js";
import { CloseIcon } from "../Icons/CloseIcon";
import { Portal, classList } from "solid-js/web";

export const Modal = (props: {
  children: JSX.Element;
  onClose?: () => void;
  disableClose?: boolean;
  open: boolean;
  classList?: { string: boolean };
}) => {
  let listener;

  return (
    <Show when={props.open}>
      <CloseOnEscape close={props.onClose} />
      <Portal mount={document.getElementById("mainview")}>
        <Presence>
          <Motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            class="fixed bg-blue inset-0 bg-opacity-80 z-[100] flex items-center justify-center"
          >
            <div
              class="relative w-11/12 md:w-10/12"
              classList={{ ...props.classList }}
            >
              <Show when={props.onClose}>
                <button
                  aria-label="close modal"
                  id="closeModal"
                  onclick={props.onClose}
                  class="absolute -top-8 right-2 "
                >
                  <CloseIcon />
                </button>
              </Show>
              <div class="bg-white m-auto rounded-md p-4 max-h-[85vh] overflow-y-auto">
                {props.children}
              </div>
            </div>
          </Motion.div>
        </Presence>
      </Portal>
    </Show>
  );
};

export const CloseOnEscape = (props: { close: () => void }) => {
  function closeOnEscape(e: KeyboardEvent) {
    if (e.key === "Escape" && props.close) {
      props.close();
    }
  }
  onMount(() => {
    window.addEventListener("keydown", closeOnEscape);
  });

  onCleanup(() => {
    window.removeEventListener("keydown", closeOnEscape);
  });

  return <></>;
};
