import { createEffect, createSignal } from "solid-js";
import { createStore, SetStoreFunction } from "solid-js/store";
import { HomeRealtor, RealtorProfile } from "./types";
import { boolean } from "zod";

const [user, setUser]: [
  user: HomeRealtor,
  setUser: SetStoreFunction<HomeRealtor>
] = createStore({
  id: null,
  name: null,
  active_assets: null,
  activity_areas: [],
});


const [toastr, setToastr] = createStore({
  status: 'success',
  show: false,
  text: ''
})

const [loggedIn, setLoggedIn] = createSignal<string | boolean>("loading");

const [mobileNavbarVisible, setMobileNavbarVisible] = createSignal(true)

export { user, setUser, loggedIn, setLoggedIn, mobileNavbarVisible, setMobileNavbarVisible, toastr, setToastr };
