import { For, Show, Setter, createSignal} from "solid-js";
import phoneValidation from "../../../helpers/PhoneValidation";
import { NewNoteModal } from "../../Customer/NewNoteModal";
import { logEvent } from "../../../analytics";
import call from "../../../utilities/call";
import shareWhatsapp from "../../../utilities/shareWhatsApp";
import { A } from "@solidjs/router";
import { user } from "../../../globalState";
import blockedIcon from '../../../Icons/user-blocked.svg'
interface IProps {
	customer: any;
	asset: any;
	checked: Array<string>;
	setChecked: Setter<string>;
	index: number;
	visible: number;
	showCheckbox: boolean;
	customerSend: Function;
	customerType: string;
}
const CustomersListItem = (props: IProps) => {
	let phone = phoneValidation(props.customer.phone).replaceAll('-', '')
	const customerId = props.customer.id
	const [notesStatus, setNotesStatus] = createSignal(false)
	const [noteModalStatus, setNoteModalStatus] = createSignal(false)

	return (
		<div
			class="asset--customers__list--item"
			classList={{
				'vip': props.customer.is_vip,
				'mobile-hide': props.index > props.visible
			}}
		>
			<div class="asset--customers__list--item-content">
				<Show when={props.customer.is_vip}>
					<span class="vip-label">
						Vip
					</span>
				</Show>
				<input
					type="checkbox"
					class="input--checkbox"
					style={{
						'visibility': !props.showCheckbox ? 'hidden' : 'visible'
					}}
					value={customerId}
					checked={props.checked.includes(customerId)}
					name={props.customer.id}
					disabled={props.customer.whatsapp_do_not_contact}
					onChange={(e) => {
						let checked:any = [...props.checked]
						if (e.currentTarget.checked && !props.checked.includes(customerId)) {
							checked.push(customerId)
						} else {
							const findIndex = checked.findIndex((item:string) => customerId === item)
							if (findIndex >= 0) {
								checked.splice(findIndex, 1)
							}
						}
						props.setChecked(checked)
					}}
				/>
				<A
					class="user-name"
					href={`/customer/${props.customer.id}`}
				>
					{props.customer.name ? props.customer.name : phone}
				</A>
				<Show when={props.customer.whatsapp_do_not_contact === true}>
					<img src={blockedIcon} alt="blocked icon" class="sub-icon" title="לא מעוניין בקבלת הודעות" />
				</Show>
				{/* <Show
					when={Boolean(
						user.sellers?.find((seller) => {
							return String(seller.id) === String(props.customer.id);
						})
					)}
				>
					<a
						href="#"
						class="st-link"
						onClick={(e) => {
							e.preventDefault()
						}}
					>
						<HomeIcon />
					</a>
				</Show> */}
				<span class="st-info">
					<span class="info-count">
						{props.customer.notes.length}
					</span>
					הערות
				</span>
				<button
					type="button"
					class="st-link-sub"
					onClick={(e) => setNotesStatus(!notesStatus())}
				>
					<ChevronDown />
				</button>
			</div>
			<div class={notesStatus() ? 'open asset--customers__list--item-notes' : 'asset--customers__list--item-notes'}>
				<div class="asset__notes--list">
				<For
					each={props.customer.notes}
				>
					{(note) => (
						<div class="asset__notes--list-item">
							<span class="st-date">
								{new Date(note.created_at).toLocaleDateString()}
							</span>
							<p class="st-note">
								{note.content}
							</p>
						</div>
					)}
				</For>
				</div>
				<div class="asset__notes--add">
					<button
						class="btn--note"
						onClick={() => setNoteModalStatus(true)}
					>
						הוסף הערה
						<PlusIcon index={props.index} />
					</button>
				</div>
				<div class="asset__notes--actions">
					<button
						class="btn btn--call"
						onclick={() => {
							call({ phone: phone });
						}}
					>
						<PhoneIcon /> התקשר
					</button>
					<button
						class="btn btn--share"
						onclick={() => {
							const data = {
								id: props.customer.id,
								// id: 489,
								type: props.customerType
							}
							props.customerSend(data)
							logEvent('button_click', {
								click_type: 'התקשר',
								button_type: 'share_asset'
							})
						}}
					>
						<MessageIcon /> שלח נכס
					</button>
				</div>
			</div>
			<Show when={noteModalStatus()}>
				<NewNoteModal
					id={props.customer.id}
					refetch={props.asset.refetch}
					setNewNoteModal={setNoteModalStatus}
				/>
			</Show>
		</div>
	);
}

export default CustomersListItem

const ChevronDown = () => (
<svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.1681 1L7.66809 8L1.16809 0.999999" stroke="#234F68" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)

const HomeIcon = () => (
<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.16833 0.955078L0.45813 8.66528L1.25354 9.46069L1.98083 8.7334V15.2505H7.04333V10.188H9.29333V15.2505H14.3558V8.7334L15.0831 9.46069L15.8785 8.66528L13.2308 6.01758V3.43799H12.1058V4.89258L8.16833 0.955078ZM8.16833 2.5459L13.2308 7.6084V14.1255H10.4183V9.06299H5.91833V14.1255H3.10583V7.6084L8.16833 2.5459Z" fill="#234F68"/>
</svg>
)

const PlusIcon = (props:any) => (
<svg width="24" height="24" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" class="fill-blue stroke-blue" fill="#234F68">
	<circle cx="15" cy="15" r="15" class="fill-blue stroke-blue"></circle>
	<g clip-path="url(.clip0_519_7352)">
		<path d="M14.6381 7.33788C14.547 7.43345 14.4973 7.56122 14.5 7.69327L14.5 14.5001L7.69322 14.5001C7.62633 14.4991 7.55992 14.5116 7.49792 14.5367C7.43593 14.5619 7.37961 14.5992 7.3323 14.6465C7.26195 14.7169 7.21421 14.8066 7.19517 14.9043C7.17612 15.002 7.18665 15.1031 7.22541 15.1948C7.26417 15.2864 7.32938 15.3644 7.41272 15.4188C7.49606 15.4732 7.59372 15.5014 7.69322 15.5L14.5 15.5L14.5 22.3068C14.4987 22.3733 14.5106 22.4394 14.5351 22.5013C14.5597 22.5631 14.5963 22.6194 14.6428 22.667C14.6894 22.7145 14.745 22.7522 14.8063 22.778C14.8676 22.8038 14.9335 22.817 15 22.817C15.0665 22.817 15.1324 22.8038 15.1937 22.778C15.255 22.7522 15.3106 22.7145 15.3572 22.667C15.4037 22.6194 15.4403 22.5631 15.4648 22.5013C15.4894 22.4394 15.5013 22.3733 15.4999 22.3068L15.4999 15.5L22.3068 15.5C22.3733 15.5013 22.4394 15.4894 22.5012 15.4649C22.5631 15.4404 22.6194 15.4038 22.6669 15.3572C22.7144 15.3106 22.7522 15.2551 22.7779 15.1938C22.8037 15.1324 22.817 15.0666 22.817 15C22.817 14.9335 22.8037 14.8677 22.7779 14.8063C22.7522 14.745 22.7144 14.6894 22.6669 14.6429C22.6194 14.5963 22.5631 14.5597 22.5012 14.5352C22.4394 14.5107 22.3733 14.4987 22.3068 14.5001L15.4999 14.5001L15.4999 7.69327C15.502 7.59251 15.4736 7.49348 15.4184 7.40916C15.3632 7.32483 15.2838 7.25916 15.1906 7.22073C15.0975 7.18231 14.9949 7.17294 14.8963 7.19384C14.7977 7.21475 14.7077 7.26495 14.6381 7.33788Z" stroke="white"></path>
	</g>
	<defs>
		<clipPath class="clip0_519_7352">
			<rect width="16" height="16" transform="translate(7 7)" stroke="white"></rect>
		</clipPath>
	</defs>
</svg>
)

const PhoneIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
<path d="M4.53706 0.00666808C4.23975 -0.0148796 3.93512 0.0153942 3.63639 0.103339L2.56724 0.418839C1.42797 0.754512 0.537799 1.65505 0.236633 2.79168C-0.432077 5.31944 0.332901 8.31141 2.32377 11.6793C4.31185 15.0425 6.57718 17.18 9.13565 17.8776C10.2878 18.1918 11.5304 17.8917 12.4031 17.0841L13.2164 16.3327C14.1265 15.4908 14.2622 14.0887 13.5269 13.0924L12.3519 11.5026C11.7221 10.6503 10.6118 10.2875 9.59083 10.593L7.75245 11.1414C7.67529 11.1639 7.49061 11.0592 7.32991 10.9208L7.32903 10.92C7.0428 10.672 6.64622 10.1708 6.22194 9.45407V9.45319C5.767 8.68344 5.57296 8.18002 5.49506 7.88273C5.41712 7.5853 5.43604 7.54912 5.44742 7.44859C5.45335 7.39842 5.47727 7.35194 5.518 7.31501L5.51888 7.31413L6.88972 6.06531C7.6751 5.35003 7.9122 4.20697 7.46929 3.24515L6.64272 1.44883V1.44795C6.25357 0.602877 5.42874 0.0712933 4.53706 0.00666808ZM4.43914 1.35303C4.85385 1.3839 5.23573 1.63172 5.41038 2.01128L6.23782 3.8076C6.43807 4.24245 6.33765 4.74007 5.97583 5.0696L4.60498 6.31753C4.32396 6.57281 4.14424 6.92085 4.10128 7.29655V7.29743C4.09009 7.39623 4.06364 7.76363 4.1842 8.22371C4.30477 8.68379 4.55368 9.29233 5.05399 10.1387H5.05487C5.5217 10.9275 5.9629 11.5267 6.44071 11.9403H6.4416C6.59677 12.0741 7.24711 12.6951 8.13177 12.4377L8.13442 12.4368L9.98073 11.8858C10.4602 11.7423 10.9728 11.9136 11.2607 12.3032L12.4357 13.893C12.7685 14.3446 12.7103 14.9585 12.2946 15.3431L11.4813 16.0954C10.9611 16.577 10.1887 16.7649 9.49291 16.5752C7.42462 16.0112 5.37795 14.1856 3.49172 10.9947C1.60288 7.7994 1.01211 5.15661 1.54661 3.13618C1.72329 2.46936 2.26464 1.91583 2.95185 1.71335L4.02012 1.39785C4.15928 1.35689 4.3009 1.34274 4.43914 1.35303Z" fill="white"></path>
</svg>
)

const MessageIcon = () => (
<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.4935 2.37349e-05C18.3821 0.000898895 18.2724 0.0258732 18.1728 0.0730394L0.63441 8.3806C0.521694 8.43398 0.425464 8.51407 0.355063 8.61308C0.284662 8.7121 0.242482 8.82668 0.232619 8.94569C0.222756 9.06471 0.245546 9.18412 0.298774 9.29233C0.352003 9.40054 0.433862 9.49388 0.536404 9.56327L3.75254 11.7402L5.13891 15.6804C5.18109 15.8002 5.25708 15.9068 5.35865 15.9887C5.46021 16.0705 5.58349 16.1245 5.71511 16.1448C5.84674 16.165 5.98171 16.1507 6.10539 16.1035C6.22907 16.0563 6.33676 15.9779 6.4168 15.8769L7.44634 14.5761L12.223 17.8672C12.3159 17.9314 12.4235 17.9738 12.5369 17.9912C12.6504 18.0085 12.7666 18.0002 12.8761 17.967C12.9856 17.9338 13.0853 17.8766 13.1671 17.8001C13.2488 17.7235 13.3104 17.6298 13.3467 17.5265L19.1929 0.911367C19.2296 0.806829 19.2396 0.695481 19.2218 0.586578C19.204 0.477676 19.1591 0.374366 19.0907 0.285234C19.0223 0.196103 18.9324 0.123727 18.8285 0.0741204C18.7247 0.0245141 18.6098 -0.000888622 18.4935 2.37349e-05ZM16.501 4.18175L12.2753 16.1906L8.35314 13.489L16.501 4.18175ZM12.7539 4.18806L4.3501 10.4431L2.37284 9.10444L12.7539 4.18806ZM13.6227 5.30132L6.73746 13.1672C6.7365 13.1681 6.73555 13.169 6.7346 13.1699L6.73175 13.1735C6.72423 13.182 6.71694 13.1908 6.70986 13.1996C6.70199 13.2091 6.69437 13.2187 6.68703 13.2285L6.08472 13.9884L5.2274 11.55L13.6227 5.30132Z" fill="white"></path>
</svg>
)