/* @refresh reload */
import "./index.css";
import { render } from "solid-js/web";
import * as Sentry from "@sentry/capacitor";
import App from "./App";
import { Router } from "@solidjs/router";
import axios from "axios";
import { QueryClientProvider, QueryClient } from "@tanstack/solid-query";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://4998cb9a11a848c99a33aaa68f344c09@o4504675324067840.ingest.sentry.io/4504675325771776",
    // Set your release version, such as 'getsentry@1.0.0'
    // Set your dist version, such as "1"
  });
}
const queryClient = new QueryClient();

axios.defaults.baseURL = import.meta.env.VITE_API_URL;
render(
  () => (
    <QueryClientProvider client={queryClient}>
      <Router>
        <App />
      </Router>
    </QueryClientProvider>
  ),
  document.getElementById("root") as HTMLElement
);
