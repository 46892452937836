import { createQuery } from "@tanstack/solid-query";
import axios from "axios";
import { City } from "../types";

export function getCitiesByName(cityName) {
  return createQuery({
    queryFn: () => {
      return getCities(cityName());
    },
    queryKey: () => ["cities", cityName()],
  });
}

const getCities = async (city: string) => {
  try {
    if (city) {
      const { data } = await axios.get("/real-estate/cities/?search=" + city);

      const cities = {};
      data.results.forEach((city: City) => {
        cities[city.id] = city.name;
      });

      return cities;
    } else {
      return {};
    }
  } catch (err) {
    if (import.meta.env.DEV) {
      return { tlv: "תל אביב" };
    }
  }
};
