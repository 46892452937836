import StepLogo from './logo.png';
import UserDataSteps from './UserDataSteps';
import TextInput from '../../../Components/TextInput';
import { createSignal, Show } from 'solid-js';
import { createStore } from 'solid-js/store';
import Button from '../../../Components/Button';
import axios from 'axios';
import { createEventDispatcher } from '@solid-primitives/event-dispatcher';
import TranslateError from '../../../helpers/TranslateError';

// '{"skip":0,"City":"חולון","FirstName":"רותם","MisparTik":"3134020","LastName":"יצחק לוי"}'
interface IProps {
	onSetUserData: (evt:CustomEvent<any>) => void;
	token: string;
	phone: string;
}


const UserData = (props: IProps) => {
	const dispatch = createEventDispatcher(props)
	const step = 1;
	const [error, setError] = createSignal('')

	const [form, setForm] = createStore({
		first_name: '',
		last_name: '',
		license_number: '',
		user_id: '',
		phone: props.phone
	})

	const checkLicense = async () => {
		let data = {...form}
		const request = await axios.post('/rentals/realtors/verify-license/', form, {
			headers: {
				Authorization: props.token
			}
		})
			.then(resp => {
				data.user_id = resp.data.user_id
				dispatch('setUserData', data, {cancelable: true})
			})
			.catch(err => {
				setError(TranslateError(err.response.data))
				console.log(err)
			})
		// dispatch('setUserData', form, {cancelable: true})
	}

	
	return (
		<div class="registration__step">
			<div class="registration__step--title">
				<p class="st-logo">
					<img src={StepLogo} alt="" />
				</p>
				<h1 class="st-title">
					ברוך הבא<span class="symbol">!</span>
				</h1>
				<p class="st-description code">
					הצטרף למשפחת גשר ותהיה חלק ממהפכה בעולם הנדל”ן
				</p>
			</div>
			<UserDataSteps
				step={step}
			/>
			<div class="registration__step--form">
				<TextInput
					name="first_name"
					type="text"
					value={form.first_name}
					placeholder='שם פרטי'
					label="&nbsp;"
					iconComponent={<UserIcon />}
					onChange={(e) => {
						setForm({first_name: e.currentTarget.value})
					}}
				/>
				<TextInput
					name="last_name"
					type="text"
					value={form.last_name}
					placeholder='שם משפחה'
					label="&nbsp;"
					iconComponent={<UserIcon />}
					onChange={(e) => {
						setForm({last_name: e.currentTarget.value})
					}}
				/>
				<TextInput
					name="license"
					type="text"
					value={form.license_number}
					placeholder='מס’ רישיון תיווך'
					label="&nbsp;"
					iconComponent={<LicenseIcon />}
					onChange={(e) => {
						setForm({license_number: e.currentTarget.value})
					}}
				/>
				<Show
					when={error().length}
				>
					<div class="text-error text-center h-4">
						{error()}
					</div>
				</Show>
				<div class="submit--container">
					<Button
						color="green"
						name="phone-button"
						class={`w-full font-bold bottom-12 mt-6`}
						fullWidth={true}
						onClick={() => {
							checkLicense()
						}}
					>
						המשך
					</Button>
				</div>
			</div>
		</div>
	)
}


export default UserData;

const LicenseIcon = () => (
	<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_165_10065)">
			<path d="M4.09883 5.30945C2.88909 5.30945 1.89258 6.30596 1.89258 7.5157V17.8115C1.89258 19.0213 2.88909 20.0178 4.09883 20.0178H12.4197C13.0778 22.5469 15.3814 24.4303 18.1091 24.4303C21.3449 24.4303 23.9924 21.7828 23.9924 18.5469C23.9924 17.0465 23.4174 15.6764 22.4842 14.6343V7.5157C22.4842 6.30596 21.4877 5.30945 20.278 5.30945H4.09883ZM4.09883 6.78028H20.278C20.6935 6.78028 21.0134 7.10017 21.0134 7.5157V13.4407C20.5576 13.1796 20.0633 12.9809 19.5426 12.8489V11.9282H13.6592V13.399H15.2723C13.4597 14.4045 12.2258 16.3379 12.2258 18.5469H4.09883C3.6833 18.5469 3.36341 18.2271 3.36341 17.8115V7.5157C3.36341 7.10017 3.6833 6.78028 4.09883 6.78028ZM8.51133 8.25111C6.89537 8.25111 5.56966 9.57683 5.56966 11.1928C5.56966 12.04 5.94529 12.7931 6.52484 13.3315C5.53181 13.9918 4.83424 15.0658 4.83424 16.3407H6.30508C6.30508 15.1137 7.2843 14.1344 8.51133 14.1344C9.73836 14.1344 10.7176 15.1137 10.7176 16.3407H12.1884C12.1884 15.0658 11.4908 13.9918 10.4978 13.3315C11.0774 12.7931 11.453 12.04 11.453 11.1928C11.453 9.57683 10.1273 8.25111 8.51133 8.25111ZM13.6592 8.98653V10.4574H19.5426V8.98653H13.6592ZM8.51133 9.72195C9.33254 9.72195 9.98216 10.3716 9.98216 11.1928C9.98216 12.014 9.33254 12.6636 8.51133 12.6636C7.69011 12.6636 7.04049 12.014 7.04049 11.1928C7.04049 10.3716 7.69011 9.72195 8.51133 9.72195ZM18.1091 14.1344C20.536 14.1344 22.5216 16.1201 22.5216 18.5469C22.5216 20.9738 20.536 22.9594 18.1091 22.9594C15.6822 22.9594 13.6966 20.9738 13.6966 18.5469C13.6966 16.1201 15.6822 14.1344 18.1091 14.1344ZM20.3153 16.5619L17.8879 18.9879L16.1959 17.2973L15.1674 18.3257L17.8879 21.0477L21.3438 17.5903L20.3153 16.5619Z" fill="#234F68"/>
		</g>
		<defs>
			<clipPath id="clip0_165_10065">
				<rect width="23.5333" height="23.5333" fill="white" transform="translate(0.421875 0.896973)"/>
			</clipPath>
		</defs>
	</svg>
	)
const UserIcon = () => (
<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M16.2003 17.0867C14.8731 18.2835 13.1153 19.0121 11.1874 19.0121C9.14142 19.0121 7.28707 18.1915 5.93555 16.8615" stroke="#234F68" stroke-width="1.71152" stroke-linecap="round"/>
	<path d="M15.8698 16.703C15.7302 16.5296 15.5808 16.3631 15.4219 16.2042C14.2517 15.034 12.6646 14.3766 11.0097 14.3766C9.35474 14.3766 7.76759 15.034 6.59738 16.2042C6.38731 16.4143 6.19376 16.6378 6.01758 16.8727" stroke="#234F68" stroke-width="1.71152" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M11.1876 13.6637C12.0387 13.6637 12.855 13.3256 13.4568 12.7237C14.0586 12.1219 14.3967 11.3057 14.3967 10.4546C14.3967 9.60347 14.0586 8.78723 13.4568 8.1854C12.855 7.58358 12.0387 7.24548 11.1876 7.24548C10.3365 7.24548 9.52026 7.58358 8.91844 8.1854C8.31662 8.78723 7.97852 9.60347 7.97852 10.4546C7.97852 11.3057 8.31662 12.1219 8.91844 12.7237C9.52026 13.3256 10.3365 13.6637 11.1876 13.6637V13.6637Z" stroke="#234F68" stroke-width="1.71152" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)
