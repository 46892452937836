import { Setter } from "solid-js";

const validate = ({ condition, setter, name, description }) => {
  if (condition) {
    setter("errors", { [name]: description });
  } else {
    setter("errors", { [name]: "" });
  }
};

const checkForErrors = (form, setForm) => {
  if (form.validations) {
    Object.keys(form.validations).forEach((key) => {
      if (key === "required") {
        form.validations.required.forEach((requiredField) => {
          if (!form[requiredField]) {
            setForm("errors", { [requiredField]: "חסר שדה חובה" });
          } else {
            setForm("errors", { [requiredField]: false });
          }
        });
      } else if (form.validations[key]) {
        setForm("errors", { [key]: form.validations[key]() });
      } else {
        setForm("errors", { [key]: false });
      }
    });
  }

  const noErrors = Object.keys(form.errors).length === 0;
  if (noErrors) {
    return false;
  }
  const error = Object.keys(form.errors).find((key) => {
    return form.errors[key];
  });

  return error;
};

const handleRequired = (name, value, setter) => {
  if (!value) {
    setter("errors", { [name]: "שדה חובה" });
  } else {
    setter("errors", { [name]: false });
  }
};

const handleRequiredInObject = (name, store, setter) => {
  if (Object.keys(store[name]).every((i) => !store[name][i])) {
    setter("errors", { [name]: "שדה חובה" });
  } else {
    setter("errors", { [name]: false });
  }
};

export { validate, checkForErrors, handleRequired, handleRequiredInObject };
