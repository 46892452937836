export const ShareIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7291 0.291992C14.2715 0.292568 13.8201 0.398567 13.4101 0.601768C13 0.804968 12.6423 1.09991 12.3646 1.4637C12.087 1.82749 11.8969 2.25036 11.8091 2.6995C11.7213 3.14864 11.7381 3.61197 11.8583 4.05355L11.8055 4.07951L5.67167 7.14551L5.61886 7.17147C5.34071 6.8138 4.98465 6.52429 4.57776 6.32495C4.17088 6.12562 3.72388 6.02172 3.27079 6.02116C2.48067 6.02116 1.72291 6.33504 1.1642 6.89374C0.605501 7.45244 0.291626 8.2102 0.291626 9.00033C0.291626 9.79045 0.605501 10.5482 1.1642 11.1069C1.72291 11.6656 2.48067 11.9795 3.27079 11.9795C3.72419 11.9792 4.17153 11.8754 4.57875 11.6761C4.98597 11.4767 5.34232 11.1871 5.62065 10.8292L5.67167 10.8551L11.8055 13.9211L11.8556 13.9462C11.7352 14.3881 11.7183 14.8517 11.8061 15.3012C11.894 15.7506 12.0843 16.1738 12.3623 16.5377C12.6403 16.9017 12.9984 17.1967 13.4089 17.3998C13.8194 17.6028 14.2712 17.7085 14.7291 17.7087C15.5193 17.7087 16.277 17.3948 16.8357 16.8361C17.3944 16.2774 17.7083 15.5196 17.7083 14.7295C17.7083 13.9394 17.3944 13.1816 16.8357 12.6229C16.277 12.0642 15.5193 11.7503 14.7291 11.7503C14.3115 11.7509 13.8988 11.8393 13.5175 12.0097C13.1363 12.1801 12.7952 12.4287 12.5162 12.7395L12.4196 12.6912L6.28666 9.62516L6.19088 9.57772C6.26578 9.19671 6.26548 8.80472 6.18998 8.42383L6.28666 8.37549L12.4196 5.30949L12.5136 5.26204C12.7929 5.57298 13.1345 5.82166 13.5162 5.99192C13.8979 6.16219 14.3112 6.25023 14.7291 6.25033C15.5193 6.25033 16.277 5.93645 16.8357 5.37775C17.3944 4.81905 17.7083 4.06128 17.7083 3.27116C17.7083 2.48103 17.3944 1.72327 16.8357 1.16457C16.277 0.605868 15.5193 0.291992 14.7291 0.291992Z"
      fill="white"
    />
  </svg>
);
