import { For } from "solid-js";

type Props = {
  options: { [key: string]: string };
  active: string | boolean;
  handleChange: (key: string) => void;
  name?: string;
  class?: string;
};
export default (props: Props) => {
  return (
    <div
      class={
        "bg-lightGrey rounded-3xl w-10/12 md:h-[72px] m-auto flex justify-around p-2 " +
        props.class
      }
    >
      <For each={Object.keys(props.options)}>
        {(key) => {
          return (
            <button
              class="flex justify-center items-center"
              onclick={() => {
                props.handleChange(key);
              }}
              class={`${
                key === props.active ? "bg-blue" : "bg-lightGrey"
              } p-2 ${
                key === props.active ? "text-white" : "text-blue"
              } rounded-3xl w-2/3 text-center transition-all`}
            >
              {props.options[key]}
            </button>
          );
        }}
      </For>
    </div>
  );
};
