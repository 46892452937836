import { RealtorHomeAsset } from "../types"

const shareDecodedUrl = (asset: RealtorHomeAsset, agent_phone: string) => {
  const asset_id = asset.id.toString()
  const baseUrl =
    import.meta.env.VITE_PUBLIC_URL +
    "/public-view/asset/" +
    btoa(asset_id);

    const queryParams = {
      agent_phone: agent_phone,
      customer_status: "FA",
    };
    return `*🏠 ${asset.street.name} ${asset.address_number || ""} - ${asset.city.name}*
      ${baseUrl.toString()}?${btoa(
        new URLSearchParams(queryParams).toString()
      )}`;
}

export default shareDecodedUrl