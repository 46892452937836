export const Back = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 0C17.8411 0 23 5.15894 23 11.5C23 17.8411 17.8411 23 11.5 23C5.15894 23 0 17.8411 0 11.5C0 5.15894 5.15894 0 11.5 0ZM11.5 1.725C6.0912 1.725 1.725 6.0912 1.725 11.5C1.725 16.9088 6.0912 21.275 11.5 21.275C16.9088 21.275 21.275 16.9088 21.275 11.5C21.275 6.0912 16.9088 1.725 11.5 1.725ZM12.3793 7.17964C12.6033 7.18617 12.816 7.27961 12.9723 7.44019L16.4223 10.8902C16.584 11.0519 16.6748 11.2713 16.6748 11.5C16.6748 11.7287 16.584 11.9481 16.4223 12.1098L12.9723 15.5598C12.8928 15.6426 12.7976 15.7087 12.6923 15.7542C12.587 15.7997 12.4736 15.8238 12.3589 15.8249C12.2441 15.8261 12.1303 15.8044 12.0241 15.761C11.9178 15.7176 11.8213 15.6535 11.7402 15.5723C11.659 15.4912 11.5949 15.3947 11.5515 15.2884C11.5081 15.1822 11.4864 15.0684 11.4876 14.9536C11.4887 14.8389 11.5128 14.7255 11.5583 14.6202C11.6038 14.5149 11.6699 14.4197 11.7527 14.3402L13.7304 12.3625H7.1875C7.07321 12.3641 6.95973 12.343 6.85367 12.3004C6.74761 12.2578 6.65108 12.1945 6.56969 12.1142C6.4883 12.034 6.42366 11.9384 6.37955 11.8329C6.33543 11.7275 6.31272 11.6143 6.31272 11.5C6.31272 11.3857 6.33543 11.2725 6.37955 11.1671C6.42366 11.0616 6.4883 10.966 6.56969 10.8858C6.65108 10.8055 6.74761 10.7422 6.85367 10.6996C6.95973 10.657 7.07321 10.6359 7.1875 10.6375H13.7304L11.7527 8.65981C11.6283 8.53863 11.5433 8.3827 11.509 8.21244C11.4746 8.04218 11.4925 7.86552 11.5602 7.70557C11.6279 7.54563 11.7423 7.40985 11.8885 7.31601C12.0346 7.22217 12.2057 7.17464 12.3793 7.17964Z"
      fill="#234F68"
    />
  </svg>
);
