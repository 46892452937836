import axios from "axios";
import { RealtorHomeAsset } from "../../types";

export const fetchAsset = async (id: string) => {
  if (!id) {
    return null;
  }
  const { data }: { data: RealtorHomeAsset } = await axios.get(
    "/real-estate/asset/" + id + "/"
  );
  return data;
};

export const fetchPublicAsset = async (id: string, client_status) => {
  if (!id) {
    return null;
  }
  const { data }: { data: RealtorHomeAsset } = await axios.get(
    "/real-estate/public-asset/" + id + "/?customer_status=" + client_status
  );
  return data;
};
