const generateRange = (range: number, offset?: number) => {
  const object = {};
  if (!offset) {
    offset = 0;
  }
  Array.from(Array(range).keys()).forEach((_, i) => {
    object[i + offset + 1] = i + 1 + offset;
  });
  return object;
};

export default generateRange;
