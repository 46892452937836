export const firebaseConfig = {
  apiKey: "AIzaSyD2PfLWFG9F6_s9Z-5nRzZ6y3sBS1zym8U",
  authDomain: "airtable-9177b.firebaseapp.com",
  projectId: "airtable-9177b",
  storageBucket: "airtable-9177b.appspot.com",
  messagingSenderId: "498783398302",
  appId: "1:498783398302:web:3a742d2c500021745d4e8f",
  measurementId: "G-3WFR2RNR9J"
};

// export const firebaseConfig = {
//   apiKey: "AIzaSyD2PfLWFG9F6_s9Z-5nRzZ6y3sBS1zym8U",
//   authDomain: "airtable-9177b.firebaseapp.com",
//   projectId: "airtable-9177b",
//   storageBucket: "airtable-9177b.appspot.com",
//   messagingSenderId: "498783398302",
//   appId: "1:498783398302:web:3a742d2c500021745d4e8f",
//   measurementId: "G-J4YRZB308P",
// };
