import Button from "../../Components/Button";
import { Show } from "solid-js";
import { logEvent } from "../../analytics";
import { RealtorHomeAsset } from "../../types";
import shareWhatsapp from "../../utilities/shareWhatsApp";

export function ShareAssetsButton(props:any) {
  const getAssetsData = () => {
    let ids:Array<string | number> = [],
        names:Array<string> = [],
        assets:Array<RealtorHomeAsset> = [];
    Object.keys(props.pickedAssets)
      .filter((checked) => {
        return props.pickedAssets[checked];
      })
      .forEach((id) => {
        const asset = props.matchQuery.data?.find(
          (element:any) => String(element.id) === String(id)
        );
        if (asset) {
          assets.push(asset)
          ids.push(asset.id)
          let name = ''
          if (asset.street && asset.street.name) {
            name += asset.street.name;
          }
          if (asset.address_number) {
            name += ` ${asset.address_number}`
          }
          names.push(name)
        }
      });
    return {
      ids: ids,
      assets: assets,
      names: names
    }
  }



  return (
    <Show
      when={
        Object.keys(props.pickedAssets).filter((checked) => {
          return props.pickedAssets[checked];
        }).length
      }
    >
      <div class="fixed md:flex md:justify-center md:w-full md:absolute z-30 w-full bottom-32 md:bottom-2 text-center">
        <Button
          onClick={() => {
            if (props.searchPage) {
              props.openShareAssetModal();
              return;
            }
            const assetsEventData = getAssetsData()
            logEvent('button_click', {
              click_type: 'שתף נכסים',
              button_type: 'share_assets',
              asset_id: assetsEventData.ids.join(','),
              asset_name: assetsEventData.names.join(',')
            })
            if (props.openWhatsUpContainer) {
              props.openWhatsUpContainer(assetsEventData.ids, assetsEventData.assets)
            } else {
              const assets:any = [];
              Object.keys(props.pickedAssets)
                .filter((checked) => {
                  return props.pickedAssets[checked];
                })
                .forEach((id) => {
                  const asset = props.matchQuery.data?.find(
                    (element:any) => String(element.id) === String(id)
                  );
                  assets.push(asset);
                });
              shareWhatsapp({
                assets: assets,
                phone: props.phone,
                customerStatus: props.customer.buyer_end_customer_status,
              });
            }
          }}
          color="green"
          class="w-8/12 md:w-3/12 m-auto"
        >
          שתף נכסים
        </Button>
      </div>
    </Show>
  );
}
