export const Edit = (props: { fill?: string; class?: string }) => (
  <svg
    class={props.class}
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8719 0.0234375C13.0688 0.0234375 12.2658 0.327903 11.6572 0.936789L9.94757 2.64637C9.88643 2.68962 9.83296 2.7428 9.78937 2.8037L1.75184 10.8412C1.5639 11.0291 1.42626 11.2628 1.35329 11.5194L0.0256444 16.1683C-0.00709785 16.2834 -0.00851209 16.4051 0.0215481 16.5208C0.0516082 16.6366 0.112051 16.7422 0.196625 16.8268C0.281198 16.9114 0.386831 16.9718 0.502597 17.0019C0.618363 17.0319 0.740058 17.0305 0.855094 16.9978L5.50491 15.6702C5.5052 15.6699 5.50549 15.6696 5.50578 15.6693C5.76064 15.596 5.99379 15.4601 6.18227 15.2716L14.2032 7.24984C14.2781 7.20019 14.3423 7.13599 14.392 7.06105L16.0867 5.36633C17.3044 4.14863 17.3044 2.15449 16.0867 0.936789C15.4781 0.327903 14.6751 0.0234375 13.8719 0.0234375ZM13.8719 1.35807C14.3288 1.35807 14.7849 1.53408 15.1367 1.88598C15.1369 1.88598 15.1372 1.88598 15.1375 1.88598C15.8423 2.59073 15.8423 3.71238 15.1375 4.41714L13.8221 5.73254L11.2909 3.20138L12.6064 1.88598C12.9581 1.53408 13.4151 1.35807 13.8719 1.35807ZM10.3418 4.15056L12.8729 6.68173L5.23221 14.3224C5.20597 14.3485 5.17274 14.3684 5.13519 14.3792L4.69468 14.5051L2.51836 12.3288L2.64422 11.8874C2.64422 11.8871 2.64422 11.8868 2.64422 11.8865C2.65448 11.8504 2.67328 11.8181 2.70103 11.7904L10.3418 4.15056ZM2.09621 13.805L3.21846 14.9272L1.64783 15.3756L2.09621 13.805Z"
      fill={props.fill || "#234F68"}
    />
  </svg>
);
