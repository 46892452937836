import Logo from '../logo.png'
import { JSX, Show, createSignal } from 'solid-js';
import { createEventDispatcher } from '@solid-primitives/event-dispatcher';
import { A } from '@solidjs/router';
interface Props {
	onUploadFile: (evt:CustomEvent<any>) => void;
}

const UploadFile = (props: Props) => {
	const [error, setError] = createSignal("");
	const dispatch = createEventDispatcher(props);
	const checkFile: JSX.EventHandler<HTMLInputElement, InputEvent> = (e) => {
		let validExts = new Array(".xlsx", ".xls", ".csv");
		let fileExt = e.currentTarget.value;
		fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
		if (validExts.indexOf(fileExt) < 0) {
			setError("Invalid file selected, valid files are of " + validExts.toString() + " types");
			return false;
		} else {
			setError('');
		}
		const target = e.currentTarget
		if (target.files && target.files[0]) {
			const file = target.files[0]
			const max_file_size = 4 * 1024 * 1024
			if (file.size < max_file_size) {
				dispatch('uploadFile', file)
			} else {
				setError('Your file is to big, please upload file less then 4MB.')
				return false
			}
		} else {
			setError('Unexpected error!');
		}
	}


	return (
		<div class="single-import_tab">
			<div class="single-import_tab--container">
				<div class="single-import_tab--head">
					<p class="st-logo">
						<img src={Logo} alt="" />
					</p>
					<h2 class="st-title">
						יבוא לקוחות ממאגר חיצוני
					</h2>
				</div>
				<div class="single-import_tab--body">
					<p class="st-quote">
						<span class="icon">
							<InfoIcon/>
						</span>
						בחרו את הקובץ בו שמורים כל הלקוחות שלכם!<br/>
						אנחנו נדאג להעלות את כולם לגשר ולעזור לכם לעשות קצת סדר
					</p>
					<span class="st-upload">
						<input
							type="file"
							class="input--upload"
							accept=".xlsx, .xls, .csv"
							onChange={(e:any) => checkFile(e)}
						/>
						<span class="upload--info">
							העלאת קובץ Excel
							<span class="icon">
								<UploadIcon />
							</span>
						</span>
					</span>
					<Show when={error().length}>
						<div class="text-error text-center h-4">
							{error()}
						</div>
					</Show>
				</div>
				<div class="single-import_tab--actions">
					<button class="btn--next">
						המשך
					</button>
					<A href="/" class="btn--prev">
						הזכירו לי יותר מאוחר
					</A>
				</div>
			</div>
		</div>
	)
}

export default UploadFile;


const InfoIcon = () => (
<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M8.125 0C3.64502 0 0 3.64502 0 8.125C0 12.605 3.64502 16.25 8.125 16.25C12.605 16.25 16.25 12.605 16.25 8.125C16.25 3.64502 12.605 0 8.125 0ZM8.125 1.25C11.9287 1.25 15 4.32129 15 8.125C15 11.9287 11.9287 15 8.125 15C4.32129 15 1.25 11.9287 1.25 8.125C1.25 4.32129 4.32129 1.25 8.125 1.25Z" fill="#234F68"/>
	<path d="M6 6.11133C6.052 4.93777 6.71294 4 8.02131 4C9.19403 4 10 4.84285 10 5.96206C10 6.81463 9.6276 7.41923 9.03196 7.83788C8.44178 8.24506 8.26634 8.55839 8.26634 9.12989V9.47735H7.4581V8.98062C7.45264 8.26306 7.74643 7.77452 8.38352 7.3287C8.92352 6.9419 9.13849 6.59393 9.13849 6.01182C9.13849 5.338 8.68353 4.8442 7.98935 4.8442C7.28716 4.8442 6.84813 5.33518 6.79759 6.11133H6Z" fill="#234F68"/>
	<path d="M7.27628 11.242C7.27628 10.7962 7.54906 10.4841 7.92543 10.4841C8.30179 10.4841 8.56428 10.7962 8.56392 11.242C8.56392 11.6815 8.30324 12 7.92543 12C7.54761 12 7.27628 11.6815 7.27628 11.242Z" fill="#234F68"/>
</svg>
)
const UploadIcon = () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_296_5509)">
	<path d="M11.9994 0.285034L11.6544 0.615034L7.81437 4.45503C7.62313 4.64628 7.62313 4.95378 7.81437 5.14503C8.00562 5.33628 8.31312 5.33628 8.50437 5.14503L11.5194 2.13003V15.36C11.5175 15.5325 11.6075 15.6938 11.7575 15.7819C11.9075 15.8682 12.0912 15.8682 12.2412 15.7819C12.3912 15.6938 12.4812 15.5325 12.4794 15.36V2.13003L15.4944 5.14503C15.6856 5.33628 15.9931 5.33628 16.1844 5.14503C16.3756 4.95378 16.3756 4.64628 16.1844 4.45503L12.3444 0.615034L11.9994 0.285034ZM3.35938 7.68003V24H20.6394V7.68003H15.8394C15.6669 7.67816 15.5056 7.76816 15.4175 7.91816C15.3312 8.06816 15.3312 8.25191 15.4175 8.40191C15.5056 8.55191 15.6669 8.64191 15.8394 8.64003H19.6794V23.04H4.31937V8.64003H8.15937C8.33187 8.64191 8.49312 8.55191 8.58125 8.40191C8.6675 8.25191 8.6675 8.06816 8.58125 7.91816C8.49312 7.76816 8.33187 7.67816 8.15937 7.68003H3.35938Z" fill="#234F68"/>
	</g>
	<defs>
	<clipPath id="clip0_296_5509">
	<rect width="24" height="24" fill="white"/>
	</clipPath>
	</defs>
</svg>
)