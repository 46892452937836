import { Show } from "solid-js";

import { CheckedIcon } from "../Icons/CheckedIcon";
import { EmptyIcon } from "../Icons/EmptyIcon";

type Props = {
  value: boolean;
  onChange: () => void;
  label: string;
};

export default (props: Props) => {
  return (
    <div class="w-fit flex gap-2">
      <button
        onclick={() => {
          props.onChange();
        }}
        class="opacity-0 w-6 h-6 absolute"
      />
      <Show when={props.value}>
        <CheckedIcon /> {props.label}
      </Show>
      <Show when={!props.value}>
        <EmptyIcon /> {props.label}
      </Show>
    </div>
  );
};
